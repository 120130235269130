import { Form, Input } from "antd";
import React from "react";

const FormPassword = () => {
  return (
    <Form.Item
      name="password"
      label="Password"
      rules={[
        {
          required: true,
          message: "Please Enter the Password",
        },
      ]}
    >
      <Input.Password style={{ fontSize: "16px" }} />
    </Form.Item>
  );
};

export default FormPassword;
