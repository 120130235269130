import { Radio } from "antd";
import { Box } from "native-base";
import React, { useState } from "react";
import QuestionForm from "./simple_questions_form/add_question_form";
import QuestionName from "./simple_questions_form/question_name";
import AddComplexQuestionForm from "./complex_question_form/add_complex_question_form";

const SelectQuestion = ({ form }) => {

  return (
    <Box style={{  marginTop: "2px" }}>
      <QuestionForm
        form={form}
      />
    </Box>
  );
};

export default SelectQuestion;
