import { Box, HStack, Text, VStack } from "native-base";
import React from "react";

const SectorHeader = ({ list, index }) => {
  return (
    <div style={{ cursor: "move" }}>
      <HStack width={"100%"}>
        {/* <HStack space={2}>
        <Text color={"white"} bold>
          Sector number:
        </Text>
        <Text color={"white"}>{list?.sector?.order_number}</Text>
      </HStack> */}
        <Box flex={1}>
          <HStack space={2}>
            <Box width={"80px"}>
              <Text color={"white"} bold>
                Name:
              </Text>
            </Box>
            <Box flex={1}>
              <Text color={"white"}>{list?.sector?.name}</Text>
            </Box>
          </HStack>
        </Box>
        <Box width={"250px"}>
          <HStack space={2}>
            <Box width={"100px"}>
              <Text color={"white"} bold>
                Weight:
              </Text>
            </Box>
            <Text color={"white"}>{list?.weight}</Text>
          </HStack>
        </Box>
      </HStack>
    </div>
  );
};

export default SectorHeader;
