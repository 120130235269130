import { Button, Select, Table } from "antd";
import { Box, HStack, Text } from "native-base";
import React from "react";

const DepartmentPendency = () => {
  const items = [
    {
      district: {
        name: "Ariyalur",
        sector: [
          {
            name: "Agriculture & Allied",
            indicator: [
              {
                name: "Growth rate of Major Food Grain (Rice)",
                question: [
                  {
                    name: "Total productivity of Rice in the reference year numerator 1",
                    answer: { value: 23 },
                  },
                  {
                    name: "Total productivity of Rice in the previous year numerator 2",
                    answer: { value: 34 },
                  },
                  {
                    name: "Total productivity of Rice in the previous year denominator",
                    answer: { value: 34 },
                  },
                ],
              },
              {
                name: "Percentage of farmers issued with Soil Health Card",
                question: [
                  {
                    name: "No. of  Soil Health Cards issued to Farmers numerator",
                    answer: { value: 34 },
                  },
                  {
                    name: "Total No. of  Holdings denominator",
                    answer: { value: 34 },
                  },
                  {
                    name: "total amount of agricultural credit during previous year  numerator 2",
                    answer: { value: 34 },
                  },
                ],
              },
            ],
          },
          {
            name: "Industry & Commerce",
            indicator: [
              {
                name: "Percentage of MSME units registered under online Udyam",
                question: [
                  {
                    name: "No. of MSME units registered under online Udyam numerator",
                    answer: { value: 12 },
                  },
                  {
                    name: "Total No. of MSME units denominator",
                    answer: { value: 13.2 },
                  },
                ],
              },
              {
                name: "Percentage of MSMEs including startups with financial assistance - GoI over the State",
                question: [
                  {
                    name: "No. of MSMEs including startups with financial assistance - GOI numerator",
                    answer: { value: 123.2 },
                  },
                  {
                    name: "Total No. of MSME units denominator",
                    answer: { value: 1.009 },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      district: {
        name: "Chennai",
        sector: [
          {
            name: "Agriculture & Allied",
            indicator: [
              {
                name: "Growth rate of Major Food Grain (Rice)",
                question: [
                  {
                    name: "Total productivity of Rice in the reference year numerator 1",
                    answer: { value: 23 },
                  },
                  {
                    name: "Total productivity of Rice in the previous year numerator 2",
                    answer: { value: 34 },
                  },
                  {
                    name: "Total productivity of Rice in the previous year denominator",
                    answer: { value: 34 },
                  },
                ],
              },
              {
                name: "Percentage of farmers issued with Soil Health Card",
                question: [
                  {
                    name: "No. of  Soil Health Cards issued to Farmers numerator",
                    answer: { value: 34 },
                  },
                  {
                    name: "Total No. of  Holdings denominator",
                    answer: { value: 34 },
                  },
                  {
                    name: "total amount of agricultural credit during previous year  numerator 2",
                    answer: { value: 34 },
                  },
                ],
              },
            ],
          },
          {
            name: "Industry & Commerce",
            indicator: [
              {
                name: "Percentage of MSME units registered under online Udyam",
                question: [
                  {
                    name: "No. of MSME units registered under online Udyam numerator",
                    answer: { value: 12 },
                  },
                  {
                    name: "Total No. of MSME units denominator",
                    answer: { value: 13.2 },
                  },
                ],
              },
              {
                name: "Percentage of MSMEs including startups with financial assistance - GoI over the State",
                question: [
                  {
                    name: "No. of MSMEs including startups with financial assistance - GOI numerator",
                    answer: { value: 123.2 },
                  },
                  {
                    name: "Total No. of MSME units denominator",
                    answer: { value: 1.009 },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  ];

  const XLSX = require("xlsx");

  // function downloadExcelReport() {
  //   // Create a new workbook
  //   const wb = XLSX.utils.book_new();
  //   const ws = XLSX.utils.aoa_to_sheet([
  //     ["District", "Sector", "Indicator", "Question", "Answer"],
  //   ]);

  //   // Set wrap text style for all columns
  //   ws["!cols"] = [
  //     { wch: 15 }, // Sector
  //     { wch: 15 }, // Indicator
  //     { wch: 30 }, // Question
  //     { wch: 30 }, // Source Department
  //     { wch: 15 }, // Answer
  //   ];

  //   // Add worksheet to workbook
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   // Iterate over items and add data to the worksheet
  //   items.forEach((item) => {
  //     const districtName = item.district.name;

  //     item.district.sector.forEach((sector, sectorIndex) => {
  //       const sectorName = sector.name;

  //       sector.indicator.forEach((indicators, indicatorIndex) => {
  //         const IndicatorName = indicators.name;
  //         const questionsName = indicators.question
  //           ? indicators.question.map((quest) => quest.name)
  //           : [];
  //         const answerValues = indicators.question
  //           ? indicators.question.map((quest) =>
  //               quest.answer ? quest.answer.value : ""
  //             )
  //           : [];

  //         // Include district name and sector name for the first indicator in each sector
  //         const districtData =
  //           sectorIndex === 0 && indicatorIndex === 0 ? districtName : "";
  //         const sectorData = indicatorIndex === 0 ? sectorName : "";
  //         const indicatorData = IndicatorName;

  //         // If it's the first indicator in the sector, add district, sector, indicator, question, source department, and answer names
  //         if (indicatorIndex === 0) {
  //           const data = [
  //             districtData,
  //             sectorData,
  //             indicatorData,
  //             questionsName[0],
  //             answerValues[0],
  //           ];
  //           XLSX.utils.sheet_add_aoa(ws, [data], { origin: -1 });
  //         }

  //         // Add source department and answer values for each question (excluding the first question in the indicator)
  //         if (indicatorIndex !== 0) {
  //           questionsName.forEach((quest, questIndex) => {
  //             const data = [
  //               districtData,
  //               sectorData,
  //               indicatorData,
  //               quest,
  //               answerValues[questIndex],
  //             ];
  //             XLSX.utils.sheet_add_aoa(ws, [data], { origin: -1 });
  //           });
  //         }
  //       });
  //     });
  //   });

  //   // Write the workbook to a file
  //   XLSX.writeFile(wb, "Department Pendency Report.xlsx");

  // }

  function downloadExcelReport() {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ["District", "Sector", "Indicator", "Question", "Answer"],
    ]);

    // Set wrap text style for all columns
    ws["!cols"] = [
      { wch: 15 }, // Sector
      { wch: 15 }, // Indicator
      { wch: 30 }, // Question
      { wch: 30 }, // Source Department
      { wch: 15 }, // Answer
    ];

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Iterate over items and add data to the worksheet
    items.forEach((item) => {
      const districtName = item.district.name;

      item.district.sector.forEach((sector, sectorIndex) => {
        const sectorName = sectorIndex === 0 ? sector.name : ""; // Include sector name only for the first sector

        sector.indicator.forEach((indicators, indicatorIndex) => {
          const IndicatorName = indicators.name;
          const questions = indicators.question || [];

          // Include district name, sector name, and indicator name for the first question in each indicator
          if (questions.length > 0) {
            const data = [
              districtName,
              sectorName,
              IndicatorName,
              questions[0].name,
              questions[0].answer ? questions[0].answer.value : "",
            ];
            XLSX.utils.sheet_add_aoa(ws, [data], { origin: -1 });
          }

          // Add remaining questions and answers
          for (
            let questIndex = 1;
            questIndex < questions.length;
            questIndex++
          ) {
            const data = [
              "", // Empty for district name
              "", // Empty for sector name
              "", // Empty for indicator name
              questions[questIndex].name,
              questions[questIndex].answer
                ? questions[questIndex].answer.value
                : "",
            ];
            XLSX.utils.sheet_add_aoa(ws, [data], { origin: -1 });
          }
        });
      });
    });

    // Write the workbook to a file
    XLSX.writeFile(wb, "Department Pendency Report.xlsx");
  }

  const columns = [
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      render: (record) => {
        return record.name;
      },
    },
  ];

  const data = items?.map((item, index) => ({ ...item, key: index }));

  const expandedRowRender = (record) => {
    const sectorColumns = [
      {
        title: "Sector",
        dataIndex: "name",
        key: "name",
      },
    ];

    return (
      <div>
        <Table
          columns={sectorColumns}
          dataSource={record?.district?.sector}
          pagination={false}
          expandable={{
            expandedRowRender: (indicatorRecord) =>
              expandableRenderForIndicator(indicatorRecord),
          }}
        />
      </div>
    );
  };

  const expandableRenderForIndicator = (record) => {
    const indicatorColumns = [
      {
        title: "Indicator",
        dataIndex: "name",
        key: "name",
      },
    ];
    return (
      <Table
        columns={indicatorColumns}
        dataSource={record.indicator}
        pagination={false}
        expandable={{
          expandedRowRender: (questionRecord) =>
            expandableRenderForQuestion(questionRecord),
        }}
      />
    );
  };
  const expandableRenderForQuestion = (record) => {
    const questionColumns = [
      {
        title: "Question",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Answer",
        dataIndex: "answer",
        key: "answer",
        render: (value) => <div>{value?.value}</div>,
      },
    ];
    return (
      <Table
        columns={questionColumns}
        dataSource={record.question}
        pagination={false}
      />
    );
  };
  return (
    <div>
      <HStack justifyContent={"space-between"} m="5" alignItems={"center"}>
        <HStack space={"5"} mb="5">
          <Box width={"200px"}>
            <Text>{"Survey"}</Text>
            <Select />
          </Box>
          <Box width={"200px"}>
            <Text>{"Department"}</Text>
            <Select />
          </Box>
        </HStack>
        <Button
          onClick={() => {
            downloadExcelReport();
          }}
        >
          Download
        </Button>
      </HStack>

      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{ expandedRowRender }}
      />
    </div>
  );
};

export default DepartmentPendency;
