import { Button, Select, Table } from "antd";
import { Box, HStack, Text } from "native-base";
import React from "react";

const DistrictPendency = () => {
  const items = [
    {
      sector: {
        name: "Agriculture & Allied",
        indicator: [
          {
            name: "Growth rate of Major Food Grain (Rice)",
            question: [
              {
                name: "Total productivity of Rice in the reference year numerator 1",

                source_department: [{ name: "DES", answer: { value: 23 } }],
              },
              {
                name: "Total productivity of Rice in the previous year numerator 2",

                source_department: [{ name: "DES", answer: { value: 34 } }],
              },
              {
                name: "Total productivity of Rice in the previous year denominator",
                source_department: [{ name: "DES" }],
              },
            ],
          },
          {
            name: "Percentage of farmers issued with Soil Health Card",
            question: [
              {
                name: "No. of  Soil Health Cards issued to Farmers numerator",
                source_department: [
                  { name: "DES", answer: { value: 34 } },
                  { name: "Agriculture Department", answer: { value: 34 } },
                ],
              },
              {
                name: "Total No. of  Holdings denominator",
                source_department: [
                  { name: "DES", answer: { value: 34 } },
                  { name: "Agriculture Department", answer: { value: 34 } },
                ],
              },
              {
                name: "total amount of agricultural credit during previous year  numerator 2",

                source_department: [
                  { name: "DES", answer: { value: 34 } },
                  { name: "Agriculture Department" },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      sector: {
        name: "Industry & Commerce",
        indicator: [
          {
            name: "Percentage of MSME units registered under online Udyam",
            question: [
              {
                name: "No. of MSME units registered under online Udyam numerator",
                source_department: [
                  { name: "DES", answer: { value: 12 } },
                  { name: "Industries & Commerce Department" },
                ],
              },
              {
                name: "Total No. of MSME units denominator",
                source_department: [
                  { name: "DES", answer: { value: 13.2 } },
                  { name: "Industries & Commerce Department" },
                ],
              },
            ],
          },
          {
            name: "Percentage of MSMEs including startups with financial assistance - GoI over the State",
            question: [
              {
                name: "No. of MSMEs including startups with financial assistance - GOI numerator",
                source_department: [
                  { name: "DES", answer: { value: 123.2 } },
                  { name: "Industries & Commerce Department" },
                ],
              },
              {
                name: "Total No. of MSME units denominator",
                source_department: [
                  { name: "DES", answer: { value: 1.009 } },
                  { name: "Industries & Commerce Department" },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      sector: {
        name: "Human Resource Development",
        indicator: [
          {
            name: "Percentage of Beneficiaries under Integrated Child Development Services (ICDS)",
            question: [
              {
                name: "Number of children beneficiaries (0-6 years) registered with ICDS numerator",
                source_department: [{ name: "ICDS", answer: { value: 12 } }],
              },
              {
                name: "Total Child population aged (0-6 years) denominator",
                source_department: [
                  {
                    name: "ICDS",

                    answer: { value: 13.2 },
                  },
                ],
              },
            ],
          },
          {
            name: "Percentage of Children covered under Noon Meal Scheme",
            question: [
              {
                name: "Number of children covered under Noon Meal Scheme in Govt. and Aided schools numerator",
                answer: { value: 123.2 },
                source_department: [{ name: "ICDS" }],
              },
              {
                name: "Total children in Govt. and Aided Schools upto X standard denominator",
                source_department: [
                  { name: "RD & PR", answer: { value: 1.009 } },
                  { name: "Corporation", answer: { value: 1.009 } },
                  { name: "Municipality", answer: { value: 1.009 } },
                  { name: "Town Panchayat", answer: { value: 1.009 } },
                ],
              },
            ],
          },
        ],
      },
    },
  ];
  const XLSX = require("xlsx");

  //   function downloadExcelReport() {
  //     // Create a new workbook
  //     const wb = XLSX.utils.book_new();
  //     const ws = XLSX.utils.aoa_to_sheet([
  //       ["Sector", "Indicator", "Question", "Answer", "Source Department"],
  //     ]);

  //     // Set wrap text style for all columns
  //     ws["!cols"] = [
  //       { wch: 15 }, // Sector
  //       { wch: 15 }, // Indicator
  //       { wch: 30 }, // Question
  //       { wch: 15 }, // Answer
  //       { wch: 30 }, // Source Department
  //     ];

  //     // Add worksheet to workbook
  //     XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //     // Iterate over items and add data to the worksheet
  //     items.forEach((item) => {
  //       const sectorName = item.sector.name;

  //       item.sector.indicator.forEach((indicator, indicatorIndex) => {
  //         const indicatorName = indicator.name;

  //         indicator.question.forEach((question, questionIndex) => {
  //           const questionName = question.name;
  //           const answerValue = question.answer ? question.answer.value : "";
  //           const sourceDepartments = question.source_department
  //             ? question.source_department.map((dept) => dept.name).join(", ")
  //             : "";

  //           // Include sector name and indicator name only for the first question in each indicator
  //           const sectorData =
  //             indicatorIndex === 0 && questionIndex === 0 ? sectorName : "";
  //           const indicatorData = questionIndex === 0 ? indicatorName : "";

  //           // If it's the first question in the indicator, add sector, indicator, and source department names
  //           if (indicatorIndex === 0 && questionIndex === 0) {
  //             const data = [
  //               sectorData,
  //               indicatorData,
  //               questionName,
  //               answerValue,
  //               sourceDepartments,
  //             ];
  //             XLSX.utils.sheet_add_aoa(ws, [data], { origin: -1 });
  //           } else {
  //             // If not the first question, only add question, answer, and source department data
  //             const data = [
  //               "",
  //               indicatorData,
  //               questionName,
  //               answerValue,
  //               sourceDepartments,
  //             ];
  //             XLSX.utils.sheet_add_aoa(ws, [data], { origin: -1 });
  //           }
  //         });
  //       });
  //     });

  //     // Write the workbook to a file
  //     XLSX.writeFile(wb, "District Pendency Report.xlsx");

  //   }
  function downloadExcelReport() {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ["Sector", "Indicator", "Question", "Source Department", "Answer"],
    ]);

    // Set wrap text style for all columns
    ws["!cols"] = [
      { wch: 15 }, // Sector
      { wch: 15 }, // Indicator
      { wch: 30 }, // Question
      { wch: 30 }, // Source Department
      { wch: 15 }, // Answer
    ];

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Iterate over items and add data to the worksheet
    items.forEach((item) => {
      const sectorName = item.sector.name;

      item.sector.indicator.forEach((indicator, indicatorIndex) => {
        const indicatorName = indicator.name;

        indicator.question.forEach((question, questionIndex) => {
          const questionName = question.name;
          const sourceDepartments = question.source_department
            ? question.source_department.map((dept) => dept.name)
            : [];
          const answerValues = question.source_department
            ? question.source_department.map((dept) =>
                dept.answer ? dept.answer.value : ""
              )
            : [];

          // Include sector name, indicator name, and question name for the first question in each indicator
          const sectorData = questionIndex === 0 ? sectorName : "";
          const indicatorData = questionIndex === 0 ? indicatorName : "";

          // If it's the first question in the indicator, add sector, indicator, question, source department, and answer names
          if (questionIndex === 0) {
            const data = [
              sectorData,
              indicatorData,
              questionName,
              sourceDepartments[0],
              answerValues[0],
            ];
            XLSX.utils.sheet_add_aoa(ws, [data], { origin: -1 });
          }

          // Add source department and answer values for each question (excluding the first question in the indicator)
          if (questionIndex !== 0) {
            sourceDepartments.forEach((dept, deptIndex) => {
              const data = [
                sectorData,
                indicatorData,
                questionName,
                dept,
                answerValues[deptIndex],
              ];
              XLSX.utils.sheet_add_aoa(ws, [data], { origin: -1 });
            });
          }
        });
      });
    });

    // Write the workbook to a file
    XLSX.writeFile(wb, "District Pendency Report.xlsx");
  }

  const columns = [
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      render: (record) => {
        return record.name;
      },
    },
  ];

  const data = items?.map((item, index) => ({ ...item, key: index }));

  const expandedRowRender = (record) => {
    const indicatorColumns = [
      {
        title: "Indicator",
        dataIndex: "name",
        key: "name",
      },
    ];

    return (
      <div>
        <Table
          columns={indicatorColumns}
          dataSource={record?.sector?.indicator}
          pagination={false}
          expandable={{
            expandedRowRender: (indicatorRecord) =>
              expandableRenderForQuestion(indicatorRecord),
          }}
        />
      </div>
    );
  };

  const expandableRenderForQuestion = (record) => {
    const questionColumns = [
      {
        title: "Question",
        dataIndex: "name",
        key: "name",
      },
    ];
    return (
      <Table
        columns={questionColumns}
        dataSource={record.question}
        pagination={false}
        expandable={{
          expandedRowRender: (departmentRecord) =>
            expandableRenderForDepartment(departmentRecord),
        }}
      />
    );
  };
  const expandableRenderForDepartment = (record) => {
    const DepartmentColumns = [
      {
        title: "Department",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Answer",
        dataIndex: "answer",
        key: "answer",
        render: (value) => <div>{value?.value}</div>,
      },
    ];
    return (
      <Table
        columns={DepartmentColumns}
        dataSource={record.source_department}
        pagination={false}
      />
    );
  };
  return (
    <div>
      <HStack justifyContent={"space-between"} m="5" alignItems={"center"}>
        <HStack space={"5"} mb="5">
          <Box width={"200px"}>
            <Text>{"Survey"}</Text>
            <Select />
          </Box>
          <Box width={"200px"}>
            <Text>{"Department"}</Text>
            <Select />
          </Box>
        </HStack>
        <Button
          onClick={() => {
            downloadExcelReport();
          }}
        >
          Download
        </Button>
      </HStack>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{ expandedRowRender }}
      />
    </div>
  );
};

export default DistrictPendency;
