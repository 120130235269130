import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  FileOutlined,
} from "@ant-design/icons";
// import "antd/dist/antd.min.css";
import { Form, Input, Select, Upload, Popconfirm, Button } from "antd";
import { Box, HStack, Pressable, Text } from "native-base";
import { useEffect, useState } from "react";
import axios from "axios";
//   import DocumentViewer from "../../form/document_viewer";
import { GrAttachment } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";
import toast from "react-hot-toast";
import { file_upload } from "@services/redux/slices/dynamic_entity/rest/rest_file";
import { getUUID, showToast } from "@helpers/functions";
import { QueryRequest } from "@helpers/constants";
import { axiosInstance } from "@services/service_call/service_call";
// import { useDispatch } from "react-router-dom";

// import { showToast } from "@helpers/toast";
// import TextEditor from "../quill_editor/quill_editor";

const { TextArea } = Input;
const { Option } = Select;

const ApiCallFunction = async (url, variables) => {
  try {
    const response = await axiosInstance({
      headers: { Authorization: `Bearer ` },
      method: "POST",
      url: url,
      data: variables,
    });

    if (response) {
      return response?.data;
    } else {
      throw new Error("Response not as expected");
    }
  } catch (error) {
    throw error;
  }
};

const validateInput = (rule, value, callback) => {
  const allowedPattern = /^[^<>]+$/;
  if (!allowedPattern.test(value)) {
    callback("Invalid characters entered.");
  } else {
    callback();
  }
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
let imageFileUploadresponse;

const ImageFile = (props) => {
  const [fileList, setFileList] = useState([]);
  const [headers, setHeaders] = useState();

  const handleBeforeUpload = async ({ name, type }) => {
    let extension = name.split(".")[1];
    let fileupload = {
      type: extension,
      file_name: getUUID() + "." + extension,
    };
    let file_type = name.split(".");
    let format_list = props?.allowFileFormats?.find(
      (format) => format === file_type?.[1]
    );
    if (format_list?.toString()?.length !== 0 && format_list) {
      imageFileUploadresponse = await ApiCallFunction(file_upload, fileupload);
    } else {
      showToast({
        type: "error",
        message: "Document is not in valid file format",
      });
    }
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (file.status === "done") {
      const newFile = {
        ...file,
        name: getUUID(),
        url: imageFileUploadresponse?.data?.url,
      };
      setFileList([newFile]);
      showToast({
        type: "success",
        message: "Document uploaded successfully",
      });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(imageFileUploadresponse?.data?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url = imageFileUploadresponse?.data?.url.split("?")[0];
          onSuccess(null, file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const action = imageFileUploadresponse?.data?.getUploadUrl;
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      extra=""
    >
      <Upload
        maxCount={props.maxCount ? props.maxCount : 1}
        action={action}
        headers={headers}
        fileList={fileList || []}
        disabled={props.isdisabled}
        listType="picture-card"
        showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
        customRequest={(e) => handleUpload(e)}
        beforeUpload={(args) => handleBeforeUpload(args)}
        onChange={(e) => handleFileChanged(e)}
      >
        {fileList?.length > 0 ? null : (
          <Button
            style={{
              width: "100%",
              height: "100%",
            }}
            icon={<UploadOutlined />}
          >
            Upload
          </Button>
        )}
      </Upload>
    </Form.Item>
  );
};

const FileUpload = (props) => {
  const { children } = props;
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState(props.initialValues);
  useEffect(() => {
    form.setFieldsValue(props.initialValues);
    setInitialValues(props.initialValues);
  }, [form, props.initialValues]);
  return (
    <Form
      form={form}
      name={props.id}
      layout={"vertical"}
      onFinish={props.onSubmit}
      onValuesChange={props.onValueChange}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );
};
FileUpload.File = ImageFile;
export default FileUpload;
