// src/components/Card.js
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const data = [
  {
    district: "Chennai",
    dep: "Agriculture Department",
    Survey: "Annual Survey",
    sector: {
      name: "Human Resource Development",
      indicator: [
        {
          name: "Percentage of Gross sown area of all crops",
          question:
            "Total Number of animals (Livestock) vaccinated during the reference year",
        },
      ],
    },
  },
  // Duplicate the above structure to create 9 more entries
  {
    district: "Thiruvallur",
    dep: "Agriculture Marketing Department - District Office",
    Survey: "Annual Survey",
    sector: {
      name: "financeSector",
      indicator: [
        {
          name: "Percentage of Gross sown area of all crops",
          question:
            "Total Number of animals (Livestock) vaccinated during the reference year",
        },
      ],
    },
  },
  {
    district: "Vellore",
    dep: "Agriculture Marketing Department - District Office",
    Survey: "Annual Survey",
    sector: {
      name: "financeSector",
      indicator: [
        {
          name: "Percentage of Gross sown area of all crops",
          question:
            "Total Number of animals (Livestock) vaccinated during the reference year",
        },
      ],
    },
  },
  // Add 7 more entries with similar structures
  // ...
];

for (let i = 1; i < 10; i++) {
  data.push({
    district: `district${i}`,
    dep: `Department${i}`,
    Survey: `Survey${i}`,
    sector: {
      name: `sector${i}`,
      indicator: [{ name: `indicator${i}`, question: `question${i}` }],
    },
  });
}
const colorPairs = [
  { background: "rgb(197, 227, 252)", color: "#d8e3ec" },
  { background: "rgb(207, 173, 245)", color: "#96dbec" },
  { background: "rgb(238, 170, 228)", color: "#a0ecea" },
  { background: "rgb(250, 156, 165)", color: "#e4bcef" },
];

const getColorPairBasedOnIndex = (index) => {
  return colorPairs[index % colorPairs.length];
};

const Card = () => {
  return (
    <>
      <VerticalTimeline>
        {data.map((item, index) => (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={getColorPairBasedOnIndex(index)}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            // date="2011 - present"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M9 16.17L5.53 12.7a.996.996 0 1 1 1.41-1.41l2.95 2.95 7.07-7.07a.996.996 0 1 1 1.41 1.41L10.41 16.17a.996.996 0 0 1-1.41 0z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            }
          >
            <VStack space={1}>
              <HStack>
                <Box>
                  <h1 className="vertical-timeline-element-title">
                    {item.district}
                  </h1>
                </Box>
                <Box flex="1" textAlign="end">
                  <Text fontWeight={"bold"}>Submitted</Text>
                </Box>
              </HStack>
              <VStack space={1}>
                <HStack>
                  <Box>
                    <Text fontWeight={"bold"}>{item.dep + " "}</Text>
                  </Box>
                  <Box>
                    <Text>Submitited Answer for</Text>
                  </Box>
                </HStack>
                <VStack space={1}>
                  <Box>
                    <Text fontWeight={"bold"}>{item.Survey}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight={"bold"}>
                      {item.sector.indicator.map((x) => x.name)}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight={"bold"}>
                      {item.sector.indicator.map((x) => x.question)}
                    </Text>
                  </Box>
                </VStack>
              </VStack>
            </VStack>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </>
  );
};

export default Card;
