import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import {
  Box,
  NativeBaseProvider,
  extendTheme,
  theme as nbTheme,
} from "native-base";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import App from "./App";
import "./i18n";
import { Toaster } from "react-hot-toast";
import { store } from "@services/redux";
import "./assets/fonts/Montserrat-Regular.ttf";

const theme = extendTheme({
  colors: {
    primary: {
      50: "#024724",
      100: "#024724",
      200: "#024724",
      300: "#024724",
      400: "#024724",
      500: "#024724",
      600: "#024724",
      700: "#024724",
      800: "#024724",
      900: "#024724",
    },
    primaryContent: nbTheme.colors.warmGray,
    inputBorder: nbTheme.colors.coolGray,
    error: nbTheme.colors.red,
  },
  breakpoints: {
    base: 0,
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    "2xl": 2560,
    "3xl": 3840,
  },
  components: {
    Box: {
      variants: {
        text_book_board_heading_box: () => {
          return {
            marginBottom: "20px",
          };
        },
        text_book_board_top_box: () => {
          return {
            width: "100%",
            mt: "10px",
            marginBottom: "20px",
          };
        },
      },
    },
    Text1: {
      variants: {
        text_book_board_heading_text: () => {
          return {
            fontSize: "md",
            fontWeight: "bold",
          };
        },
      },
    },
    Text: {
      baseStyle: {
        fontSize: "14px",
      },
    },
    VStack: {
      variants: {
        text_book_board_top_vstack: () => {
          return {
            space: "5",
          };
        },
      },
    },
    HStack: {
      variants: {
        add_text_book_board_button_hstack: () => {
          return {
            display: "flex",
            space: "3",
            justifyContent: "flex-end",
            marginLeft: "-363px",
            marginBottom: "20px",
            marginTop: "20px",
            marginRight: "20px",
            height: "40px",
            // width: "50%"
          };
        },
        submit_hstack: () => {
          return {
            space: "10",
            justifyContent: "flex-end",
          };
        },
      },
    },
  },
  fonts: {
    heading: "Roboto",
    body: "Roboto",
    mono: "Roboto",
    project_header: "revert",
  },
});
ReactDOM.render(
  <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <IconContext.Provider value={{ className: "global-class-name" }}>
        <App />
        <ToastContainer newestOnTop pauseOnFocusLoss />
        {/* <Toaster position="top-center" reverseOrder={false} /> */}
      </IconContext.Provider>
    </NativeBaseProvider>
  </Provider>,
  document.getElementById("root")
);
