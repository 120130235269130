import React, { useEffect } from "react";
import FormName from "./form_name";
import { Form } from "antd";
import { CallService, MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import {
  department_create,
  department_update,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import FormDepartment from "./form_department";
import FormLevel from "./form_level";
import FormArea from "./form_area";
import {
  department_office_create,
  department_office_update,
} from "@services/redux/slices/dynamic_entity/rest/rest_department_office";

const DepartmentOfficerForm = ({ initialValues }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { items: Department_list, loading: question_loading } =
    useDynamicSelector("get_all_department");

  const on_finish = (values) => {
    if (initialValues?.id) {
      let key = [{ key: "department_office_update", loading: true }];
      values.id = initialValues.id;
      delete values.id;
      let query = `${department_office_update}${initialValues.id}`;
      let variables = values;
      dispatch(dynamicRequest(key, query, variables, MethodType.PATCH));
    } else {
      let key = [{ key: "department_office_create", loading: true }];
      let query = department_office_create;
      let variables = values;

      dispatch(dynamicRequest(key, query, variables, MethodType.PUT));
    }
  };
  const get_all_department_list = (data) => {
    let keys = [{ key: "get_all_department", loading: true }];
    dispatch(dynamicRequest(keys, get_all_department, {}, MethodType.POST));
  };

  useEffect(() => {
    get_all_department_list();
  }, []);
  return (
    <Form
      id="department_form"
      onFinish={on_finish}
      form={form}
      initialValues={initialValues}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 20,
      }}
      style={{ width: "100%" }}
    >
      <FormName />
      <FormDepartment Department_list={Department_list} />
      <FormLevel />
      <FormArea form={form} />
    </Form>
  );
};

export default DepartmentOfficerForm;
