import { departments } from "@helpers/config";
import { CallService, MethodType } from "@helpers/service_call";
import { showToast } from "@helpers/toast";
import { useDynamicSelector } from "@services/redux";
import {
  department,
  department_delete,
  dynamicClear,
  dynamicRequest,
  get_all_department,
} from "@services/redux/slices/dynamic_entity";
import { Button, Modal, Space, Table, Typography, message } from "antd";
import { Box } from "native-base";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import CustomDeleteModal from "../common/custom_delete_modal";
import { paginationOptions } from "@helpers/constants";

const DepartmentList = (props) => {
  const {
    delete_record_modal,
    set_add_modal_open,
    set_header_type,
    setInitialValues,
  } = props;
  const dispatch = useDispatch();
  const department_list = useDynamicSelector("get_all_department");
  const create_response = useDynamicSelector("department");
  const update_response = useDynamicSelector("department_update");
  const {
    status: delete_status,
    error: delete_error,
    loading: delete_loading,
  } = useDynamicSelector("department_delete");
  const [page, setPage] = React.useState(1);
  const [delete_record, set_delete_record] = useState({});
  const [delete_modal_open, set_delete_modal_open] = useState(false);
  const handle_row_click = (record) => {
    set_add_modal_open(true);
    set_header_type("Edit");
    setInitialValues({
      ...record, logo: record.logo ? [
        {
          name: "view",
          uid: 1,
          url: record?.logo,
          status: "done",
        },
      ] : []
    });
  };
  const get_all_department_values = (data) => {
    let keys = [{ key: "get_all_department", loading: true }];
    dispatch(dynamicRequest(keys, get_all_department, {}, MethodType.POST));
  };

  useEffect(() => {
    get_all_department_values();
  }, []);

  useEffect(() => {
    if (create_response?.status === "success") {
      // showToast({
      //   type: "success",
      //   message: "Department created successfully",
      // });
      message.success("Department created successfully");
      get_all_department_values();
      set_add_modal_open(false);
      dispatch(dynamicClear("department"));
    } else if (create_response?.error) {
      // showToast({
      //   type: "error",
      //   message: "Something went wrong",
      // });
      message.error("Something went wrong");
      dispatch(dynamicClear("department"));
    } else if (update_response?.status === "success") {
      // showToast({
      //   type: "success",
      //   message: "Department updated successfully",
      // });
      message.success("Department updated successfully");
      get_all_department_values();
      set_add_modal_open(false);
      dispatch(dynamicClear("department_update"));
    } else if (update_response?.error) {
      // showToast({
      //   type: "error",
      //   message: "Something went wrong",
      // });
      message.error("Something went wrong");
      dispatch(dynamicClear("department_update"));
    } else if (delete_status) {
      // showToast({
      //   type: "success",
      //   message: "Department updated successfully",
      // });
      message.success("Department deleted successfully");
      get_all_department_values();
      set_add_modal_open(false);
      set_delete_modal_open(false);
      dispatch(dynamicClear("department_delete"));
    } else if (delete_error) {
      // showToast({
      //   type: "error",
      //   message: "Something went wrong",
      // });
      message.error("Something went wrong");
      dispatch(dynamicClear("department_delete"));
    }
  }, [
    create_response?.status,
    create_response?.error,
    update_response?.status,
    update_response?.error,
    delete_error,
    delete_status,
  ]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      width: "300px",
      onCell: (record) => ({
        onClick: () => {
          handle_row_click(record);
        },
      }),
    },
    {
      title: "Action",
      dataIndex: "",
      align: "left",
      width: "30px",
      render: (_, record) => (
        <>
          <Space>
            {/* <Button
              type="link"
              onClick={() => {
                handle_row_click(record);
              }}
            >
              <FiEdit />
            </Button> */}

            <Button
              type="link"
              danger
              onClick={() => {
                delete_record_modal(record);
                set_delete_record(record);
                set_delete_modal_open(true);
              }}
            >
              <AiFillDelete />
            </Button>
          </Space>
        </>
      ),
    },
  ];
  const delete_function = () => {
    let keys = [{ key: "department_delete", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        department_delete + delete_record?.id,
        {},
        MethodType.DELETE
      )
    );
  };
  const handle_close = () => {
    set_delete_modal_open(false);
  };
  return (
    <Box style={{ padding: "40px", marginBottom: "40px" }}>
      <Table
        loading={department_list?.loading}
        style={{ width: "100%", height: "100%", marginTop: "50px" }}
        columns={columns}
        dataSource={department_list?.items}
        pagination={paginationOptions}
        size="large"
      />
      {/* <CustomDeleteModal
        delete_modal_open={delete_modal_open}
        set_delete_modal_open={set_delete_modal_open}
        form_values={delete_record}
        initialValues={initialValues}
      /> */}
      <Modal
        title="Confirmation"
        open={delete_modal_open}
        onCancel={handle_close}
        footer={[
          <Button key="cancel" onClick={handle_close} danger>
            Cancel
          </Button>,
          <Button
            onClick={delete_function}
            type="primary"
            loading={delete_loading}
          >
            Ok
          </Button>,
        ]}
      >
        Do you want to delete <b>{delete_record?.name}</b> department?
      </Modal>
    </Box>
  );
};

export default DepartmentList;
