import { numberFormat } from "@helpers/constants";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
} from "@services/redux";
import { get_previous_year_indicator_answer } from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import CustomPageTitle from "@views/components/common/custom_page_title";
import { Card, Skeleton, Spin } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const PreviousYearIndicator = (props) => {
  const { survey_indicator_id, indicator } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [score, setScore] = useState();

  const item = useDynamicSelector("get_previous_year_indicator_answer");
  const get_previous_year_indicator_with_answer = () => {
    let keys = [{ key: "get_previous_year_indicator_answer", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_previous_year_indicator_answer,
        { survey_indicator_id: survey_indicator_id },
        MethodType.POST
      )
    );
  };

  useEffect(() => {
    get_previous_year_indicator_with_answer();
  }, []);
  useEffect(() => {
    if (indicator?.is_direct_value) {
      setScore(item?.survey_indicators?.[0]?.answers?.[0]?.value);
    } else {
      if (indicator.second_numerator_question?.text) {
        let numerator_1 = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.numerator_question?.id
        )?.value;

        let numerator_2 = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.second_numerator_question?.id
        )?.value;
        let denominator = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.denominator_question?.id
        )?.value;
        let score =
          ((numerator_1 - numerator_2) / denominator) *
          indicator.survey_indicators?.[0]?.multiplier;
        if (numerator_1 && numerator_2 && denominator) {
          setScore(score);
        }
      } else {
        let numerator_1 = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.numerator_question?.id
        )?.value;

        let denominator = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.denominator_question?.id
        )?.value;
        let score =
          (numerator_1 / denominator) *
          indicator.survey_indicators?.[0]?.multiplier;
        if (numerator_1 && denominator) {
          setScore(score);
        }
      }
    }
  }, [indicator, item]);
  let numerator_question = item?.survey_indicators?.[0]?.answers.find(
    (x) => x.question_id === indicator?.numerator_question?.id
  )?.value;

  let second_numerator_question = item?.survey_indicators?.[0]?.answers.find(
    (x) => x.question_id === indicator?.second_numerator_question?.id
  )?.value;
  let denominator_question = item?.survey_indicators?.[0]?.answers.find(
    (x) => x.question_id === indicator?.denominator_question?.id
  )?.value;
  let total = Number(
    denominator_question && numerator_question && second_numerator_question
      ? ((numerator_question - second_numerator_question) /
          denominator_question) *
          indicator.survey_indicators?.[0]?.multiplier
      : (numerator_question / denominator_question) *
          indicator.survey_indicators?.[0]?.multiplier
  ).toFixed(3);

  useEffect(() => {
    if (item) {
      dispatch(dynamicSet("previous_numerator_question", numerator_question));
      dispatch(
        dynamicSet(
          "previous_second_numerator_question",
          second_numerator_question
        )
      );
      dispatch(
        dynamicSet("previous_denominator_question", denominator_question)
      );
      dispatch(dynamicSet("previous_total", total));
    } else {
      dispatch(dynamicClear("previous_numerator_question"));
      dispatch(dynamicClear("previous_second_numerator_question"));
      dispatch(dynamicClear("previous_denominator_question"));
      dispatch(dynamicClear("previous_total"));
    }
  }, [item, indicator]);

  return (
    <div style={{ padding: "30px" }}>
      {item.loading ? (
        <Center>
          <Spin />
        </Center>
      ) : (
        <Card style={{ borderRadius: "5px", backgroundColor: "#d3d3d324" }}>
          <VStack space={"10"} mb="20px" style={{ width: "85%" }}>
            <Box>
              <Text
                fontSize={"lg"}
                fontWeight={"600"}
                color={"#0a2773"}
              >{`Previous Year > ${item?.year}`}</Text>
            </Box>
            <HStack space={"10"}>
              <Box width="200px">
                <Text bold fontSize={"md"}>
                  {t("numerator")}
                </Text>
              </Box>
              <Box flex={1}>
                <Text fontSize={"md"}>
                  {indicator.numerator_question?.text}
                </Text>
              </Box>
              <Box width={"200px"}>
                <>
                  {item?.survey_indicators?.[0]?.answers.find(
                    (x) => x.question_id === indicator?.numerator_question?.id
                  )?.value ? (
                    <Text bold fontSize={"md"} textAlign={"right"}>
                      {
                        item?.survey_indicators?.[0]?.answers.find(
                          (x) =>
                            x.question_id === indicator?.numerator_question?.id
                        )?.value
                      }
                    </Text>
                  ) : (
                    <Text bold fontSize={"md"}>
                      {"No Data Available"}
                    </Text>
                  )}
                </>
              </Box>
            </HStack>
            {indicator.second_numerator_question?.text ? (
              <HStack space={"10"}>
                <Box width="150px">
                  <Text bold fontSize={"md"}>
                    {" "}
                    {t("numerator2")}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize={"md"}>
                    {" "}
                    {indicator.second_numerator_question?.text}
                  </Text>
                </Box>
                <Box width={"200px"}>
                  <>
                    {item?.survey_indicators?.[0]?.answers.find(
                      (x) =>
                        x.question_id ===
                        indicator?.second_numerator_question?.id
                    )?.value ? (
                      <Text bold fontWeight={"md"} textAlign={"right"}>
                        {
                          item?.survey_indicators?.[0]?.answers.find(
                            (x) =>
                              x.question_id ===
                              indicator?.second_numerator_question?.id
                          )?.value
                        }
                      </Text>
                    ) : (
                      <Text bold fontSize={"md"}>
                        {"No Data Available"}
                      </Text>
                    )}
                  </>
                </Box>
              </HStack>
            ) : (
              <></>
            )}
            {indicator?.denominator_question?.text && (
              <HStack space={"10"}>
                <Box width="200px">
                  <Text bold fontSize={"md"}>
                    {" "}
                    {t("denominator")}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize={"md"}>
                    {" "}
                    {indicator?.denominator_question?.text}
                  </Text>
                </Box>
                <Box width={"200px"}>
                  <>
                    {item?.survey_indicators?.[0]?.answers.find(
                      (x) =>
                        x.question_id === indicator?.denominator_question?.id
                    )?.value ? (
                      <Text bold fontWeight={"md"} textAlign={"right"}>
                        {
                          item?.survey_indicators?.[0]?.answers.find(
                            (x) =>
                              x.question_id ===
                              indicator?.denominator_question?.id
                          )?.value
                        }
                      </Text>
                    ) : (
                      <Text bold fontSize={"md"} textAlign={"right"}>
                        {"No Data Available"}
                      </Text>
                    )}
                  </>
                </Box>
              </HStack>
            )}
            <HStack space={"45px"}>
              <Box width={"200px"}>
                <Text bold fontSize={"md"}>
                  {t("indicator_value")}
                </Text>
              </Box>
              <Box flex={1}>
                <Text fontSize={"md"}> {item?.denominator?.text}</Text>
              </Box>
              <Box width={"200px"}>
                <>
                  {score ? (
                    <Text bold fontSize={"md"} textAlign={"right"}>
                      {score?.toFixed(2)}
                    </Text>
                  ) : (
                    <Text bold fontSize={"md"} textAlign={"right"}>
                      {"No Data Available"}
                    </Text>
                  )}
                </>
              </Box>
            </HStack>
          </VStack>
        </Card>
      )}
    </div>
  );
};

export default PreviousYearIndicator;
