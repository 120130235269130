import { Form, Input } from "antd";
import React from "react";

const FormMobile = () => {
  return (
    <Form.Item
      name="mobile"
      label="Mobile"
      rules={[
        {
          required: false,
          message: "Please Enter the Mobile",
        },
        {
          pattern: new RegExp("^[6-9][0-9]{9}$"),
          message: "Enter Valid Mobile Number",
        },
      ]}
    >
      <Input style={{ fontSize: "16px" }} />
    </Form.Item>
  );
};

export default FormMobile;
