import React from "react";
import DepartmentName from "./department_name";
import LevelInput from "./level_input";
import { Col, Form, Row } from "antd";

const AddDepartmentForm = () => {
  const [form] = Form.useForm();
  const handle_submit = (values) => {
  };
  return (
    <Form id="department form" onFinish={handle_submit}>
      <DepartmentName />
      <LevelInput />
    </Form>
  );
};

export default AddDepartmentForm;
