import React from "react";
import Card from "../notifications/card";
import { Box, HStack, Text, VStack } from "native-base";

const notifications = () => {
  const data = [
    {
      district: "chennai",
      dep: "IT",
      Survey: "test1",
      sector: {
        name: "testsector",
        indicator: [{ name: "indicator", question: "testindicator" }],
      },
    },
    // Duplicate the above structure to create 9 more entries
    {
      district: "bangalore",
      dep: "Finance",
      Survey: "test2",
      sector: {
        name: "financeSector",
        indicator: [
          { name: "financeIndicator", question: "testFinanceIndicator" },
        ],
      },
    },
    {
      district: "mumbai",
      dep: "Marketing",
      Survey: "test3",
      sector: {
        name: "marketingSector",
        indicator: [
          { name: "marketingIndicator", question: "testMarketingIndicator" },
        ],
      },
    },
    // Add 7 more entries with similar structures
    // ...
  ];

  for (let i = 1; i < 10; i++) {
    data.push({
      district: `district${i}`,
      dep: `Department${i}`,
      Survey: `Survey${i}`,
      sector: {
        name: `sector${i}`,
        indicator: [{ name: `indicator${i}`, question: `question${i}` }],
      },
    });
  }

  return (
    <VStack space={5}>
      <Box></Box>
      <Box>
        <Text bold marginLeft={"2%"} fontSize={20}>
          Notifications
        </Text>
      </Box>
      <Box alignItems={"center"}>
        <Card />
      </Box>
    </VStack>
  );
};

export default notifications;
