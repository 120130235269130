import { Form, Input } from "antd";
import React from "react";

const FormName = () => {
  return (
    <Form.Item
      name="name"
      label="Name"
      rules={[
        {
          required: true,
          message: "Please Enter the Name",
        },
      ]}
    >
      <Input style={{ width: "250px", height: "30px", fontSize: "16px" }} />
    </Form.Item>
  );
};

export default FormName;
