import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import { Input } from "antd";
import { Box } from "native-base";
import React from "react";

const QuestionName = () => {
  const { TextArea } = Input;
  return (
    <Box>
      <AntdDynamicForm.TextBox
        field="text"
        label={<span className="label-font-size">Question content</span>}
        rules={[
          {
            required: true,
            message: "Question content is monitory",
          },
        ]}
      />
    </Box>
  );
};

export default QuestionName;
