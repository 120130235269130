import { retrieveItem } from "@helpers/functions";
import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_notification } from "@services/redux/slices/dynamic_entity/rest/rest_notification";
import { Empty, Spin } from "antd";
import moment from "moment";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const Notification = () => {
  const dispatch = useDispatch();
  const token = retrieveItem("token");

  const { items, loading } = useDynamicSelector("get_notification");
  const get_notification_list = () => {
    let keys = [{ key: "get_notification", loading: true }];
    dispatch(dynamicRequest(keys, get_notification, {}, MethodType.POST));
  };
  useEffect(() => {
    get_notification_list();
  }, []);

  return (
    <div>
      {loading ? (
        <Center>
          {" "}
          <Spin />{" "}
        </Center>
      ) : items?.length !== 0 ? (
        <VStack justifyContent={"center"} space={"20px"} padding={"10px"}>
          {items?.map((item, index) => (
            <HStack width={"100%"} justifyContent={"space-between"} space={"5px"}>
              <Box width={"50px"}>
                <HStack
                  backgroundColor={
                    item?.type.slice(0, 1).includes("R")
                      ? "red.600"
                      : "green.600"
                  }
                  borderRadius={"50px"}
                  width={"50px"}
                  height={"50px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Text bold color={"white"}>
                    {item?.type.slice(0, 1)}
                  </Text>
                </HStack>
              </Box>
              <VStack
                width={"300px"}
                mt={"10px"}
                style={{
                  border: 0.5,
                  borderColor: "gray",
                  borderRadius: "10px",
                }}
              >
                <HStack justifyContent={"flex-start"} width={"100%"}>
                  <Text bold fontSize={"18px"}>
                    {
                      item?.sent_by?.department_officer?.department_office?.area
                        ?.name
                    }
                  </Text>
                </HStack>
                <HStack justifyContent={"flex-end"}>
                  <Text>{moment(item.time).format("DD/MM/YYYY MM:HH A")}</Text>
                </HStack>
                <VStack>
                  <VStack justifyContent={"flex-start"} width={"100%"}>
                    <HStack>
                      <Text>
                        {
                          <b>
                            {
                              item?.sent_by?.department_officer
                                ?.department_office?.department?.name
                            }
                          </b>
                        }{" "}
                        {item?.type} Answer for{" "}
                        {<b>{item?.answer?.survey_indicator?.survey.name}</b>}{" "}
                        {
                          <b>
                            {item?.answer?.survey_indicator?.indicator?.name}
                          </b>
                        }
                      </Text>
                    </HStack>
                    <Text>{item?.question?.text}</Text>
                  </VStack>
                </VStack>
                <HStack justifyContent={"flex-end"} space={"5px"}>
                  <Text bold>Value</Text>
                  <Text>{`: ${item.answer.value}`}</Text>
                </HStack>
              </VStack>
            </HStack>
          ))}
        </VStack>
      ) : (
        <Empty description={"No notification"} />
      )}
    </div>
  );
};

export default Notification;
