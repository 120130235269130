import { Form, Select } from "antd";
import React from "react";

const Periodicity = () => {
  const indicator_periodicity = [
    { value: "FinancialYear", label: "FinancialYear" },
    { value: "AcademicYear", label: "AcademicYear" },
    { value: "CalendarYear", label: "CalendarYear" },
    {
      value: "FasliYear", label: "FasliYear"
    }
  ];
  return (
    <Form.Item name="periodicity" label="Periodicity" labelCol={{ span: 24 }} rules={[{required:true,message:"Periodicity is monitory"}]}>
      <Select
        style={{  fontSize: "16px" }}
        allowClear
      >
        {indicator_periodicity?.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Periodicity;
