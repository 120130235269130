import { Form, Input } from "antd";
import React from "react";

const FormDescription = () => {
  return (
    <Form.Item
      name="description"
      label="Description"
      //   rules={[
      //     {
      //       required: true,
      //       message: "Please Enter any Description",
      //     },
      //   ]}
    >
      <Input style={{ width: "250px", height: "30px", fontSize: "16px" }} />
    </Form.Item>
  );
};

export default FormDescription;
