import { Form, Input } from "antd";
import React from "react";

const IndicatorName = () => {
  return (
    <Form.Item name="name" label="Name" labelCol={{ span: 24 }} rules={[{required:true,message:"Name is monitory"}]}>
      <Input style={{ fontSize: "16px" }} />
    </Form.Item>
  );
};

export default IndicatorName;
