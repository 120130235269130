import { useDynamicSelector } from "@services/redux";
import { Table } from "antd";
import React, { useState } from "react";

const SectorRankList = ({ items, result_loading, is_print }) => {
  const district_name = useDynamicSelector("district_name");
  const [sortedInfo, setSortedInfo] = useState({});

  const columns = [
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Districts",
      dataIndex: "",
      key: "district",
      align: "left",
      render: (record) => record?.data?.area?.name,
      // sorter: (a, b) => {
      //   return sortedInfo.order === "descend" ? 1 : -1;
      // },
      // sortOrder: sortedInfo.columnKey === "district" ? sortedInfo.order : null,
    },
    {
      title: "Score",
      dataIndex: "",
      align: "left",
      key: "score",
      render: (record) => {
        console.log("record", record);
        if (record?.data?.survey_score) {
          return record?.data?.survey_score?.toFixed(3);
        } else if (record?.data?.indicator_score) {
          return record?.data?.indicator_score?.toFixed(3);
        } else if(record?.data?.sector_score){
          return record?.data?.sector_score?.toFixed(3);
        } else{
          return record?.data?.sector_score?.toFixed(3) || record?.data?.survey_score?.toFixed(3) || record?.data?.indicator_score?.toFixed(3);
        }
      },
      sorter: (a, b) => {
        if (record?.data?.indicator_score) {
          return (a.data?.indicator_score) - (b.data?.indicator_score);
        } else if (record.data?.sector_score) {
          return (a?.data?.sector_score) - (b?.data?.sector_score);
        } else {
          return (a?.data?.survey_score) - (b?.data?.survey_score);
        }
      },
      sortOrder: sortedInfo.columnKey === "score" ? sortedInfo.order : null,
      //   record?.data?.indicator_score ||
      //   record?.data?.indicator_score === 0
      //     ? parseFloat(record?.data?.indicator_score).toFixed(3)
      //     : parseFloat(record?.data?.sector_score).toFixed(3)
      // sorter: (a, b) =>
      //   (a.data?.indicator_score || a.data?.sector_score) -
      //   (b.data?.indicator_score || b.data?.sector_score),
      // sortOrder: sortedInfo.columnKey === "score" ? sortedInfo.order : null,
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  return (
    <Table
      columns={columns}
      dataSource={items}
      style={{ width: "400px", marginBottom: !is_print ? "70px" : "" }}
      pagination={false}
      loading={result_loading}
      size="small"
      scroll={is_print ? { y: "75vh" } : {}}
      onChange={handleChange}
    />
  );
};

export default SectorRankList;
