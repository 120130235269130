import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_survey_questions } from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { Button, Form, Input, Spin } from "antd";
import {
  Box,
  Center,
  HStack,
  Pressable,
  SimpleGrid,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { retrieveItem } from "@helpers/functions";
import { FcInfo } from "react-icons/fc";
const UserSectorList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const department_office = retrieveItem("department_office");

  const [show_levels, set_show_levels] = useState(false);
  const [editedFields, setEditedFields] = useState({});

  const { loading: survey_questions_loading, sectors } = useDynamicSelector(
    "get_survey_questions"
  );

  const areas = {
    data: [
      {
        data: {
          id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
          name: "Coimbatore",
          parent_area_id: "7e686c68-3f27-4f7b-bea0-60a48fe60305",
          level_id: "03eebe90-fa7a-43bf-b976-84edb4b8bb7e",
          level_name: "District",
        },
        level_name: "District",
        children_areas: [
          {
            data: {
              id: "06bcb224-3343-4e19-adcb-5bcdd0a8ec73",
              name: "Coimbatore City",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "8c797e07-6d33-4541-a2f1-4bb1b187df65",
              level_name: "Corporation",
            },
            level_name: "Corporation",
            children_areas: [],
          },
          {
            data: {
              id: "4d0da00c-bda4-46f9-8ff1-a354dcfccad6",
              name: "Gudalur",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "27c73786-ae2b-4818-9bbc-b95afabee274",
              level_name: "Municipality",
            },
            level_name: "Municipality",
            children_areas: [],
          },
          {
            data: {
              id: "aba0976e-1639-4d41-8228-30cb21ef41c1",
              name: "Karamadai",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "27c73786-ae2b-4818-9bbc-b95afabee274",
              level_name: "Municipality",
            },
            level_name: "Municipality",
            children_areas: [],
          },
          {
            data: {
              id: "8e242f59-8c50-4d17-bc24-3690108c6f42",
              name: "Karumathampatti",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "27c73786-ae2b-4818-9bbc-b95afabee274",
              level_name: "Municipality",
            },
            level_name: "Municipality",
            children_areas: [],
          },
          {
            data: {
              id: "104ed85e-bc90-4723-9d98-ba96eafc8cdf",
              name: "Madukkarai",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "27c73786-ae2b-4818-9bbc-b95afabee274",
              level_name: "Municipality",
            },
            level_name: "Municipality",
            children_areas: [],
          },
          {
            data: {
              id: "e45788b4-3f10-444d-980e-d90652578523",
              name: "Mettupalayam",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "27c73786-ae2b-4818-9bbc-b95afabee274",
              level_name: "Municipality",
            },
            level_name: "Municipality",
            children_areas: [],
          },
          {
            data: {
              id: "253a0646-6b94-42e6-aa6d-87cea9b82476",
              name: "Pollachi",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "27c73786-ae2b-4818-9bbc-b95afabee274",
              level_name: "Municipality",
            },
            level_name: "Municipality",
            children_areas: [],
          },
          {
            data: {
              id: "16b39ce9-b7d7-4fc5-8259-4699514b5856",
              name: "Valparai",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "27c73786-ae2b-4818-9bbc-b95afabee274",
              level_name: "Municipality",
            },
            level_name: "Municipality",
            children_areas: [],
          },
          {
            data: {
              id: "410412f9-8b37-47e5-b419-d9e0d7df8a2b",
              name: "Coimbatore City Police",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "15119670-20c2-4e53-8055-ebf7f1f08eeb",
              level_name: "Police Commissionerate",
            },
            level_name: "Police Commissionerate",
            children_areas: [],
          },
          {
            data: {
              id: "15119670-20c2-4e53-8055-ebf7f1f08egd",
              name: "Coimbatore Rural",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "9e3c427c-7c4f-419b-ab19-5e0f4a9e5169",
              level_name: "Police Rural",
            },
            level_name: "Police Rural",
            children_areas: [],
          },
          {
            data: {
              id: "847becdd-2059-4b99-babc-f774333303cc",
              name: "ALANDURAI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "6734f8d2-0e34-4147-9232-bae33c2a02b7",
              name: "Alanthurai",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "d89ede3e-e0a2-4046-bb36-68132f47fe5d",
              name: "ANAMALAI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "8f8af24e-a924-450e-8574-7a7dd39714c6",
              name: "ANNUR",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "a4f7da19-58a1-414e-8cc0-fdd5c111c3b4",
              name: "CHETTIPALAYAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "d271ecd6-13f9-4e89-8c70-d3767e4b7fea",
              name: "DHALIYUR",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "c8bfc017-b7b6-4259-b4a7-fd5e724ed2c5",
              name: "ETTIMADAI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "eb9a164a-4677-4747-8b72-f4e41c573a65",
              name: "IDIGARAI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "dcf57952-973b-483f-9120-2c141fa45beb",
              name: "IRUGUR",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "cfd80636-efcf-4f2b-877f-fa690cb85044",
              name: "KANNAMPALAYAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "231db4a5-7105-464c-9cf8-6dbf18a64236",
              name: "KINATHUKADAVU",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "0de789b5-6a39-4416-9c7b-c14a57233cb7",
              name: "KOTTUR",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "bf3b206b-2c1b-423d-9784-cb1debb2745e",
              name: "MOPPERIPALAYAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "a289f29d-664c-4994-81d6-c8472c51da17",
              name: "N.S.N.PALAYAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "f1d9ed43-03a8-466f-bd10-73de10811812",
              name: "ODAYAKULAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "7538bb55-f751-4a89-be50-10db6f235f45",
              name: "OTHAKKALMANDAPAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "18576846-37df-4b30-9f26-cb47960ea494",
              name: "PALLAPALAYAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "4055651d-19fe-4253-9456-05a7570bde7a",
              name: "PERIANAICKEN PALAYAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "f9d83ef9-79b3-480d-8e63-566f3e2bf8f9",
              name: "PERIANEGAMAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "f6fc169a-03c5-423f-a263-823cd7b89cff",
              name: "PERUR",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "2346e1f2-969b-4e4f-b069-9dd927190132",
              name: "POOLUVAPATTI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "823ab2d0-e579-418b-8603-b2b04daa62f6",
              name: "SAMATHUR",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "792a08a9-98d8-4ed6-86fe-4542e4eb7010",
              name: "SARCARSAMAKULAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "062ac79c-4565-45ff-ab24-58e7bc9392b0",
              name: "SIRUMUGAI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "689cab42-dfd4-41c8-a984-a994470efde3",
              name: "SULESWARANPATTI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "6d6489f7-cb73-48b9-87e8-a787afd1e125",
              name: "SULUR",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "867969d6-b53d-472e-8ad1-e08b43ef0bb7",
              name: "THENKARAI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "be865b7d-3bcc-4f1e-9b92-5d7ffab38323",
              name: "THIRUMALAYAMPALAYAM",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "db149944-a866-44f2-9642-cdd855e3101b",
              name: "THONDAMUTHUR",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "e5d0f40b-ceac-4576-89df-4a1c32263132",
              name: "VEDAPATTI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "aabad869-6046-4a0d-b43c-36cb491c1c3b",
              name: "VEERAPANDI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "187dda44-edac-4cae-97c0-2128f2e582ad",
              name: "VELLALORE",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "ee1111f2-a003-414c-a621-4f18ac082fc7",
              name: "VETTAIKARANPUDUR",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "aea2ed2e-444e-437e-abda-4267a5cabbe1",
              name: "ZAMINUTHUKULI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "eb56bf9b-a68d-4a83-be1b-43876a49cc03",
              level_name: "Town Panchayat",
            },
            level_name: "Town Panchayat",
            children_areas: [],
          },
          {
            data: {
              id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
              name: "Anaimalai",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "036c07cb-f0b1-449c-ac1e-77136618d510",
              level_name: "Union",
            },
            level_name: "Union",
            children_areas: [
              {
                data: {
                  id: "e7dccd67-cd33-4f1a-9f59-6f61776f4cef",
                  name: "ANGALAKURICHI",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "7afc7343-1a65-4f5d-b473-4b131fceaac4",
                  name: "ARTHANARIPALAYAM",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "6cb4b668-51a5-4d3a-9195-495f3cc8f81c",
                  name: "AUTHUPOLLACHI",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "70283dee-b90d-4c87-83c7-81832434a9f3",
                  name: "DIVANSAPUDUR",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "9544cbd2-bb7d-4a0d-b6f8-62d5b0c3140f",
                  name: "JALLIPATTI",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "cfd8844d-2199-48d0-bd2a-526b0badb0d0",
                  name: "KALIAPURAM",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "8f8941e1-22d7-4e09-92e8-39adabffde36",
                  name: "KAMBALAPATTI",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "faaed39a-fc51-41bd-bc0b-d2ca9cc12185",
                  name: "KARIYANCHETTIPALAYAM",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "aca99206-2aa0-4793-a21c-b048a7e3429c",
                  name: "MARAPPAGOUNDENPUDUR",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "cf22f367-0fd3-450d-9339-170270a6a15c",
                  name: "PERIAPODU",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "1cc82574-89cc-4e7c-90ea-28e57c75e2b1",
                  name: "PETHANAICKENUR",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "fd902db7-26a9-4e65-8a52-437edfe16aa3",
                  name: "PILCHINNAMPALAYAM",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "2e68aaec-2005-4c20-82d4-40a19eaa9cb1",
                  name: "RAMANAMUDALIPUDUR",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "ef50e648-dd75-4a89-9368-6e4b77762ec1",
                  name: "Somandurai",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "51fcf362-3f22-48c0-a3d1-bbfaecc5adf6",
                  name: "SOMANDURAI",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "dd69177f-3427-4225-895d-7309f821a1d1",
                  name: "SUBBEGOUNDENPUDUR",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "6d565d4d-55e7-4b4b-b9dd-48172a8408cb",
                  name: "THATHUR",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "7769dc64-b968-490b-8104-388bff5e89a4",
                  name: "THENCHITTUR",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "bde11746-c82c-4984-b5b9-beb98987c26e",
                  name: "THENSANGAMPALAYAM",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "f26d295d-1a1a-4891-a7fc-576e0291a058",
                  name: "VALAIKOMBUNAGOOR",
                  parent_area_id: "d52a01b2-ef25-44e1-936b-397d588b18ee",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
            ],
          },
          {
            data: {
              id: "8d8e76df-76ce-4516-8d46-8849a619554d",
              name: "MADUKKARAI",
              parent_area_id: "ddaf694c-44a2-4ea9-83b2-80de33834058",
              level_id: "036c07cb-f0b1-449c-ac1e-77136618d510",
              level_name: "Union",
            },
            level_name: "Union",
            children_areas: [
              {
                data: {
                  id: "236c35d3-d852-458b-a841-cc9e066e1556",
                  name: "ARISIPALAYAM",
                  parent_area_id: "8d8e76df-76ce-4516-8d46-8849a619554d",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "d9760d28-280e-4d54-a1d4-eb48009142b4",
                  name: "MALUMICHAMPATTI",
                  parent_area_id: "8d8e76df-76ce-4516-8d46-8849a619554d",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "1255cc41-310e-422d-a4d6-705f41be487c",
                  name: "MAVUTHAMPATHY",
                  parent_area_id: "8d8e76df-76ce-4516-8d46-8849a619554d",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "24b912aa-30d7-4f22-bbfc-07c390cdfd1a",
                  name: "MYLERIPALAYAM",
                  parent_area_id: "8d8e76df-76ce-4516-8d46-8849a619554d",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "3a699e97-15d4-495d-b308-88efc1c28079",
                  name: "NACHIPALAYAM",
                  parent_area_id: "8d8e76df-76ce-4516-8d46-8849a619554d",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "2b347a2d-c006-4183-a760-543914a0d08c",
                  name: "PALATHURAI",
                  parent_area_id: "8d8e76df-76ce-4516-8d46-8849a619554d",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "9fedcdbf-5716-49d0-9350-1ffbe494e984",
                  name: "PICHANUR",
                  parent_area_id: "8d8e76df-76ce-4516-8d46-8849a619554d",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "69503bd8-f4c3-429d-894f-2dc8bc217336",
                  name: "SEERAPALAYAM",
                  parent_area_id: "8d8e76df-76ce-4516-8d46-8849a619554d",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
              {
                data: {
                  id: "ace660a0-a265-42ae-a87a-37532145d1b3",
                  name: "VALUKKUPARAI",
                  parent_area_id: "8d8e76df-76ce-4516-8d46-8849a619554d",
                  level_id: "82feb732-b231-480a-b3d6-76b872f49c3a",
                  level_name: "Village Panchayat",
                },
                level_name: "Village Panchayat",
                children_areas: [],
              },
            ],
          },
        ],
      },
    ],
    pagination: null,
  };
  const get_survey_questions_values = () => {
    let keys = [{ key: "get_survey_questions", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_survey_questions,
        { survey_id: id },
        MethodType.POST
      )
    );
  };
  useEffect(() => {
    if (id) get_survey_questions_values();
  }, [id]);

  const handleBack = () => {
    history.goBack();
  };
  const handle_show_levels = () => {
    set_show_levels(!show_levels);
  };

  const handleInputChange = (indicatorId, value) => {
    setEditedFields((prevEditedFields) => ({
      ...prevEditedFields,
      [indicatorId]: {
        edited: true,
        value: Number(value),
      },
    }));
  };
  const isSaveButtonEnabled = (indicatorId) => editedFields[indicatorId];

  const handleSave = (value, enteredText) => {
    // survey_indicators
    let variables = {
      survey_id: id,
      indicator_id: value.indicators?.id,
      question_id: value.indicators?.numerator_question_id,
      department_office_id: department_office?.id,
      value: enteredText?.value,
      answered_departments: department_office?.department_id,
      answered_levels: department_office?.level_id,
    };
  };
  return survey_questions_loading ? (
    <Center height={"300px"}>
      <Spin size="large" />
    </Center>
  ) : (
    <div style={{ paddingLeft: "15px", padding: "30px" }}>
      <Form style={{ padding: "50px" }}>
        {sectors?.map((x) => {
          return (
            <div>
              <VStack space={"10"} mt="20px">
                <Box>
                  <Box>
                    <Text bold fontSize={"xl"}>
                      {x?.name}
                    </Text>
                  </Box>
                  <VStack mt="10px">
                    <HStack marginLeft={"20px"}>
                      <Text bold fontSize={"md"}>
                        {x?.indicators?.name}
                      </Text>
                    </HStack>
                    <HStack
                      width={"100%"}
                      alignItems={"center"}
                      space={"10px"}
                      marginLeft={"35px"}
                    >
                      <HStack alignItems={"center"} mt={"-23px"}>
                        <Pressable onPress={handle_show_levels}>
                          {show_levels ? <HiMinusSm /> : <HiPlusSm />}
                        </Pressable>
                        <Box>
                          <Pressable onPress={handle_show_levels}>
                            <Text>
                              {x?.indicators?.numerator_question?.text}
                            </Text>
                          </Pressable>
                        </Box>
                      </HStack>

                      <VStack space={"10"} width={"500px"}>
                        <HStack space={"5"} mt="10px">
                          <Box width={"80%"}>
                            <Form.Item
                              // hasFeedback
                              name={x?.indicators?.id}
                              initialValue={
                                x?.indicators?.numerator_question?.fixed_answer
                              }
                              rules={[
                                {
                                  required: true,
                                  message: `${x?.indicators?.numerator_question?.text} question is mandatory`,
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                onChange={(e) =>
                                  handleInputChange(
                                    x?.indicators?.id,
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Item>
                          </Box>
                          <Box mt="1">
                            {x?.indicators?.numerator_question?.fixed_answer ? (
                              <AiOutlineCheckCircle color="green" size={20} />
                            ) : x?.indicators?.numerator_question
                                ?.fixed_answer == null &&
                              !editedFields[x?.indicators?.id]?.value ? (
                              <AiOutlineCloseCircle color="red" size={20} />
                            ) : x?.indicators?.numerator_question
                                ?.fixed_answer !==
                              editedFields[x?.indicators?.id]?.value ? (
                              <FcInfo size={20} />
                            ) : (
                              <AiOutlineCloseCircle color="red" size={20} />
                            )}
                          </Box>

                          <Box>
                            <Button
                              disabled={!isSaveButtonEnabled(x?.indicators?.id)}
                              onClick={() => {
                                handleSave(x, editedFields[x?.indicators?.id]);
                              }}
                            >
                              Save
                            </Button>
                          </Box>
                        </HStack>
                      </VStack>
                    </HStack>
                    <HStack
                      display={show_levels ? "block" : "none"}
                      mt={"20px"}
                    >
                      <SimpleGrid
                        columns={{
                          base: 2,
                          sm: 1,
                          xs: 1,
                          md: 2,
                          lg: 2,
                          xl: 6,
                          "2xl": 5,
                          "3xl": 5,
                        }}
                        spacingX={10}
                        justifyContent={"center"}
                        alignItems={"start"}
                      >
                        {areas?.data?.map((area_list) => {
                          return (
                            <VStack
                              minW={"200px"}
                              space={"4px"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              mb={"20px"}
                            >
                              <VStack
                                _text={{ fontWeight: "bold" }}
                                textAlign={"center"}
                              >
                                {area_list?.data?.area_name}
                                <Text fontSize={"13px"}>
                                  {area_list?.level_name}
                                </Text>
                              </VStack>
                              <Box>
                                <Input />
                              </Box>
                            </VStack>
                          );
                        })}
                      </SimpleGrid>
                    </HStack>
                    {x?.indicators?.denominator_question && (
                      <Box marginLeft={"35px"}>
                        <VStack space={"10"}>
                          <HStack space={"5"} mt="10px">
                            <Box flex={1}>
                              <Text>
                                {x?.indicators?.denominator_question?.text}
                              </Text>
                            </Box>
                            <Box flex={1}>
                              <Input
                                disabled={
                                  x?.indicators?.denominator_question
                                    ?.is_direct_value
                                }
                                value={x?.indicators?.fixed_answer}
                              />
                            </Box>
                          </HStack>
                        </VStack>
                      </Box>
                    )}
                  </VStack>
                </Box>
              </VStack>
            </div>
          );
        })}
      </Form>

      <HStack space={"6"} mt="10" justifyContent={"flex-end"}>
        <Button type="primary">{t("submit")}</Button>
        <Button
          onClick={() => {
            handleBack();
          }}
          type="default"
          danger
        >
          {t("back")}
        </Button>
      </HStack>
    </div>
  );
};

export default UserSectorList;
