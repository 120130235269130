import { Button, Form, Input, Upload } from "antd";
import { Text } from "native-base";
import React from "react";
import { BsUpload } from "react-icons/bs";
import FileUpload from "@views/components/file_upload/file_upload"

const FormLogo = () => {
  return (
    <FileUpload.File label={"Logo"} field={"logo"} />
  );
};

export default FormLogo;
