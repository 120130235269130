import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { get_all_area_district } from "@services/redux/slices/dynamic_entity/rest/rest_area";
import {
  get_all_indicator,
  get_available_indicator,
} from "@services/redux/slices/dynamic_entity/rest/rest_indicators";
import {
  create_survey_indicator,
  update_survey_indicator,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  message,
} from "antd";
import { Box, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const IntegratorForm = ({
  sector_value,
  open,
  onCancel,
  title,
  indicator_values,
  get_one_survey_sector_function,
  survey_sector_id,
  getall_survey_sector,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { items: indicators } = useDynamicSelector("get_available_indicator");
  const { items: area } = useDynamicSelector("get_all_area_district");
  const district_list = Form.useWatch("additional_weight_setting", form); // Watch changes in selected districts

  const {
    loading: create_loading,
    error: create_error,
    status: create_status,
  } = useDynamicSelector("create_survey_indicator");
  const {
    loading: update_loading,
    error: update_error,
    status: update_status,
  } = useDynamicSelector("update_survey_indicator");
  const { state } = useLocation();

  const get_all_indicator_values = (id) => {
    let keys = [{ key: "get_available_indicator", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_available_indicator,
        { survey_sector_id: id },
        MethodType.POST
      )
    );
  };

  const get_all_area_function = (data) => {
    let keys = [{ key: "get_all_area_district", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_area_district,
        { search_string: data },
        MethodType.POST
      )
    );
  };

  useEffect(() => {
    get_all_area_function();
  }, []);

  useEffect(() => {
    if (sector_value?.id) {
      get_all_indicator_values(sector_value?.id);
    }
  }, [sector_value]);
  const create_one_survey_indicator = (data) => {
    let keys = [{ key: "create_survey_indicator", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        create_survey_indicator,
        {
          ...data,
          survey_id: state?.id,
          serial_number: 2,
          survey_sector_id: survey_sector_id,
        },
        MethodType.PUT
      )
    );
  };

  const update_one_survey_indicator = (data) => {
    let keys = [{ key: "update_survey_indicator", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        update_survey_indicator + indicator_values?.id,
        {
          ...data,
          survey_id: state?.id,
          serial_number: indicator_values?.serial_number,
          survey_sector_id:
            indicator_values?.survey_sector_id || getall_survey_sector?.[0]?.id,
          indicator_id: indicator_values?.indicator_id,
        },
        MethodType.PATCH
      )
    );
  };

  const handle_submit = (values) => {
    console.log("values", values);
    if (indicator_values?.id) {
      update_one_survey_indicator(values);
    } else {
      create_one_survey_indicator(values);
    }
  };
  useEffect(() => {
    if (indicator_values?.id) {
      console.log("indicator_values", indicator_values);
      form.setFieldsValue(indicator_values);
    } else {
      form.resetFields();
    }
  }, [indicator_values]);

  useEffect(() => {
    if (create_status === "success") {
      message.success(
        sector_value?.sector?.name + " to " + t("indicator_added")
      );
      dispatch(dynamicClear("create_survey_indicator"));
      onCancel();
      get_one_survey_sector_function();
    } else if (create_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("create_survey_indicator"));
    } else if (update_status === "success") {
      message.success(t("indicator_updated"));
      dispatch(dynamicClear("update_survey_indicator"));
      onCancel();
      get_one_survey_sector_function();
    } else if (update_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("update_survey_indicator"));
    }
  }, [create_status, create_error, update_error, update_status]);
  const handle_close = () => {
    onCancel();
    form.resetFields();
  };

  const get_district_options = (index) => {
    let districts = area;
    if (districts?.length) {
      district_list?.map((x, i) => {
        if (i != index) {
          districts = districts.filter(
            (y) => !x?.area_ids?.includes(y.data.id)
          );
        }
      });
    } else districts = [];
    return districts;
  };

  return (
    <div>
      <Modal
        open={open}
        onCancel={onCancel}
        title={title}
        footer={null}
        width={700}
      >
        <Form
          form={form}
          onFinish={handle_submit}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 100,
          }}
        >
          {indicator_values?.id ? (
            ""
          ) : (
            <Form.Item
              label="Indicator"
              name={"indicator_id"}
              rules={[{ required: true, message: "Indicator is monitory" }]}
            >
              <Select
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
                allowClear
              >
                {indicators?.map((option, index) => (
                  <Select.Option key={option?.id} value={option?.id}>
                    {option?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Multiplier"
            name={"multiplier"}
            rules={[{ required: true, message: "Multiplier is monitory" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Weight"
            name={"weight"}
            rules={[{ required: true, message: "Weight is monitory" }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.List name="additional_weight_setting">
            {(fields, { add, remove }) => (
              <>
                <HStack
                  space="10px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>
                    <Text fontSize="16px">
                      {t("additional_weight_setting")}
                    </Text>
                  </div>
                  <Box alignItems="flex-end">
                    <Button type="dashed" onClick={() => add()}>
                      <PlusOutlined />
                      {t("add_weight_setting")}
                    </Button>
                  </Box>
                </HStack>
                <div>
                  {fields.map((field, index) => {
                    const currentDistrict =
                      form.getFieldValue([field.name, "area_ids"]) || [];
                    return (
                      <div key={field.key}>
                        <Divider>
                          {t("weight_setting")} {index + 1}
                        </Divider>
                        <Form.Item
                          label={t("form:district")}
                          name={[field.name, "area_ids"]}
                          initialValue={currentDistrict}
                          rules={[
                            {
                              required: true,
                              message: t("form:area_required"),
                            },
                          ]}
                          key={index}
                        >
                          <Select showSearch allowClear mode="multiple">
                            {get_district_options(index).map((option) => (
                              <Select.Option
                                key={option?.data?.id}
                                value={option?.data?.id}
                              >
                                {option?.data?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label={t("form:weight")}
                          name={[field.name, "weight"]}
                          rules={[
                            {
                              required: true,
                              message: t("form:weight_required"),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        {fields.length >= 1 && (
                          <Button
                            type="danger"
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            icon={<MinusCircleOutlined />}
                          >
                            {t("remove_weight_setting")}
                          </Button>
                        )}
                      </div>
                    );
                  })}
                  <Divider />
                </div>
              </>
            )}
          </Form.List>
          <HStack justifyContent={"flex-end"} space={"20px"}>
            <Button danger onClick={handle_close}>
              Close
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={create_loading || update_loading}
            >
              {indicator_values?.id ? "Update" : "Submit"}
            </Button>
          </HStack>
        </Form>
      </Modal>
    </div>
  );
};

export default IntegratorForm;
