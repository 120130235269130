import { Button, Popconfirm, Space, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SortableTree from "react-sortable-tree";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  TableOutlined,
} from "@ant-design/icons";
import "react-sortable-tree/style.css";
import { Box, HStack, Text, VStack } from "native-base";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import {
  dynamicClear,
  dynamicRequest,
  get_vendor_categories,
  update_category_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showNotification } from "@helpers/notify";
import { update_level } from "@services/redux/slices/dynamic_entity/rest/rest_levels";
import { MethodType } from "@helpers/service_call";

function Tree(props) {
  const history = useHistory();
  const {
    data,
    handleEdit,
    handleDelete,
    handleGetProducts,
    handleAdd,
    handleCategoriesOrderUpdate,
    user,
  } = props;
  const [treeData, setTreeData] = React.useState();
  const [prevTreeData, setPrevTreeData] = useState(treeData);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const { status: updateStatus, error: updateError } =
  //   useDynamicSelector("update_category");

  function replaceKeysDeep(obj, keysMap) {
    return _.transform(obj, function (result, value, key) {
      var currentKey = keysMap[key] || key; // if the key is in keysMap use the replacement, if not use the original key

      result[currentKey] = _.isObject(value)
        ? replaceKeysDeep(value, keysMap)
        : value; // if the key is an object run it through the inner function - replaceKeys
    });
  }
  useEffect(() => {
    setTreeData(
      replaceKeysDeep(data, {
        id: "parent_category_id",
        id: "current_node_id",
        name: "title",
        sub_levels: "children",
      })
    );
  }, [props.data]);
  function getPath(object, search) {
    if (object.parent_category_id === search)
      return [
        { title: object.title, parent_category_id: object.parent_category_id },
      ];
    else if (object.children || Array.isArray(object)) {
      let children = Array.isArray(object) ? object : object.children;
      for (let child of children) {
        let result = getPath(child, search);
        if (result) {
          if (object.parent_category_id)
            result.unshift({
              title: object.title,
              parent_category_id: object.parent_category_id,
            });
          return result;
        }
      }
    }
  }
  const handleFormData = (formAction, data) => {
    formAction(
      replaceKeysDeep(data, {
        current_node_id: "id",
        title: "name",
      })
    );
  };

  const handleProduct = (formAction, data) => {
    const result = getPath(treeData, data.current_node_id);
    formAction(
      replaceKeysDeep(data, {
        current_node_id: "id",
        title: "name",
      }, result)
    );
    // history.push({
    //   pathname: `${ROUTES.PRODUCT}/${data.current_node_id}`,
    //   state: { data: data, ls: result },
    // });
  };
  function extractCategoryIds(treeData, parentIds = []) {
    const categoryIds = [];
    treeData?.forEach((node) => {
      const nodeParentIds = [...parentIds, node.parent_category_id];
      categoryIds.push({ nodeId: node.id, parentIds: nodeParentIds });
      if (node.children) {
        categoryIds.push(...extractCategoryIds(node.children, nodeParentIds));
      }
    });
    return categoryIds;
  }

  function getChangedNodeAndParent(treeData, prevTreeData) {
    const prevIds = new Set(
      extractCategoryIds(prevTreeData).map((item) => item.nodeId)
    );
    const currentIds = new Set(
      extractCategoryIds(treeData).map((item) => item.nodeId)
    );

    let changedNode = null;
    let changedParentIds = null;

    // Find the changed node and its parent ID
    treeData?.forEach((node) => {
      if (!prevIds.has(node.id) && currentIds.has(node.id)) {
        changedNode = node;
        changedParentIds = extractCategoryIds(treeData)
          ?.find((item) => item.nodeId === node.id)
          .parentIds.slice(0, -1); // Removing the last element as it's the parent of the node itself
      }
    });

    return { changedNode, changedParentIds };
  }

  const updateCategoryOrder = (_id, _parent_id, record) => {

    let keys = [{ key: "update_level", loading: true }];
    dispatch(dynamicRequest(keys, update_level + _id, { parent_level_id: _parent_id || null }, MethodType.PATCH));
    // dispatch(dynamicRequest(keys, update_level + _id, variables, "M"));
  };
  const getCategoriesList = () => {
    // let keys = [{ key: "vendor_categories", loading: true }];
    // dispatch(
    //   dynamicRequest(keys, get_vendor_categories, {
    //     //page_limit: 1,
    //   })
    // );
  };
  // useEffect(() => {
  //   if (updateStatus === "success") {
  //     dispatch(dynamicClear("update_category"));

  //     // getCategoriesList();
  //   }
  // }, [updateStatus]);
  return (
    <SortableTree
      canDrag={user === "vendor" ? false : true}
      treeData={treeData}
      onMoveNode={({ treeData, node, nextParentNode }) => {
        node = { ...node, parent_category_id: nextParentNode?.current_node_id };
        updateCategoryOrder(node?.current_node_id, node?.parent_category_id, node);
      }}
      onChange={(updatedTreeData) => {
        let changedNode = null;
        let changedNodeParentId = null;

        updatedTreeData?.forEach((node) => {
          const prevNode = prevTreeData?.find(
            (prevNode) => prevNode?.id === node?.id
          );

          if (
            !prevNode ||
            prevNode.parent_category_id !== node?.parent_category_id
          ) {
            if (!changedNode) {
              changedNode = node;
              changedNodeParentId = node?.parent_category_id;
            }
          }
        });

        setTreeData(updatedTreeData);
        setPrevTreeData(updatedTreeData);
      }}
      isVirtualized={false}
      generateNodeProps={(row, index) => {
        return {
          title: row?.node?.title,
          subtitle: (
            <div style={{ lineHeight: "2em" }}>{row?.node?.subtitle}</div>
          ),
          buttons: [
            <Space>
              <Typography.Link
                onClick={() => handleFormData(handleAdd, row?.node)}
              >
                <Space>
                  <Tooltip title="Add" placement="bottom">
                    <PlusOutlined />
                  </Tooltip>
                </Space>
              </Typography.Link>
              <Typography.Link
                onClick={() => handleFormData(handleEdit, row.node)}
              >
                <Space>
                  <Tooltip title="Edit" placement="bottom">
                    <EditOutlined />
                  </Tooltip>
                </Space>
              </Typography.Link>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleFormData(handleDelete, row.node)}
              >
                <Space>
                  <Tooltip title="Delete" placement="bottom">
                    <DeleteOutlined color="red" />
                  </Tooltip>
                </Space>
              </Popconfirm>

              {/* <Typography.Link
                onClick={() => handleProduct(handleGetProducts, row.node)}
              >
                <Space>
                  <Tooltip title="Department" placement="bottom">
                    <TableOutlined color="yellow" />
                  </Tooltip>
                </Space>
              </Typography.Link> */}
            </Space>,
          ],
        };
      }}
    />
  );
}

export default Tree;
