import { Card, Typography } from "antd";
import React from "react";
const { Text } = Typography;
const SectorRankCard = (props) => {
  const { sub_title, color, marginLeft, icon, title, marginBottom } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: color,
        height: "150px",
        width: "300px",
        padding: "22px",
        borderRadius: "20px",
        marginLeft: marginLeft,
        marginBottom: marginBottom,
        textAlign: "center",
        cursor: "pointer",
      }}
    >
      <Card
        style={{
          backgroundColor: "#f8f8f8",
          width: "230px",
          height: "200px",
          borderRadius: "20px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Text
          style={{ color: "#482058", fontSize: "20px", fontWeight: "bold" }}
        >
          {sub_title}
        </Text>
        <img
          src={icon}
          alt={title}
          style={{
            width: "23px",
            display: "flex",
            margin: "auto",
            alignItems: "center",
            marginTop: "17px",
          }}
        />
      </Card>
    </div>
  );
};

export default SectorRankCard;
