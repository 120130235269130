import { callService } from "@helpers/config";
import { MethodType } from "@helpers/service_call";
import { dynamicRequest, get_all_department, useDynamicSelector } from "@services/redux";
import CustomButton from "@views/components/common/custom_button";
import CustomDeleteModal from "@views/components/common/custom_delete_modal";
import CustomModal from "@views/components/common/custom_modal";
import CustomPageTitle from "@views/components/common/custom_page_title";
import DepartmentList from "@views/components/departments/department_list";
import { Col, Input, Row } from "antd";
import { Box, HStack } from "native-base";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const Departments = () => {
  const [add_modal, set_add_modal_open] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const { Search } = Input;
  const dispatch = useDispatch()
  const [header_type, set_header_type] = useState("");
  const department_list = useDynamicSelector("get_all_department");
  const delete_record_modal = (record) => {
    // set_delete_modal_open(true);
    // set_delete_record(record);
  };

  const add_department = () => {
    setInitialValues(null);
    set_header_type("Add");

    set_add_modal_open(true);
  };
  const get_all_department_values = (data) => {
    let keys = [{ key: "get_all_department", loading: true }];
    dispatch(dynamicRequest(keys, get_all_department, { search_string: data }, MethodType.POST));
  };
  const onSearch = (value) => {
    let search_value = value?.target?.value || value
    if (search_value?.length > 2 || search_value?.length === 0)
      get_all_department_values(search_value)
  }
  return (
    <Row
      style={{
        width: "100%",
        maxHeight: "100vh",
      }}
    >
      <Col span={24}>
        <CustomPageTitle title="Departments" />
        <HStack style={{ width: "97%", marginBottom: "-60px", justifyContent: "flex-end", zIndex: 100000 }} space={"10px"}>
          <Search
            enterButton={true}
            onSearch={onSearch}
            allowClear={true}
            onChange={onSearch}
            placeholder="Search"
            style={{ width: '250px' }}
          />
          <CustomButton 
            button_name="Add Department"
            onclick_button={add_department}
            header_type={header_type}
          />
        </HStack>
        <DepartmentList
          delete_record_modal={delete_record_modal}
          set_add_modal_open={set_add_modal_open}
          set_header_type={set_header_type}
          header_type={header_type}
          setInitialValues={setInitialValues}
        />
        <CustomModal
          header_type={header_type}
          add_modal={add_modal}
          set_add_modal_open={set_add_modal_open}
          initialValues={initialValues}
        />
        {/* <CustomDeleteModal
          delete_modal_open={delete_modal_open}
          // set_delete_modal_open={set_delete_modal_open}
          delete_record={delete_record}
          initialValues={initialValues}
        /> */}
      </Col>
    </Row>
  );
};

export default Departments;
