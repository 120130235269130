import { departments } from "@helpers/config";
import { CallService, MethodType } from "@helpers/service_call";
import { showToast } from "@helpers/toast";
import { useDynamicSelector } from "@services/redux";
import {
  department,
  department_delete,
  dynamicClear,
  dynamicRequest,
  get_all_department,
} from "@services/redux/slices/dynamic_entity";
import {
  Button,
  Form,
  Modal,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import CustomDeleteModal from "../common/custom_delete_modal";
import { FaUserFriends } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  department_office_delete,
  get_all_department_office,
} from "@services/redux/slices/dynamic_entity/rest/rest_department_office";
import { navigateWithProps } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";
import { paginationOptions } from "@helpers/constants";
import { get_all_area_district } from "@services/redux/slices/dynamic_entity/rest/rest_area";

const DepartmentOfficersList = (props) => {
  const {
    delete_record_modal,
    set_add_modal_open,
    set_header_type,
    setInitialValues,
  } = props;
  const dispatch = useDispatch();
  const department_list = useDynamicSelector("get_all_department_office");
  const create_response = useDynamicSelector("department_office_create");
  const update_response = useDynamicSelector("department_office_update");

  const location = useLocation();
  const {
    status: delete_status,
    error: delete_error,
    loading: delete_loading,
  } = useDynamicSelector("department_office_delete");
  const [page, setPage] = React.useState(1);
  const [delete_record, set_delete_record] = useState({});
  const [delete_modal_open, set_delete_modal_open] = useState(false);
  const handle_row_click = (record) => {
    set_add_modal_open(true);
    set_header_type("Edit");
    setInitialValues(record);
  };
  const get_all_department_offices = (data) => {
    let keys = [{ key: "get_all_department_office", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_department_office, {}, MethodType.POST)
    );
  };

  useEffect(() => {
    get_all_department_offices();
  }, []);

  useEffect(() => {
    if (create_response?.status === "success") {
      message.success("Department office created successfully");
      get_all_department_offices();
      set_add_modal_open(false);
      dispatch(dynamicClear("department_office_create"));
    } else if (create_response?.error) {
      message.error("Something went wrong");
      dispatch(dynamicClear("department_office_create"));
    } else if (update_response?.status === "success") {
      message.success("Department office updated successfully");
      get_all_department_offices();
      set_add_modal_open(false);
      dispatch(dynamicClear("department_office_create"));
    } else if (update_response?.error) {
      message.error("Something went wrong");
      dispatch(dynamicClear("department_office_create"));
    } else if (delete_status) {
      message.success("Department office deleted successfully");
      get_all_department_offices();
      set_add_modal_open(false);
      set_delete_modal_open(false);
      dispatch(dynamicClear("department_office_delete"));
    } else if (delete_error) {
      message.error("Something went wrong");
      dispatch(dynamicClear("department_office_delete"));
    }
  }, [
    create_response?.status,
    create_response?.error,
    update_response?.status,
    update_response?.error,
    delete_error,
    delete_status,
  ]);

  const handle_user = (value) => {
    navigateWithProps(`${ROUTES.DEPARTMENTS_OFFICER_USERS}/${value?.id}`, {
      department_office: value,
    });
  };

  const columns = [
    // {
    //   title: "S.No",
    //   dataIndex: "",
    //   align: "left",
    //   width: "300px",
    //   render: (value, item, index) => (page - 1) * 10 + index + 1,
    // },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      width: "300px",
    },
    {
      title: "Action",
      dataIndex: "",
      align: "left",
      width: "50px",
      render: (_, record) => (
        <HStack space={"10px"}>
          <Pressable onPress={() => handle_user(record)}>
            <FaUserFriends size={18} />
          </Pressable>
          <Pressable
            onPress={() => {
              delete_record_modal(record);
              set_delete_record(record);
              set_delete_modal_open(true);
              set_add_modal_open(false);
            }}
          >
            <AiFillDelete size={18} />
          </Pressable>
        </HStack>
      ),
    },
  ];
  const delete_function = () => {
    let keys = [{ key: "department_office_delete", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        department_office_delete + delete_record?.id,
        {},
        MethodType.DELETE
      )
    );
  };
  const handle_close = () => {
    set_delete_modal_open(false);
  };

  return (
    <Box style={{ padding: "40px", marginBottom: "40px" }}>
      <HStack
        justifyContent={"flex-start"}
        mb={"-20px"}
        space={"10px"}
        zIndex={100000}
      ></HStack>
      <Table
        loading={department_list?.loading}
        style={{ width: "100%", height: "100%", marginTop: "50px" }}
        columns={columns}
        dataSource={department_list?.items}
        size="large"
        pagination={paginationOptions}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_row_click(record), // click row
          };
        }}
      />
      {/* <CustomDeleteModal
        delete_modal_open={delete_modal_open}
        set_delete_modal_open={set_delete_modal_open}
        form_values={delete_record}
        initialValues={initialValues}
      /> */}
      <Modal
        title="Confirmation"
        open={delete_modal_open}
        onCancel={handle_close}
        footer={[
          <Button key="cancel" onClick={handle_close} danger>
            Cancel
          </Button>,
          <Button
            onClick={delete_function}
            type="primary"
            loading={delete_loading}
          >
            Ok
          </Button>,
        ]}
      >
        Do you want to delete <b>{delete_record?.name}</b> department officer?
      </Modal>
    </Box>
  );
};

export default DepartmentOfficersList;
