import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import {
  create_area,
  update_area,
} from "@services/redux/slices/dynamic_entity/rest/rest_area";
import { Button, Form, Input, Select } from "antd";
import { HStack } from "native-base";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const AreaForm = ({ areas_form_values, onCancel, areas }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { loading: create_loading } = useDynamicSelector("create_area");
  const { loading: update_loading } = useDynamicSelector("update_area");

  useEffect(() => {
    if (areas_form_values?.data?.id) {
      form.setFieldsValue(areas_form_values?.data);
    } else {
      form.resetFields();
    }
  }, [areas_form_values]);

  const handle_close = () => {
    onCancel();
    form.resetFields();
  };

  const handleSubmit = (values) => {
    values.parent_area_id = areas?.[0]?.data?.parent_area_id;
    values.level_id = areas?.[0]?.data?.level_id;

    if (areas_form_values?.data?.id) {
      delete values.id;
      let keys = [{ key: "update_area", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          `${update_area}${areas_form_values?.data?.id}`,
          values,
          MethodType.PATCH
        )
      );
    } else {
      let keys = [{ key: "create_area", loading: true }];
      dispatch(dynamicRequest(keys, `${create_area}`, values, MethodType.PUT));
    }
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        id="area-form"
      >
        <Form.Item label="Name" name={"name"}>
          <Input />
        </Form.Item>

        <HStack justifyContent={"flex-end"} space={"20px"}>
          <Button
            type="primary"
            htmlType="submit"
            loading={update_loading || create_loading}
          >
            Submit
          </Button>
          <Button danger onClick={handle_close}>
            Close
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default AreaForm;
