import { useDynamicSelector } from "@services/redux";
import { Button, Form, Input } from "antd";
import { t } from "i18next";
import { Box } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const ChangePassword = (props) => {
  const { action_item } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handle_submit = (val, values) => {
    action_item(values);
  };

  return (
    <Box>
      <Form
        name="operation"
        id="form"
        form={form}
        onValuesChange={handle_submit}
      >
        <Form.Item
          label={t("current_password")}
          name="current_password"
          placeholder="current password"
          rules={[
            {
              required: true,
              message: t("current_password_is_required"),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={t("new_password")}
          name="new_password"
          placeholder="new password"
          rules={[
            {
              required: true,
              message: t("new_password_is_required"),
            },
          ]}
        >
          <Input.Password type="text" />
        </Form.Item>
        <Form.Item
          label={t("confirm_password")}
          name="confirm_password"
          placeholder="confirm password"
          rules={[
            { required: "true", message: t("confirm_password_required") },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The passwords do not match."));
              },
            }),
          ]}
        >
          <Input.Password type="text" />
        </Form.Item>
      </Form>
    </Box>
  );
};

export default ChangePassword;
