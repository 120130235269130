import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_all_sector } from "@services/redux/slices/dynamic_entity/rest/rest_sector";
import { Form, Input, InputNumber, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const Multiplier = () => {
  const dispatch = useDispatch()
  const { items: sector_list, loading: sector_loading } = useDynamicSelector("get_all_sector")

  const get_all_sector_list = () => {
    let keys = [{ key: "get_all_sector", loading: true }];
    dispatch(dynamicRequest(keys, get_all_sector, {}, MethodType.POST));
  };
  useEffect(() => {
    get_all_sector_list()
  }, [])
  return (
    <Form.Item name="sector_id" label="Sector" labelCol={{ span: 24 }} rules={[{required:true,message:"Sector is monitory"}]}>
      <Select
        style={{ fontSize: "16px" }}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {sector_list?.map((option, index) => (
          <Select.Option key={option?.id} value={option?.id}>
            {option?.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Multiplier;
