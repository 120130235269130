import { MethodType } from "@helpers/service_call";
import { dynamicClear, dynamicRequest, useDynamicSelector } from "@services/redux";
import { delete_sector, get_all_sector } from "@services/redux/slices/dynamic_entity/rest/rest_sector";
import CustomButton from "@views/components/common/custom_button";
import CustomDeleteModal from "@views/components/common/custom_delete_modal";
import CustomPageTitle from "@views/components/common/custom_page_title";
import AddSectorModal from "@views/components/sectors/add_sector_modal";
import SectorsList from "@views/components/sectors/sectors_list";
import { Col, Input, Row, message } from "antd";
import { Box, HStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const Sectors = () => {
  const [add_sector_modal, set_add_sector_modal] = useState("");
  const [delete_modal_open, set_delete_modal_open] = useState("");
  const [header_type, set_header_type] = useState("");
  const [form_values, set_form_values] = useState({});
  const dispatch = useDispatch()
  const { Search } = Input;

  const add_sector = () => {
    set_add_sector_modal(true);
    set_header_type("Add");
    set_form_values({})
  };

  const delete_one_sector_modal = () => {
    set_delete_modal_open(true);
  };
  const get_all_sector_list = (data) => {
    let keys = [{ key: "get_all_sector", loading: true }];
    dispatch(dynamicRequest(keys, get_all_sector, { search_string: data }, MethodType.POST));
  };
  const onSearch = (value) => {
    let search_value = value?.target?.value || value
    if (search_value?.length > 2 || search_value?.length === 0)
      get_all_sector_list(search_value)
  }
  return (
    <Row
      style={{
        width: "100%",
        overflowY: "auto",
        maxHeight: "100vh",
      }}
    >
      <Col span={24}>
        <CustomPageTitle title="Sectors" />
        <HStack width={"97%"} justifyContent={"flex-end"} space={"20px"} marginBottom={"-60px"} zIndex={1000000}>
          <Search
            enterButton={true}
            onSearch={onSearch}
            allowClear={true}
            onChange={onSearch}
            placeholder="Search"
            style={{ width: '250px' }}
          />
          <CustomButton button_name="Add Sector" onclick_button={add_sector} />
        </HStack>
        <SectorsList
          set_add_sector_modal={set_add_sector_modal}
          header_type={header_type}
          set_header_type={set_header_type}
          set_form_values={set_form_values}
          get_one={form_values}
        />
        <AddSectorModal
          add_sector_modal={add_sector_modal}
          set_add_sector_modal={set_add_sector_modal}
          header_type={header_type}
          get_one={form_values}
        />
      </Col>
    </Row>
  );
};

export default Sectors;
