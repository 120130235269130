import { MethodType } from '@helpers/service_call';
import { dynamicClear, dynamicRequest, get_all_department, useDynamicSelector } from '@services/redux';
import { create_level, update_level } from '@services/redux/slices/dynamic_entity/rest/rest_levels';
import { Button, Form, Input, Select, message } from 'antd'
import { HStack } from 'native-base';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const LevelDetails = ({ form_values, onCancel, action_type, get_level_list }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { status: update_status, loading: update_loading, error: update_error } = useDynamicSelector("update_level");
    const { status: create_status, loading: create_loading, error: create_error } = useDynamicSelector("create_level");
    const { items: departments, loading: department_list_loading } = useDynamicSelector("get_all_department");

    const handle_update = (value) => {
        if (action_type?.includes("add")) {
            create_one_level(value)
        } else {
            update_one_level(value)
        }
    }
    const get_all_department_values = () => {
        let keys = [{ key: "get_all_department", loading: true }];
        dispatch(dynamicRequest(keys, get_all_department, {}, MethodType.POST));
    };

    const update_one_level = (value) => {
        let keys = [{ key: "update_level", loading: true }];
        dispatch(dynamicRequest(keys, update_level + form_values?.id, { ...value, parent_level_id: form_values?.parent_level_id || null }, MethodType.PATCH));
    }

    const create_one_level = (value) => {
        let keys = [{ key: "create_level", loading: true }];
        dispatch(dynamicRequest(keys, create_level, { ...value, parent_level_id: form_values?.parent_level_id || null }, MethodType.PUT));
    }

    useEffect(() => {
        if (form_values)
            form.setFieldsValue(form_values)
        else
            form.resetFields()
    }, [form_values])

    useEffect(() => {
        if (update_status === "success") {
            message.success(form_values?.parent_name || form_values?.name + " " + t("level_updated"))
            dispatch(dynamicClear("update_level"))
            handle_close()
            get_level_list()
        } else if (update_error) {
            message.error(t("something_went_wrong"))
        } else if (create_status === "success") {
            message.success(t("level_created"))
            dispatch(dynamicClear("update_level"))
            handle_close()
            get_level_list()
        } else if (create_error) {
            message.error(t("something_went_wrong"))
        }
    }, [update_status, update_error, create_status, create_error])
    const handle_close = () => {
        onCancel()
        form.resetFields()
    }
    useEffect(() => {
        get_all_department_values()
    }, [])
    return (
        <div>
            <Form form={form} onFinish={handle_update}>
                <Form.Item label="Level" name={"name"}>
                    <Input />
                </Form.Item>
                <Form.Item name={"department_id"} label={"Departments"} >
                    <Select mode='single' showArrow showSearch loading={department_list_loading} allowClear>
                        {departments?.map((option) => (
                            <Select.Option key={option?.id} value={option?.id}>
                                {option?.name}
                            </Select.Option>
                        ))}
                    </Select>

                </Form.Item>
                <HStack space={"20px"} justifyContent={"flex-end"} >
                    <Button type='primary' htmlType='submit' loading={update_loading || create_loading}>
                        Submit
                    </Button>
                    <Button danger onClick={handle_close}>
                        Close
                    </Button>
                </HStack>
            </Form>
        </div>
    )
}

export default LevelDetails
