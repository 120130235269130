import { ROUTES } from "@views/routes/my_routes";
import { Button, Input, Modal, Table, message } from "antd";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { TableOutlined } from "@ant-design/icons";
import AreaForm from "./area_form";
import { navigateWithProps } from "@helpers/functions";

import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import {
  delete_area,
  get_all_area_district,
  get_all_district,
  revert_contribution,
} from "@services/redux/slices/dynamic_entity/rest/rest_area";
import { useDispatch } from "react-redux";
import { MethodType } from "@helpers/service_call";
import AreaDelete from "./area_delete";

const AreaList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [areas_modal, set_areas_modal] = useState(false);
  const [delete_modal, set_delete_modal] = useState(false);
  const [areas_form_values, set_areas_form_values] = useState({});
  const history = useHistory();
  const { items, loading } = useDynamicSelector("get_all_area_district");
  const { status: create_status, error: create_error } =
    useDynamicSelector("create_area");
  const { error: update_error, status: update_status } =
    useDynamicSelector("update_area");
  const {
    error: delete_error,
    status: delete_status,
    loading: delete_loading,
  } = useDynamicSelector("delete_area");
  const { Search } = Input;
  const handle_edit = (record) => {
    set_areas_form_values(record);
    set_areas_modal(true);
  };
  const handle_department_details = (record) => {
    set_areas_form_values(record);
    // history.push(`${ROUTES.AREAS_DETAILS}/edit`)
    navigateWithProps(
      `${ROUTES.AREAS_DETAILS}/edit/${record?.data?.id}/${record?.data?.level_id}`,
      record
    );
  };
  const handle_delete = (record) => {
    set_delete_modal(true);
    set_areas_form_values(record);
  };

  const handle_delete_modal_close = () => {
    set_delete_modal(false);
  };

  const get_all_area_function = (data) => {
    let keys = [{ key: "get_all_area_district", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_area_district,
        { search_string: data },
        MethodType.POST
      )
    );
  };

  const areas_actions = (record) => {
    return (
      <HStack space={"20px"}>
        <Pressable
          onPress={() => {
            handle_department_details(record);
          }}
        >
          <TableOutlined size={25} />
        </Pressable>
        {/* <Pressable
          onPress={() => {
            handle_edit(record);
          }}
        >
          <FiEdit size={18} />
        </Pressable> */}
        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiFillDelete size={18} />
        </Pressable>
      </HStack>
    );
  };

  const handle_close = () => {
    set_areas_form_values({});
    set_areas_modal(false);
  };
  const handle_click = () => {
    set_areas_form_values({});
    set_areas_modal(true);
  };

  const delete_function = () => {
    let keys = [{ key: "delete_area", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        `${delete_area}${areas_form_values?.data?.id}`,
        { id: areas_form_values?.data?.id },
        MethodType.DELETE
      )
    );
  };
  const onSearch = (value) => {
    let search_value = value?.target?.value || value;
    if (search_value?.length > 2 || search_value?.length === 0)
      get_all_area_function(search_value);
    else if (!search_value?.length) get_all_area_function(null);
  };
  useEffect(() => {
    get_all_area_function();
  }, []);

  useEffect(() => {
    if (create_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("create_area"));
    } else if (create_status === "success") {
      message.success(t("Area created successfully"));
      get_all_area_function();
      handle_close();
      dispatch(dynamicClear("create_area"));
    } else if (update_status === "success") {
      message.success(t("Area updated successfully"));
      get_all_area_function();
      handle_close();
      dispatch(dynamicClear("update_area"));
    } else if (update_error) {
      message.error(t("something_went_wrong"));

      dispatch(dynamicClear("update_area"));
    }

    if (delete_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("delete_area"));
    } else if (delete_status === "success") {
      message.success(t("Area deleted successfully"));
      get_all_area_function();
      handle_delete_modal_close();
      dispatch(dynamicClear("delete_area"));
    }
  }, [
    create_status,
    create_error,
    update_status,
    update_error,
    delete_error,
    delete_status,
  ]);
  const column = [
    {
      title: t("table:name"),
      dataIndex: "data",
      key: "name",
      render: (record) => record?.name,
    },
    // {
    //     title: t("table:type"),
    //     dataIndex: "type",
    //     key: "type",
    // },
    // {
    //     title: t("table:department"),
    //     dataIndex: "department",
    //     key: "department",
    // },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "",
      width: "150px",
      render: (record) => areas_actions(record),
    },
  ];

  return (
    <VStack padding={"40px"} space={"20px"}>
      <HStack justifyContent={"flex-end"} space={"20px"}>
        <Search
          enterButton={true}
          onSearch={onSearch}
          allowClear={true}
          onChange={onSearch}
          placeholder="Search"
          style={{ width: "250px" }}
        />
        <Button type="primary" onClick={handle_click}>
          Add Area
        </Button>
      </HStack>
      <Table
        columns={column}
        dataSource={items}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_edit(record), // click row
          };
        }}
      />
      <Modal
        open={areas_modal}
        onCancel={handle_close}
        footer={null}
        title={areas_form_values?.data?.id ? "Update area" : "Create area"}
      >
        <AreaForm
          areas={items}
          areas_form_values={areas_form_values}
          onCancel={handle_close}
        />
      </Modal>
      <Modal
        open={delete_modal}
        onCancel={handle_delete_modal_close}
        footer={[
          <Button key="cancel" onClick={handle_delete_modal_close} danger>
            Cancel
          </Button>,
          <Button
            onClick={delete_function}
            type="primary"
            loading={delete_loading}
          >
            Ok
          </Button>,
        ]}
        title={"Delete area"}
      >
        <AreaDelete
          data={areas_form_values}
          onCancel={handle_delete_modal_close}
        />
      </Modal>
    </VStack>
  );
};

export default AreaList;
