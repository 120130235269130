import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Table,
  message,
  Checkbox,
} from "antd";
import { HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillDelete, AiFillEdit, AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import {
  dynamicClear,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { MethodType } from "@helpers/service_call";
import { FcDownLeft } from "react-icons/fc";
import {
  get_indicator_contributions,
  revert_contribution,
  approve_answer,
  save_answer,
  get_approve_selected,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import TextArea from "antd/lib/input/TextArea";
import { getUUID, retrieveItem } from "@helpers/functions";
import { paginationOptions } from "@helpers/constants";
import { ImCancelCircle } from "react-icons/im";
import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { IoMdCheckmarkCircle } from "react-icons/io";
import CustomPageTitle from "@views/components/common/custom_page_title";

const ContributionList = (props) => {
  console.log("props", props);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const role = retrieveItem("user");
  const data = retrieveItem("data");
  const [checked, setChecked] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const { indicator_id, survey_id, survey_indicator_id, indicator } = props;
  const [department_with_question, set_department_with_question] = useState([]);

  const [search_string, set_search_string] = useState(null);
  const [remarkstext, setremarks] = useState("");
  const [answerid, setanswerid] = useState("");
  const [action_item, set_action_item] = useState({});
  const [revert_modal_open, set_revert_modal_open] = useState(false);
  const [approve_modal_open, set_approve_modal_open] = useState(false);
  const [show_edit_option, set_show_edit_option] = useState({});
  const [submit_response, set_submit_response] = useState([]);
  const items = useDynamicSelector("save_answer");
  const { Search } = Input;
  const { answers, filtered_dept_offices, loading } = useDynamicSelector(
    "get_indicator_contributions"
  );
  const { survey_indicators } = useDynamicSelector(
    "get_current_year_indicator_answer"
  );
  const {
    loading: create_loading,
    error: create_error,
    status: create_status,
  } = useDynamicSelector("revert_contribution");
  const {
    loading: approve_loading,
    error: approve_error,
    status: approve_status,
  } = useDynamicSelector("approve_answer");
  const {
    loading: approve_selected_loading,
    error: approve_selected_error,
    status: approve_selected_status,
  } = useDynamicSelector("get_approve_selected");

  const get_all_indicator_contributions_list = () => {
    let keys = [{ key: "get_indicator_contributions", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_indicator_contributions,
        { survey_indicator_id: survey_indicator_id },
        MethodType.POST
      )
    );
  };

  let revert_contribution_function = (data) => {
    let keys = [{ key: "revert_contribution", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        revert_contribution,
        {
          remarks: remarkstext,
          answer_id: answerid,
        },
        MethodType.POST
      )
    );
  };
  let approve_contribution_function = (data) => {
    let keys = [{ key: "approve_answer", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        approve_answer,
        {
          id: answerid,
        },
        MethodType.POST
      )
    );
  };

  const approve_selected_function = () => {
    let keys = [{ key: "get_approve_selected", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_approve_selected,
        { ids: checked },
        MethodType.POST
      )
    );
  };

  const handle_close = () => {
    set_revert_modal_open(false);
    set_approve_modal_open(false);
  };
  const handle_revert = (values) => {
    set_action_item(values);
    setanswerid(values?.answer_id);
    set_revert_modal_open(true);
  };
  const handle_approve_modal = (values) => {
    setanswerid(values?.answer_id);
    set_approve_modal_open(true);
  };
  const handle_approve = (values) => {
    approve_contribution_function();
  };

  const handle_submit = (values) => {
    revert_contribution_function();
  };

  const remarks = (values) => {
    setremarks(values?.target?.value);
  };
  const handle_edit_option = (record) => {
    set_show_edit_option(record);
  };
  const handle_press = () => {
    set_show_edit_option({});
  };

  const handleApproved = () => {
    approve_selected_function();
  };
  const save_indicator_list = () => {
    let keys = [{ key: "save_answer", loading: true }];
    dispatch(
      dynamicRequest(keys, save_answer, [...submit_response], MethodType.POST)
    );
  };
  const indicator_actions = (record) => {
    console.log("record", record);
    const stateValue = survey_indicators?.[0]?.answers?.find(
      (x) => x.question_id === indicator.numerator_question?.id
    )?.state;
    if (record?.state == "Published") {
      return (
        <HStack alignItems={"center"} justifyContent={"center"}>
          <Text color={"green.500"} bold>
            {record.state}
          </Text>
        </HStack>
      );
    }
    return record?.question?.id == show_edit_option?.question?.id &&
      record?.question?.id == show_edit_option?.question?.id &&
      record?.id == show_edit_option?.id ? (
      <HStack justifyContent={"center"}>
        <HStack justifyContent={"center"} space={"10px"} alignItems={"center"}>
          <Pressable onPress={save_indicator_list}>
            <IoMdCheckmarkCircle size={22} color="green" />
          </Pressable>
          <Pressable onPress={handle_press}>
            <MdCancel size={22} color="red" />
          </Pressable>
        </HStack>
      </HStack>
    ) : (
      <HStack space={5} alignItems={"center"} justifyContent={"center"}>
        {console.log("stateValue", stateValue)}
        {record.state !== "Reverted" ? (
          record.state !== "Approved" &&
            (record?.value || record?.value == 0) ? (
            role?.name === "District DES User" &&
              (stateValue === "Submitted" ||
                stateValue === "StateSubmitted" ||
                stateValue === "Approved" ||
                stateValue === "Published") ? (
              <></>
            ) : (
              <Pressable
                onPress={() => {
                  handle_revert(record);
                }}
              >
                <FcDownLeft size={18} />
              </Pressable>
            )
          ) : (
            <></>
          )
        ) : (
          // <Text bold color="red.600">
          //   Sent Back
          // </Text>
          ""
        )}
        {role?.name === "State DES User" ||
          role?.name === "District DES User" ||
          role?.name === "Regional User" ? (
          record.state !== "Approved" ? (
            record.state !== "Reverted" ? (
              role?.name !== "Regional User" ? (
                <HStack
                  justifyContent={"center"}
                  alignItems={"center"}
                  space={"10px"}
                >
                  {/* <Pressable
                    onPress={() => handle_edit_option(record)}
                    marginLeft={
                      !record?.value && !record?.value == 0 ? "-50px" : ""
                    }
                  >
                    <AiFillEdit size={18} />
                  </Pressable> */}

                  {(record?.value || record?.value == 0) &&
                    role?.name !== "District DES User" ? (
                    <>
                      <Button
                        onClick={() => {
                          handle_approve_modal(record);
                        }}
                        type="primary"
                      >
                        Approve
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </HStack>
              ) : (
                ""
              )
            ) : (
              <Text bold color="red.600" marginRight={"13px"}>
                Sent Back
              </Text>
            )
          ) : (
            <HStack
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
            >
              <Text bold color="green.500">
                Approved
              </Text>
            </HStack>
          )
        ) : (
          <></>
        )}
      </HStack>
    );
  };

  useEffect(() => {
    if (create_status === "success") {
      message.success(t("Data Reverted Successfully"));
      dispatch(dynamicClear("revert_contribution"));
      setremarks("");
      handle_close();
      get_all_indicator_contributions_list();
    } else if (create_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("revert_contribution"));
      handle_close();
    }
  }, [create_status, create_error]);

  useEffect(() => {
    if (approve_status === "success") {
      message.success(t("Value approved successfully"));
      dispatch(dynamicClear("approve_answer"));
      setremarks("");
      handle_close();
      get_all_indicator_contributions_list();
    } else if (approve_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("approve_answer"));
      handle_close();
    }
  }, [approve_status, approve_error]);

  useEffect(() => {
    if (approve_selected_status === "success") {
      message.success("Selected Data Approved Successfully");
      dispatch(dynamicClear("get_approve_selected"));
      get_all_indicator_contributions_list();
    } else if (approve_selected_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("get_approve_selected"));
    }
  }, [approve_selected_status, approve_selected_error]);

  useEffect(() => {
    if (items?.status === "success") {
      get_all_indicator_contributions_list();
      set_show_edit_option({});
      set_submit_response([]);
    } else {
      get_all_indicator_contributions_list();
    }
  }, [items?.status]);
  const handle_value_change = (value, record) => {
    let empty_value_response = [];
    if (record.value) {
      empty_value_response.push({
        id: record.answer_id,
        department_office_id: record.id,
        question_id: record.question.id,
        value: value,
        state:
          role?.name === "District DES User" ? "Submitted" : "StateSubmitted",
        survey_indicator_id: survey_indicator_id,
      });
    } else {
      empty_value_response.push({
        id: getUUID(),
        department_office_id: record.id,
        question_id: record.question.id,
        value: value,
        state:
          role?.name === "District DES User" ? "Submitted" : "StateSubmitted",
        survey_indicator_id: survey_indicator_id,
      });
    }
    set_submit_response(empty_value_response);
  };

  const handle_value = (record) => {
    if (
      record?.question?.id == show_edit_option?.question?.id &&
      record?.id == show_edit_option?.id
    ) {
      return (
        <InputNumber
          onChange={(e) => {
            handle_value_change(e, record);
          }}
          style={{ width: "150px" }}
          defaultValue={record?.value}
        />
      );
    }
    return record.value || record?.value == 0 ? record.value : "-";
  };

  const ans_arr = [];
  const handleCheckedChange = (answerId, data) => {
    ans_arr.push(answerId);
    let arr = [...checked];
    console.log("answerId, data", answerId, data);
    if (data.target.checked) {
      arr.push(answerId);
    } else {
      arr = arr.filter((x) => x !== answerId);
    }
    setChecked(arr);
  };
  console.log("handleCheckedChange", checked);

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const allAnswerIds = isChecked
      ? department_with_question.map((record) =>
        record.state === "StateSubmitted" ? record.answer_id : ""
      )
      : [];

    setChecked(allAnswerIds);
  };

  const column = [
    {
      title: "",
      dataIndex: "",
      align: "left",
      width: "20px",
      render: (record) =>
        role?.name === "State DES User" && record.state === "StateSubmitted" ? (
          <>
            <Checkbox
              value={record.answer_id}
              checked={checked.includes(record.answer_id)}
              onChange={(data) => handleCheckedChange(record.answer_id, data)}
            ></Checkbox>
          </>
        ) : (
          " "
        ),
    },
    {
      title: "S.No",
      dataIndex: "s_no",
      align: "left",
      width: "20px",
    },
    {
      title: t("table:source_department"),
      dataIndex: "",
      key: "source_department",
      render: (record) => record?.name,
    },

    {
      title: t("table:description"),
      dataIndex: "question",
      key: "type_of_indicator",
      render: (record) => record?.text,
    },
    {
      title: t("table:value"),
      dataIndex: "",
      key: "value",
      width: "100px",
      render: (record) => handle_value(record),
    },
    // {
    //   title: t("table:status"),
    //   dataIndex: "",
    //   key: "",
    //   width: "150px",
    //   render: (record) => indicator_actions(record),
    // },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "",
      width: "150px",
      align: "center",
      render: (record) => indicator_actions(record),
    },
  ];

  const findState = (questionId, departmentOfficeId, get) => {
    const foundItem = answers.find(
      (item) =>
        item?.question_id === questionId &&
        item?.department_office_id === departmentOfficeId
    );

    return foundItem ? foundItem?.[get] : null;
  };

  useEffect(() => {
    if (filtered_dept_offices && !search_string) {
      let filter_department_with_question = filtered_dept_offices
        ?.flatMap((item, index) =>
          item?.questions?.map((question, questionIndex) => ({
            s_no: index * item?.questions.length + questionIndex + 1,
            question: { ...question },
            id: item.id,
            name: item.name,
            value: findState(question.id, item.id, "value"),
            state: findState(question.id, item.id, "state"),
            answer_id: findState(question.id, item.id, "id"),
            system_user_id: findState(question.id, item.id, "system_user_id"),
            department: {
              id: item?.department?.id,
              name: item?.department?.name,
              is_admin_department: item?.department?.is_admin_department,
            },
          }))
        )
        .filter(Boolean)
        .sort((a, b) => {
          if (a.value === null && b.value !== null) {
            return 1; // Move null values to the end
          } else if (a.value !== null && b.value === null) {
            return -1; // Move null values to the end
          } else if (a.value === 0 && b.value !== 0) {
            return 1; // Move 0 values to the end
          } else if (a.value !== 0 && b.value === 0) {
            return -1; // Move 0 values to the beginning
          } else {
            return b.value - a.value; // Sort by value in descending order
          }
        })
        ?.map((list, index) => {
          delete list?.s_no;
          return {
            s_no: index + 1,
            ...list,
          };
        });

      set_department_with_question(filter_department_with_question);
    }
  }, [filtered_dept_offices, search_string]);
  console.log("department_with_question", department_with_question);
  const onSearch = (value) => {
    let search_value =
      value?.target?.value?.toLowerCase() || value?.toLowerCase() || "";
    if (search_value?.length > 2 || search_value?.length === 0) {
      set_search_string(search_value);
      let find_search_string = department_with_question
        ?.filter(
          (list) =>
            list?.name?.toLowerCase()?.includes(search_value.toLowerCase()) ||
            list?.question?.text
              ?.toLowerCase()
              ?.includes(search_value.toLowerCase())
        )
        ?.map((list, index) => {
          delete list?.s_no;
          return {
            s_no: index + 1,
            ...list,
          };
        });
      set_department_with_question(find_search_string);
    } else {
      set_search_string(null);
    }
  };

  return (
    <div style={{ padding: "20px", marginTop: props.isOpen ? "" : "-100px" }}>
      <CustomPageTitle title={"Contribution"} />
      <VStack padding={"20px"} space={"10px"}>
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          {role?.name === "State DES User" &&
          answers?.find((item)=> item.state === "StateSubmitted") ? (
            <Checkbox
              checked={selectAllChecked}
              onChange={handleSelectAllChange}
            >
              Select All
            </Checkbox>
          ) : (
            ""
          )}
          <HStack justifyContent={"flex-end"} space={"10px"}>
            <Search
              enterButton={true}
              onSearch={onSearch}
              allowClear={true}
              // onChange={onSearch}
              placeholder="Search"
              style={{ width: "250px" }}
            />
            {role?.name === "State DES User" &&
              (answers?.find((item) => item.state === "StateSubmitted") ? (
                <Button onClick={handleApproved}>Approve Selected</Button>
              ) : (
                ""
              ))}
          </HStack>
        </HStack>
        <Table
          columns={column}
          dataSource={department_with_question}
          loading={loading}
          pagination={paginationOptions}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) =>
        //       record.state !== "Reverted" && handle_revert(record), // click row
        //   };
        // }}
        />
      </VStack>
      <Modal
        title="Confirmation"
        open={revert_modal_open}
        onCancel={handle_close}
        footer={null}
      >
        <Form
          id="conformation_modal"
          form={form}
          onFinish={handle_submit}
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 100,
          }}
        >
          {action_item?.system_user_id ===
            data?.officer_details?.system_user_id ? (
            <Text>
              This data was submitted by you. So, you can't Revert this data.
            </Text>
          ) : (
            <>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[{ required: true, message: "Remarks is required" }]}
              >
                <TextArea onChange={remarks} value={remarkstext} />
              </Form.Item>
              Do you want to Send Back This submission?
            </>
          )}
          <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
            <Button key="cancel" onClick={handle_close} danger>
              {action_item?.system_user_id ===
                data?.officer_details?.system_user_id
                ? "Ok"
                : "Cancel"}
            </Button>

            {action_item?.system_user_id !==
              data?.officer_details?.system_user_id && (
                <Button
                  id="conformation_modal"
                  type="primary"
                  loading={create_loading}
                  htmlType="submit"
                >
                  Send Back
                </Button>
              )}
          </HStack>
        </Form>
      </Modal>
      <Modal
        title="Confirmation"
        open={approve_modal_open}
        onCancel={handle_close}
        footer={[
          <Button key="cancel" onClick={handle_close} danger>
            Cancel
          </Button>,
          <Button
            onClick={handle_approve}
            type="primary"
            loading={approve_loading}
          >
            Approve
          </Button>,
        ]}
      >
        Do you want to Approve this submission?
      </Modal>
    </div>
  );
};

export default ContributionList;
