import { Form, Input } from "antd";
import React from "react";

const FormUsername = ({ id }) => {
  return (
    <Form.Item
      name="username"
      label="Username"
      rules={[
        {
          required: true,
          message: "Please Enter the Username",
        },
      ]}
    >
      <Input style={{ fontSize: "16px" }} disabled={id ? true : false} />
    </Form.Item>
  );
};

export default FormUsername;
