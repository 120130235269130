import React, { useEffect, useState } from "react";
import FormName from "./form_name";
import FormDesignation from "./form_designation";

import { Form } from "antd";
import { CallService, MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import {
  department_create,
  department_update,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import { useParams } from "react-router-dom";
import {
  department_officer_create,
  department_officer_update,
} from "@services/redux/slices/dynamic_entity/rest/rest_department_officers";
import FormEmail from "./form_email";
import FormUsername from "./form_username";
import FormMobile from "./form_mobile";
import FormPassword from "./password";

const DepartmentOfficerUserForm = ({ initialValues, department_name }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [action_item, setActionItem] = useState();
  console.log("initialValues, department_name", initialValues, department_name);

  useEffect(() => {
    if (initialValues?.id) {
      setActionItem({ ...initialValues });
      form.setFieldsValue({ ...initialValues });
    } else {
      setActionItem({
        name: department_name,
        designation: department_name.split(" - ")?.[0],
      });
      form.setFieldsValue({
        name: department_name,
        designation: department_name.split(" - ")?.[0],
      });
    }
  }, []);
  console.log("actionItem", action_item);
  const on_finish = (values) => {
    if (initialValues?.id) {
      delete values.username;
      let key = [{ key: "department_officer_update", loading: true }];
      values.id = initialValues.id;
      delete values.id;
      // delete values.email;
      let query = `${department_officer_update}${initialValues.id}`;
      let variables = { ...values, department_office_id: id };
      dispatch(dynamicRequest(key, query, variables, MethodType.PATCH));
    } else {
      let key = [{ key: "department_officer_create", loading: true }];
      let query = department_officer_create;
      let variables = { ...values, department_office_id: id };
      dispatch(dynamicRequest(key, query, variables, MethodType.PUT));
    }
  };

  return (
    <Form
      id="department_form"
      onFinish={on_finish}
      form={form}
      initialValues={action_item}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 20,
      }}
      style={{ width: "100%" }}
    >
      <FormName />
      <FormDesignation />
      <FormEmail />
      <FormMobile />
      <FormUsername id={initialValues?.id} />
      {!initialValues?.id && <FormPassword />}
    </Form>
  );
};

export default DepartmentOfficerUserForm;
