import { Skeleton, Space } from "antd";
import { VStack } from "native-base";
import React from "react";

const SkeletonLoading = () => {
  return (
    <div>
      <VStack space={"5"}>
        <Space>
          <Skeleton.Input
            active={true}
            size={"large"}
            shape={"square"}
            style={{
              width: "200px",
              height: "100px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          />
          <Skeleton.Input
            active={true}
            size={"large"}
            shape={"square"}
            style={{
              width: "200px",
              height: "100px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          />
          <Skeleton.Input
            active={true}
            size={"large"}
            shape={"square"}
            style={{
              width: "200px",
              height: "100px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          />
        </Space>{" "}
        <Space>
          <Skeleton.Input
            active={true}
            size={"large"}
            shape={"square"}
            style={{
              width: "200px",
              height: "100px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          />
          <Skeleton.Input
            active={true}
            size={"large"}
            shape={"square"}
            style={{
              width: "200px",
              height: "100px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          />
          <Skeleton.Input
            active={true}
            size={"large"}
            shape={"square"}
            style={{
              width: "200px",
              height: "100px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          />
        </Space>{" "}
        <Space>
          <Skeleton.Input
            active={true}
            size={"large"}
            shape={"square"}
            style={{
              width: "200px",
              height: "100px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          />
          <Skeleton.Input
            active={true}
            size={"large"}
            shape={"square"}
            style={{
              width: "200px",
              height: "100px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          />
          <Skeleton.Input
            active={true}
            size={"large"}
            shape={"square"}
            style={{
              width: "200px",
              height: "100px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          />
        </Space>
      </VStack>
    </div>
  );
};

export default SkeletonLoading;
