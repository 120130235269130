import React, { useEffect, useState } from "react";
import { Button, Drawer, Layout, Space } from "antd";
import Sidebar from "@views/components/system/sidebar/sidebar";
import {
  TbChartAreaFilled,
  TbLayoutDashboard,
  TbReportSearch,
} from "react-icons/tb";
import HeaderBar from "@views/components/user/header_bar/header_bar";
import { useCheckLogin } from "@helpers/hooks";
import { navigate, retrieveItem } from "@helpers/functions";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@views/routes/my_routes";
import { HStack } from "native-base";
import {
  BsBuildingsFill,
  BsQuestionSquare,
  BsQuestionSquareFill,
} from "react-icons/bs";
import { RiCoinsFill, RiCoinsLine, RiQuestionnaireFill } from "react-icons/ri";
import { GiNotebook } from "react-icons/gi";
import { CgNotes } from "react-icons/cg";
import { FaFileAlt, FaFileExcel, FaMedal, FaUserFriends } from "react-icons/fa";
import Scrollbars from "@views/components/system/scroll_bars";
import { SiFeedly, SiLevelsdotfyi } from "react-icons/si";
import { MdDashboard, MdKeyboardHide, MdNoteAlt } from "react-icons/md";
const { Content } = Layout;

const UserLayout = ({ children }) => {
  const is_logged_in = useCheckLogin();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const user = retrieveItem("user");
  const [menu_list, set_menu_list] = useState([]);
  useEffect(() => {
    if (user?.name === "Super Admin") {
      set_menu_list([
        // {
        //   key: "Dashboard",
        //   icon: <MdDashboard />,
        //   label: t("Dashboard"),
        //   url: ROUTES.DASHBOARD,
        // },
        {
          key: "Survey response",
          icon: <MdNoteAlt />,
          label: t("Survey"),
          url: ROUTES.USER_SURVEY,
        },
        {
          key: "Progress",
          icon: <TbReportSearch />,
          label: t("Progress"),
          url: ROUTES.PROCESS_DISTRICT_PAGE,
        },
        // {
        //   key: "Reports",
        //   icon: <FaFileAlt />,
        //   label: t("Reports"),
        //   children: [
        //     {
        //       key: "District Pendency Report",
        //       icon: <FaFileExcel />,
        //       label: t("District Pendency Report"),
        //       url: ROUTES.DISTRICT_PENDENCY_REPORT,
        //     },
        //     {
        //       key: "Department Pendency Report",
        //       icon: <FaFileExcel />,
        //       label: t("Department Pendency Report"),
        //       url: ROUTES.DEPARTMENT_PENDENCY_REPORT,
        //     },
        //     {
        //       key: "Sector / Indicator Pendency Report",
        //       icon: <FaFileExcel />,
        //       label: t("Sector / Indicator Pendency Report"),
        //       url: ROUTES.SECTOR_INDICATOR_PENDENCY_REPORT,
        //     },
        //   ],
        // },
        {
          key: "Master data",
          icon: <MdKeyboardHide />,
          label: t("Master data"),
          children: [
            {
              key: "Departments",
              icon: <BsBuildingsFill />,
              label: t("Departments"),
              url: ROUTES.DEPARTMENTS,
            },
            {
              key: "Departments offices",
              icon: <BsBuildingsFill />,
              label: t("Departments offices"),
              url: ROUTES.DEPARTMENTS_OFFICERS,
            },
            {
              key: "Departments officers",
              icon: <FaUserFriends />,
              label: t("Departments officers"),
              url: ROUTES.DEPARTMENT_OFFICERS_LIST,
            },

            {
              key: "Survey",
              icon: <MdNoteAlt />,
              label: t("Survey"),
              url: ROUTES.SURVEY,
            },
            {
              key: "Sectors",
              icon: <RiCoinsFill />,
              label: t("Sectors"),
              url: ROUTES.SECTORS,
            },
            {
              key: "Indicators",
              icon: <SiFeedly />,
              label: t("Indicators"),
              url: ROUTES.INDICATORS,
            },
            {
              key: "Questions",
              icon: <BsQuestionSquareFill />,
              label: t("Questions"),
              url: ROUTES.QUESTIONS,
            },
            {
              key: "Levels",
              icon: <SiLevelsdotfyi />,
              label: t("level"),
              url: ROUTES.LEVEL,
            },
            {
              key: "Areas",
              icon: <TbChartAreaFilled />,
              label: t("areas"),
              url: ROUTES.AREAS,
            },
          ],
        },
        // {
        //   key: "Scores",
        //   icon: <CgNotes />,
        //   label: t("Scores"),
        //   url: ROUTES.SCORES,
        // },
        // {
        //   key: "Sectoral Ranking",
        //   icon: <FaMedal />,
        //   label: t("Sectoral Ranking"),
        //   url: ROUTES.SECTORAL_RANKING,
        // },
      ]);
    } else if (user?.name === "Regional User") {
      set_menu_list([
        {
          key: "Survey response",
          icon: <MdNoteAlt />,
          label: t("Survey"),
          url: ROUTES.USER_SURVEY,
        },
        // {
        //   key: "Sectoral Ranking",
        //   icon: <FaMedal />,
        //   label: t("Sectoral Ranking"),
        //   url: ROUTES.SECTORAL_RANKING,
        // },
      ]);
    } else if (
      user?.name === "State DES User" ||
      user?.name === "District DES User"
    ) {
      set_menu_list([
        {
          key: "Dashboard",
          icon: <MdDashboard />,
          label: t("Dashboard"),
          url: ROUTES.DASHBOARD,
        },
        {
          key: "Survey response",
          icon: <MdNoteAlt />,
          label: t("Survey"),
          url: ROUTES.USER_SURVEY,
        },
        {
          key: "Progress",
          icon: <TbReportSearch />,
          label: t("Progress"),
          url:
            user.name === "State DES User"
              ? ROUTES.PROCESS_DISTRICT_PAGE
              : ROUTES.PROCESS_PAGE,
        },
      ]);
    } else {
      set_menu_list([
        {
          key: "Dashboard",
          icon: <MdDashboard />,
          label: t("Dashboard"),
          url: ROUTES.DASHBOARD,
        },
        {
          key: "Survey response",
          icon: <MdNoteAlt />,
          label: t("Survey"),
          url: ROUTES.USER_SURVEY,
        },

        // {
        //   key: "Sectoral Ranking",
        //   icon: <FaMedal />,
        //   label: t("Sectoral Ranking"),
        //   url: ROUTES.SECTORAL_RANKING,
        // },
      ]);
    }
  }, [user?.name]);
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Layout>
      <HeaderBar />
      <HStack flex={1}>
        <Sidebar menu_list={menu_list} />
        <Content
          style={{
            width: "100%",
            overflowY: "scroll",
            backgroundColor: "white",
          }}
        >
          {children}
        </Content>
      </HStack>
      <Drawer
        title="Notifications"
        placement="right"
        onClose={onClose}
        open={open}
        closable
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
          </Space>
        }
      >
        {/* <Box
          style={{
            padding: 10,
            alignItems: "center",
          }}
        >
          <Text>{"Notifications"}</Text>
        </Box>
        </Box> */}
      </Drawer>
    </Layout>
  );
};
export default UserLayout;
