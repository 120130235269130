import React, { useEffect, useState } from "react";
import { Button, Collapse, Empty, Spin, Tooltip, message } from "antd";
import { Box, Center, HStack, Pressable, Text } from "native-base";
import { FiEdit, FiPlus } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { ReactSortable } from "react-sortablejs";
import SectorHeader from "./sector_header";
import SectorForm from "./sector_form";
import IntegratorForm from "./integrator_form";
import DeleteIntegratorModal from "./delete_integrator_modal";
import DeleteSectorModal from "./delete_sector_modal";
import {
  get_survey_indicators,
  update_survey_indicator_serial_number,
  update_survey_sector,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  dynamicSet,
} from "@services/redux";
import Sortable from "@views/components/sortable";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Sortable from "@views/components/ui/sortable/sortable";
const initialItems = [
  {
    id: "item-1",
    name: "Item 1",
    sector_number: 1111,
    weight: 23,
    indicator: [
      {
        id: "indicator-1",
        name: "Indicator 1",
        number: 1111,
        weight: 23,
        multiplier: 12,
      },
      {
        id: "indicator-2",
        name: "Indicator 2",
        number: 1111,
        weight: 23,
        multiplier: 12,
      },
    ],
  },
];

const DraggableTable = ({
  set_indicator_modal,
  getall_survey_sector,
  sector_map_loading,
  get_one_survey_sector_function,
  set_updated_values,
  get_all_survey_sector,
}) => {
  const { t } = useTranslation();

  const [listItems, setListItems] = useState(getall_survey_sector);
  const [sector_form, set_sector_form] = useState(false);
  const [indicator_form, set_indicator_form] = useState(false);
  const [sector_form_header, set_sector_form_header] = useState("Add Sector");
  const [indicator_form_header, set_indicator_form_header] =
    useState("Add Indicator");
  const [indicator_delete_modal, set_indicator_delete_modal] = useState(false);
  const [sector_delete_modal, set_sector_delete_modal] = useState(false);
  const [sector_values, set_sector_values] = useState({});
  const [sector_key, set_sector_key] = useState(null);
  const [indicator_values, set_indicator_values] = useState({});
  const [indicator_sort_order_value, set_indicator_sort_order_value] = useState(
    []
  );
  const [sorted_indicator, setSortedIndicator] = useState([]);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { items: survey_indicator_list, loading: survey_indicator_loading } =
    useDynamicSelector("get_survey_indicators");
  const {
    loading: update_serial_number_loading,
    error: update_serial_number_error,
    status: update_serial_number_status,
  } = useDynamicSelector("update_survey_indicator_serial_number");
  const handle_sector_edit = (record) => {
    set_sector_values(record);
    set_sector_form(true);
    set_sector_form_header("Update Sector");
  };

  const handle_sector_delete = (sector) => {
    set_sector_values(sector);
    // set_sector_modal(true);
    set_sector_delete_modal(true);
  };

  const handleAddIndicator = (record) => {
    set_sector_key(record.id);
    set_sector_values(record);
    set_indicator_form_header("Add Indicator");
    set_indicator_form(true);
  };

  const handle_indicator_edit = (indicator) => {
    set_sector_key(indicator.survey_sector_id);
    set_indicator_values(indicator);
    set_indicator_form_header("Update Indicator");
    set_indicator_form(true);
  };

  const handle_indicator_delete = (indicator) => {
    set_indicator_delete_modal(true);
    set_indicator_values(indicator);
  };
  const handle_close = () => {
    set_indicator_form(false);
    set_sector_form(false);
    set_indicator_delete_modal(false);
    set_sector_delete_modal(false);
    set_indicator_values({});
    set_sector_values({});
  };

  const handle_sector_change = (key, item, index) => {
    set_sector_key(key);
    setSortedIndicator(item?.item?.survey_indicators);
    // get_one_survey_indicator(key)
  };
  useEffect(() => {}, [sector_key]);
  const get_one_survey_sector = (record) => {
    let keys = [{ key: "update_survey_sector", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        update_survey_sector + `/${sector_values?.id}`,
        {
          survey_id: sector_values?.survey_id,
          ...record,
          serial_number: getall_survey_sector?.length + 1,
        },
        MethodType.PATCH
      )
    );
  };
  const get_one_survey_indicator = (id) => {
    let keys = [{ key: "get_survey_indicators", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_survey_indicators,
        { sector_id: id || sector_key, survey_id: state?.id },
        MethodType.POST
      )
    );
  };
  // useEffect(() => {
  //   if (sector_key) {
  //   }

  // }, [sector_key])

  const SurveyIndicator = (item) => {
    return (
      <HStack
        mt={"5px"}
        backgroundColor={"lightBlue.200"}
        zIndex={1000000000000000}
        padding={"10px"}
        key={item?.id}
      >
        <Box flex={1}>
          <Text flex={1}>{item?.item?.indicator?.name}</Text>
        </Box>
        <Box width={"120px"}>
          <Text flex={1}>{item?.item?.multiplier}</Text>
        </Box>
        <Box width={"120px"}>
          <Text flex={1}>{item?.item?.weight}</Text>
        </Box>
        <Box width={"120px"}>
          <HStack flex={1} zIndex={100000} space={"10px"}>
            {/* <IndicatorActionButton /> */}
            <Pressable
              onPress={() => {
                handle_indicator_edit(item?.item);
              }}
            >
              <FiEdit color={"black"} />
            </Pressable>
            <Pressable
              onPress={() => {
                handle_indicator_delete(item?.item);
              }}
            >
              <AiFillDelete color={"black"} />
            </Pressable>
          </HStack>
        </Box>
      </HStack>
    );
  };

  const SurveySector = (item) => {
    return (
      <Collapse
        onChange={(e) => {
          handle_sector_change(e, item);
        }}
        activeKey={sector_key}
        accordion={true}
      >
        <Collapse.Panel
          header={<SectorHeader list={item?.item} />}
          key={item?.item?.sector_id}
          extra={
            <HStack space={"10px"}>
              <Pressable
                onPress={() => {
                  handleAddIndicator(item?.item);
                }}
              >
                <FiPlus color={"white"} />
              </Pressable>
              <Pressable
                onPress={() => {
                  handle_sector_edit(item?.item);
                }}
              >
                <FiEdit color={"white"} />
              </Pressable>
              <Pressable
                onPress={() => {
                  handle_sector_delete(item?.item);
                }}
              >
                <AiFillDelete color={"white"} />
              </Pressable>
            </HStack>
          }
          style={{ zIndex: -10, cursor: "move" }}
        >
          {item?.item?.survey_indicators?.length !== 0 ? (
            <>
              {" "}
              <HStack justifyContent={"flex-end"}>
                <Button
                  type="primary"
                  onClick={() => {
                    handleUpdateIndicatorSortOrder();
                  }}
                  loading={update_serial_number_loading}
                >
                  Update Order
                </Button>
              </HStack>
              <HStack padding={"10px"}>
                <Box flex={1}>
                  <Text flex={1} bold>
                    Indicator Name
                  </Text>
                </Box>
                <Box width={"120px"}>
                  <Text flex={1} bold>
                    Multiplier
                  </Text>
                </Box>

                <Box width={"120px"}>
                  <Text flex={1} bold>
                    Weight
                  </Text>
                </Box>
                <Box width={"120px"}>
                  <Text flex={1} bold>
                    Action
                  </Text>
                </Box>
              </HStack>
              <Sortable
                items={sorted_indicator}
                onSortChange={(e) => {
                  handleSortChange(e);
                }}
                idField="id"
                sortField="serial_number"
                component={<SurveyIndicator />}
              />
            </>
          ) : (
            <Empty description="No indicator" />
          )}
        </Collapse.Panel>
      </Collapse>
    );
  };

  const handleSortChange = (log) => {
    set_indicator_sort_order_value(log);
    set_updated_values(log);
  };
  const handleUpdateIndicatorSortOrder = () => {
    let keys = [
      { key: "update_survey_indicator_serial_number", loading: true },
    ];
    dispatch(
      dynamicRequest(
        keys,
        update_survey_indicator_serial_number,
        indicator_sort_order_value,
        MethodType.POST
      )
    );
  };

  useEffect(() => {
    if (indicator_sort_order_value?.length) {
      // const sortedResult = sorted_indicator.sort((a, b) => {
      //   const serialNumberA = indicator_sort_order_value.find(
      //     (item) => item.id === a.id
      //   ).serial_number;

      //   const serialNumberB = indicator_sort_order_value.find(
      //     (item) => item.id === b.id
      //   ).serial_number;

      //   return serialNumberA - serialNumberB;
      // });
      // const sortOrderLookup = Object.fromEntries(
      //   indicator_sort_order_value.map(({ id, serial_number }) => [
      //     id,
      //     serial_number,
      //   ])
      // );

      // // Create a new array with the sorted elements
      // const sortedResult = [...sorted_indicator].sort(
      //   (a, b) => sortOrderLookup[a.id] - sortOrderLookup[b.id]
      // );

      // const sortOrderLookup = Object.fromEntries(
      //   indicator_sort_order_value.map(({ id, serial_number }) => [
      //     id,
      //     serial_number,
      //   ])
      // );

      // // Create a new array with the sorted elements
      // const sortedResult = [...sorted_indicator].sort(
      //   (a, b) => sortOrderLookup[a.id] - sortOrderLookup[b.id]
      // );

      // // Update the serial_number values in indicator_sort_order_value
      // sortedResult.forEach((element, index) => {
      //   indicator_sort_order_value[index].serial_number =
      //     element?.serial_number;
      // });

      const sortOrderLookup = Object.fromEntries(
        indicator_sort_order_value.map(({ id, serial_number }) => [
          id,
          serial_number,
        ])
      );

      const sortedResult = [...sorted_indicator].sort(
        (a, b) => sortOrderLookup[a.id] - sortOrderLookup[b.id]
      );

      setSortedIndicator(sortedResult);
    }
  }, [indicator_sort_order_value]);
  useEffect(() => {
    if (update_serial_number_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("update_survey_indicator_serial_number"));
    } else if (update_serial_number_status === "success") {
      message.success(t("sector_serial_number_updated_successfully"));
      get_all_survey_sector(state?.id);

      dispatch(dynamicClear("update_survey_indicator_serial_number"));
    }
  }, [update_serial_number_error, update_serial_number_status]);
  return (
    <>
      <Sortable
        items={getall_survey_sector}
        onSortChange={(e) => {
          handleSortChange(e);
        }}
        idField="id"
        sortField="serial_number"
        component={<SurveySector />}
      />
      <SectorForm
        open={sector_form}
        onCancel={handle_close}
        title={sector_form_header}
        form_values={sector_values}
        map_to_sector={get_one_survey_sector}
        sector_map_loading={sector_map_loading}
        get_one_survey_sector_function={get_one_survey_sector_function}
      />
      <IntegratorForm
        open={indicator_form}
        onCancel={handle_close}
        title={indicator_form_header}
        indicator_values={indicator_values}
        sector_value={sector_values}
        get_one_survey_sector_function={get_one_survey_indicator}
        survey_sector_id={sector_key}
        getall_survey_sector={getall_survey_sector}
      />
      <DeleteIntegratorModal
        open={indicator_delete_modal}
        onCancel={handle_close}
        title={"Delete Integrator"}
        form_item={indicator_values}
      />
      <DeleteSectorModal
        open={sector_delete_modal}
        onCancel={handle_close}
        title={"Delete Sector"}
        form_item={sector_values}
        get_one_survey_sector_function={get_one_survey_indicator}
      />
    </>
  );
};

export default DraggableTable;
