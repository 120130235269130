import React from "react";
import { navigate, navigateWithProps, retrieveItem } from "@helpers/functions";
import { marginLeft } from "styled-system";
import { Box, HStack, Image, Pressable, Text, VStack } from "native-base";
import { snakeCase } from "lodash";
import { ROUTES } from "@views/routes/my_routes";
import { queryStringSet } from "@helpers/constants";

const DetailCard = (props) => {
  const { icon, value, sub_color, name, color, key_value, data } = props;
  const role = retrieveItem("role");
  const onPress = (status, status_name) => {
    let encrypted_status = queryStringSet("type", status)
    navigateWithProps(`${ROUTES.DASHBOARD}/${encrypted_status}/${data?.survey_id}`, { name: status_name, data: data })
  }
  return (
    <Box
      size="100px"
      backgroundColor={color}
      style={{
        cursor: "pointer",
        width: "220px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Pressable
        onPress={() => {
          onPress(snakeCase(key_value), name);
        }}
      >
        <VStack
          justifyContent={"space-between"}
          alignItems={"space-around"}
        >
          <HStack
            space={"10px"}
            justifyContent={"space-around"}
          // padding={"10px"
          >
            <Box h={"80px"} justifyContent={"center"}>
              <Image
                source={icon}
                size={"50px"}
                style={{ tintColor: sub_color }}
              />
            </Box>
            <Box h={"80px"} justifyContent={"center"}>
              <Text
                color={"white"}
                fontSize={"26px"}
                // fontStyle={"italic"}
                bold
              >
                {value || 0}
              </Text>
            </Box>
          </HStack>
          <HStack
            backgroundColor={sub_color}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"30px"}
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Text
              textAlign={"center"}
              alignItems={"center"}
              color={"white"}
              fontSize={"14px"}
              bold
            >
              {name}
            </Text>
          </HStack>
        </VStack>
      </Pressable>
    </Box>
  );
};

export default DetailCard;
