import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Typography, message } from "antd";
import "antd/dist/antd.css";
import ForgetConformationModal from "./forget_conformation_modal";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HStack, Pressable, VStack } from "native-base";
import { navigate } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";
import RedirectorHeaderBar from "../header_bar/redirector_header_bar";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import {
  logout,
  reset_force_password,
  reset_password,
  reset_password_open,
} from "@services/redux/slices/dynamic_entity/rest/rest_forget_password";
import { MethodType } from "@helpers/service_call";

const { Title, Text } = Typography;

const ResetPassword = () => {
  const { t } = useTranslation();
  const { data } = useDynamicSelector("reset_password_open");
  const reset_password_response = useDynamicSelector("reset_password");
  const force_password_change = useDynamicSelector("reset_force_password");
  var currentUrl = window.location.href;

  const [confirmation_modal, set_confirmation_modal] = useState(false);
  const [confirmation_value, set_confirmation_value] = useState({});
  const { token } = useParams();
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    delete values.confirm_password;
    if (currentUrl?.includes("reset-password")) {
      handle_force_password_change(values);
    } else handle_submit_forget_password(values);
  };
  const handle_close = () => {
    set_confirmation_modal(false);
  };
  const handle_back = () => {
    navigate(ROUTES.LOGIN);
  };
  const handle_submit_forget_password = (data) => {
    let keys = [{ key: "reset_password", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        reset_password,
        { ...data, token: token?.split("token=")?.[1] },
        MethodType.POST
      )
    );
  };

  const handle_force_password_change = (data) => {
    let keys = [{ key: "reset_force_password", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        reset_force_password,
        { ...data, token: token?.split("token=")?.[1] },
        MethodType.POST
      )
    );
  };

  const handle_forget_password = (id) => {
    let token_spilt = token?.split("token=")?.[1];
    let keys = [{ key: "reset_password_open", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        reset_password_open + token_spilt,
        {},
        MethodType.GET
      )
    );
  };

  const handleLogout = () => {
    let keys = [{ key: "logout", loading: true }];
    dispatch(dynamicRequest(keys, logout, {}, MethodType.GET));
  };
  // useEffect(() => {
  //   if (token) {
  //     handle_forget_password();
  //   }
  // }, [token]);
  useEffect(() => {
    if (reset_password_response?.status_code === "200") {
      message.success(t("password_changed_successfully"));
      navigate(ROUTES.LOGIN);
      dispatch(dynamicClear("reset_password"));
    } else if (data && data !== "Valid") {
      message.error(t("session_timeout"));
      dispatch(dynamicClear("reset_password_open"));
      // navigate(ROUTES.FORGOT_PASSWORD)
    } else if (!reset_password_response?.status_code === "200") {
      message.success(t("password_changed_successfully"));
      navigate(ROUTES.LOGIN);
      dispatch(dynamicClear("reset_password"));
    }
  }, [reset_password_response]);

  useEffect(() => {
    if (force_password_change?.status_code === "200") {
      message.success(t("password_changed_successfully"));
      navigate(ROUTES.LOGIN);
      handleLogout();
      dispatch(dynamicClear("login"));
      dispatch(dynamicClear("reset_force_password"));
    } else if (force_password_change?.status_code === "500") {
      message.error(t("session_timeout"));
      dispatch(dynamicClear("reset_force_password"));
      // navigate(ROUTES.FORGOT_PASSWORD)
    }
  }, [force_password_change?.status_code]);

  return (
    <VStack>
      <RedirectorHeaderBar />

      <div style={{ backgroundColor: "#f2f2f2" }}>
        <div className="background-images-set">
          <Card
            style={{
              minWidth: 500,
              width: 400,
              padding: 20,
              textAlign: "center",
            }}
          >
            <Title level={3}>Reset Password</Title>
            <Form
              name="operation"
              id="form"
              // form={form}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                label={t("new_password")}
                name="new_password"
                placeholder="new password"
                rules={[
                  {
                    required: true,
                    message: t("new_password_is_required"),
                  },
                  {
                    pattern: new RegExp(
                      "^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()-_+./]){1,}).{6,}$"
                    ),
                    message: t("password_not_valid"),
                  },
                ]}
              >
                <Input.Password type="text" />
              </Form.Item>
              <Form.Item
                label={t("confirm_password")}
                name="confirm_password"
                placeholder="confirm password"
                rules={[
                  { required: "true", message: t("confirm_password_required") },
                  {
                    pattern: new RegExp(
                      "^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()-_+./]){1,}).{6,}$"
                    ),
                    message: t("password_not_valid"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The passwords do not match.")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password type="text" />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "80%", marginTop: "10px" }}
                loading={
                  reset_password_response?.loading ||
                  force_password_change?.loading
                }
              >
                Submit
              </Button>
              <HStack justifyContent={"center"} mt={"10px"}>
                <Pressable onPress={handle_back}>
                  <Text style={{ textDecoration: "underline" }}>Login</Text>
                </Pressable>
              </HStack>
            </Form>
          </Card>
        </div>
      </div>
    </VStack>
  );
};

export default ResetPassword;
