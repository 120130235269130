import { departments } from "@helpers/config";
import { CallService, MethodType } from "@helpers/service_call";
import { showToast } from "@helpers/toast";
import { useDynamicSelector } from "@services/redux";
import {
  department,
  department_delete,
  dynamicClear,
  dynamicRequest,
  get_all_department,
} from "@services/redux/slices/dynamic_entity";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import CustomDeleteModal from "../common/custom_delete_modal";
import { FaUserFriends } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  department_office_delete,
  get_all_department_office,
  get_all_department_officer,
} from "@services/redux/slices/dynamic_entity/rest/rest_department_office";
import { navigateWithProps } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";
import { paginationOptions } from "@helpers/constants";
import { get_all_area_district } from "@services/redux/slices/dynamic_entity/rest/rest_area";
import FilterForm from "./filter_form";

const AdminDepartmentOfficersList = (props) => {
  const { set_add_modal_open, set_header_type, setInitialValues } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Search } = Input;

  const department_id = Form.useWatch("department_id", form);
  const area_id = Form.useWatch("area_id", form);
  const department_list = useDynamicSelector("get_all_department_officer");
  const update_response = useDynamicSelector("department_officer_update");

  const handle_row_click = (record) => {
    set_add_modal_open(true);
    set_header_type("Edit");
    setInitialValues({
      id: record?.id,
      name: record?.name,
      designation: record?.designation,
      email: record?.system_user?.email,
      username: record?.system_user?.username,
    });
  };
  const get_all_department_offices = (data) => {
    let keys = [{ key: "get_all_department_officer", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_department_officer,
        { search_string: data, department_id: department_id, area_id: area_id },

        MethodType.POST
      )
    );
  };

  const onSearch = (value) => {
    let search_value = value?.target?.value || value;
    if (search_value?.length > 2 || search_value?.length === 0)
      get_all_department_offices(search_value);
  };
  useEffect(() => {
    get_all_department_offices();
  }, [department_id, area_id]);

  useEffect(() => {
    if (update_response?.status === "success") {
      message.success("Department officer updated successfully");
      set_add_modal_open(false);
      get_all_department_offices();
      dispatch(dynamicClear("department_officer_update"));
    } else if (update_response?.error) {
      message.error("Something went wrong");
      dispatch(dynamicClear("department_officer_update"));
    }
  }, [update_response?.status, update_response?.error]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      width: "500px",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      align: "left",
      width: "300px",
    },
    {
      title: "Department",
      dataIndex: "department_office",
      align: "left",
      width: "300px",
      render: (record) => {
        return record?.department?.name;
      },
    },
    {
      title: "Area",
      dataIndex: "department_office",
      align: "left",
      width: "200px",
      render: (record) => {
        return record?.area?.name;
      },
    },
    {
      title: "Username",
      dataIndex: "",
      align: "left",
      width: "200px",
      render: (record) => {
        return record?.system_user?.username;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      align: "left",
      width: "50px",
      render: (_, record) => (
        <HStack space={"10px"}>
          <Pressable
            onPress={() => {
              handle_row_click(record);
            }}
          >
            <FiEdit size={18} />
          </Pressable>
        </HStack>
      ),
    },
  ];

  return (
    <Box style={{ padding: "40px", marginBottom: "40px" }}>
      <HStack
        style={{
          width: "100%",
          justifyContent: "space-between",
          zIndex: 100000,
          marginBottom: "-90px",
          padding: "30px",
          alignItems: "center",
        }}
      >
        <FilterForm form={form} />
        <HStack>
          <Search
            enterButton={true}
            onSearch={onSearch}
            allowClear={true}
            onChange={onSearch}
            placeholder="Search"
            style={{ width: "250px" }}
          />
        </HStack>
      </HStack>
      <Table
        loading={department_list?.loading}
        style={{ width: "100%", height: "100%", marginTop: "50px" }}
        columns={columns}
        dataSource={department_list?.items}
        size="large"
        pagination={paginationOptions}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_row_click(record), // click row
          };
        }}
      />
      {/* <CustomDeleteModal
        delete_modal_open={delete_modal_open}
        set_delete_modal_open={set_delete_modal_open}
        form_values={delete_record}
        initialValues={initialValues}
      /> */}
    </Box>
  );
};

export default AdminDepartmentOfficersList;
