import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import {
  dynamicClear,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { MethodType } from "@helpers/service_call";

const FormDepartment = ({ Department_list }) => {
  const dispatch = useDispatch();

  return (
    <Form.Item
      name="department_id"
      label="Department"
        rules={[
          {
            required: true,
            message: "Department is monitory",
          },
        ]}
    >
      <Select
        style={{ fontSize: "16px" }}
        allowClear
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {Department_list?.map((option, index) => (
          <Select.Option key={option?.id} value={option?.id}>
            {option?.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FormDepartment;
