import React, { useEffect, useRef, useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Center,
  Stack,
  Box,
  Pressable,
  useMediaQuery,
} from "native-base";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "@helpers/toast";
import { Button, Form, Input, Spin, message } from "antd";
import { IoReloadOutline } from "react-icons/io5";
import { LoadingOutlined } from "@ant-design/icons";
import { color_gradients } from "@helpers/constants";
import Logo from "@assets/icons/logo512.png";
import DGGI_LOGO from "@assets/icons/dggi_logo_new.png";

import {
  get_captcha_query,
  login,
} from "@services/redux/slices/dynamic_entity/rest/rest_login";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { storeItem } from "@helpers/functions";
import { HiPuzzle } from "react-icons/hi";
import lock from "@assets/icons/padlock.png";
import locked from "@assets/icons/lock_pass.png";
import Profile from "@assets/icons/avatar.png";
import Profile_icon from "@assets/icons/profile.png";

// import FooterBar from "@views/components/ui/footer_bar";
export function SignInForm({ props }) {
  // add next router here
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const form_username = Form.useWatch("username", form);
  const form_password = Form.useWatch("password", form);
  const form_captcha = Form.useWatch("captcha", form);

  const UsernameInput = useRef(null);
  // const {
  //   item,
  //   authorize,
  //   loading,
  //   error,
  //   is_profile_completed,
  //   status: login_status,
  // } = "tev";
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [entered_captcha, setCaptcha] = useState("");

  const [showPass, setShowPass] = useState(false);
  const canvasRef = useRef();
  const login_response = useDynamicSelector("login");

  const captcha = useDynamicSelector("get_captcha_query");

  // const handleLogin = (values) => {
  //   user_login(values);
  // };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  // let captcha = 12344;

  const createCaptcha = () => {
    let decryptCaptcha = captcha?.captcha ? captcha?.captcha : "";
    // var canvas = canvasRef.current;
    // var context = canvas.getContext("2d");
    // context.clearRect(0, 0, 100, 100);
    // context.font = "20px Georgia white";
    // context.fillStyle = "black";
    // context.fillText(decryptCaptcha, 20, 30);
    let canvas = canvasRef.current;
    canvas.width = 150;
    canvas.height = 100;
    let ctx = canvas.getContext("2d");
    ctx.font = "bold 20px Arial";
    ctx.fillStyle = "white";
    ctx.fillText(decryptCaptcha, 10, 20);
    ctx.textAlign = "center";
    ctx.alignItems = "center";
    ctx.justifyContent = "center";
  };

  const user_login = (data) => {
    let keys = [{ key: "login", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        login,
        {
          username: form_username,
          password: form_password,
          captcha: form_captcha,
        },
        MethodType.POST
      )
    );
  };

  const get_login_captcha = () => {
    let keys = [{ key: "get_captcha_query", loading: true }];
    dispatch(dynamicRequest(keys, get_captcha_query, {}, MethodType.GET));
  };
  useEffect(() => {
    get_login_captcha();
    dispatch(dynamicClear("login"));
  }, []);
  useEffect(() => {
    if (captcha) {
      createCaptcha();
    }
  }, [captcha]);

  // useEffect(() => {
  //   if (captcha?.error) {
  //     message.error("Too many requests");
  //     dispatch(dynamicClear("get_captcha_query"));
  //   }
  // }, [captcha.error]);
  // if (login_response?.token) {
  //   history.push({
  //     pathname: `${ROUTES.DASHBOARD}`,
  //   });
  // }
  console.log("login_response", login_response);
  useEffect(() => {
    if (login_response?.token_status === "Password Change required") {
      storeItem("data", login_response);

      storeItem("token", login_response?.token);
      storeItem(
        "department_office",
        login_response?.officer_details?.department_office
      );
      storeItem(
        "department",
        login_response?.officer_details?.department_office?.department
      );
      storeItem("user_name", login_response?.officer_details?.name);

      storeItem("user", {
        id: login_response?.id,
        name: login_response?.role,
      });
      history.push(`${ROUTES.RESET_PASSWORD}`);
    } else {
      if (login_response?.token) {
        storeItem("data", login_response);
        storeItem("notification_count", login_response.notification_count);

        storeItem("token", login_response?.token);
        storeItem(
          "department_office",
          login_response?.officer_details?.department_office
        );
        storeItem(
          "department",
          login_response?.officer_details?.department_office?.department
        );
        storeItem("user_name", login_response?.officer_details?.name);

        storeItem("user", {
          id: login_response?.id,
          name: login_response?.role,
        });
        if (
          login_response?.role === "Super Admin" ||
          login_response?.role === "Regional User"
        ) {
          history.push(ROUTES.USER_SURVEY);
        } else if (login_response?.role) {
          history.push(ROUTES.DASHBOARD);
        }
      }
    }
  }, [login_response]);

  useEffect(() => {
    if (login_response?.error) {
      message.error("Too many requests");
      get_login_captcha();
      dispatch(dynamicClear("login"));
    } else if (login_response?.errors === "Invalid Captcha") {
      get_login_captcha();
      dispatch(dynamicClear("login"));
    } else if (captcha?.error) {
       message.error("Too many requests");
      dispatch(dynamicClear("get_captcha_query"));
    }
  }, [login_response?.error, login_response?.errors, captcha?.error]);

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      // user_login()
    }
  };

  const handleForgetPassword = () => {
    history.push(ROUTES.FORGOT_PASSWORD);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  useEffect(() => {
    if (UsernameInput.current) {
      // or, if Input component in your ref, then use input property like:
      // passwordInput.current.input.focus();
      UsernameInput.current.focus();
    }
  }, [UsernameInput]);

  return (
    <VStack
      // width={"800px"}
      backgroundColor={"transparent"}
      justifyContent={"center"}
      alignItems={"center"}
      // height={"450px"}
      // style={{
      //   borderRadius: "26px",
      // }}
      // opacity={}
      mt={{ base: "0px", lg: "-50px" }}
    >
      <Box
        // backgroundColor={color_gradients.menu_gradients}
        // height={"100%"}
        justifyContent={"center"}
        // borderLeftRadius={"26px"}
        // borderBottomLeftRadius={"26px"}
        // opacity={0.9}
        height={"40%"}
        // space={{ base: "20px", lg: "-40px" }}
      >
        <VStack
          alignItems={"center"}
          justifyContent={"center"}
          textAlign={"center"}
          space={"10px"}
        >
          <Box width={{ base: "170px", md: "100px", lg: "90px", xl: "170px" }}>
            <img src={Logo} alt="SprotsLogo" className="tn-logo" />
          </Box>
          <Text
            fontSize={{ base: "xl", md: "md", lg: "18px", xl: "xl" }}
            fontWeight={800}
            bold
          >
            {t("dggi_header")}
          </Text>
        </VStack>
      </Box>
      <Box
        space={3}
        display={"flex"}
        flexDirection={"row"}
        justifyItems={"center"}
        alignItems={"center"}
        flex={1}
        // backgroundColor={"gray.200"}
        height={"100%"}
        // borderRightRadius={"26px"}
        // borderBottomRightRadius={"26px"}
        opacity={0.9}
      >
        <Box mx="auto" mt={{ base: "0" }}>
          <Form
            onFinish={user_login}
            autoComplete="off"
            autoSave="off"
            form={form}
          >
            <Box
              width={{
                base: "300px",
                md: "300px",
                lg: "260px",
                xl: "300px",
                "2xl": "400px",
                "3xl": "400px",
              }}
            >
              {" "}
              <Form.Item
                // label="Username"
                className="form-username"
                name={"username"}
                rules={[
                  {
                    required: true,
                    message: "Please enter your username / Email",
                  },
                  {
                    pattern: /^[^<>]+$/,
                    message: "Invalid characters entered.",
                  },
                ]}
              >
                <Input
                  style={{
                    borderLeft: "0px",
                    borderRight: "0px",
                    borderTop: "0px",
                    borderColor: color_gradients.menu_gradients,
                    borderWidth: "1px",
                    borderRadius: "40px",
                  }}
                  className="username-input"
                  maxLength={30}
                  placeholder="Username"
                  defaultValue={text}
                  onPressEnter={handleKeypress}
                  onChange={(txt) => setText(txt.target.value)}
                  ref={UsernameInput}
                  prefix={
                    text ? (
                      <img src={Profile_icon} alt="Profile Icon" width="12px" />
                    ) : (
                      <img src={Profile} alt="Profile" width="12px" />
                    )
                  }
                  autoComplete={false}
                />
              </Form.Item>
              <Form.Item
                name={"password"}
                className="form-password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                  {
                    pattern: /^[^<>]+$/,
                    message: "Invalid characters entered.",
                  },
                ]}
              >
                <Input.Password
                  style={{
                    borderLeft: "0px",
                    borderRight: "0px",
                    borderTop: "0px",
                    borderColor: color_gradients.menu_gradients,
                    borderWidth: "1px",
                    borderRadius: "40px",
                  }}
                  className="password-input"
                  maxLength={30}
                  placeholder="Password"
                  type={showPass ? "text" : "password"}
                  defaultValue={pass}
                  onChangeText={(txt) => setPass(txt)}
                  onChange={(e) => setPass(e.target.value)}
                  prefix={
                    pass ? (
                      <img src={locked} alt="Profile Icon" width="12px" />
                    ) : (
                      <img src={lock} alt="Profile" width="12px" />
                    )
                  }
                  autoComplete={false}
                />
              </Form.Item>
              <HStack
                space={"3"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <HStack
                  space={"10px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <HStack
                    mt={5}
                    marginTop={"0px"}
                    space={2}
                    flex={1}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#0A2773",
                        alignItems: "center",
                        width: "90px",
                        height: "40px",
                      }}
                      // width={{ base: "125px", md: "125px", lg: "100px" }}
                      // height={{ base: "50px", md: "40px", lg: "40px" }}
                    >
                      <canvas
                        ref={canvasRef}
                        style={{
                          marginLeft: "60px",
                          objectFit: "cover",
                          marginTop: "6px",
                          display: captcha?.loading ? "none" : "block",
                        }}
                      />
                      {captcha?.loading && (
                        <div class="leap-frog">
                          <div class="leap-frog__dot"></div>
                          <div class="leap-frog__dot"></div>
                          <div class="leap-frog__dot"></div>
                        </div>
                      )}
                    </Box>
                    <Box>
                      <Pressable onPress={get_login_captcha}>
                        <IoReloadOutline
                          // onClick={get_one_captcha}
                          size={20}
                          style={{ cursor: "pointer", color: "#15255e" }}
                        />
                      </Pressable>
                    </Box>
                  </HStack>
                </HStack>
                <Box mt={"20px"} flex={1}>
                  <Form.Item
                    // label="Username"
                    className="form-captcha"
                    name={"captcha"}
                    rules={[
                      {
                        required: true,
                        message: "Captcha is required",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderLeft: "0px",
                        borderRight: "0px",
                        borderTop: "0px",
                        borderColor: color_gradients.menu_gradients,
                        borderWidth: "1px",
                        borderRadius: "40px",
                      }}
                      className="captcha-input"
                      maxLength={6}
                      placeholder="Captcha"
                      defaultValue={text}
                      onPressEnter={handleKeypress}
                      onChange={(txt) => setCaptcha(txt.target.value)}
                      autoComplete={false}
                      prefix={
                        entered_captcha ? (
                          <HiPuzzle color="#094b7f" />
                        ) : (
                          <HiPuzzle color="#b5a4a4" />
                        )
                      }
                    />
                  </Form.Item>
                </Box>
              </HStack>
            </Box>
            <HStack
              mt={{ base: "10px", lg: "-10px" }}
              justifyContent={"flex-end"}
            >
              <Text
                textAlign={"end"}
                fontSize={"15px"}
                // color={"white"}
                style={{ cursor: "pointer" }}
                onClick={() => handleForgetPassword()}
              >
                {"Forgot Password?"}
              </Text>
            </HStack>
            <Box alignItems={"center"} mt={{ base: "30px", lg: "10px" }}>
              <Form.Item className="form-button">
                <Button
                  style={{
                    backgroundColor: color_gradients.menu_gradients,
                    borderRadius: "24px",
                    width: "150px",
                    color: "white",
                    fontSize: "18px",
                    height: "36px",
                  }}
                  className="btn_width"
                  type="text"
                  htmlType="submit"
                  loading={login_response?.loading}
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Box>
          </Form>
        </Box>
      </Box>
    </VStack>
  );
}
export default function LoginFormElements(props) {
  const { t } = useTranslation();
  return (
    <>
      {/* <Header /> */}
      {/* <div style={{ backgroundColor: "#f2f2f2" }}>
        <div className="background-images-set">
          <Box
            _dark={{
              bgImage: `url("")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            _light={{
              bgImage: `url("")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            overflowY="hidden"
          >
            <Center
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
            >
              <Stack>
                <Box flex={1}>
                  <SignInForm props={props} />
                </Box>
              </Stack>
            </Center>
          </Box>
        </div>
      </div> */}
      <HStack justifyContent={"space-between"} height={"100%"}>
        {/* <HStack alignItems={"center"} justifyContent={"center"} width={{ base: "75%", xs: "60%", md: "60%" }} backgroundColor={"#f3f3f3"}>
          <img src={DGGI_LOGO} className="login-image" />
        </HStack> */}
        <div className="login-screen-image" />
        <HStack
          width={{ base: "25%", xs: "40%", md: "40%", lg: "30%", xl: "25%" }}
          alignItems={{ base: "start", md: "center", lg: "center" }}
          justifyContent={{ base: "start", md: "center", lg: "center" }}
          backgroundColor={"#0a277317"}
        >
          <div className="login-form">
            <SignInForm props={props} />
          </div>
        </HStack>
      </HStack>
      {/* <Footer /> */}
    </>
  );
}
