export const get_all_survey = "/survey";
export const get_survey = "/survey/";
export const create_survey = "/survey";
export const update_survey = "/survey/";
export const update_survey_sector = "/survey-sector";
export const delete_survey_sector = "/survey-sector/";
export const get_survey_sector = "/survey-sector";
export const get_survey_questions = "/survey/questions";
export const update_survey_sector_serial_number =
  "survey-sector/update-serial-number";
export const update_survey_indicator_serial_number =
  "survey-indicator/update-serial-number";
//survey-indicators
export const get_survey_indicators = "/survey-indicator";
export const create_survey_indicator = "/survey-indicator";
export const update_survey_indicator = "/survey-indicator/";
export const delete_survey_indicator = "/survey-indicator/";
export const get_my_sectors = "/survey-sector/mine";
export const get_my_survey_indicators = "/survey-sector/my-indicators";
export const get_previous_year_indicator_answer = "/survey-indicator/previous";
export const get_current_year_indicator_answer = "/survey-indicator/current";
export const get_indicator_contributions = "/survey-indicator/contributions";
export const save_answer = "/answer/save-answers";
export const get_save_submit_answer = "answer/submit-answers";
export const revert_contribution = "/answer/send-back-answer";
export const approve_answer = "answer/approve-answer";

export const survey_indicator_progress = "/survey-indicator/progress";
export const survey_sector_search = "/helpers/search";
export const get_approve_selected="answer/approve-many";
export const survey_indicator_result="/survey-indicator-result/";
export const survey_sector_result="/survey-sector-result/";
export const survey_result="/survey-result/";
