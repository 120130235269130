import { Form, Select } from "antd";
import React from "react";

const LevelInput = () => {
  const options = [
    { value: "option1", label: "Level 1" },
    { value: "option2", label: "Level 2" },
    { value: "option3", label: "Level 3" },
  ];
  return (
    <Form.Item name="level" label="Level">
      <Select
        style={{ width: "250px", height: "30px", fontSize: "16px" }}
        mode="multiple"
        allowClear
      >
        {options?.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default LevelInput;
