import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  HStack,
  Image,
  Link,
  Menu,
  Pressable,
  Text,
  View,
} from "native-base";
import Avatar from "react-avatar";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";

import {
  clearLocalStorage,
  navigate,
  retrieveItem,
  storeItem,
} from "@helpers/functions";
import { useDispatch, useSelector } from "react-redux";
import HeaderBreadcrumb from "./header_breadcrumb";
import { useTranslation } from "react-i18next";
import { Button, Drawer, Empty, Modal, message } from "antd";
// import ChangePassword from "../change_password/change_password";
import { MdOutlineNotifications } from "react-icons/md";
import { changeLanguage } from "i18next";
import ChangePassword from "../change_password/change_password";
import userAvatar from "../../../../assets/icons/user (4).png";
import { toast } from "react-toastify";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { change_password } from "@services/redux/slices/dynamic_entity/rest/rest_login";
import { MethodType } from "@helpers/service_call";
import { FaBell } from "react-icons/fa";
import Notification from "@views/components/notification/notification";
import { FaUserLarge } from "react-icons/fa6";

const HeaderBar = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = retrieveItem("user");
  const user_name = retrieveItem("user_name");
  const notification_count = retrieveItem("notification_count");

  const [is_modal_open, setIsModalOpen] = useState(false);
  const [notification_modal, set_notification_modal] = useState(false);

  const [action_item, setActionItem] = useState({});
  const {
    error: change_password_error,
    status: change_password_status,
    loading: change_password_loading,
  } = useDynamicSelector("change_password");

  let change_password_function = (values) => {
    const variable = {
      old_password: values?.current_password,
      new_password: values?.new_password,
    };
    let keys = [{ key: "change_password", loading: true }];
    dispatch(dynamicRequest(keys, change_password, variable, MethodType.POST));
    handleModalClose();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setActionItem({});
  };

  const handle_submit = (values) => {
    const { current_password, new_password, confirm_password } = action_item;
    if (current_password && new_password && confirm_password) {
      change_password_function(action_item);
    }
  };
  useEffect(() => {
    if (change_password_status === "success") {
      toast.success("Password is changed successfully");
      setIsModalOpen(false);
      dispatch(dynamicClear("change_password"));
    } else if (change_password_status === "failure") {
      toast.error("Passwords do not match");
      setIsModalOpen(false);
      dispatch(dynamicClear("change_password"));
    }
  }, [change_password_status, change_password_error]);
  const handleLogout = () => {
    clearLocalStorage();
    dispatch(dynamicClear("login"));
    dispatch(dynamicClear("institution_item"));
    dispatch(dynamicClear("data"));
    dispatch(dynamicClear("login_with_otp"));
    navigate(ROUTES.LOGIN);
  };

  const showDrawer = () => {
    set_notification_modal(true);
  };
  const onClose = () => {
    set_notification_modal(false);
  };
  return (
    <Box
      h={"80px"}
      w="100%"
      style={{
        // boxShadow: "0px 6px 4px 0px rgba(0, 0, 0, 0.24)",
        // background: "linear-gradient(270deg, #5C0FB1 3.72%, #01C4FC 44.89%)",
        background: "#0A2773",
        boxShadow: "0px 6px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <HStack
        h="100%"
        w="100%"
        // bg="#ffffff"
        space="2"
        justifyContent={"space-between"}
      >
        <Center ml="20px">
          {/* <HeaderBreadcrumb /> */}
          <HStack alignItems={"center"}>
            <Image
              source={require(`../../../../assets/icons/logo512.png`)}
              width={"60px"}
              height={"60px"}
            />
            <Text
              color={"#fff"}
              fontFamily={"project_header"}
              fontSize={18}
              bold
              mx={2}
            >
              {t("dggi_header")}
            </Text>
          </HStack>
        </Center>

        <Box my={"auto"}>
          {/* <HStack justifyContent={"flex-end"} right="10px" mt={0} ml="6">
            <Link
              href="#"
              onClick={() => {
                changeLanguage("ta");
              }}
            >
              <Text color="#fff" fontSize={"8"}>
                தமிழ்
              </Text>
            </Link>

            <Link
              href="#"
              onClick={() => {
                // changeLanguage("en");
              }}
            >
              <Text mx={2} color="#fff" fontSize={"8"}>
                |
              </Text>
            </Link>
            <Link
              href="#"
              onClick={() => {
                changeLanguage("en");
              }}
            >
              <Text color="#fff" fontSize={"8"}>
                English
              </Text>
            </Link>
          </HStack> */}

          <HStack>
            <Pressable
              onPress={() => {
                showDrawer();
              }}
              w="80px"
              alignSelf={"center"}
            >
              <HStack
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"center"}
              >
                <FaBell color="#fff" size={30} />
                <Box style={{ marginTop: "-20px" }}>
                  <Text color={"white"} fontSize={"md"}>
                    {notification_count}
                  </Text>
                </Box>
              </HStack>
              {/* <Box
                style={{
                  position: "absolute",
                  height: 20,
                  width: 20,
                  borderRadius: 15,
                  backgroundColor: "#f1f1f1",
                  right: 45,
                  bottom: 18,
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 2000,
                }}
              >
                <Text
                  style={{
                    color: "#111",
                  }}
                  fontSize={12}
                >
                  {"10"}
                </Text>
              </Box> */}
            </Pressable>
            <Box right="10px" ml="6">
              <Menu
                w="160"
                position="absolute"
                right="0px"
                top="20px"
                style={{
                  border: "1px solid #d7d7d7",
                  boxShadow: "-2px 2px #7a7a7a42",
                }}
                shouldOverlapWithTrigger={true}
                placement={"left bottom"}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      alignSelf="center"
                      alignItems="center"
                      variant="solid"
                      {...triggerProps}
                    >
                      <HStack justifyContent={"flex-end"} alignItems={"center"}>
                        <Box textAlign={"end"}>
                          <Text bold fontSize="md" color={"#fff"}>
                            Welcome, {role?.name}
                          </Text>
                          <Text bold fontSize="14px" color={"#fff"}>
                            {user_name}
                          </Text>
                        </Box>
                        <Box right="10px" ml="6">
                          <HStack
                            backgroundColor={"white"}
                            borderRadius={"50px"}
                            w={"35px"}
                            h={"35px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <FaUserLarge color="#0a2773" size={23} />
                          </HStack>
                          {/* <View style={{ alignItems: "center" }}>
                            <Avatar
                              round
                              size="27"
                              style={{
                                backgroundColor: "white",
                                // Add any other styles you need for the Avatar here
                              }}
                              src={userAvatar}
                            />
                          </View> */}
                        </Box>
                      </HStack>
                    </Pressable>
                  );
                }}
              >
                {/* <Menu.Item onPress={handleAccount}>Account</Menu.Item> */}
                {role === "Agency" ? null : (
                  <Menu.Item onPress={handleModalOpen}>
                    Change Password
                  </Menu.Item>
                )}
                <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
              </Menu>
            </Box>
          </HStack>
        </Box>
      </HStack>

      <Modal
        destroyOnClose={true}
        title={t("change_password")}
        open={is_modal_open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            form="form"
            type="primary"
            htmlType="submit"
            onClick={handle_submit}
          >
            Change Password
          </Button>,
        ]}
        // width={500}
      >
        <ChangePassword
          action_item={setActionItem}
          handleModalClose={handleModalClose}
        />
      </Modal>
      <Drawer
        title="Notifications"
        placement="right"
        onClose={onClose}
        open={notification_modal}
      >
        <Box>
          <Notification />
        </Box>
      </Drawer>
    </Box>
  );
};

export default HeaderBar;
