import { Typography } from "antd";
import React from "react";

const { Text } = Typography;

const DepartmentCard = (props) => {
  const { sub_title, marginLeft, icon, title, marginBottom, status } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#f8f8f8",
        height: "85px",
        width: "300px",
        padding: "22px",
        borderRadius: "10px",
        marginLeft: marginLeft,
        marginBottom: marginBottom,
        textAlign: "center",
        cursor: "pointer",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <img
        src={icon}
        alt={title}
        style={{
          width: "35px",
          height: "35px",
          display: "flex",
          alignItems: "center",
          borderRadius: "50%",
          backgroundColor: "#efeef3",
        }}
      />
      <div style={{ marginLeft: "15px" }}>
        <Text
          style={{
            color: "#593467",
            marginBottom: "23px",
            fontWeight: "bold",
          }}
        >
          {sub_title}
        </Text>
      </div>
      <div style={{ marginTop: "25px" }}>
        <Text style={{ fontSize: "12px", fontStyle: "italic" }}>{status}</Text>
      </div>
    </div>
  );
};

export default DepartmentCard;
