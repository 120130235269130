import CustomPageTitle from "@views/components/common/custom_page_title";
import UserIndicatorList from "@views/components/user/user_indicator/user_indicator_list";
import UserSurveyList from "@views/components/user_survey/user_survey_list";
import { Card } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const UserIndicator = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const container_style = {
    // height: "100vh",
    overflowY: "auto",
    width: "100%",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  };

  const card_style = {
    flex: 1,
  };
  return (
    <div style={container_style}>
      <Card style={card_style}>
        <UserIndicatorList />
      </Card>
    </div>
  );
};

export default UserIndicator;
