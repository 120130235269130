import CustomPageTitle from "@views/components/common/custom_page_title";
import DistrictPendency from "@views/components/user/reports/district_pendency";
import React from "react";

const DistrictPendencyReport = () => {
  const container_style = {
    // height: "100vh",
    overflowY: "auto",
    width: "100%",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  };

  return (
    <div style={container_style}>
      <CustomPageTitle title="District Pendency Report" />

      <DistrictPendency />
    </div>
  );
};

export default DistrictPendencyReport;
