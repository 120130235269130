import { Col, Form, Row } from "antd";
import { Box, Center, HStack, Image, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import { alignItems } from "styled-system";
import FormElement from "./login_form_element";
import LoginFormElements from "./login_form_element";
import RedirectorHeaderBar from "../../header_bar/redirector_header_bar";

const LoginForm = (props) => {
  useEffect(() => {
    window.localStorage.clear()
  }, [])
  return (

    <VStack height={"100%"} width={"100%"} >
      {/* <Box h={{ base: "25%", lg: "10%", xl: "10%" }} >
      </Box>
      <Box h={{ base: "85%", lg: "100%", xl: "100%" }} >
      </Box> */}
        <RedirectorHeaderBar />
        <LoginFormElements />
    </VStack>
  );
};

export default LoginForm;
