import { navigateWithProps } from "@helpers/functions";
import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { survey_progress_dashboard } from "@services/redux/slices/dynamic_entity/rest/rest_sector";
import { ROUTES } from "@views/routes/my_routes";
import {
  Button,
  Card,
  Collapse,
  Divider,
  Empty,
  Spin,
  Tag,
  Typography,
} from "antd";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const UserSectorView = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const { Title } = Typography;

  const { items: sector_list, loading: sector_loading } = useDynamicSelector(
    "survey_progress_dashboard"
  );
  const [grouped_sector_list, setGroupedSectorList] = useState([]);

  const get_sector_dashboard_progress = (data) => {
    let keys = [{ key: "survey_progress_dashboard", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        `${survey_progress_dashboard}${id}`,
        {},
        MethodType.GET
      )
    );
  };

  useEffect(() => {
    get_sector_dashboard_progress();
  }, []);

  useEffect(() => {
    const groupedItems = sector_list?.reduce((result, item) => {
      const sectorId = item.sector.id;
      if (!result[sectorId]) {
        result[sectorId] = [];
      }
      result[sectorId].push(item);
      return result;
    }, {});

    const groupedItemsArray = groupedItems
      ? Object.entries(groupedItems).map(([sectorId, sector_list]) => ({
          sectorId,
          sector_list,
        }))
      : [];
    setGroupedSectorList(groupedItemsArray);
  }, [sector_list]);

  const handle_back = (route, values) => {
    navigateWithProps(route, values);
  };

  const SectorHeader = ({ header_name }) => {
    return (
      <Box flex={1} style={{ padding: "2px" }}>
        <Text bold fontSize={"lg"} color="white" textAlign={"center"}>
          {header_name}
        </Text>
      </Box>
    );
  };

  const IndicatorHeader = ({ header_name, index }) => {
    return (
      <Box>
        <Text bold fontSize={"lg"} textAlign={"start"} color={"white"}>
          {index + 1}. {header_name}
        </Text>
      </Box>
    );
  };
  const [expandedPanels, setExpandedPanels] = useState([]);

  // Function to handle expanding a panel
  const handleExpand = (panelKey) => {
    setExpandedPanels((prevExpanded) => [...prevExpanded, panelKey]);
  };

  // Function to handle collapsing a panel
  const handleCollapse = (panelKey) => {
    setExpandedPanels((prevExpanded) =>
      prevExpanded.filter((key) => key !== panelKey)
    );
  };

  // Function to handle collapsing all panels
  const handleCollapseAll = () => {
    setExpandedPanels([]);
  };

  // Function to handle expanding all panels
  const handleExpandAll = () => {
    // Extracting all panel keys from grouped_sector_list
    const allPanelKeys =
      grouped_sector_list?.map((x) => x.sector_list?.[0]?.sector?.name) || [];
    setExpandedPanels(allPanelKeys);
  };
  const handle_change_collapse = (value, values) => {
    setExpandedPanels(value);
  };
  return (
    <div style={{ paddingLeft: "10px", marginTop: "10px" }}>
      <Box>
        <HStack space={"4px"}>
          <Pressable onPress={() => handle_back(`${ROUTES.USER_SURVEY}`)}>
            <Title className="title-style">Survey</Title>
          </Pressable>
          <Title className="title-style-none">
            {` / ${state.survey?.name} ( ${state.survey?.year} )`}
          </Title>
        </HStack>
        {sector_loading ? (
          <Center>
            <Spin size="default" />
          </Center>
        ) : (
          <VStack space={"10"}>
            <HStack mb="2" justifyContent={"flex-end"} space={"10px"}>
              <Button onClick={handleExpandAll} type="primary">
                Expand All
              </Button>
              <Button onClick={handleCollapseAll} danger>
                Collapse All
              </Button>
            </HStack>
            <Collapse
              activeKey={expandedPanels}
              onChange={handle_change_collapse}
            >
              {grouped_sector_list?.map((x) => {
                return (
                  <Collapse.Panel
                    key={x.sector_list?.[0]?.sector?.name}
                    header={
                      <SectorHeader
                        header_name={x.sector_list?.[0]?.sector?.name}
                      />
                    }
                    isOpen={expandedPanels.includes(
                      x.sector_list?.[0]?.sector?.name
                    )}
                    onToggle={() =>
                      expandedPanels.includes(x.sector_list?.[0]?.sector?.name)
                        ? handleCollapse(x.sector_list?.[0]?.sector?.name)
                        : handleExpand(x.sector_list?.[0]?.sector?.name)
                    }
                    className="sector-header-view-question"
                  >
                    <VStack space={"5"}>
                      <Collapse>
                        {x.sector_list?.map((ind, i) => {
                          return (
                            <Collapse.Panel
                              header={
                                <IndicatorHeader
                                  header_name={ind.indicator?.name}
                                  index={i}
                                />
                              }
                              className="question-view-section-header"
                            >
                              <VStack>
                                {ind.indicator?.numerator_question && (
                                  <VStack mb={"5"}>
                                    <Box flex={1} mt="5">
                                      <HStack space={"2"}>
                                        <Box>
                                          <AiFillQuestionCircle size={"20"} />
                                        </Box>
                                        <Box>
                                          <Text bold fontSize={"md"}>
                                            {
                                              ind?.indicator?.numerator_question
                                                ?.text
                                            }
                                          </Text>
                                        </Box>
                                      </HStack>
                                    </Box>
                                    {ind?.answers?.length ? (
                                      <div
                                        style={{
                                          paddingLeft: "20px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <Box>
                                          <HStack
                                            padding="3"
                                            alignItems={"center"}
                                            height={"40px"}
                                            style={{
                                              border: "1px solid #b0b0b04a",
                                            }}
                                          >
                                            <Box flex={1}>
                                              <Text bold>Department</Text>
                                            </Box>
                                            <Box flex={1}>
                                              <Text bold>Value</Text>
                                            </Box>
                                            <Box flex={1}>
                                              <Text bold>State</Text>
                                            </Box>
                                          </HStack>
                                        </Box>
                                        <Box>
                                          {ind.answers
                                            ?.filter(
                                              (y) =>
                                                y.question_id ===
                                                ind?.indicator
                                                  ?.numerator_question?.id
                                            )
                                            ?.map((ans, i) => {
                                              return (
                                                <HStack
                                                  backgroundColor={
                                                    i / 2 == 0
                                                      ? "#b0b0b04a"
                                                      : "white"
                                                  }
                                                  padding={"10px"}
                                                  style={{
                                                    border:
                                                      "1px solid #b0b0b04a",
                                                  }}
                                                >
                                                  <Box flex={1}>
                                                    <Text bold>
                                                      {
                                                        ans?.department_office
                                                          ?.office_name
                                                      }
                                                    </Text>
                                                  </Box>
                                                  <Box flex={1}>
                                                    <Text bold>
                                                      {ans.question_id ===
                                                        ind?.indicator
                                                          ?.numerator_question
                                                          ?.id && ans.value}
                                                    </Text>
                                                  </Box>
                                                  <Box flex={1}>
                                                    <Text
                                                      bold
                                                      color={
                                                        ans.state ===
                                                        "Submitted"
                                                          ? "#096dd9"
                                                          : ans.state ===
                                                            "Published"
                                                          ? "#1f996f"
                                                          : ans.state ===
                                                            "Reverted"
                                                          ? "#ff4d4f"
                                                          : ans.state ===
                                                            "NotSubmitted"
                                                          ? "#faad14"
                                                          : ans.state ===
                                                            "StateSubmitted"
                                                          ? "#52c41a"
                                                          : ""
                                                      }
                                                    >
                                                      {ans.question_id ===
                                                        ind?.indicator
                                                          ?.numerator_question
                                                          ?.id && ans.state}
                                                    </Text>
                                                  </Box>
                                                </HStack>
                                              );
                                            })}
                                        </Box>
                                      </div>
                                    ) : (
                                      <>
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      </>
                                    )}
                                  </VStack>
                                )}
                                {ind.indicator?.second_numerator_question && (
                                  <VStack mb={"5"}>
                                    <Box flex={1} paddingY={"3"}>
                                      <HStack space={"2"}>
                                        <Box>
                                          <AiFillQuestionCircle size={"20"} />
                                        </Box>
                                        <Box>
                                          <Text bold fontSize={"md"}>
                                            {
                                              ind?.indicator
                                                ?.second_numerator_question
                                                ?.text
                                            }
                                          </Text>
                                        </Box>
                                      </HStack>
                                    </Box>
                                    {ind?.answers?.length ? (
                                      <div
                                        style={{
                                          paddingLeft: "20px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <Box>
                                          <HStack
                                            padding="3"
                                            alignItems={"center"}
                                            height={"40px"}
                                            style={{
                                              border: "1px solid #b0b0b04a",
                                            }}
                                          >
                                            <Box flex={1}>
                                              <Text bold>Department</Text>
                                            </Box>
                                            <Box flex={1}>
                                              <Text bold>Value</Text>
                                            </Box>
                                            <Box flex={1}>
                                              <Text bold>State</Text>
                                            </Box>
                                          </HStack>
                                        </Box>
                                        <Box>
                                          {ind?.answers
                                            ?.filter(
                                              (y) =>
                                                y.question_id ===
                                                ind?.indicator
                                                  ?.second_numerator_question
                                                  ?.id
                                            )
                                            ?.map((ans, i) => {
                                              return (
                                                <HStack
                                                  backgroundColor={
                                                    i / 2 == 0
                                                      ? "#b0b0b04a"
                                                      : "white"
                                                  }
                                                  padding={"10px"}
                                                  style={{
                                                    border:
                                                      "1px solid #b0b0b04a",
                                                  }}
                                                >
                                                  <Box flex={1}>
                                                    <Text bold>
                                                      {
                                                        ans?.department_office
                                                          ?.office_name
                                                      }
                                                    </Text>
                                                  </Box>
                                                  <Box flex={1}>
                                                    <Text bold>
                                                      {ans.question_id ===
                                                        ind?.indicator
                                                          ?.second_numerator_question
                                                          ?.id && ans.value}
                                                    </Text>
                                                  </Box>
                                                  <Box flex={1}>
                                                    <Text
                                                      bold
                                                      color={
                                                        ans.state ===
                                                        "Submitted"
                                                          ? "#096dd9"
                                                          : ans.state ===
                                                            "Published"
                                                          ? "#1f996f"
                                                          : ans.state ===
                                                            "Reverted"
                                                          ? "#ff4d4f"
                                                          : ans.state ===
                                                            "NotSubmitted"
                                                          ? "#faad14"
                                                          : ans.state ===
                                                            "StateSubmitted"
                                                          ? "#52c41a"
                                                          : ""
                                                      }
                                                    >
                                                      {ans.question_id ===
                                                        ind?.indicator
                                                          ?.second_numerator_question
                                                          ?.id && ans.state}
                                                    </Text>
                                                  </Box>
                                                </HStack>
                                              );
                                            })}
                                        </Box>
                                      </div>
                                    ) : (
                                      <>
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      </>
                                    )}
                                  </VStack>
                                )}
                                {ind.indicator?.denominator_question && (
                                  <VStack mb={"5"}>
                                    <Box flex={1} paddingY={"3"}>
                                      <HStack space={"2"}>
                                        <Box>
                                          <AiFillQuestionCircle size={"20"} />
                                        </Box>
                                        <Box>
                                          <Text bold fontSize={"md"}>
                                            {
                                              ind?.indicator
                                                ?.denominator_question?.text
                                            }
                                          </Text>
                                        </Box>
                                      </HStack>
                                    </Box>
                                    {ind?.answers?.length ? (
                                      <div
                                        style={{
                                          paddingLeft: "20px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <Box>
                                          <HStack
                                            padding="3"
                                            alignItems={"center"}
                                            height={"40px"}
                                            style={{
                                              border: "1px solid #b0b0b04a",
                                            }}
                                          >
                                            <Box flex={1}>
                                              <Text bold>Department</Text>
                                            </Box>
                                            <Box flex={1}>
                                              <Text bold>Value</Text>
                                            </Box>
                                            <Box flex={1}>
                                              <Text bold>State</Text>
                                            </Box>
                                          </HStack>
                                        </Box>
                                        <Box>
                                          {ind?.answers
                                            ?.filter(
                                              (y) =>
                                                y.question_id ===
                                                ind?.indicator
                                                  ?.denominator_question?.id
                                            )
                                            ?.map((ans, i) => {
                                              return (
                                                <HStack
                                                  style={{
                                                    border:
                                                      "1px solid #b0b0b04a",
                                                  }}
                                                  backgroundColor={
                                                    i / 2 == 0
                                                      ? "#b0b0b04a"
                                                      : "white"
                                                  }
                                                  padding={"10px"}
                                                >
                                                  <Box flex={1}>
                                                    <Text bold>
                                                      {
                                                        ans?.department_office
                                                          ?.office_name
                                                      }
                                                    </Text>
                                                  </Box>
                                                  <Box flex={1}>
                                                    <Text bold>
                                                      {ans.question_id ===
                                                        ind?.indicator
                                                          ?.denominator_question
                                                          ?.id && ans.value}
                                                    </Text>
                                                  </Box>
                                                  <Box flex={1}>
                                                    <Text
                                                      bold
                                                      color={
                                                        ans.state ===
                                                        "Submitted"
                                                          ? "#096dd9"
                                                          : ans.state ===
                                                            "Published"
                                                          ? "#1f996f"
                                                          : ans.state ===
                                                            "Reverted"
                                                          ? "#ff4d4f"
                                                          : ans.state ===
                                                            "NotSubmitted"
                                                          ? "#faad14"
                                                          : ans.state ===
                                                            "StateSubmitted"
                                                          ? "#52c41a"
                                                          : ""
                                                      }
                                                    >
                                                      {ans.question_id ===
                                                        ind?.indicator
                                                          ?.denominator_question
                                                          ?.id && ans.state}
                                                    </Text>
                                                  </Box>
                                                </HStack>
                                              );
                                            })}
                                        </Box>
                                      </div>
                                    ) : (
                                      <>
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      </>
                                    )}
                                  </VStack>
                                )}
                              </VStack>
                            </Collapse.Panel>
                          );
                        })}
                      </Collapse>
                    </VStack>
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          </VStack>
        )}
      </Box>
    </div>
  );
};

export default UserSectorView;
