import { retrieveItem } from "@helpers/functions";
import {
  NOT_SUBMITTED,
  PENDING,
  PENDING_WITH_STATE,
  SUBMITTED,
  TOTAL_DEPARTMENTS,
  REVERTED,
} from "@helpers/image_constants";
import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { dashboard } from "@services/redux/slices/dynamic_entity/rest/rest_dashboard";
import CustomPageTitle from "@views/components/common/custom_page_title";
import DetailCard from "@views/components/user/dashboard/card";
import { Box, Center, HStack, SimpleGrid, Text } from "native-base";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import RevertedToMe from "@assets/icons/reverted1.png";
import RevertedByMe from "@assets/icons/reverted2.png";
import SubmittedByMe from "@assets/icons/submitted1.png";
import SubmittedToMe from "@assets/icons/submitted2.png";
import SkeletonLoading from "@views/components/ui/skeleton_loading/skeleton_loading";

const Dashboard = () => {
  const role = retrieveItem("user");
  const dispatch = useDispatch();
  const data = useDynamicSelector("dashboard");
  const get_dashboard = () => {
    let key = [{ key: "dashboard", loading: true }];
    dispatch(dynamicRequest(key, dashboard, {}, MethodType.GET));
  };
  useEffect(() => {
    get_dashboard();
  }, []);
  const gridConfig = {
    columns: {
      base: 2,
      sm: 2,
      xs: 1,
      md: 2,
      lg: 3,
      xl: 3,
      "2xl": 5,
      "3xl": 6,
    },
    spacingY: "28px",
    spacingX: 21,
    justifyContent: "center",
    alignItems: "center",
  };

  const renderCards = (cards) => (
    <SimpleGrid {...gridConfig}>
      {cards.map((card) => (
        <DetailCard
          key={card.name}
          {...card}
          key_value={card?.key}
          data={data}
        />
      ))}
    </SimpleGrid>
  );

  const dashboards = {
    "State DES User": [
      {
        name: "Approved",
        icon: PENDING_WITH_STATE,
        color: "#00D142",
        sub_color: "#00BD2B",
        value: 0,
        value: data?.approved_count,
        key: "approved",
      },
      {
        name: "Submitted",
        color: "#228B22",
        sub_color: "#1C731C",
        icon: SUBMITTED,
        value: data?.submitted_count,
        key: "submitted_to_me",
      },
      {
        name: "Reverted",
        color: "#CE2029",
        sub_color: "#B51C24",
        icon: REVERTED,
        value: data?.reverted_by_me_count,
        key: "reverted_by_me",
      },
      // {
      //   name: "Not Submitted",
      //   icon: NOT_SUBMITTED,
      //   color: "#D10014",
      //   sub_color: "#BD0012",
      //   value: data?.submitted_count,

      // },
      // {
      //   name: "Pending With District DES",
      //   color: "#C060A1",
      //   sub_color: "#a14e86",
      //   icon: TOTAL_DEPARTMENTS,
      //   value: data?.pending_with_des_count,
      //   key:"pending_with_des"
      // },
      // {
      //   name: "Pending with State Department",
      //   color: "#4D4C7D",
      //   sub_color: "#3d3d64",
      //   icon: PENDING,
      // },
      // {
      //   name: "Pending With District Department",
      //   color: "#5C3D2E",
      //   sub_color: "#482f23",
      //   icon: PENDING_WITH_STATE,
      //   value: data?.submitted_count,
      // },
      {
        name: "Published",
        color: "#133864",
        sub_color: "#0e2e54",
        icon: PENDING_WITH_STATE,
        value: data?.published_count,
        key: "published",
      },
    ],
    "Super Admin": [
      {
        name: "Approved",
        icon: PENDING_WITH_STATE,
        color: "#00D142",
        sub_color: "#00BD2B",
      },
      {
        name: "Submitted",
        color: "#228B22",
        sub_color: "#1C731C",
        icon: SUBMITTED,
      },
      {
        name: "Reverted",
        color: "#CE2029",
        sub_color: "#B51C24",
        icon: REVERTED,
      },
      // {
      //   name: "Not Submitted",
      //   icon: NOT_SUBMITTED,
      //   color: "#D10014",
      //   sub_color: "#BD0012",
      // },
      // {
      //   name: "Pending With State DES",
      //   color: "#efbb58",
      //   sub_color: "#d0a24d",
      //   icon: TOTAL_DEPARTMENTS,
      // },
      {
        name: "Pending With District DES",
        color: "#C060A1",
        sub_color: "#a14e86",
        icon: TOTAL_DEPARTMENTS,
      },
      // {
      //   name: "Pending with State Department",
      //   color: "#4D4C7D",
      //   sub_color: "#3d3d64",
      //   icon: PENDING,
      // },
      // {
      //   name: "Pending With District Department",
      //   color: "#5C3D2E",
      //   sub_color: "#482f23",
      //   icon: PENDING_WITH_STATE,
      // },
      {
        name: "Published",
        color: "#133864",
        sub_color: "#0e2e54",
        icon: PENDING_WITH_STATE,
      },
    ],
    "District Department User": [
      {
        name: "Submitted",
        color: "#228B22",
        sub_color: "#1C731C",
        icon: SUBMITTED,
        value: data?.submitted_count,
        key: "submitted_by_me",
      },
      {
        name: "Reverted",
        color: "#CE2029",
        sub_color: "#B51C24",
        icon: REVERTED,
        value: data?.reverted_to_me_count,
        key: "reverted_to_me",
      },
      // {
      //   name: "Not Submitted",
      //   icon: NOT_SUBMITTED,
      //   color: "#D10014",
      //   sub_color: "#BD0012",
      // },
    ],
    "District Collector": [
      {
        name: "Submitted",
        color: "#228B22",
        sub_color: "#1C731C",
        icon: SUBMITTED,
        value: data?.submitted_count,
        key: "submitted_by_me",
      },
      {
        name: "Reverted",
        color: "#CE2029",
        sub_color: "#B51C24",
        icon: REVERTED,
        value: data?.reverted_by_me_count,
        key: "reverted_by_me",
      },

      // {
      //   name: "Not Submitted",
      //   icon: NOT_SUBMITTED,
      //   color: "#D10014",
      //   sub_color: "#BD0012",
      // },
    ],
    "District DES User": [
      {
        name: "Submitted to me",
        color: "#1abd4ed9",
        sub_color: "#31af4d",
        icon: SubmittedToMe,
        value: data?.submitted_to_me_count,
        key: "submitted_to_me",
      },
      {
        name: "Submitted by me",
        color: "#228B22",
        sub_color: "#1C731C",
        icon: SubmittedByMe,
        value: data?.submitted_by_me_count,
        key: "submitted_by_me",
      },
      {
        name: "Reverted by me",
        color: "#A91201",
        sub_color: "#8F0F01",
        icon: RevertedByMe,
        value: data?.reverted_by_me_count,
        key: "reverted_by_me",
      },
      {
        name: "Reverted to me",
        color: "#CE2029",
        sub_color: "#B51C24",
        icon: RevertedToMe,
        value: data?.reverted_to_me_count,
        key: "reverted_to_me",
      },
      // {
      //   name: "Not Submitted", icon: NOT_SUBMITTED, color: "#D10014",
      //   sub_color: "#BD0012"
      // },
      // {
      //   name: "Pending With District Department",
      //   color: "#5C3D2E",
      //   sub_color: "#482f23",
      //   icon: PENDING_WITH_STATE,
      // },
    ],
  };
  return (
    <>
      {data?.loading ? (
        <Center
          marginTop={"4%"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <SkeletonLoading />
        </Center>
      ) : (
        <>
          <CustomPageTitle title="Dashboard" />
          <HStack justifyContent="center" width="100%" marginTop={"3%"}>
            <Text fontSize={"lg"} bold color={"gray.500"}>
              Survey{" "}
            </Text>
            <Text
              bold
              fontSize={"lg"}
              color={"gray.500"}
            >{`: ${data?.survey_name} (${data?.survey_year})`}</Text>
          </HStack>
          <HStack justifyContent="center" width="100%" marginTop={"4%"}>
            {role?.name &&
              dashboards[role.name] &&
              renderCards(dashboards[role.name])}
          </HStack>
        </>
      )}
    </>
  );
};

export default Dashboard;
