import { Form, Select } from "antd";
import React from "react";

const IndicatorType = () => {
  const indicator_type = [
    { value: "Impact", label: "Impact" },
    { value: "Support", label: "Support" },
  ];
  return (
    <Form.Item name="type" label="Type" labelCol={{ span: 24 }} rules={[{required:true,message:"Type is monitory"}]}>
      <Select
        style={{ fontSize: "16px" }}
        allowClear
      >
        {indicator_type?.map((option) => (
          <Select.Option key={option?.value} value={option?.value}>
            {option?.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default IndicatorType;
