import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

export function showToast({ type, message, duration = 5000, id = uuidv4() }) {
  switch (type) {
    case "info":
      toast.info(message);
      break;
    case "success":
      toast.success(message);
      break;
    case "warn":
      toast.warn(message);
      break;
    case "error":
      toast.error(message);
      break;
    default:
      toast(message);
  }
}
