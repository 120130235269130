import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { MethodType } from "@helpers/service_call";
import { get_all_level } from "@services/redux/slices/dynamic_entity/rest/rest_levels";

const FormLevel = () => {
  const dispatch = useDispatch();

  const { items: levels_list, loading: question_loading } =
    useDynamicSelector("get_all_level");
  const get_all_level_list = (data) => {
    let keys = [{ key: "get_all_level", loading: true }];
    dispatch(dynamicRequest(keys, get_all_level, {}, MethodType.POST));
  };

  useEffect(() => {
    get_all_level_list();
  }, []);
  return (
    <Form.Item
      name="level_id"
      label="Level"
      rules={[
        {
          required: true,
          message: "Level is monitory",
        },
      ]}
    >
      <Select
        style={{ fontSize: "16px" }}
        allowClear
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {levels_list?.map((option, index) => (
          <Select.Option key={option?.id} value={option?.id}>
            {option?.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FormLevel;
