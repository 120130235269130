import { Button, Input, Table } from 'antd'
import { HStack, Pressable, VStack } from 'native-base';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import CustomPageTitle from '../common/custom_page_title';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@views/routes/my_routes';
import DeleteQuestionModal from './delete_question_modal';
import { dynamicRequest, useDynamicSelector } from '@services/redux';
import { get_all_question } from '@services/redux/slices/dynamic_entity/rest/rest_question';
import { MethodType } from '@helpers/service_call';
import { useDispatch } from 'react-redux';
import { navigateWithProps } from '@helpers/functions';

const QuestionList = () => {
    const { t } = useTranslation();
    const history = useHistory()
    const [delete_modal, set_delete_modal] = useState(false)
    const [form_values, set_form_values] = useState({})
    const dispatch = useDispatch()
    const { items: question_list, loading: question_loading } = useDynamicSelector("get_all_question")

    const { Search } = Input;
    const handle_edit = (record) => {
        navigateWithProps(`${ROUTES.QUESTIONS_DETAILS}/edit/${record?.id}`, record)
    }

    const handle_delete = (record) => {
        set_form_values(record)
        set_delete_modal(true)
    }
    const indicator_actions = (record) => {
        return (
            <HStack space={"20px"}>
                {/* <Pressable onPress={() => { handle_edit(record) }}>
                    <FiEdit size={18} />
                </Pressable> */}
                <Pressable onPress={() => { handle_delete(record) }}>
                    <AiFillDelete size={18} />
                </Pressable>
            </HStack>
        )
    }

    const column = [
        {
            title: t("table:name"),
            dataIndex: "text",
            key: "text",
        },
        {
            title: t("table:action"),
            dataIndex: "",
            key: "",
            width: "",
            render: (record) => indicator_actions(record)
        },

    ]

    const handle_add = () => {
        history.push(ROUTES.QUESTIONS_DETAILS)

    }
    const handle_close = () => {
        set_delete_modal(false)
        set_form_values({})
    }

    const get_all_question_list = (data) => {
        let keys = [{ key: "get_all_question", loading: true }];
        dispatch(dynamicRequest(keys, get_all_question, { search_string: data }, MethodType.POST));
    };
    useEffect(() => {
        get_all_question_list()
    }, [])

    const onSearch = (value) => {
        let search_value = value?.target?.value || value
        if (search_value?.length > 2 || search_value?.length === 0)
            get_all_question_list(search_value)
    }
    return (
        <div>
            <CustomPageTitle title="Questions" />
            <VStack padding={"20px"}>
                <HStack justifyContent={"flex-end"} mb={"20px"} space={"20px"}>
                    <Search
                        enterButton={true}
                        onSearch={onSearch}
                        allowClear={true}
                        onChange={onSearch}
                        placeholder="Search"
                        style={{ width: '250px' }}
                    />
                    <Button onClick={handle_add} type="primary">
                        Add Question
                    </Button>
                </HStack>
                <Table columns={column} dataSource={question_list} loading={question_loading} onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => handle_edit(record), // click row
                    };
                }} />
                <DeleteQuestionModal open={delete_modal} onCancel={handle_close} form_item={form_values} title={"Delete Question"} get_all_question_list={get_all_question_list} />
            </VStack>
        </div>
    )
}

export default QuestionList
