import { Form } from "antd";
import PreviousQuestion from "./previous_question";
import QuestionName from "./question_name";
import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import { Box, HStack } from "native-base";

const QuestionForm = (props) => {
  const { form } = props;
  return (
    <>
      <HStack space={5}>
        <Box flex={1}>
          <QuestionName />
        </Box>
        <Box flex={1}>
          <AntdDynamicForm.Number
            field="fixed_answer"
            label={<span className="label-font-size">Fixed Answer</span>}
            rules={[
              {
                required: true,
                message: "Fixed Answer is monitory",
              },
            ]}
          />
        </Box>
      </HStack>

      <PreviousQuestion form={form} />
    </>
  );
};

export default QuestionForm;
