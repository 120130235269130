import { Button, Input, Modal, Table, message } from 'antd'
import { HStack, Pressable, Text, VStack } from 'native-base'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillDelete } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import AddIndicatorForm from './indicator_form/add_indicator_form'
import { dynamicClear, dynamicRequest, get_all_department, useDynamicSelector } from '@services/redux'
import { delete_indicator, get_all_indicator } from '@services/redux/slices/dynamic_entity/rest/rest_indicators'
import { useDispatch } from 'react-redux'
import { MethodType } from '@helpers/service_call'
import DeleteIndicatorModal from './delete_sector_modal'

const IndicatorList = () => {
    const { t } = useTranslation();
    const [indicator_modal, set_indicator_modal] = useState(false)
    const [indicator_delete_modal, set_indicator_delete_modal] = useState(false)
    const [indicator_form_values, set_indicator_form_values] = useState({})
    const dispatch = useDispatch()
    const { items: indicator_list, loading: indicator_loading } = useDynamicSelector("get_all_indicator")
    const { status: delete_status, loading: indicator_delete_loading, error: delete_error } = useDynamicSelector("delete_indicator")
    const { Search } = Input;

    const handle_edit = (record) => {
        set_indicator_form_values(record)
        set_indicator_modal(true)
    }

    const handle_delete = (record) => {
        set_indicator_delete_modal(true)
        set_indicator_form_values(record)
    }

    const handle_add_indicator = () => {
        set_indicator_form_values({})
        set_indicator_modal(true)
    }
    const handle_close = () => {
        set_indicator_delete_modal(false)
        set_indicator_modal(false)
    }
    const indicator_actions = (record) => {
        return (
            <HStack space={"20px"}>
                {/* <Pressable onPress={() => { handle_edit(record) }}>
                    <FiEdit size={18} />
                </Pressable> */}
                <Pressable onPress={() => { handle_delete(record) }}>
                    <AiFillDelete size={18} />
                </Pressable>
            </HStack>
        )
    }
    const get_all_indicator_list = (data) => {
        let keys = [{ key: "get_all_indicator", loading: true }];
        dispatch(dynamicRequest(keys, get_all_indicator, { search_string: data }, MethodType.POST));
    };

    useEffect(() => {
        get_all_indicator_list()
    }, [])

    useEffect(() => {
        if (delete_status === "success") {
            message.success(t("indicator_deleted"))
            dispatch(dynamicClear("delete_indicator"))
            handle_close()
            get_all_indicator_list()
        } else if (delete_error) {
            message.error(t("something_went_wrong"))
            dispatch(dynamicClear("delete_indicator"))
        }
    }, [delete_status, delete_error])

    const format_numerator_display = (value) => {
        return (
            <VStack justifyContent={"center"} alignItems={"center"}>
                {value?.numerator_question &&
                    <Text>
                        {` ${value?.numerator_question?.text} `}
                    </Text>
                }
                {value?.second_numerator_question &&
                    <>
                        <Text bold fontSize={20}>
                            -
                        </Text>
                        <Text>
                            {` ${value?.second_numerator_question?.text} `}
                        </Text>
                    </>
                }
            </VStack>
        )
    }
    const format_denominator_display = (record) => {

        return (
            <Text>
                {record?.denominator_question?.text}
            </Text>
        )
    }
    const column = [
        {
            title: t("table:indicator_number"),
            dataIndex: "unique_id",
            key: "unique_id",
            width: '20px'
        },
        {
            title: t("table:name"),
            dataIndex: "name",
            key: "name",
            width: "150px",
        },
        {
            title: t("table:numerator"),
            dataIndex: "",
            key: "",
            width: "150px",
            render: (record) => format_numerator_display(record)
        },
        {
            title: t("table:denominator"),
            dataIndex: "",
            key: "",
            width: "150px",
            render: (record) => format_denominator_display(record)

        },
        {
            title: t("table:action"),
            dataIndex: "",
            key: "",
            width: "30px",
            render: (record) => indicator_actions(record)
        },
    ]

    const handle_delete_indicator = () => {
        let keys = [{ key: "delete_indicator", loading: true }];
        dispatch(dynamicRequest(keys, delete_indicator + indicator_form_values?.id, {}, MethodType.DELETE));
    }
    const onSearch = (value) => {
        let search_value = value?.target?.value || value
        if (search_value?.length > 2 || search_value?.length === 0)
            get_all_indicator_list(search_value)
    }
    return (
        <VStack padding={"20px"}>
            <HStack justifyContent={"flex-end"} mb={"20px"} space={"20px"}>
                <Search
                    enterButton={true}
                    onSearch={onSearch}
                    allowClear={true}
                    onChange={onSearch}
                    placeholder="Search"
                    style={{ width: '250px' }}
                />
                <Button onClick={handle_add_indicator} type='primary'>
                    {t("add_indicator")}
                </Button>
            </HStack>
            <Table columns={column} dataSource={indicator_list} loading={indicator_loading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => handle_edit(record), // click row
                    };
                }} />
            <Modal open={indicator_modal} onCancel={handle_close} width={"1300px"} title={indicator_form_values?.id ? t("update_indicator") : t("add_indicator")} footer={null}>
                <AddIndicatorForm form_values={indicator_form_values} onCancel={handle_close} get_all_indicator_list={get_all_indicator_list} />
            </Modal>
            <DeleteIndicatorModal onCancel={handle_close} open={indicator_delete_modal} form_item={indicator_form_values} title={"Confirmation"} delete_loading={indicator_delete_loading} delete_function={handle_delete_indicator} />
        </VStack>
    )
}

export default IndicatorList
