import { MethodType } from '@helpers/service_call'
import { dynamicRequest, useDynamicSelector } from '@services/redux'
import { get_all_sector } from '@services/redux/slices/dynamic_entity/rest/rest_sector'
import { Card, Form, Select, Table } from 'antd'
import { HStack, Text, VStack } from 'native-base'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

const UserDistrictWiseSector = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [indicators, set_indicators] = useState([])
  const { items: sector_list, loading: sector_loading } = useDynamicSelector("get_all_sector")

  let column = [
    {
      title: t("table:s_no"),
      dataIndex: "Sl_No",
      key: "Sl_No"
    },
    {
      title: t("table:district"),
      dataIndex: "District",
      key: "District"
    },
    {
      title: t("table:indicator_value"),
      dataIndex: "Indicator_Value",
      key: "Indicator_Value"
    },
    {
      title: t("table:max_value"),
      dataIndex: "Max_Value",
      key: "Max_Value"
    },

    {
      title: t("table:min_value"),
      dataIndex: "Min_Value",
      key: "Min_Value"
    },
    {
      title: t("table:normalize"),
      dataIndex: "Normalize",
      key: "Normalize"
    },
    {
      title: t("table:indicator_score"),
      dataIndex: "Indicator_Score",
      key: "Indicator_Score"
    },

  ]

  let districts = [
    {
      "Sl_No": 1,
      "District": "Chennai",
      "Indicator_Value": 0.000,
      "Max_Value": 15.000,
      "Min_Value": 0.000,
      "Normalize": 0.000,
      "Indicator_Score": 0.000
    },
    {
      "Sl_No": 2,
      "District": "Chengalpet",
      "Indicator_Value": 9.040,
      "Max_Value": null,
      "Min_Value": 0.603,
      "Normalize": 0.060,
      "Indicator_Score": null
    },
    {
      "Sl_No": 3,
      "District": "Kanchipuram",
      "Indicator_Value": 4.462,
      "Max_Value": null,
      "Min_Value": 0.297,
      "Normalize": 0.030,
      "Indicator_Score": null
    },
    {
      "Sl_No": 4,
      "District": "Tiruvallur",
      "Indicator_Value": 5.624,
      "Max_Value": null,
      "Min_Value": 0.375,
      "Normalize": 0.037,
      "Indicator_Score": null
    },
    {
      "Sl_No": 5,
      "District": "Tiruvannamalai",
      "Indicator_Value": 3.186,
      "Max_Value": null,
      "Min_Value": 0.212,
      "Normalize": 0.021,
      "Indicator_Score": null
    },
    {
      "Sl_No": 6,
      "District": "Vellore",
      "Indicator_Value": 15.000,
      "Max_Value": null,
      "Min_Value": 1.000,
      "Normalize": 0.100,
      "Indicator_Score": null
    },
    {
      "Sl_No": 7,
      "District": "Coimbatore",
      "Indicator_Value": 6.880,
      "Max_Value": null,
      "Min_Value": 0.459,
      "Normalize": 0.046,
      "Indicator_Score": null
    },
    {
      "Sl_No": 8,
      "District": "Erode",
      "Indicator_Value": 6.469,
      "Max_Value": null,
      "Min_Value": 0.431,
      "Normalize": 0.043,
      "Indicator_Score": null
    },
    {
      "Sl_No": 9,
      "District": "Karur",
      "Indicator_Value": 3.922,
      "Max_Value": null,
      "Min_Value": 0.261,
      "Normalize": 0.026,
      "Indicator_Score": null
    },
    {
      "Sl_No": 10,
      "District": "Namakkal",
      "Indicator_Value": 4.402,
      "Max_Value": null,
      "Min_Value": 0.293,
      "Normalize": 0.029,
      "Indicator_Score": null
    },
    {
      "Sl_No": 11,
      "District": "Nilgiris",
      "Indicator_Value": 4.902,
      "Max_Value": null,
      "Min_Value": 0.327,
      "Normalize": 0.033,
      "Indicator_Score": null
    },
    {
      "Sl_No": 12,
      "District": "Tiruppur",
      "Indicator_Value": 3.715,
      "Max_Value": null,
      "Min_Value": 0.248,
      "Normalize": 0.025,
      "Indicator_Score": null
    },
    {
      "Sl_No": 13,
      "District": "Cuddalore",
      "Indicator_Value": 2.592,
      "Max_Value": null,
      "Min_Value": 0.173,
      "Normalize": 0.017,
      "Indicator_Score": null
    },
    {
      "Sl_No": 14,
      "District": "Thiruvarur",
      "Indicator_Value": 4.933,
      "Max_Value": null,
      "Min_Value": 0.329,
      "Normalize": 0.033,
      "Indicator_Score": null
    },
    {
      "Sl_No": 15,
      "District": "Thanjavur",
      "Indicator_Value": 4.637,
      "Max_Value": null,
      "Min_Value": 0.309,
      "Normalize": 0.031,
      "Indicator_Score": null
    },
    {
      "Sl_No": 16,
      "District": "Kallakurichi",
      "Indicator_Value": 1.392,
      "Max_Value": null,
      "Min_Value": 0.093,
      "Normalize": 0.009,
      "Indicator_Score": null
    },
    {
      "Sl_No": 17,
      "District": "Nagapattinam",
      "Indicator_Value": 2.821,
      "Max_Value": null,
      "Min_Value": 0.188,
      "Normalize": 0.019,
      "Indicator_Score": null
    },
    {
      "Sl_No": 18,
      "District": "Villupuram",
      "Indicator_Value": 3.541,
      "Max_Value": null,
      "Min_Value": 0.236,
      "Normalize": 0.024,
      "Indicator_Score": null
    },
    {
      "Sl_No": 19,
      "District": "Madurai",
      "Indicator_Value": 8.234,
      "Max_Value": null,
      "Min_Value": 0.549,
      "Normalize": 0.055,
      "Indicator_Score": null
    },
    {
      "Sl_No": 20,
      "District": "Dindigul",
      "Indicator_Value": 2.058,
      "Max_Value": null,
      "Min_Value": 0.137,
      "Normalize": 0.014,
      "Indicator_Score": null
    },
    {
      "Sl_No": 21,
      "District": "Theni",
      "Indicator_Value": 5.834,
      "Max_Value": null,
      "Min_Value": 0.389,
      "Normalize": 0.039,
      "Indicator_Score": null
    },
    {
      "Sl_No": 22,
      "District": "Sivagangai",
      "Indicator_Value": 4.068,
      "Max_Value": null,
      "Min_Value": 0.271,
      "Normalize": 0.027,
      "Indicator_Score": null
    },
    {
      "Sl_No": 23,
      "District": "Pudukottai",
      "Indicator_Value": 3.555,
      "Max_Value": null,
      "Min_Value": 0.237,
      "Normalize": 0.024,
      "Indicator_Score": null
    },
    {
      "Sl_No": 24,
      "District": "Salem",
      "Indicator_Value": 5.360,
      "Max_Value": null,
      "Min_Value": 0.357,
      "Normalize": 0.036,
      "Indicator_Score": null
    },
    {
      "Sl_No": 25,
      "District": "Dharmapuri",
      "Indicator_Value": 1.329,
      "Max_Value": null,
      "Min_Value": 0.089,
      "Normalize": 0.009,
      "Indicator_Score": null
    },
    {
      "Sl_No": 26,
      "District": "Krishnagiri",
      "Indicator_Value": 5.355,
      "Max_Value": null,
      "Min_Value": 0.357,
      "Normalize": 0.036,
      "Indicator_Score": null
    },
    {
      "Sl_No": 27,
      "District": "Ariyalur",
      "Indicator_Value": 4.208,
      "Max_Value": null,
      "Min_Value": 0.281,
      "Normalize": 0.028,
      "Indicator_Score": null
    },
    {
      "Sl_No": 28,
      "District": "Perambalur",
      "Indicator_Value": 6.417,
      "Max_Value": null,
      "Min_Value": 0.428,
      "Normalize": 0.043,
      "Indicator_Score": null
    },
    {
      "Sl_No": 29,
      "District": "Trichy",
      "Indicator_Value": 2.826,
      "Max_Value": null,
      "Min_Value": 0.188,
      "Normalize": 0.019,
      "Indicator_Score": null
    },
    {
      "Sl_No": 30,
      "District": "Kanyakumari",
      "Indicator_Value": 2.466,
      "Max_Value": null,
      "Min_Value": 0.164,
      "Normalize": 0.016,
      "Indicator_Score": null
    },
    {
      "Sl_No": 31,
      "District": "Tenkasi",
      "Indicator_Value": 2.392,
      "Max_Value": null,
      "Min_Value": 0.159,
      "Normalize": 0.016,
      "Indicator_Score": null
    },
    {
      "Sl_No": 32,
      "District": "Tirunelveli",
      "Indicator_Value": 5.198,
      "Max_Value": null,
      "Min_Value": 0.346,
      "Normalize": 0.035,
      "Indicator_Score": null
    },
    {
      "Sl_No": 33,
      "District": "Thoothukudi",
      "Indicator_Value": 6.636,
      "Max_Value": null,
      "Min_Value": 0.442,
      "Normalize": 0.044,
      "Indicator_Score": null
    },
    {
      "Sl_No": 34,
      "District": "Ramanathapuram",
      "Indicator_Value": 2.986,
      "Max_Value": null,
      "Min_Value": 0.199,
      "Normalize": 0.020,
      "Indicator_Score": null
    },
    {
      "Sl_No": 35,
      "District": "Virudhunagar",
      "Indicator_Value": 7.978,
      "Max_Value": null,
      "Min_Value": 0.532,
      "Normalize": 0.053,
      "Indicator_Score": null
    }
  ]

  const get_all_sector_list = () => {
    let keys = [{ key: "get_all_sector", loading: true }];
    dispatch(dynamicRequest(keys, get_all_sector, {}, MethodType.POST));
  };
  useEffect(() => {
    get_all_sector_list()
  }, [])
  const handle_change_sector = (val, values) => {
    let get_indicator_values = sector_list.filter((list) => list?.id === val)?.[0]?.indicators
    set_indicators(get_indicator_values)
  }
  return (
    <VStack padding={"40px"} space={"40px"}>
      <HStack>
        <Text fontSize={"20px"}>
          Survey
        </Text>
        <Text>

        </Text>
      </HStack>

      {/* filter */}
      <HStack space={"10px"}>
        <VStack width={"300px"}>
          <Text bold>
            Sector
          </Text>
          <Select onChange={handle_change_sector} showSearch>
            {sector_list?.map((option) => (
              <Select.Option key={option?.id} value={option?.id}>
                {option?.name}
              </Select.Option>
            ))}
          </Select>
        </VStack>
        {/* <VStack width={"400px"}>
          <Text bold>
            Indicator
          </Text>
          <Select showSearch>
            {indicators?.map((option) => (
              <Select.Option key={option?.id} value={option?.id}>
                {option?.name}
              </Select.Option>
            ))}
          </Select>
        </VStack> */}
      </HStack>

      {/* Description for indicator */}
      <Card style={{ width: "80%" }}>
        <HStack>
          <Text>
            Indicator name
          </Text>
          <Text>{`: ${""}`}</Text>
        </HStack>
      </Card>

      {/* District wise  */}
      <Table columns={column} dataSource={districts} pagination={false} />
    </VStack>
  )
}

export default UserDistrictWiseSector
