import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_all_question } from "@services/redux/slices/dynamic_entity/rest/rest_question";
import { Checkbox, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const PreviousQuestion = ({ form }) => {
  const is_previous_question = Form.useWatch("is_previous_question", form);
  const [checked, set_checked] = useState(false);
  const options = [
    { value: "option1", label: "Question 1" },
    { value: "option2", label: "Question 2" },
    { value: "option3", label: "Question 3" },
  ];
  const { items: question_list, loading: question_loading } =
    useDynamicSelector("get_all_question");
  const dispatch = useDispatch();
  const handle_change = (value) => {
    set_checked(value?.target?.checked);
  };
  const get_all_question_list = (data) => {
    let keys = [{ key: "get_all_question", loading: true }];
    dispatch(dynamicRequest(keys, get_all_question, {}, MethodType.POST));
  };
  useEffect(() => {
    set_checked(is_previous_question);
  }, [is_previous_question]);
  useEffect(() => {
    get_all_question_list();
  }, []);
  return (
    <>
      <Form.Item
        name={"is_previous_question"}
        style={{ height: "30px", fontSize: "16px" }}
      >
        <Checkbox onChange={handle_change} checked={checked}>
          <span className="label-font-size">Previous year Question</span>
        </Checkbox>
      </Form.Item>
      {checked && (
        <Form.Item
          name="same_as_previous_year_question_id"
          label={<span className="label-font-size">Question</span>}
          rules={[
            {
              required: true,
              message: "Question is monitory",
            },
          ]}
        >
          <Select
            style={{ width: "350px", height: "30px", fontSize: "16px" }}
            allowClear
            showSearch
          >
            {question_list?.map((option, index) => (
              <Select.Option key={option?.id} value={option?.id}>
                {option?.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
};

export default PreviousQuestion;
