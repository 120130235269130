import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { delete_survey_indicator } from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { Modal, message } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const DeleteIntegratorModal = ({
  sector_form,
  type,
  open,
  onCancel,
  title,
  form_item,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    loading: delete_loading,
    status: delete_status,
    error: delete_error,
  } = useDynamicSelector("delete_survey_indicator");
  const delete_one_survey_sector = () => {
    let keys = [{ key: "delete_survey_indicator", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        delete_survey_indicator + form_item?.id,
        {},
        MethodType.DELETE
      )
    );
  };
  useEffect(() => {
    if (delete_status === "success") {
      message.success(t("indicator_removed"));
      dispatch(dynamicClear("delete_survey_indicator"));
      onCancel();
      // get_all_indicator_list()
    } else if (delete_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("delete_survey_indicator"));
    }
  }, [delete_status, delete_error]);
  return (
    <div>
      <Modal
        open={open}
        onCancel={onCancel}
        title={title}
        onOk={delete_one_survey_sector}
        confirmLoading={delete_loading}
        cancelButtonProps={{ danger: true }}
      >
        Do you want to delete <b>{form_item?.indicator?.name}</b> integrator?
      </Modal>
    </div>
  );
};

export default DeleteIntegratorModal;
