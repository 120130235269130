import React, { useState, useRef } from "react";
import {
  UploadOutlined,
  FolderViewOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Checkbox as ANTCheckBox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio as ANTRadio,
  Select as ANTSelect,
  Switch as ANTSwitch,
  // Upload as ANTUpload,
  Upload,
  Cascader,
  Row,
  Col,
  Button,
} from "antd";
import JoditEditor from "jodit-react";
import lodash from "lodash";
import axios from "axios";
import FormData from "form-data";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import DocumentViewer from "./document_viewer";
import { showToast } from "@helpers/functions";
// import { QueryRequest } from "@services/apollo/api_service";
import { file_query } from "@services/redux";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const { TextArea: TextAreaInput, Password: PasswordInput } = Input;
const { Option } = ANTSelect;
const { RangePicker, TimePicker } = DatePicker;

const FormItem = (props) => {
  const {
    type,
    index,
    label,
    field,
    tooltip,
    dependencies,
    rules,
    disabled,
    defaultValue,
    controls,
    showCount,
    maxLength,
    isMulti,
    isSearchable,
    valueField,
    labelField,
    options,
    columns,
    prefix,
    suffix,
  } = props;
  const inputFileRef = useRef();
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [isDocOpen, setIsDocOpen] = useState(false);

  const onFileSelect = async (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadError(null);
    let file = event.target.files[0];
    const url =
      "https://docy-assets.blr1.vultrobjects.com/new11.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=A450TJP2662ZW9AX0VAL%2F20230502%2Fblr1%2Fs3%2Faws4_request&X-Amz-Date=20230502T094037Z&X-Amz-Expires=3000&X-Amz-Signature=9102aea56f23ef4ffcfdabc0579d7c5a8b7b074a4a35d7937a50f045d46b0306&X-Amz-SignedHeaders=host";
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.upload.addEventListener("progress", (event) => {
      const progress = (event.loaded / event.total) * 100;
      setUploadProgress(progress);
    });

    xhr.onload = () => {
      if (xhr.status === 200) {
      } else {
        setUploadError("Error");
      }
    };

    xhr.onerror = () => {
      setUploadError("Error");
    };

    xhr.send(file);
    return "ok";
  };

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const handleUpload = () => {
  //   setUploadProgress(0);
  //   const xhr = new XMLHttpRequest();

  //   xhr.upload.addEventListener("progress", (event) => {
  //     if (event.lengthComputable) {
  //       const percentComplete = (event.loaded / event.total) * 100;
  //       setUploadProgress(percentComplete);
  //     }
  //   });
  //   uploadFile(selectedFile).then((response) => {
  //   });
  // };

  const openViewDoc = () => {
    setIsDocOpen(true);
  };

  const closeViewDoc = () => {
    setIsDocOpen(false);
  };

  const prefixItem = (
    <Form.Item name={prefix?.field} noStyle>
      <ANTSelect style={{ width: prefix?.width || 70 }}>
        {prefix?.options &&
          prefix.options?.map((item, index) => {
            let value = lodash.get(item, valueField || "id", null);
            let label = lodash.get(item, labelField || "name", null);
            return (
              <Option value={value} key={index}>
                {label}
              </Option>
            );
          })}
      </ANTSelect>
    </Form.Item>
  );
  const suffixItem = (
    <Form.Item name={suffix?.field} noStyle>
      <ANTSelect style={{ width: suffix?.width || 70 }}>
        {suffix?.options &&
          suffix.options?.map((item, index) => {
            let value = lodash.get(item, valueField || "id", null);
            let label = lodash.get(item, labelField || "name", null);
            return (
              <Option value={value} key={index}>
                {label}
              </Option>
            );
          })}
      </ANTSelect>
    </Form.Item>
  );
  const TextBox = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <Input
        disabled={disabled}
        defaultValue={defaultValue}
        addonBefore={prefix ? prefixItem : null}
        addonAfter={suffix ? suffixItem : null}
        maxLength={maxLength || 10000000}
      />
    </Form.Item>
  );
  const NumberBox = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <InputNumber
        style={{ width: "100%" }}
        controls={controls || false}
        disabled={disabled}
        defaultValue={defaultValue}
        addonBefore={prefix ? prefixItem : null}
        addonAfter={suffix ? suffixItem : null}
        maxLength={maxLength || 10000000}
      />
    </Form.Item>
  );
  const TextArea = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <TextAreaInput
        disabled={disabled}
        showCount={showCount || false}
        maxLength={maxLength || 10000000}
      />
    </Form.Item>
  );
  const Password = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <PasswordInput
        disabled={disabled}
        addonBefore={prefix ? prefixItem : null}
        addonAfter={suffix ? suffixItem : null}
      />
    </Form.Item>
  );
  const Select = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <ANTSelect
        mode={isMulti ? "multiple" : "single"}
        disabled={disabled}
        defaultValue={defaultValue}
        showSearch={isSearchable}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        addonBefore={prefix ? prefixItem : null}
        addonAfter={suffix ? suffixItem : null}
      >
        {options?.map((item, index) => {
          let value = lodash.get(item, valueField || "id", null);
          let label = lodash.get(item, labelField || "name", null);
          return (
            <Option value={value} key={index}>
              {label}
            </Option>
          );
        })}
      </ANTSelect>
    </Form.Item>
  );
  const Switch = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
      valuePropName="checked"
    >
      <ANTSwitch disabled={disabled} />
    </Form.Item>
  );
  const Radio = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <ANTRadio.Group>
        {options?.map((item, index) => {
          let value = lodash.get(item, valueField || "id", null);
          let label = lodash.get(item, labelField || "name", null);
          return (
            <ANTRadio key={index} value={value} disabled={item.disabled}>
              {label}
            </ANTRadio>
          );
        })}
      </ANTRadio.Group>
    </Form.Item>
  );
  const RadioButton = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <ANTRadio.Group>
        {options?.map((item, index) => {
          let value = lodash.get(item, valueField || "id", null);
          let label = lodash.get(item, labelField || "name", null);
          return (
            <ANTRadio.Button key={index} value={value} disabled={item.disabled}>
              {label}
            </ANTRadio.Button>
          );
        })}
      </ANTRadio.Group>
    </Form.Item>
  );
  const CheckBox = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <ANTCheckBox.Group>
        <Row>
          {options?.map((item, index) => {
            let _columns = columns || 3;
            let value = lodash.get(item, valueField || "id", null);
            let label = lodash.get(item, labelField || "name", null);
            return (
              <Col span={24 / _columns}>
                <ANTCheckBox
                  key={index}
                  value={value}
                  disabled={item.disabled}
                  style={{ lineHeight: "32px" }}
                >
                  {label}
                </ANTCheckBox>
              </Col>
            );
          })}
        </Row>
      </ANTCheckBox.Group>
    </Form.Item>
  );
  const Date = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <DatePicker showTime={type === "datetime"} disabled={disabled} />
    </Form.Item>
  );
  const DateRange = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <RangePicker showTime={type === "datetime_range"} disabled={disabled} />
    </Form.Item>
  );
  const Month = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <DatePicker picker="month" disabled={disabled} />
    </Form.Item>
  );
  const Year = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <DatePicker picker="year" disabled={disabled} />
    </Form.Item>
  );
  const Time = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <TimePicker disabled={disabled} />
    </Form.Item>
  );
  const RichText = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
    >
      <JoditEditor />
    </Form.Item>
  );
  // const Upload = (
  //   <Form.Item
  //     key={index}
  //     label={label}
  //     name={field}
  //     dependencies={dependencies}
  //     rules={rules}
  //     tooltip={tooltip}
  //   >
  //     <HStack w="100%">
  //       <HStack w="90%">
  //         <input
  //           type="file"
  //           ref={inputFileRef}
  //           style={{ visibility: "hidden", width: "0px" }}
  //           onChange={onFileSelect}
  //         />
  //         <Button
  //           icon={<UploadOutlined />}
  //           onClick={() => {
  //             inputFileRef.current.click();
  //           }}
  //         >
  //           {defaultValue || selectedFile ? "Change File" : "Choose File"}
  //         </Button>
  //         <Center ml="5px" w="100px">
  //           <Text w="100%">{selectedFile?.name}</Text>
  //         </Center>
  //         {!uploadError && uploadProgress > 0 && (
  //           <Center mt="5px">
  //             <progress value={uploadProgress} max="100">
  //               {uploadProgress}%
  //             </progress>
  //           </Center>
  //         )}
  //         {uploadError && (
  //           <Center mt="5px">
  //             <Text w="100%" color={"#ff0000"}>
  //               Error uploading..
  //             </Text>
  //           </Center>
  //         )}
  //       </HStack>
  //       <Center mt="5px">
  //         <HStack>
  //           <Pressable onPress={openViewDoc}>
  //             <FolderViewOutlined style={{ fontSize: "16px", color: "blue" }} />
  //           </Pressable>
  //           <Pressable onPress={openViewDoc} ml="10px">
  //             <DeleteOutlined style={{ fontSize: "15px", color: "red" }} />
  //           </Pressable>
  //         </HStack>
  //       </Center>
  //     </HStack>
  //     <DocumentViewer
  //       title="Preview"
  //       isOpen={isDocOpen}
  //       onClose={closeViewDoc}
  //     />
  //   </Form.Item>
  // );

  let file_upload_response = "";
  const [fileList, setFileList] = useState({});
  const [headers, setHeaders] = useState();
  const handleBeforeUpload = async ({ name, type }) => {
    let file_upload = {
      type: type,
      fileName: name,
    };

    // file_upload_response = await QueryRequest(file_query, file_upload);
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      file = {
        ...file,
        url: file_upload_response?.data?.getUploadUrl?.url,
      };
      setFileList([file]);
      return file;
    } else if (file.status === "done") {
      // toast.success(t("document_updated_successfully"));

      // showToast({
      //   type: "success",
      //   message: "Image updated successfully",
      // });
      setFileList([...fileList]);
      return file;
    }
  };
  const handleUpload = ({ onSuccess, onError, file }) => {
    axios
      .put(fileList?.[0]?.url, file, {
        headers: { "Content-Type": file.type },
      })
      .then((response) => {
        file.url = fileList?.[0]?.url.split("?")[0];

        onSuccess(null, file);
      })
      .catch((error) => {
      });
  };
  const action = file_upload_response?.data?.getUploadUrl;
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const File = (
    <Form.Item
      key={index}
      label={label}
      name={field}
      dependencies={dependencies}
      rules={rules}
      tooltip={tooltip}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      extra=""
    >
      <Upload
        maxCount={1}
        action={action}
        headers={headers}
        fileList={fileList}
        disabled={props.isdisabled}
        // showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
        customRequest={(e) => handleUpload(e)}
        beforeUpload={(args) => handleBeforeUpload(args)}
        onChange={(e) => handleFileChanged(e)}
      >
        <Button icon={<UploadOutlined />}>Click to upload</Button>{" "}
      </Upload>
    </Form.Item>
  );

  if (type === "text") {
    return TextBox;
  } else if (type === "number") {
    return NumberBox;
  } else if (type === "text_area") {
    return TextArea;
  } else if (type === "password") {
    return Password;
  } else if (type === "select") {
    return Select;
  } else if (type === "switch") {
    return Switch;
  } else if (type === "radio") {
    return Radio;
  } else if (type === "radio_button") {
    return RadioButton;
  } else if (type === "checkbox") {
    return CheckBox;
  } else if (type === "date" || type === "datetime") {
    return Date;
  } else if (type === "date_range" || type === "datetime_range") {
    return DateRange;
  } else if (type === "month") {
    return Month;
  } else if (type === "year") {
    return Year;
  } else if (type === "time") {
    return Time;
  } else if (type === "rich_text") {
    return RichText;
  } else if (type === "upload") {
    return Upload;
  } else if (type === "file") {
    return File;
  }

  // const Cascade = (props) => {
  //   return (
  //     <Form.Item label={label} name={field}>
  //       <Cascader
  //         options={options}
  //         loadData={loadData}
  //         onChange={onChange}
  //         changeOnSelect
  //         disabled={disabled}
  //       />
  //     </Form.Item>
  //   );
  // };

  // const List = (props) => {
  //   return (
  //     <Form.List name={field_name}>
  //       {(fields, { add, remove }) => {
  //         return (
  //           <div style={{ width: "80%", marginLeft: " 10%" }}>
  //             <Form.Item>
  //               <Button
  //                 type="solid"
  //                 onClick={() => add()}
  //                 style={{ width: "20%", float: "right" }}
  //               >
  //                 <PlusOutlined /> Add field
  //               </Button>
  //             </Form.Item>
  //             {fields.map((field, index) => (
  //               <div key={field.key}>
  //                 {children.map((item) => {
  //                   let on_item = {
  //                     ...item,
  //                     props: {
  //                       ...item.props,
  //                       field: [index, `${item?.props?.field}`],
  //                     },
  //                   };
  //                   return on_item;
  //                 })}
  //                 {fields.length > 1 ? (
  //                   <Button
  //                     type="danger"
  //                     className="dynamic-delete-button"
  //                     onClick={() => remove(field.name)}
  //                     icon={<MinusCircleOutlined />}
  //                   >
  //                     Remove Above Field
  //                   </Button>
  //                 ) : null}
  //               </div>
  //             ))}
  //             <Divider />
  //           </div>
  //         );
  //       }}
  //     </Form.List>
  //   );
  // };
};

export default FormItem;
