import { callService } from "@helpers/config";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import CustomButton from "@views/components/common/custom_button";
import CustomDeleteModal from "@views/components/common/custom_delete_modal";
import CustomModal from "@views/components/common/custom_modal";
import CustomPageTitle from "@views/components/common/custom_page_title";
import DepartmentList from "@views/components/departments/department_list";
import DepartmentOfficerUsersList from "@views/components/departments_officer_users/department_officer_user_list";
import { Button, Col, Input, Modal, Row, Typography, message } from "antd";
import { Box, HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DepartmentOfficerUserForm from "@views/components/departments_officer_users/department_officer_user_form/department_officer_user_form";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@views/routes/my_routes";
import { useLocation } from "react-router-dom";
import { navigateWithProps } from "@helpers/functions";

const DepartmentOfficerUsers = () => {
  const [add_modal, set_add_modal_open] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const { Search } = Input;
  const { Title } = Typography;
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [header_type, set_header_type] = useState("");
  const department_list = useDynamicSelector("get_all_department");
  const create_response = useDynamicSelector("department_officer_create");
  const update_response = useDynamicSelector("department_officer_update");

  const delete_record_modal = (record) => {
    // set_delete_modal_open(true);
    // set_delete_record(record);
  };

  const add_department = () => {
    setInitialValues(null);
    set_header_type("Add");

    set_add_modal_open(true);
  };

  const get_all_department_values = (data) => {
    let keys = [{ key: "get_all_department", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_department,
        { search_string: data },
        MethodType.POST
      )
    );
  };
  const onSearch = (value) => {
    let search_value = value?.target?.value || value;
    if (search_value?.length > 2 || search_value?.length === 0)
      get_all_department_values(search_value);
  };
  const handle_back = (route, values) => {
    navigateWithProps(route, values);
  };

  return (
    <VStack space={"10px"} padding={"10px"}>
      {/* <CustomPageTitle title="Departments officers" /> */}
      <HStack space={"4px"}>
        <Pressable onPress={() => handle_back(ROUTES.DEPARTMENTS_OFFICERS, {})}>
          <Title className="title-style">Departments officer</Title>
        </Pressable>
        <Title className="title-style-none">
          {` / ${location?.state?.department_office?.name}`}
        </Title>
      </HStack>
      <HStack
        style={{
          width: "97%",
          marginBottom: "-60px",
          justifyContent: "flex-end",
          zIndex: 100000,
        }}
        space={"10px"}
      >
        <Search
          enterButton={true}
          onSearch={onSearch}
          allowClear={true}
          onChange={onSearch}
          placeholder="Search"
          style={{ width: "250px" }}
        />
        <CustomButton
          button_name="Add Department officer"
          onclick_button={add_department}
          header_type={header_type}
        />
      </HStack>
      <DepartmentOfficerUsersList
        delete_record_modal={delete_record_modal}
        set_add_modal_open={set_add_modal_open}
        set_header_type={set_header_type}
        header_type={header_type}
        setInitialValues={setInitialValues}
      />
      {/* <CustomModal
          header_type={header_type}
          add_modal={add_modal}
          set_add_modal_open={set_add_modal_open}
          initialValues={initialValues}
        /> */}
      <Modal
        title={`${header_type} Department officer`}
        open={add_modal}
        destroyOnClose={true}
        onCancel={() => set_add_modal_open(false)}
        width={600}
        footer={[
          <Button
            htmlType="submit"
            form="department_form"
            type="primary"
            loading={create_response?.loading || update_response?.loading}
          >
            {header_type?.includes("Edit") ? t("update") : t("create")}
          </Button>,
          <Button key="cancel" onClick={() => set_add_modal_open(false)} danger>
            {t("close")}
          </Button>,
        ]}
      >
        <DepartmentOfficerUserForm
          initialValues={initialValues}
          department_name={location?.state?.department_office?.name}
        />
      </Modal>
      {/* <CustomDeleteModal
          delete_modal_open={delete_modal_open}
          // set_delete_modal_open={set_delete_modal_open}
          delete_record={delete_record}
          initialValues={initialValues}
        /> */}
    </VStack>
  );
};

export default DepartmentOfficerUsers;
