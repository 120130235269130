import React from "react";
import { Box, Center, HStack, Image, Menu, Pressable, Text } from "native-base";
import Avatar from "react-avatar";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { clearLocalStorage, navigate, retrieveItem } from "@helpers/functions";
import { dynamicClear } from "@services/redux";
import { useDispatch } from "react-redux";
import HeaderBreadcrumb from "./header_breadcrumb";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useLocation } from "react-router-dom";

const RedirectorHeaderBar = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = retrieveItem("role");
  const { pathname } = useLocation()
  const login = () => {
    window.localStorage.clear()
    navigate(ROUTES.LOGIN);
  };
  const handleChangePassword = () => { };

  const handleAccount = () => { };
  return (
    <Box
      h={"80px"}
      w="100%"
      style={{
        background: "#0A2773",
        boxShadow: "0px 6px 4px 0px rgba(0, 0, 0, 0.25)",
        // boxShadow: "0px 6px 4px 0px rgba(0, 0, 0, 0.24)",
        // background: "linear-gradient(270deg, #5C0FB1 3.72%, #01C4FC 44.89%)",
      }}
    >
      <HStack h="100%" w="100%" space="2" justifyContent={"space-between"}>
        <Center ml="20px">
          {/* <HeaderBreadcrumb /> */}
          <HStack alignItems={"center"}>
            <Image
              source={require(`../../../../assets/icons/logo512.png`)}
              width={"60px"}
              height={"60px"}
            />
            <Text
              color={"#fff"}
              fontFamily={"project_header"}
              fontSize={18}
              bold
              mx={2}
            >
              {t("dggi_header")}
            </Text>
          </HStack>
        </Center>

        {/* <Box my={"auto"}>
          <HStack>
            <Box right="10px" ml="6">
              {!pathname.includes("/login") &&
                <Button
                  style={{
                    borderRadius: 5,
                    backgroundColor: "#fff",
                  }}
                  // type="primary"
                  onClick={login}
                >
                  <Text fontWeight={"bold"}>{t("login")}</Text>
                </Button>
              }
            </Box>
          </HStack>
        </Box> */}
      </HStack>
    </Box>
  );
};

export default RedirectorHeaderBar;
