import React, { useState } from "react";
import { Form, Input, Button, Card, Typography } from "antd";
import "antd/dist/antd.css";
import ForgetConformationModal from "./forget_conformation_modal";
import { useParams } from "react-router-dom";
import { HStack, Pressable, VStack } from "native-base";
import { navigate } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";
import RedirectorHeaderBar from "../header_bar/redirector_header_bar";
import BannerLogo from "@assets/icons/dggi_logo.jpg"

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const [confirmation_modal, set_confirmation_modal] = useState(false)
  const [confirmation_value, set_confirmation_value] = useState({})
  const onFinish = async (values) => {
    set_confirmation_value(values)
    set_confirmation_modal(true)
  };
  const handle_close = () => {
    set_confirmation_modal(false)

  }
  const handle_back = () => {
    navigate(ROUTES.LOGIN)
  }
  return (
    <VStack>
      <RedirectorHeaderBar />
      <div style={{ backgroundColor: "#f2f2f2" }}>

        <div
          className="background-images-set"
        >
          <Card
            style={{
              minWidth:500,
              width: 400,
              padding: 20,
              textAlign: "center",
              // backgroundColor: "#ffffff4f",
            }}
          >
            <Title level={3}>Forgot Password</Title>
            <Text>Please enter your user name to reset your password.</Text>
            <Form
              name="forgot-password"
              onFinish={onFinish}
              initialValues={{ remember: true }}
              style={{ marginTop: 20 }}
            >
              <Form.Item
                name="system_user_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter a username!",
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>

              <Button type="primary" htmlType="submit" style={{ width: "60%", marginTop: "10px" }}>
                Submit
              </Button>
              <HStack justifyContent={"center"} mt={"10px"}>
                <Pressable onPress={handle_back}>
                  <Text style={{ textDecoration: "underline" }}>
                    Login
                  </Text>
                </Pressable>
              </HStack>
            </Form>

          </Card>
          <ForgetConformationModal open={confirmation_modal} handle_close={handle_close} confirmation_value={confirmation_value} />
        </div>
      </div>
    </VStack>

  );
};

export default ForgotPassword;
