import { navigateWithProps, retrieveItem } from "@helpers/functions";
import CustomPageTitle from "@views/components/common/custom_page_title";
import DepartmentWiseReport from "@views/components/process/department_wise_report";
import DistrictList from "@views/components/process/district_list";
import DistrictWiseReport from "@views/components/process/district_wise_report";
import ProcessList from "@views/components/process/process_list";
import SectorList from "@views/components/survey_responce/sector_list";
import SurveyQuestion from "@views/components/survey_responce/survey_question";
import UserSurveyList from "@views/components/user_survey/user_survey_list";
import { ROUTES } from "@views/routes/my_routes";
import { Card, Tabs, Typography } from "antd";
import { HStack, Pressable } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const UserProcessList = (props) => {
  const { t } = useTranslation();
  const user = retrieveItem("user");

  const state = useLocation();
  const { Title } = Typography;

  const container_style = {
    // height: "100vh",
    overflowY: "auto",
    width: "100%",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  };

  const card_style = {
    flex: 1,
  };

  const handle_back = (route, values) => {
    navigateWithProps(route, values);
  };
  const tab_items = [
    {
      id: 1,
      label: t(`Districtwise Sector`),
      key: 1,
      children: <ProcessList />,
    },
    {
      id: 2,
      label: t(`Departmentwise Indicator`),
      key: 2,
      children: <DepartmentWiseReport />,
    },
  ];
  return (
    <>
      {user?.name === "District DES User" ? (
        <div style={container_style}>
          <Tabs items={tab_items} />
        </div>
      ) : (
        <div style={container_style}>
          <HStack space={"3px"}>
            <Pressable
              onPress={() => handle_back(ROUTES.PROCESS_DISTRICT_PAGE)}
            >
              <Title className="title-style">Districts</Title>
            </Pressable>
            <Title className="title-style-none">
              {state?.state?.data?.name
                ? `/ ${state?.state?.data?.name} Progress`
                : "Progress"}
            </Title>
          </HStack>
          <ProcessList district={state?.state?.data} />
        </div>
      )}
    </>
  );
};

export default UserProcessList;
