import { Form, Input } from "antd";
import React from "react";

const FormEmail = () => {
  return (
    <Form.Item
      name="email"
      label="Email"
      rules={[
        {
          required: false,
          message: "Please Enter the Email",
        },
        {
          pattern: new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}"),
          message: "Enter valid email",
        },
      ]}
    >
      <Input style={{ fontSize: "16px" }} />
    </Form.Item>
  );
};

export default FormEmail;
