import { Form, Select } from "antd";
import React from "react";

const Direction = () => {
  const indicator_direction = [
    { value: "Positive", label: "Positive" },
    { value: "Negative", label: "Negative" },
  ];
  return (
    <Form.Item name="direction" label="Direction" labelCol={{ span: 24 }}rules={[{required:true,message:"Direction is monitory"}]}>
      <Select
        style={{  fontSize: "16px" }}
        allowClear
      >
        {indicator_direction?.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Direction;
