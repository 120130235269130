import Redirector from "@views/pages/redirector";
import UserLayout from "@views/layouts/user_layout";
// import Home from "@views/pages/home";
// import Login from "@views/pages/login";
// import Registration from "@views/components/user/auth/agency_register/registration";
import Departments from "@views/pages/departments";
import Questions from "@views/pages/questions";
import Indicators from "@views/pages/indicators";
import Sectors from "@views/pages/sectors";
import Dashboard from "@views/pages/dashboard";
import Survey from "@views/pages/survey";
import Scores from "@views/pages/scores";
import SectoralRanking from "@views/pages/sectoral_ranking";
import SurveyDetails from "@views/components/survey/survey_details";
import QuestionList from "@views/components/questions/question_list";
import LevelList from "@views/components/level/level_list";
import Areas from "@views/pages/areas";
import AreaDetails from "@views/components/areas/area_details";
import UserSurvey from "@views/pages/user_survey";
import UserDistrictWiseSector from "@views/components/user_sector/user_district_wise_sector";
import RankingSector from "@views/components/sector_ranking/ranking_survey";
import RankingSurveySector from "@views/components/sector_ranking/ranking_survey_sector";
import RankingSurveySectorIndicator from "@views/components/sector_ranking/ranking_survey_sector_indicators";
import UserSector from "@views/pages/user_sector";
import LoginForm from "@views/components/user/auth/login_page/login_form";
import ForgotPassword from "@views/components/user/auth/forgot_password";
import UserIndicator from "@views/pages/user_indicator";
import IndicatorsQuestionView from "@views/pages/indicator_question_view";
import SectorList from "@views/components/survey_responce/sector_list";
import ResetPassword from "@views/components/user/auth/reset_password";
import DepartmentOfficers from "@views/pages/departments_officers";
import DepartmentOfficerUsers from "@views/pages/departments_officer_users";
import PendencyReport from "@views/components/user/reports/pendency_report";
import DistrictPendency from "@views/components/user/reports/district_pendency";
import DistrictPendencyReport from "@views/pages/district_pendency_report";
import DepartmentPendency from "@views/components/user/reports/department_pendency";
import DepartmentPendencyReport from "@views/pages/department_pendency_report";
import SectorIndicatorPendencyReport from "@views/pages/sector_indicator_pendency_report";
import Notifications from "@views/pages/notifications/notifications/";
import UserSectorViewAndAnswer from "@views/pages/user_sector_view";
import DashboardIndicatorList from "@views/components/dashboard/dashboard_indicator_list";
import UserProcessList from "@views/pages/user_process";
import UserProcessDistrictList from "@views/pages/user_process_district_list";
import DistrictWiseReport from "@views/components/process/district_wise_report";
import AdminDepartmentOfficersPage from "@views/pages/admin_department_officers_page";
import ChildAreaList from "@views/components/areas/child_area_list";
import MapRankingSurveyWise from "@views/components/sector_ranking/map_ranking_survey_wise";
import MapRankingSectorWise from "@views/components/sector_ranking/map_ranking_sector_wise";

export const ROUTES = {
  REDIRECTOR: "/",
  HOME: "/home",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  // REGISTRATION: "/registration",
  DEPARTMENTS: "/departments",
  DEPARTMENTS_OFFICERS: "/departments-officers",
  DEPARTMENTS_OFFICER_USERS: "/departments-officer-users",
  QUESTIONS: "/questions",
  QUESTIONS_DETAILS: "/question-details",
  INDICATORS: "/indicators",
  SECTORS: "/sectors",
  DASHBOARD: "/dashboard",
  SURVEY: "/survey",
  SURVEY_DETAILS: "/survey-details",
  SCORES: "/scores",
  SECTORAL_RANKING: "/sectoral-ranking",
  SECTORAL_RANKING_SECTOR: "/sectoral-ranking",
  SECTORAL_RANKING_INDICATOR: "/sectoral-ranking",
  SECTORAL_RANKING_INDICATOR_MAP: "/sectoral-ranking-map",
  SECTORAL_RANKING_SURVEY_MAP: "/sectoral-ranking-survey-map",
  SECTORAL_RANKING_SECTOR_MAP: "/sectoral-ranking-sector-map",
  LEVEL: "/levels",
  AREAS: "/areas",
  AREAS_DETAILS: "/areas-details",
  CHILD_AREAS_DETAILS: "/child-areas-details",
  USER_SURVEY: "/survey/response",
  USER_SECTOR: "/survey/question-view",
  SECTOR_DISTRICT_VIEW: "/survey/district-view",
  USER_INDICATOR: "/user-indicator",
  INDICATOR_QUESTION_VIEW: "/indicator-question-view",
  SECTOR_LIST: "/sector-list",
  PENDENCY_REPORT: "/pendency-report",
  DISTRICT_PENDENCY_REPORT: "/district-pendency-report",
  DEPARTMENT_PENDENCY_REPORT: "/department-pendency-report",
  SECTOR_INDICATOR_PENDENCY_REPORT: "/sector-indicator-pendency-report",
  NOTIFICATIONS: "/notifications",
  USER_SECTOR_AND_ANSWER_VIEW: "/sector-and-answer-view",
  PROCESS_PAGE: "/progress",
  PROCESS_DISTRICT_PAGE: "/progress-district-page",
  PROCESS_DISTRICT_WISE_REPORT_PAGE: "/progress-district-wise-report",
  DEPARTMENT_OFFICERS_LIST: "/department-officer-list",
};
const myRoutes = [
  {
    path: ROUTES.REDIRECTOR,
    exact: true,
    key: "login",
    component: LoginForm,
    authenticate: false,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    key: "login",
    component: LoginForm,
    authenticate: false,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    key: "forget-password",
    component: ForgotPassword,
    authenticate: false,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:token?`,
    exact: true,
    key: "reset-password",
    component: ResetPassword,
    authenticate: false,
  },
  {
    key: "Admin",
    component: UserLayout,
    authenticate: false,
    children: [
      {
        path: ROUTES.DEPARTMENTS,
        exact: true,
        key: "departments",
        authenticate: false,
        component: Departments,
      },
      {
        path: `${ROUTES.DEPARTMENTS_OFFICERS}/:id?`,
        exact: true,
        key: "departments-officers",
        authenticate: false,
        component: DepartmentOfficers,
      },
      {
        path: `${ROUTES.DEPARTMENTS_OFFICER_USERS}/:id?`,
        exact: true,
        key: "departments-officer-users",
        authenticate: false,
        component: DepartmentOfficerUsers,
      },
      {
        path: ROUTES.LEVEL,
        exact: true,
        key: "levels",
        component: LevelList,
        authenticate: false,
      },
      {
        path: ROUTES.AREAS,
        exact: true,
        key: "areas",
        component: Areas,
        authenticate: false,
      },
      {
        path: `${ROUTES.AREAS_DETAILS}/:action_type?/:id?/:level_id?`,
        exact: true,
        key: "areas-details",
        component: AreaDetails,
        authenticate: false,
      },
      {
        path: `${ROUTES.CHILD_AREAS_DETAILS}/:id?/:level_id?`,
        exact: true,
        key: "areas-details",
        component: ChildAreaList,
        authenticate: false,
      },
      {
        path: ROUTES.QUESTIONS,
        exact: true,
        key: "question",
        authenticate: false,
        component: QuestionList,
      },
      {
        path: `${ROUTES.QUESTIONS_DETAILS}/:action_type?/:id?`,
        exact: true,
        key: "question-details",
        authenticate: false,
        component: Questions,
      },
      {
        path: ROUTES.INDICATORS,
        exact: true,
        key: "indicators",
        authenticate: false,
        component: Indicators,
      },
      {
        path: ROUTES.SECTORS,
        exact: true,
        key: "sectors",
        authenticate: false,
        component: Sectors,
      },
      {
        path: ROUTES.DASHBOARD,
        exact: true,
        key: "dashboard",
        component: Dashboard,
        authenticate: false,
      },
      {
        path: `${ROUTES.DASHBOARD}/:status/:survey_id?`,
        exact: true,
        key: "dashboard",
        component: DashboardIndicatorList,
        authenticate: false,
      },
      {
        path: ROUTES.SURVEY,
        exact: true,
        key: "survey",
        component: Survey,
        authenticate: false,
      },
      {
        path: `${ROUTES.SURVEY_DETAILS}/:action_type?`,
        exact: true,
        key: "survey-details",
        component: SurveyDetails,
        authenticate: false,
      },
      {
        path: ROUTES.SCORES,
        exact: true,
        key: "scores",
        component: Scores,
        authenticate: false,
      },
      {
        path: ROUTES.SECTORAL_RANKING,
        exact: true,
        key: "sectoral-ranking",
        component: RankingSector,
        authenticate: false,
      },
      {
        path: `${ROUTES.SECTORAL_RANKING_SECTOR}/:survey_id?`,
        exact: true,
        key: "sectoral-ranking-sector",
        component: RankingSurveySector,
        authenticate: false,
      },
      {
        path: `${ROUTES.SECTORAL_RANKING_INDICATOR}/:survey_id/:sector_id`,
        exact: true,
        key: "sectoral-ranking-sector",
        component: RankingSurveySectorIndicator,
        authenticate: false,
      },
      {
        path: `${ROUTES.SECTORAL_RANKING_INDICATOR_MAP}/:survey_id?/:sector_id?/:indicator_id?`,
        exact: true,
        key: "sectoral-ranking-map",
        component: SectoralRanking,
        authenticate: false,
      },
      {
        path: `${ROUTES.SECTORAL_RANKING_SECTOR_MAP}/:survey_id?/:sector_id?/:indicator_id?`,
        exact: true,
        key: "sectoral-ranking-sector-map",
        component: MapRankingSectorWise,
        authenticate: false,
      },
      {
        path: `${ROUTES.SECTORAL_RANKING_SURVEY_MAP}/:survey_id?/:sector_id?/:indicator_id?`,
        exact: true,
        key: "sectoral-ranking-survey-map",
        component: MapRankingSurveyWise,
        authenticate: false,
      },
      {
        path: ROUTES.USER_SURVEY,
        exact: true,
        key: "user-survey",
        component: UserSurvey,
        authenticate: false,
      },
      {
        path: `${ROUTES.USER_SECTOR}/:id?`,
        exact: true,
        key: "user-sector",
        component: UserSector,
        authenticate: false,
      },
      {
        path: `${ROUTES.SECTOR_DISTRICT_VIEW}/:id?`,
        exact: true,
        key: "user-sector",
        component: UserDistrictWiseSector,
        authenticate: false,
      },
      {
        path: `${ROUTES.USER_INDICATOR}/:sector_id?/:survey_id?`,
        exact: true,
        key: "user-indicator",
        component: UserIndicator,
        authenticate: false,
      },
      {
        path: `${ROUTES.INDICATOR_QUESTION_VIEW}/:survey_indicator_id?/:indicator_id?/:survey_id?`,
        exact: true,
        key: "indicator-question-view",
        component: IndicatorsQuestionView,
        authenticate: false,
      },
      {
        path: `${ROUTES.SECTOR_LIST}/:id?`,
        exact: true,
        key: "sector-list",
        component: SectorList,
        authenticate: false,
      },
      {
        path: `${ROUTES.PENDENCY_REPORT}/:id?`,
        exact: true,
        key: "pendency-report",
        component: DistrictPendency,
        authenticate: false,
      },
      {
        path: `${ROUTES.DISTRICT_PENDENCY_REPORT}/:id?`,
        exact: true,
        key: "district-pendency-report",
        component: DistrictPendencyReport,
        authenticate: false,
      },
      {
        path: `${ROUTES.DEPARTMENT_PENDENCY_REPORT}/:id?`,
        exact: true,
        key: "department-pendency-report",
        component: DepartmentPendencyReport,
        authenticate: false,
      },
      {
        path: `${ROUTES.SECTOR_INDICATOR_PENDENCY_REPORT}/:id?`,
        exact: true,
        key: "sector-indicator-pendency-report",
        component: SectorIndicatorPendencyReport,
        authenticate: false,
      },
      {
        path: ROUTES.NOTIFICATIONS,
        exact: true,
        key: "notifications",
        component: Notifications,
        authenticate: false,
      },
      {
        path: `${ROUTES.USER_SECTOR_AND_ANSWER_VIEW}/:id?`,
        exact: true,
        key: "user-sector",
        component: UserSectorViewAndAnswer,
        authenticate: false,
      },
      {
        path: `${ROUTES.PROCESS_PAGE}/:id?`,
        exact: true,
        key: "process",
        component: UserProcessList,
        authenticate: false,
      },
      {
        path: `${ROUTES.PROCESS_DISTRICT_PAGE}`,
        exact: true,
        key: "district",
        component: UserProcessDistrictList,
        authenticate: false,
      },
      {
        path: `${ROUTES.PROCESS_DISTRICT_WISE_REPORT_PAGE}`,
        exact: true,
        key: "district-wise-report",
        component: DistrictWiseReport,
        authenticate: false,
      },
      {
        path: `${ROUTES.DEPARTMENT_OFFICERS_LIST}`,
        exact: true,
        key: "department-officers",
        component: AdminDepartmentOfficersPage,
        authenticate: false,
      },
    ],
  },
];

export default myRoutes;
