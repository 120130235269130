import { navigateWithProps, retrieveItem } from "@helpers/functions";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { get_indicator } from "@services/redux/slices/dynamic_entity/rest/rest_indicators";
import {
  get_my_survey_indicators,
  get_previous_year_indicator_answer,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import CustomPageTitle from "@views/components/common/custom_page_title";
import IndicatorList from "@views/components/indicators/indicator_list";
import SurveyQuestionDetails from "@views/components/survey_responce/survey_question";
import ContributionList from "@views/components/user/contributions/contribution_list";
import CurrentYearIndicator from "@views/components/user/current_year_indicator/current_year_indicator";
import PreviousYearIndicator from "@views/components/user/previous_year_indicator/previous_year_indicator";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Card, Row, Spin } from "antd";
import { Box, Center, HStack, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

const IndicatorsQuestionView = () => {
  const dispatch = useDispatch();
  const role = retrieveItem("user");
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [submit_status, set_submit_status] = useState({});
  const [submitted_status, set_submitted_status] = useState(null);
  const { survey_indicator_id, indicator_id, survey_id } = useParams();
  const indicator = useDynamicSelector("get_indicator");
  const { items: survey_sector } = useDynamicSelector(
    "get_my_survey_indicators"
  );
  const item = useDynamicSelector("get_previous_year_indicator_answer");
  const { loading: save_loading } = useDynamicSelector("save_answer");
  const { status: _status } = useDynamicSelector("get_save_submit_answer");
  const { loading: get_answer_loading, survey_indicators } = useDynamicSelector(
    "get_current_year_indicator_answer"
  );
  const { loading: indicator_contributions_loading } = useDynamicSelector(
    "get_indicator_contributions"
  );
  const get_question = [
    indicator?.denominator_question?.is_current_user_answerable &&
      indicator?.denominator_question_id,
    indicator?.numerator_question?.is_current_user_answerable &&
      indicator?.numerator_question_id,
    indicator?.second_numerator_question?.is_current_user_answerable &&
      indicator?.second_numerator_question_id,
  ].filter((list) => list);
  let get_question_ids = survey_indicators?.[0]?.answers?.map(
    (ques) => ques.question_id
  );
  let arraysEqual =
    get_question?.every((item) => get_question_ids?.includes(item)) &&
    get_question_ids?.every((item) => get_question?.includes(item));
  const history = useHistory();

  const get_indicator_details = (data) => {
    let keys = [{ key: "get_indicator", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        `${get_indicator}${indicator_id}/${survey_id}`,
        { survey_id: survey_id },
        MethodType.GET
      )
    );
  };

  const isAllSubmitted =
    survey_indicators?.[0]?.answers.length !== 0 &&
    survey_indicators?.[0]?.answers?.every(
      (x) =>
        x.state === "Submitted" ||
        x.state === "StateSubmitted" ||
        x.state === "Approved" ||
        x.state === "Published"
    ) &&
    arraysEqual;

  useEffect(() => {
    get_indicator_details();
  }, [survey_indicator_id]);

  useEffect(() => {
    set_submitted_status(isAllSubmitted);
  }, [isAllSubmitted]);

  const container_style = {
    // height: "100vh",
    width: "100%",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    // height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
  const handle_next_button = () => {
    dispatch(dynamicClear("get_previous_year_indicator_answer"));
    survey_sector?.[0]?.sector?.indicators?.map((x, index) => {
      if (location?.state?.index === index) {
        let next =
          survey_sector?.[0]?.sector?.indicators?.[location?.state?.index + 1];

        navigateWithProps(
          `${ROUTES.INDICATOR_QUESTION_VIEW}/${next?.survey_indicators?.[0]?.id}/${next?.id}/${survey_id}`,
          {
            index: location?.state?.index + 1,
            item: survey_sector?.[0]?.sector?.indicators?.[
              location?.state?.index + 1
            ],
            sector: survey_sector?.[0]?.sector,
            sector_id: location?.state?.sector_id,
            survey_id: survey_id,
          }
        );
      }
    });
  };

  const handle_previous_button = () => {
    dispatch(dynamicClear("get_previous_year_indicator_answer"));
    survey_sector?.[0]?.sector?.indicators?.map((x, index) => {
      if (location?.state?.index === index) {
        let next =
          survey_sector?.[0]?.sector?.indicators?.[location?.state?.index - 1];
        navigateWithProps(
          `${ROUTES.INDICATOR_QUESTION_VIEW}/${next?.survey_indicators?.[0]?.id}/${next?.id}/${survey_id}`,
          {
            index: location?.state?.index - 1,
            item: survey_sector?.[0]?.sector?.indicators?.[
              location?.state?.index - 1
            ],
            sector: survey_sector?.[0]?.sector,
            sector_id: location?.state?.sector_id,
            survey_id: survey_id,
          }
        );
      }
    });
  };

  const handleSave = (props) => {
    set_submit_status(props);
  };

  const handleBack = () => {
    dispatch(dynamicClear("get_current_year_indicator_answer"));
    history.goBack();
  };
  // useEffect(() => {
  //   let isAllSubmitted = retrieveItem("isAllSubmitted")

  // }, [])
  return (
    <>
      <div style={container_style}>
        {indicator?.loading ? (
          <Center>
            <Spin size="large" />
          </Center>
        ) : (
          <Card style={{ height: "100%" }}>
            <VStack space={"10"}>
              <SurveyQuestionDetails
                indicator_id={indicator_id}
                survey_id={survey_id}
                indicator={indicator}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
              <div
                style={{
                  display:
                    role.name !== "Regional User" ||
                    role.name !== "State DES User"
                      ? "none"
                      : "block",
                }}
              >
                {role.name !== "Regional User" &&
                  role.name !== "State DES User" && (
                    <div style={{ marginTop: isOpen ? "" : "-100px" }}>
                      <PreviousYearIndicator
                        survey_indicator_id={survey_indicator_id}
                        survey_id={survey_id}
                        indicator={indicator}
                        isOpen={isOpen}
                      />
                    </div>
                  )}
              </div>
              {role.name !== "Regional User" &&
                role.name !== "State DES User" && (
                  <div style={{ marginTop: isOpen ? "" : "-100px" }}>
                    <CurrentYearIndicator
                      survey_indicator_id={survey_indicator_id}
                      survey_id={survey_id}
                      indicator={indicator}
                      isOpen={isOpen}
                      submit_status={submit_status}
                      set_submit_status={set_submit_status}
                    />
                  </div>
                )}
              {role?.name !== "District Department User" && (
                <ContributionList
                  indicator={indicator}
                  indicator_id={indicator_id}
                  survey_id={survey_id}
                  survey_indicator_id={survey_indicator_id}
                  isOpen={isOpen}
                />
              )}
            </VStack>
          </Card>
        )}
      </div>
      {!indicator?.loading &&
        !item?.loading &&
        !get_answer_loading &&
        !indicator_contributions_loading && (
          <Box
            style={{
              position: "fixed",
              bottom: "0px",
              backgroundColor: "#e8e8e8",
              padding: "10px",
              width: "83%",
            }}
          >
            <HStack justifyContent={"space-between"} alignItems={"center"}>
              {!location.state.is_dashboard_view ? (
                <HStack space="5">
                  <Button
                    type="primary"
                    // icon={<FaArrowLeft />}
                    style={{ background: "#b52019", color: "white" }}
                    disabled={location?.state?.index === 0 ? true : false}
                    onClick={() => {
                      handle_previous_button();
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    type="primary"
                    disabled={
                      location?.state?.index + 1 ===
                      survey_sector?.[0]?.sector?.indicators?.length
                        ? true
                        : false
                    }
                    // icon={<FaArrowRight />}
                    style={{ background: "#ff9100", color: "white" }}
                    onClick={() => {
                      handle_next_button();
                    }}
                  >
                    Next
                  </Button>
                </HStack>
              ) : (
                <></>
              )}
              <HStack space={"10px"}>
                {role.name !== "Regional User" &&
                  role.name !== "State DES User" && (
                    <>
                      {!isAllSubmitted && (
                        <Button
                          loading={save_loading}
                          // disabled={!hasValue}
                          onClick={() => {
                            handleSave({ save: true });
                          }}
                          htmlType="button"
                          style={{ background: "#ff9100", color: "white" }}
                          color="red"
                        >
                          Save
                        </Button>
                      )}
                      {!isAllSubmitted && (
                        <Button
                          onClick={() => {
                            // handleOpenModal("save_submit");
                            handleSave({ save_submit: true });
                          }}
                          // disabled={!hasValue}
                          style={{ color: "white", background: "#18741c" }}
                        >
                          Save and Submit
                        </Button>
                      )}
                    </>
                  )}
                <Button
                  onClick={() => {
                    handleBack();
                  }}
                  danger
                >
                  Back
                </Button>
              </HStack>
            </HStack>
          </Box>
        )}
    </>
  );
};

export default IndicatorsQuestionView;
