import { numberFormat } from "@helpers/constants";
import {
  getUUID,
  navigateWithProps,
  retrieveItem,
  showToast,
  storeItem,
} from "@helpers/functions";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import {
  get_current_year_indicator_answer,
  get_my_survey_indicators,
  get_previous_year_indicator_answer,
  get_save_submit_answer,
  save_answer,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import CustomPageTitle from "@views/components/common/custom_page_title";
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Modal,
  Spin,
  message,
} from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import SaveSubmitConfirmationModal from "./save_submit_confirmation_modal";
import RemarksModal from "./remarks_modal";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ROUTES } from "@views/routes/my_routes";
import PreviousYearIndicatorSecondFormat from "../previous_year_indicator/previous_year_indicator_second_format";

const CurrentYearIndicator = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { survey_id } = useParams();
  const {
    survey_indicator_id,
    indicator,
    submit_status: save_button_status,
    set_submit_status,
  } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [open_remarks, set_open_remarks] = useState(false);
  const [form] = Form.useForm();
  const [save_submit, set_save_submit] = useState(false);
  const [score, setScore] = useState(null);
  const [formState, setFormState] = useState({
    numeratorValue: null,
    secondNumeratorValue: null,
    denominatorValue: null,
  });
  const [form_values, set_form_values] = useState({});
  const save_response = useDynamicSelector("save_answer");
  const previous_numerator = useDynamicSelector(
    "get_previous_year_indicator_answer"
  );
  let numerator_question =
    previous_numerator?.survey_indicators?.[0]?.answers.find(
      (x) => x.question_id === indicator?.numerator_question?.id
    )?.value;
  const previous_numerator_question = useDynamicSelector(
    "previous_numerator_question"
  );
  const previous_second_numerator_question = useDynamicSelector(
    "previous_second_numerator_question"
  );
  const previous_denominator_question = useDynamicSelector(
    "previous_denominator_question"
  );
  const previous_total = useDynamicSelector("previous_total");
  const department = retrieveItem("department");
  const [show_indicator_value, set_show_indicator_value] = useState(false);
  let numerator_value =
    formState?.numeratorValue ||
    formState.denominatorValue ||
    formState.secondNumeratorValue;
  // let show_indicator_valuedd = indicator?.denominator_question?.source_departments?.find((list) => list?.name === department?.name) && indicator?.second_numerator_question?.source_departments?.find((list) => list?.name === department?.name) && indicator?.numerator_question?.source_departments
  //   ?.find((x) => x?.name === department?.name)

  const {
    loading: save_loading,
    status: save_status,
    error: save_error,
  } = useDynamicSelector("save_answer");
  const {
    loading: submit_loading,
    status: submit_status,
    error: submit_error,
  } = useDynamicSelector("get_save_submit_answer");

  const { items: survey_sector } = useDynamicSelector(
    "get_my_survey_indicators"
  );

  const role = retrieveItem("user");
  const department_office = retrieveItem("department_office");
  const [answer_process_histories, set_answer_process_histories] = useState([]);
  const {
    survey_indicators,
    loading: get_answer_loading,
    year,
  } = useDynamicSelector("get_current_year_indicator_answer");
  const handleOpenModal = (save_submit) => {
    form
      .validateFields()
      .then((value) => {
        if (
          (value?.numerator || value?.numerator === 0) &&
          indicator?.numerator_question?.is_current_user_answerable &&
          !indicator?.second_numerator_question?.is_current_user_answerable &&
          !indicator?.denominator_question?.is_current_user_answerable
        ) {
          set_save_submit(true);
          setOpen(true);
        } else if (
          !indicator?.second_numerator_question &&
          (value?.numerator || value?.numerator === 0) &&
          (value?.denominator || value?.denominator === 0)
        ) {
          set_save_submit(true);
          setOpen(true);
        } else if (
          (value?.numerator || value?.numerator === 0) &&
          (value?.numerator_1 || value?.numerator_1 === 0)
        ) {
          set_save_submit(true);
          setOpen(true);
        } else if (value?.denominator || value?.denominator === 0) {
          set_save_submit(true);
          setOpen(true);
        } else {
          message.error("Please fill all fields");
        }
      })
      .catch((error) => {
        message.error("Please fill all fields");
      });
  };

  const closeModal = () => {
    setOpen(false);
    set_open_remarks(false);
    set_answer_process_histories([]);
  };
  const get_previous_year_indicator_with_answer = () => {
    let keys = [{ key: "get_current_year_indicator_answer", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_current_year_indicator_answer,
        { survey_indicator_id: survey_indicator_id },
        MethodType.POST
      )
    );
  };

  const get_all_indicator_list = () => {
    let keys = [{ key: "get_my_survey_indicators", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_my_survey_indicators,
        { survey_sector_id: location?.state?.sector_id, survey_id: survey_id },
        MethodType.POST
      )
    );
  };

  const handleNumeratorValue = (value, id) => {
    setFormState((prevState) => ({
      ...prevState,
      numeratorValue: value,
    }));
  };

  const handleSecondNumeratorValue = (value, id) => {
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        secondNumeratorValue: value,
      };

      if (
        indicator?.second_numerator_question?.id ===
        indicator?.denominator_question?.id
      ) {
        updatedState.denominatorValue = value;
      }

      return updatedState;
    });
  };

  const handleDenominatorValue = (value, id) => {
    setFormState((prevState) => ({
      ...prevState,
      denominatorValue: value,
    }));
  };

  const addItemToVariables = (value, questionId, questionType) => {
    if (value == 0 || value) {
      return {
        id:
          survey_indicators?.[0]?.answers?.find(
            (x) => x.question_id === questionId
          )?.id || getUUID(),
        value: Number(value),
        question_id: questionId,
        survey_indicator_id: survey_indicator_id,
        department_office_id: department_office?.id,
        answered_departments: department_office?.department_id,
        answered_levels: department_office?.level_id,
      };
    }
  };
  const handleSave = async () => {
    const variables = [];
    if (
      indicator?.numerator_question?.id &&
      indicator?.numerator_question?.is_current_user_answerable
    ) {
      if (
        formState.numeratorValue === 0 ||
        formState.numeratorValue ||
        form_values?.numerator
      ) {
        variables.push(
          addItemToVariables(
            formState.numeratorValue || formState.numeratorValue === 0
              ? formState.numeratorValue
              : form_values?.numerator,
            indicator.numerator_question.id,
            "numerator"
          )
        );
      }
    }
    if (
      indicator?.second_numerator_question?.id &&
      indicator?.second_numerator_question?.is_current_user_answerable
    ) {
      variables.push(
        addItemToVariables(
          formState.secondNumeratorValue || formState.secondNumeratorValue === 0
            ? formState.secondNumeratorValue
            : form_values?.numerator_1,
          indicator.second_numerator_question.id,
          "second_numerator"
        )
      );
    }
    if (
      indicator?.denominator_question?.id &&
      indicator?.denominator_question?.is_current_user_answerable &&
      indicator.second_numerator_question?.id !==
        indicator.denominator_question?.id
    ) {
      if (
        formState.denominatorValue ||
        formState.denominatorValue === 0 ||
        form_values?.denominator
      ) {
        variables.push(
          addItemToVariables(
            formState.denominatorValue || formState.denominatorValue === 0
              ? formState.denominatorValue
              : form_values?.denominator,
            indicator.denominator_question.id,
            "denominator"
          )
        );
      }
    }

    let revertedItems = variables.filter((variable) =>
      survey_indicators?.[0]?.answers.some(
        (answer) =>
          answer?.question_id === variable?.question_id &&
          answer?.state === "Reverted"
      )
    );
    let filteredVariables = variables.filter(
      (item) => item?.value !== undefined || item?.value !== null
    );
    // const filteredVariables = variables.filter(Boolean);
    let keys = [{ key: "save_answer", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        save_answer,
        revertedItems?.length ? revertedItems : filteredVariables,
        MethodType.POST
      )
    );
  };

  const handle_save_submit = async () => {
    await handleSave(); // Wait for handleSave to complete
  };

  const get_save_submit_answer_function = () => {
    const Questionid = save_response?.items?.map((item) => {
      return { id: item.id };
    });
    let keys = [{ key: "get_save_submit_answer", loading: true }];
    dispatch(
      dynamicRequest(keys, get_save_submit_answer, Questionid, MethodType.POST)
    );
  };

  const isDisabled = () => {
    const stateValue = survey_indicators?.[0]?.answers?.find(
      (x) => x.question_id === indicator.denominator_question?.id
    )?.state;

    return (
      stateValue === "Submitted" ||
      stateValue === "StateSubmitted" ||
      stateValue === "Approved" ||
      stateValue === "Published"
    );
  };

  const revertedRemarks = (value) => {
    set_answer_process_histories(value);
    set_open_remarks(true);
  };

  useEffect(() => {
    if (save_button_status?.save) {
      handleSave();
    } else if (save_button_status?.save_submit) {
      handleOpenModal("save_submit");
    }
  }, [save_button_status]);

  useEffect(() => {
    get_all_indicator_list();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      numerator: indicator?.numerator_question?.fixed_answer,
      numerator_1:
        indicator?.second_numerator_question
          ?.same_as_previous_year_question_id ===
        indicator?.numerator_question_id
          ? previous_numerator?.survey_indicators?.[0]?.answers.find(
              (x) => x.question_id === indicator?.numerator_question?.id
            )?.value
          : indicator?.second_numerator_question?.fixed_answer,
      denominator: indicator?.denominator_question?.fixed_answer,
    });
    setFormState({
      ...formState,
      numeratorValue: indicator?.numerator_question?.fixed_answer,
      secondNumeratorValue:
        indicator?.second_numerator_question
          ?.same_as_previous_year_question_id ===
        indicator?.numerator_question_id
          ? previous_numerator?.survey_indicators?.[0]?.answers.find(
              (x) => x.question_id === indicator?.numerator_question?.id
            )?.value
          : indicator?.second_numerator_question?.fixed_answer,
      denominatorValue:
        indicator?.second_numerator_question
          ?.same_as_previous_year_question_id ===
        indicator?.numerator_question_id
          ? previous_numerator?.survey_indicators?.[0]?.answers.find(
              (x) => x.question_id === indicator?.numerator_question?.id
            )?.value
          : indicator?.denominator_question?.fixed_answer,
    });
  }, [indicator?.id]);

  useEffect(() => {
    if (
      indicator?.second_numerator_question
        ?.same_as_previous_year_question_id === indicator?.numerator_question_id
    ) {
      form.setFieldsValue({
        numerator: indicator?.numerator_question?.fixed_answer,
        numerator_1:
          indicator?.second_numerator_question
            ?.same_as_previous_year_question_id ===
          indicator?.numerator_question_id
            ? previous_numerator?.survey_indicators?.[0]?.answers.find(
                (x) => x.question_id === indicator?.numerator_question?.id
              )?.value
            : indicator?.second_numerator_question?.fixed_answer,
        denominator: indicator?.denominator_question?.fixed_answer,
      });
      setFormState({
        ...formState,
        numeratorValue: indicator?.numerator_question?.fixed_answer,
        secondNumeratorValue:
          indicator?.second_numerator_question
            ?.same_as_previous_year_question_id ===
          indicator?.numerator_question_id
            ? previous_numerator?.survey_indicators?.[0]?.answers.find(
                (x) => x.question_id === indicator?.numerator_question?.id
              )?.value
            : indicator?.second_numerator_question?.fixed_answer,
        denominatorValue:
          indicator?.second_numerator_question
            ?.same_as_previous_year_question_id ===
          indicator?.numerator_question_id
            ? previous_numerator?.survey_indicators?.[0]?.answers.find(
                (x) => x.question_id === indicator?.numerator_question?.id
              )?.value
            : indicator?.denominator_question?.fixed_answer,
      });
    }
  }, [indicator?.id, previous_numerator]);
  useEffect(() => {
    if (role.name === "District DES User") {
      set_show_indicator_value(true);
    } else if (
      (indicator?.denominator_question_id &&
        indicator?.denominator_question?.is_current_user_answerable &&
        indicator?.numerator_question?.is_current_user_answerable) ||
      indicator?.second_numerator_question?.is_current_user_answerable
    ) {
      set_show_indicator_value(
        indicator?.denominator_question?.source_departments?.find(
          (list) => list?.name === department?.name
        )?.name && true
      );
    } else if (
      indicator?.second_numerator_question?.id &&
      indicator?.second_numerator_question?.is_current_user_answerable &&
      indicator?.denominator_question?.is_current_user_answerable
    ) {
      set_show_indicator_value(
        indicator?.second_numerator_question?.source_departments?.find(
          (list) => list?.name === department?.name
        ) && true
      );
    } else if (
      indicator?.numerator_question_id &&
      indicator?.numerator_question?.is_current_user_answerable &&
      indicator?.denominator_question?.is_current_user_answerable
    ) {
      set_show_indicator_value(
        indicator?.numerator_question?.source_departments?.find(
          (x) => x?.name === department?.name
        ) && true
      );
    } else {
      set_show_indicator_value(false);
    }
  }, [indicator, department, role]);
  // score calculation

  useEffect(() => {
    // score calculation
    let numerator_1 =
      survey_indicators?.[0]?.answers.find(
        (x) => x.question_id === indicator?.numerator_question?.id
      )?.value || 0;
    let numerator_2 =
      survey_indicators?.[0]?.answers.find(
        (x) => x.question_id === indicator?.second_numerator_question?.id
      )?.value || 0;
    let denominator =
      survey_indicators?.[0]?.answers.find(
        (x) => x.question_id === indicator?.denominator_question?.id
      )?.value || 0;
    if (
      indicator?.is_direct_value ||
      numerator_1 ||
      numerator_2 ||
      denominator
    ) {
      if (indicator?.is_direct_value) {
        setScore(survey_indicators?.[0]?.answers?.[0]?.value);
      } else if (survey_indicators?.[0]?.answers?.length) {
        if (indicator.second_numerator_question?.text) {
          let score =
            ((numerator_1 - numerator_2) / denominator) * indicator.multiplier;
          setScore(score);
        } else {
          let score =
            (numerator_1 / denominator) *
            indicator.survey_indicators?.[0]?.multiplier;
          setScore(score);
        }
      }
    } else {
      setScore(null);
    }
  }, [indicator, survey_indicators, formState]);
  // score calculation
  useEffect(() => {
    let numerator_1 = formState?.numeratorValue;
    let numerator_2 = formState?.secondNumeratorValue;

    let denominator = formState?.denominatorValue;
    // if (numerator_1 || numerator_2 || denominator) {
    if (formState.numeratorValue == 0) {
      setScore(0);
    } else {
      if (
        (denominator || numerator_1 || numerator_2) &&
        !survey_indicators?.[0]?.answers?.length
      ) {
        if (indicator?.is_direct_value) {
          if (formState.numeratorValue) {
            setScore(formState.numeratorValue ? numerator_1 : 0);
          }
        } else {
          if (denominator && numerator_1 && numerator_2) {
            let score =
              ((numerator_1 - numerator_2) / denominator) *
              indicator.survey_indicators?.[0]?.multiplier;

            // score = Math.min(score, 100);
            setScore(denominator && numerator_1 && numerator_2 ? score : 0);
          } else if (numerator_1 && denominator) {
            let score =
              (numerator_1 / denominator) *
              indicator.survey_indicators?.[0]?.multiplier;

            setScore(denominator && denominator ? score : 0);
          } else {
            setScore(null);
          }
        }
      } else if (
        formState.numeratorValue &&
        formState?.denominatorValue &&
        formState.secondNumeratorValue
      ) {
        let numerator_1 = formState?.numeratorValue;
        let numerator_2 = formState?.secondNumeratorValue;

        let denominator = formState?.denominatorValue;
        let score =
          ((numerator_1 - numerator_2) / denominator) *
          indicator.survey_indicators?.[0]?.multiplier;
        // let score =
        //   (numerator_1 / denominator) *
        //   indicator.survey_indicators?.[0]?.multiplier;

        setScore(
          formState?.denominatorValue &&
            formState.numeratorValue &&
            formState?.secondNumeratorValue
            ? score
            : 0
        );
      } else if (formState.numeratorValue && formState.denominatorValue) {
        let score =
          (numerator_1 / denominator) *
          indicator.survey_indicators?.[0]?.multiplier;

        setScore(
          formState?.denominatorValue && formState.numeratorValue ? score : 0
        );
      } else {
        // if (!indicator || formState.numeratorValue && formState?.denominatorValue) {
        setScore(null);
        // }
      }
    }
  }, [formState]);

  useEffect(() => {
    if (indicator && survey_indicators?.[0]?.answers?.length) {
      if (
        indicator?.denominator_question?.id ===
        indicator?.second_numerator_question?.id
      ) {
        const initialValue =
          survey_indicators?.[0]?.answers?.find(
            (x) => x.question_id === indicator.denominator_question?.id
          )?.value || null;
        setFormState((prevState) => ({
          ...prevState,
          denominatorValue: initialValue,
        }));
      }

      set_form_values({
        numerator: survey_indicators?.[0]?.answers?.find(
          (x) => x.question_id === indicator?.numerator_question?.id
        )?.value,
        numerator_1: survey_indicators?.[0]?.answers?.find(
          (x) => x.question_id === indicator?.second_numerator_question?.id
        )?.value,
        denominator: survey_indicators?.[0]?.answers?.find(
          (x) => x.question_id === indicator?.denominator_question_id
        )?.value,
      });
    }
  }, [indicator, survey_indicators]);

  useEffect(() => {
    if (save_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamicClear("save_answer"));
    } else if (save_status === "success" && !save_submit) {
      showToast({
        type: "success",
        message: "Data saved successfully",
      });
      set_submit_status({});
      // set_save_submit(false);
      dispatch(dynamicClear("save_answer"));
      get_previous_year_indicator_with_answer();
    }
  }, [save_status, save_error, save_submit]);

  useEffect(() => {
    if (submit_error || submit_status === "failure") {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamicClear("get_save_submit_answer"));
      closeModal();
    } else if (submit_status === "success") {
      showToast({
        type: "success",
        message: "Data saved & submitted successfully",
      });
      set_submit_status({});
      dispatch(dynamicClear("save_answer"));

      dispatch(dynamicClear("get_save_submit_answer"));
      set_save_submit(false);
      setTimeout(() => {
        get_previous_year_indicator_with_answer();
      }, [200]);

      closeModal();
    }
  }, [submit_status, submit_error]);
  useEffect(() => {
    if (indicator && survey_indicators?.[0]?.answers?.length) {
      setFormState((prevState) => ({
        ...prevState,
        numeratorValue: survey_indicators?.[0]?.answers?.find(
          (x) => x.question_id === indicator?.numerator_question?.id
        )?.value,
        secondNumeratorValue: survey_indicators?.[0]?.answers?.find(
          (x) => x.question_id === indicator?.second_numerator_question?.id
        )?.value,
        denominatorValue:
          indicator?.denominator_question?.id ===
          indicator?.second_numerator_question?.id
            ? survey_indicators?.[0]?.answers?.find(
                (x) =>
                  x.question_id === indicator?.second_numerator_question?.id
              )?.value
            : survey_indicators?.[0]?.answers?.find(
                (x) => x.question_id === indicator?.denominator_question?.id
              )?.value,
        // ? form_values?.denominator
        // : null,
      }));
    }
  }, [indicator, survey_indicators]);
  useEffect(() => {
    if (save_status && save_submit) {
      get_save_submit_answer_function();
    }
  }, [save_status]);

  useEffect(() => {
    if (form_values) {
      form.setFieldsValue(form_values);
    }
  }, [form_values]);
  useEffect(() => {
    if (survey_indicator_id) {
      get_previous_year_indicator_with_answer();
    }
  }, [survey_indicator_id]);

  useEffect(() => {
    const hasValue = Object.values(formState).some(
      (value) => value !== null && value !== undefined
    );
  }, [survey_indicators, formState, submit_status, save_status]);
  return (
    <div style={{ padding: "30px" }}>
      {get_answer_loading || submit_loading ? (
        <Center>
          <Spin />
        </Center>
      ) : (
        <Card style={{ borderRadius: "5px", backgroundColor: "#d3d3d324" }}>
          <Form form={form}>
            <VStack space={10} mb="20px">
              <Box>
                <Text
                  fontSize={"lg"}
                  fontWeight={"600"}
                  color={"#0a2773"}
                >{`Current Year > ${year} `}</Text>
              </Box>
              <HStack space={"10"}>
                <Box width="200px">
                  <Text bold fontSize={"md"}>
                    Type
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize={"md"} bold>
                    Description
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text bold fontSize={"md"}>
                    Previous year
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text bold fontSize={"md"}>
                    Current year
                  </Text>
                </Box>

                {/* <Box flex={1}>
       <Text bold fontSize={"md"}>
         {numberFormat(numerator?.value)}
       </Text>
     </Box> */}
              </HStack>
              {indicator?.numerator_question_id &&
              indicator?.numerator_question?.is_current_user_answerable ? (
                <HStack space={"10"}>
                  <Box width="200px">
                    <Text bold fontSize={"md"}>
                      {indicator?.is_direct_value ? t("data") : t("numerator")}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text fontSize={"md"}>
                      {indicator.numerator_question?.text}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text bold fontSize={"md"}>
                      {typeof previous_numerator_question === "number"
                        ? previous_numerator_question
                        : "-"}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      {" "}
                      <Form.Item
                        label=""
                        name={"numerator"}
                        initialValue={formState?.numeratorValue}
                      >
                        <InputNumber
                          value={formState?.numeratorValue}
                          parser={(value) => {
                            // Parse the value to remove any additional digits after the decimal point
                            return parseFloat(value.replace(/[^\d.]/g, ""));
                          }}
                          className="input-number-box"
                          disabled={(() => {
                            const stateValue =
                              survey_indicators?.[0]?.answers?.find(
                                (x) =>
                                  x.question_id ===
                                  indicator.numerator_question?.id
                              )?.state;

                            return (
                              indicator?.numerator_question?.fixed_answer ||
                              stateValue === "Submitted" ||
                              stateValue === "StateSubmitted" ||
                              stateValue === "Approved" ||
                              stateValue === "Published"
                            );
                          })()}
                          onChange={(e) => {
                            handleNumeratorValue(
                              e,
                              indicator.numerator_question?.id
                            );
                          }}
                        />
                      </Form.Item>
                      <Text>
                        {survey_indicators?.[0]?.answers?.find(
                          (x) =>
                            x.question_id === indicator.numerator_question?.id
                        )?.state === "Reverted" ? (
                          <span
                            style={{
                              color: "red",
                              marginTop: "5px",
                              position: "absolute",
                              marginLeft: "5px",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              revertedRemarks(
                                survey_indicators?.[0]?.answers?.find(
                                  (x) =>
                                    x.question_id ===
                                    indicator.numerator_question?.id
                                )
                              );
                            }}
                          >
                            Reverted
                          </span>
                        ) : (
                          ""
                        )}
                      </Text>
                      <Text>
                        {survey_indicators?.[0]?.answers?.find(
                          (x) =>
                            x.question_id === indicator.numerator_question?.id
                        )?.state === "Approved" ? (
                          <span
                            style={{
                              color: "green",
                              marginTop: "5px",
                              position: "absolute",
                              marginLeft: "5px",
                            }}
                          >
                            Approved
                          </span>
                        ) : survey_indicators?.[0]?.answers?.find(
                            (x) =>
                              x.question_id === indicator.numerator_question?.id
                          )?.state === "Reverted" ? (
                          <span
                            style={{
                              color: "red",
                              marginTop: "5px",
                              position: "absolute",
                              marginLeft: "5px",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              revertedRemarks(
                                survey_indicators?.[0]?.answers?.find(
                                  (x) =>
                                    x.question_id ===
                                    indicator.numerator_question?.id
                                )
                              );
                            }}
                          >
                            Reverted
                          </span>
                        ) : (
                          ""
                        )}
                      </Text>
                    </HStack>
                  </Box>
                </HStack>
              ) : (
                <></>
              )}
              {indicator?.second_numerator_question?.id &&
              indicator?.second_numerator_question
                ?.is_current_user_answerable ? (
                <HStack space={"10"}>
                  <Box width="200px">
                    <Text bold fontSize={"md"}>
                      {t("numerator2")}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text fontSize={"md"}>
                      {indicator.second_numerator_question?.text}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text bold fontSize={"md"}>
                      {typeof previous_second_numerator_question === "number"
                        ? previous_second_numerator_question
                        : "-"}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Form.Item
                        label=""
                        name={"numerator_1"}
                        initialValue={formState?.secondNumeratorValue}
                      >
                        <InputNumber
                          className="input-number-box"
                          value={formState?.secondNumeratorValue}
                          style={{ width: "150px !important" }}
                          // formatter={(value) => {
                          //   // Format the value to allow up to 2 digits after the decimal point
                          //   return `${value}`.replace(/^(.*\..{2}).*$/, "$1");
                          // }}
                          parser={(value) => {
                            // Parse the value to remove any additional digits after the decimal point
                            return parseFloat(value.replace(/[^\d.]/g, ""));
                          }}
                          disabled={(() => {
                            const stateValue =
                              survey_indicators?.[0]?.answers?.find(
                                (x) =>
                                  x.question_id ===
                                  indicator.second_numerator_question?.id
                              )?.state;

                            return (
                              indicator?.second_numerator_question
                                ?.fixed_answer ||
                              stateValue === "Submitted" ||
                              stateValue === "StateSubmitted" ||
                              stateValue === "Approved" ||
                              stateValue === "Published"
                            );
                          })()}
                          onChange={(e) => {
                            handleSecondNumeratorValue(
                              e,
                              indicator.second_numerator_question?.id
                            );
                          }}
                        />
                      </Form.Item>
                      <Text>
                        {survey_indicators?.[0]?.answers?.find(
                          (x) =>
                            x.question_id ===
                            indicator.second_numerator_question?.id
                        )?.state === "Reverted" ? (
                          <span
                            style={{
                              color: "red",
                              marginTop: "5px",
                              position: "absolute",
                              marginLeft: "5px",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              revertedRemarks(
                                survey_indicators?.[0]?.answers?.find(
                                  (x) =>
                                    x.question_id ===
                                    indicator.second_numerator_question?.id
                                )
                              );
                            }}
                          >
                            Reverted
                          </span>
                        ) : survey_indicators?.[0]?.answers?.find(
                            (x) =>
                              x.question_id ===
                              indicator.second_numerator_question?.id
                          )?.state === "Approved" ? (
                          <span
                            style={{
                              color: "green",
                              marginTop: "5px",
                              position: "absolute",
                              marginLeft: "5px",
                            }}
                          >
                            Approved
                          </span>
                        ) : (
                          ""
                        )}
                      </Text>
                      <Text>
                        {survey_indicators?.[0]?.answers?.find(
                          (x) =>
                            x.question_id ===
                            indicator.second_numerator_question?.id
                        )?.state === "Approved" ? (
                          <span
                            style={{
                              color: "green",
                              marginTop: "5px",
                              position: "absolute",
                              marginLeft: "5px",
                              fontWeight: "bold",
                            }}
                          >
                            Approved
                          </span>
                        ) : (
                          ""
                        )}
                      </Text>
                    </HStack>
                  </Box>

                  {/* <Box flex={1}>
       <Text bold fontSize={"md"}>
         {numberFormat(numerator?.value)}
       </Text>
     </Box> */}
                </HStack>
              ) : (
                <></>
              )}
              {indicator?.denominator_question_id &&
              indicator?.denominator_question?.is_current_user_answerable ? (
                <HStack space={"10"}>
                  <Box width="200px">
                    <Text bold fontSize={"md"}>
                      {t("denominator")}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text fontSize={"md"}>
                      {indicator?.denominator_question?.text}
                    </Text>
                  </Box>

                  {/* <Box flex={1}>
       <Text bold fontSize={"md"}>
         {numberFormat(denominator?.value)}
       </Text>
     </Box> */}
                  <Box flex={1}>
                    <Text bold fontSize={"md"}>
                      {typeof previous_denominator_question === "number"
                        ? previous_denominator_question
                        : "-"}
                    </Text>
                  </Box>
                  <HStack flex={1} space={"2px"}>
                    <Form.Item
                      label=""
                      name={
                        indicator?.denominator_question?.id ===
                        indicator?.second_numerator_question?.id
                          ? "numerator_1"
                          : "denominator"
                      }
                    >
                      <InputNumber
                        // value={formState?.denominatorValue}
                        className="input-number-box"
                        style={{ width: "150px" }}
                        parser={(value) => {
                          return parseFloat(value.replace(/[^\d.]/g, ""));
                        }}
                        value={
                          formState?.denominatorValue
                            ? formState.denominatorValue
                            : indicator?.denominator_question?.id ===
                              indicator?.second_numerator_question?.id
                            ? formState.denominatorValue
                            : null
                        }
                        disabled={
                          indicator?.denominator_question?.fixed_answer ||
                          indicator?.denominator_question?.id ===
                            indicator?.second_numerator_question?.id ||
                          isDisabled()
                        }
                        onChange={(e) => {
                          handleDenominatorValue(
                            e,
                            indicator?.denominator_question?.id
                          );
                        }}
                      />
                    </Form.Item>
                    <Text>
                      {survey_indicators?.[0]?.answers?.find(
                        (x) =>
                          x.question_id === indicator.denominator_question?.id
                      )?.state === "Reverted" ? (
                        <span
                          style={{
                            color: "red",
                            marginTop: "5px",
                            position: "absolute",
                            marginLeft: "5px",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            revertedRemarks("one");
                          }}
                        >
                          Reverted
                        </span>
                      ) : survey_indicators?.[0]?.answers?.find(
                          (x) =>
                            x.question_id === indicator.denominator_question?.id
                        )?.state === "Approved" ? (
                        <span
                          style={{
                            color: "green",
                            marginTop: "5px",
                            position: "absolute",
                            marginLeft: "5px",
                          }}
                        >
                          Approved
                        </span>
                      ) : (
                        ""
                      )}
                    </Text>
                  </HStack>
                </HStack>
              ) : (
                <></>
              )}
              {show_indicator_value || indicator?.is_direct_value ? (
                <HStack space={"45px"}>
                  <Box width={"200px"}>
                    <Text bold fontSize={"md"}>
                      {t("indicator_value")}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text fontSize={"md"}> {"-"}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text bold fontSize={"md"}>
                      {typeof previous_total === "number" ||
                      typeof previous_total === "string"
                        ? !previous_total.includes("NaN")
                          ? previous_total
                          : "-"
                        : "-"}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text bold fontSize={"md"}>
                      {
                        // !indicator.is_direct_value
                        //   ? formState.denominatorValue && formState.numeratorValue && formState.secondNumeratorValue || formState.denominatorValue && formState.numeratorValue
                        //     ?
                        indicator?.is_direct_value || indicator.is_direct_value
                          ? numerator_value
                            ? numerator_value
                            : score !== 0
                            ? "-"
                            : 0
                          : Math.min(score, indicator?.restriction_value)
                          ? Math.min(
                              score,
                              indicator?.restriction_value
                            )?.toFixed(3)
                          : score !== 0
                          ? "-"
                          : 0
                        //   : "-"
                        // : score
                      }
                    </Text>
                    {!indicator.is_direct_value ? (
                      score > indicator?.restriction_value ? (
                        <Text bold color={"red.500"}>
                          The indicator value exceeds{" "}
                          {indicator?.restriction_value}, Hence it is restricted
                          to {indicator?.restriction_value}.
                        </Text>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </Box>
                </HStack>
              ) : (
                ""
              )}
            </VStack>
          </Form>
        </Card>
      )}
      {/* <div style={{ position: "sticky", top: "10px" }}>
        <HStack justifyContent={"space-between"} space="7" mt="20" position={"sticky"} top={"0px"}>
          {!location.state.is_dashboard_view ?
            <HStack space="5">
              <Button
                type="primary"
                // icon={<FaArrowLeft />}
                style={{ background: "#b52019", color: "white" }}
                disabled={location?.state?.index === 0 ? true : false}
                onClick={() => {
                  handle_previous_button();
                }}
              >
                Previous
              </Button>
              <Button
                type="primary"
                disabled={
                  location?.state?.index + 1 ===
                    survey_sector?.[0]?.sector?.indicators?.length
                    ? true
                    : false
                }
                // icon={<FaArrowRight />}
                style={{ background: "#ff9100", color: "white" }}
                onClick={() => {
                  handle_next_button();
                }}
              >
                Next
              </Button>
            </HStack>
            : <Box></Box>}
          <HStack space={"10px"}> 
            {!isAllSubmitted && (
              <Button
                loading={save_loading}
                disabled={!hasValue}
                onClick={() => {
                  handleSave();
                }}
                style={{ background: "#ff9100", color: "white" }}
                color="red"
              >
                Save
              </Button>
            )}
            {!isAllSubmitted && (
              <Button
                onClick={() => {
                  handleOpenModal("save_submit");
                }}
                disabled={!hasValue}
                style={{ color: "white", background: "#18741c" }}
              >
                Save and Submit
              </Button>
            )}
            <Button
              onClick={() => {
                handleBack();
              }}
              danger
            >
              Back
            </Button>
          </HStack>
        

        </HStack>

      </div> */}

      <Modal
        title={`Confirmation`}
        open={open}
        onOk={closeModal}
        onCancel={closeModal}
        footer={
          <>
            <Button key="cancel" danger onClick={closeModal}>
              Cancel
            </Button>

            <Button
              loading={submit_loading || save_loading}
              key="submit"
              type="primary"
              form="manage_role"
              htmlType="submit"
              onClick={handle_save_submit}
            >
              Submit
            </Button>
          </>
        }
      >
        <SaveSubmitConfirmationModal />
      </Modal>
      <Modal
        title={`Remarks`}
        open={open_remarks}
        onOk={closeModal}
        onCancel={closeModal}
        width={600}
        footer={
          <>
            <Button key="cancel" danger onClick={closeModal}>
              Cancel
            </Button>
          </>
        }
      >
        <Box>
          <RemarksModal history_list={answer_process_histories} />
        </Box>
      </Modal>
    </div>
  );
};

export default CurrentYearIndicator;
