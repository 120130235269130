import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { CallService, MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import {
  department_create,
  department_update,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import {
  department_office_create,
  department_office_update,
} from "@services/redux/slices/dynamic_entity/rest/rest_department_office";
import FormName from "./department_officers_form/form_name";
import FormDepartment from "./department_officers_form/form_department";
import FormLevel from "./department_officers_form/form_level";
import FormArea from "./department_officers_form/form_area";
import { department_officer_update } from "@services/redux/slices/dynamic_entity/rest/rest_department_officers";

const AdminDepartmentOfficersForm = ({ initialValues }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { items: Department_list, loading: question_loading } =
    useDynamicSelector("get_all_department");
  const on_finish = (values) => {
    delete values.username;
    if (initialValues?.id) {
      let key = [{ key: "department_officer_update", loading: true }];
      values.id = initialValues.id;
      delete values.id;
      let query = `${department_officer_update}${initialValues.id}`;
      let variables = values;
      dispatch(dynamicRequest(key, query, variables, MethodType.PATCH));
    }
  };
  const get_all_department_list = (data) => {
    let keys = [{ key: "get_all_department", loading: true }];
    dispatch(dynamicRequest(keys, get_all_department, {}, MethodType.POST));
  };

  useEffect(() => {
    get_all_department_list();
  }, []);
  return (
    <Form
      id="department_form"
      onFinish={on_finish}
      form={form}
      initialValues={initialValues}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 20,
      }}
      style={{ width: "100%" }}
    >
      <FormName />
      <Form.Item
        name="designation"
        label="Designation"
        rules={[
          {
            required: true,
            message: "Designation is monitory",
          },
        ]}
      >
        <Input style={{ fontSize: "16px" }} />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: "Email is monitory",
          },
          {
            pattern: new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}"),
            message: "Enter valid email",
          },
        ]}
      >
        <Input style={{ fontSize: "16px" }} />
      </Form.Item>
      <Form.Item
        name="username"
        label="Username"
        rules={[
          {
            required: true,
            message: "Username is monitory",
          },
        ]}
      >
        <Input style={{ fontSize: "16px" }} disabled />
      </Form.Item>
    </Form>
  );
};

export default AdminDepartmentOfficersForm;
