import React, { useState, useEffect } from "react";
import { Box, VStack } from "native-base";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const Sortable = (props) => {
  const [items, setItems] = useState(props.items);
  useEffect(() => {
    setItems(props.items);
  }, [props.items]);
  let idField = props.idField || "feedback_question_id";
  let sortField = props.sortField || "sort_order";

  const SortableItem = SortableElement(({ item }) => {
    const SortableBox = React.cloneElement(props.component, {
      item: item,
    });

    return <Box>{SortableBox}</Box>;
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <VStack space={1}>
        {items?.map((item, index) => (
          <SortableItem key={`item-${index}`} index={index} item={item} />
        ))}
      </VStack>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const _items = arrayMoveImmutable(items.slice(), oldIndex, newIndex);
    const sortedItems = _items.map((item, index) => ({
      [idField]: item[idField],
      [sortField]: index + 1,
    }));

    if (props.onSortChange) {
      props.onSortChange(sortedItems);
    }
    setItems(_items);
  };

  return (
    <Box>
      <SortableList items={items} onSortEnd={onSortEnd} distance={1} />
    </Box>
  );
};

export default Sortable;
