import CustomPageTitle from "@views/components/common/custom_page_title";
import SectorList from "@views/components/survey_responce/sector_list";
import SurveyQuestion from "@views/components/survey_responce/survey_question";
import UserSurveyList from "@views/components/user_survey/user_survey_list";
import { Card } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const UserSurvey = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const container_style = {
    // height: "100vh",
    overflowY: "auto",
    width: "100%",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  };

  const card_style = {
    flex: 1,
  };
  return (
    <div style={container_style}>
      <CustomPageTitle title="Surveys" />
      <UserSurveyList />
    </div>
  );
};

export default UserSurvey;
