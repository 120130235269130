import CustomPageTitle from "@views/components/common/custom_page_title";
import UserSectorList from "@views/components/user_sector/user_sector";
import UserSectorView from "@views/components/user_sector/user_sector_view";
import { Card } from "antd";
import { HStack } from "native-base";
import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const UserSectorViewAndAnswer = () => {
  const container_style = {
    // height: "100vh",
    overflowY: "auto",
    width: "100%",
    padding: "30px",
  };
  return (
    <div style={container_style}>
      <Card>
        <UserSectorView />
      </Card>
    </div>
  );
};

export default UserSectorViewAndAnswer;
