import { navigateWithProps } from "@helpers/functions";
import {
  BRONZE_MEDAL,
  GOLD_MEDAL,
  SILVER_MEDAL,
} from "@helpers/image_constants";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import {
  result,
  sector_wise_result,
  survey_result,
} from "@services/redux/slices/dynamic_entity/rest/rest_result";
import CustomPageTitle from "@views/components/common/custom_page_title";
import SectorRankCard from "@views/components/user/sectoral_ranking/sector_rank_card";
import SectorRankList from "@views/components/user/sectoral_ranking/sector_rank_list";
import SectorRankingMap from "@views/components/user/sectoral_ranking/sector_ranking_map";
import SectorRankingMapPdf from "@views/components/user/sectoral_ranking/sector_ranking_map_pdf";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Empty, Row, Spin, Typography } from "antd";
import { Box, Center, HStack, Pressable, VStack,Image,Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { usePDF } from "react-to-pdf";
import logo from "@assets/icons/logo512.png";
import moment from "moment";

const MapRankingSurveyWise = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { items: sector_result, loading: sector_result_loading } =
    useDynamicSelector("sector_wise_result");

  const { items: data, loading: survey_result_loading } =
    useDynamicSelector("survey_result");

  const { sector_id, indicator_id } = useParams();
  const { Title } = Typography;
  const location = useLocation();
  const { state } = useLocation();
  const { id, item, survey_id } = state;
  const [datas, set_data] = useState([]);
  const [table_data, setTableData] = useState([]);
  const CurrentDate = moment();

  const { toPDF, targetRef } = usePDF({ filename: "survey-result.pdf" });

  const get_survey_result = () => {
    let keys = [{ key: "survey_result", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        survey_result,
        { survey_id: survey_id },
        MethodType.POST
      )
    );
  };

  useEffect(() => {
    if (location?.state?.is_sector_map) {
    } else {
      dispatch(dynamicClear("survey_result"));
      get_survey_result();
    }
  }, [survey_id]);

  useEffect(() => {
    if (location?.state?.is_sector_map) {
      dispatch(dynamicClear("result"));
    } else {
      dispatch(dynamicClear("sector_wise_result"));
    }
  }, [indicator_id, sector_id]);
  console.log("items", sector_result);
  //   useEffect(() => {
  //     if (items?.length) {
  //       let temp = items?.map((x, index) => {
  //         return {
  //           ...x,
  //           rank: index + 1,
  //         };
  //       });
  //       set_data(temp);
  //     } else {
  //       let temp = sector_result?.map((x, index) => {
  //         return {
  //           ...x,
  //           rank: index + 1,
  //         };
  //       });
  //       set_data(temp);
  //     }
  //   }, [items, sector_result]);

  useEffect(() => {
    if (data?.length) {
      let temp = data?.map((x, index) => {
        return {
          ...x,
          rank: index + 1,
        };
      });
      set_data(temp);
    }
  }, [data]);

  useEffect(() => {
    if (datas?.length > 0) {
      const newData = datas?.slice(0, -1);
      setTableData(newData);
    }
  }, [datas]);

  const handle_back = (route, values) => {
    navigateWithProps(route, values);
  };

  return (
    <VStack
      width={"100%"}
      justifyContent={"center"}
      overflowY={"hidden"}
      padding={"10px"}
    >
      <HStack space={"4px"}>
        <Pressable onPress={() => handle_back(`${ROUTES.USER_SURVEY}`, {})}>
          <Title className="title-style">Survey</Title>
        </Pressable>
        {!location?.state?.is_survey_view ? (
          <>
            {location?.state?.is_sector_map ? (
              <Pressable
                onPress={() =>
                  handle_back(
                    `${ROUTES.SECTOR_LIST}/${location?.state?.item?.survey_id}`,
                    { survey: location?.state?.item?.survey }
                  )
                }
              >
                <Title className="title-style">
                  {` / ${location?.state?.item?.survey?.name} ( ${location?.state?.item?.survey?.year} )`}
                </Title>
              </Pressable>
            ) : (
              <Pressable
                onPress={() =>
                  handle_back(
                    `${ROUTES.SECTOR_LIST}/${location?.state?.item?.survey_indicators?.[0]?.survey?.id}`,
                    {
                      survey:
                        location?.state?.item?.survey_indicators?.[0]?.survey,
                    }
                  )
                }
              >
                <Title className="title-style">
                  {` / ${location?.state?.item?.survey_indicators?.[0]?.survey?.name} ( ${location?.state?.item?.survey_indicators?.[0]?.survey?.year} )`}
                </Title>
              </Pressable>
            )}
            <Pressable
              onPress={() =>
                !location?.state?.is_sector_map &&
                handle_back(
                  `${ROUTES.USER_INDICATOR}/${location?.state?.sector_id}/${location?.state?.survey_id}`
                )
              }
            >
              <Title
                className={
                  location?.state?.is_sector_map
                    ? "title-style-none"
                    : "title-style"
                }
              >
                {` / ${location?.state?.sector?.name}`}
              </Title>
            </Pressable>
            {!location?.state?.is_sector_map && (
              <Title Title className="title-style-none">
                {` / ${location?.state?.item?.name}`}
              </Title>
            )}
          </>
        ) : (
          <Title Title className="title-style-none">
            {` / ${location?.state?.item?.name}`}
          </Title>
        )}
      </HStack>
      {/* <VStack width={"100%"} justifyContent={"center"}>
        <HStack style={{ justifyContent: "center" }}>
          <SectorRankCard sub_title="Thiruvarur" icon={GOLD_MEDAL} />
        </HStack>
        <HStack style={{ justifyContent: "center" }}>
          <SectorRankCard sub_title="Nagapattinam" icon={SILVER_MEDAL} />
          <SectorRankCard sub_title="Tanjavur" icon={BRONZE_MEDAL} />
        </HStack>
      </VStack> */}
      {table_data?.length !== 0 && (
        <HStack justifyContent={"flex-end"}>
          <Button onClick={() => toPDF()} type="primary">
            Download result
          </Button>
        </HStack>
      )}
      <VStack width={"100%"}>
        {survey_result_loading ? (
          <Center>
            <Spin size="large" />
          </Center>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "50px",
              justifyContent: "space-around",
            }}
          >
            {data?.length !== 0 && data ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "50px",
                    justifyContent: "space-around",
                  }}
                >
                  <SectorRankList
                    items={table_data}
                    result_loading={survey_result_loading}
                  />
                  <SectorRankingMap items={data} location={location} />
                </div>
              </>
            ) : (
              <Empty description={"No results"} />
            )}
            <div ref={targetRef} style={{ position: "fixed", top: "10000px" }}>
              <VStack
                w="100%"
                justifyContent={"center"}
                alignItems={"center"}
                padding={"30px"}
              >
                <Image
                  size="80px"
                  borderRadius={"120px"}
                  alt="logo"
                  source={logo}
                />
                <Text fontSize={"16px"} bold alignItems={"center"}>
                  District Good Governance Index
                </Text>
                <Text fontSize={"14px"} bold alignItems={"center"}>
                  Survey Result
                </Text>
              </VStack>

              <HStack alignItems={"center"} justifyContent={"space-between"} padding="30px">
                <div>
                  <Text fontSize={"12px"} bold>
                    Name :{" "}
                  </Text>
                  <Text
                    fontSize={"12px"}
                  >{`${location?.state?.item?.name}`}</Text>
                </div>
                <div>
                  <Text fontSize={"12px"} bold>
                    Date :{" "}
                  </Text>
                  <Text fontSize={"12px"}>
                    {CurrentDate.format("DD-MM-YYYY")}
                  </Text>
                </div>
              </HStack>
              {table_data?.length === 35 ? (
                <div style={{ height: "1100px" }}></div>
              ) : (
                ""
              )}
              <div style={{ height: "1100px",padding:"30px" }}>
                <SectorRankingMapPdf items={data} location={location} />
              </div>
              <div
                style={{
                  breakAfter: "page",
                  height: "auto",
                  padding: "30px",
                }}
              >
                <SectorRankList items={table_data} />
              </div>
            </div>
          </div>
        )}
        {!survey_result_loading && (
          <HStack justifyContent={"flex-end"} space="7" mt="5" zIndex={-10}>
            <Button
              onClick={() => {
                handle_back(history.push(ROUTES.USER_SURVEY));
              }}
              danger
            >
              Back
            </Button>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export default MapRankingSurveyWise;
