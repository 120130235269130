import { Input, Table } from "antd";
import { HStack, VStack } from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@views/routes/my_routes";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import { navigateWithProps } from "@helpers/functions";
import { get_all_area_district } from "@services/redux/slices/dynamic_entity/rest/rest_area";
import { paginationOptions } from "@helpers/constants";

const DistrictList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: district_list, loading: district_loading } =
    useDynamicSelector("get_all_area_district");
  const { Search } = Input;

  const handle_edit = (record) => {
    navigateWithProps(`${ROUTES.PROCESS_PAGE}/${record?.data?.id}`, record);
  };

  const column = [
    {
      title: t("table:name"),
      dataIndex: "",
      key: "name",
      render: (record) => record.data?.name,
    },
  ];

  const get_all_district_list = (data) => {
    let keys = [{ key: "get_all_area_district", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_area_district,
        { search_string: data },
        MethodType.POST
      )
    );
  };
  useEffect(() => {
    get_all_district_list();
  }, []);

  const onSearch = (value) => {
    let search_value = value?.target?.value || value;
    if (search_value?.length > 2) get_all_district_list(search_value);
    else if (!search_value?.length) get_all_district_list(null);
  };
  return (
    <div>
      <VStack padding={"20px"}>
        <HStack justifyContent={"flex-end"} mb={"20px"} space={"20px"}>
          <Search
            enterButton={true}
            onSearch={onSearch}
            allowClear={true}
            onChange={onSearch}
            placeholder="Search"
            style={{ width: "250px" }}
          />
        </HStack>
        <Table
          columns={column}
          dataSource={district_list}
          loading={district_loading}
          pagination={paginationOptions}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => handle_edit(record), // click row
            };
          }}
        />
      </VStack>
    </div>
  );
};

export default DistrictList;
