import { Card } from "antd";
import moment from "moment/moment";
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";

const RemarksModal = ({ history_list }) => {
  return (
    <div>
      {history_list?.answer_process_histories
        ?.filter((x) => x.status === "Reverted")
        ?.map((list) => {
          return (
            <>
              <Card style={{ marginTop: "10px" }}>
                <HStack space={"8"}>
                  <Box>
                    <Text bold>
                      {list?.processed_by?.department_officer?.name}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{list?.remarks}</Text>
                  </Box>
                  <Box
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    mt="5"
                  >
                    <Text style={{ textAlign: "right" }}>
                      {moment(list?.processed_by?.processed_datetime).format(
                        "DD/MM/YYYY HH:MM A"
                      )}
                    </Text>
                  </Box>
                </HStack>
              </Card>
            </>
          );
        })}
    </div>
  );
};
export default RemarksModal;
