import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import {
  delete_sector,
  get_all_sector,
} from "@services/redux/slices/dynamic_entity/rest/rest_sector";
import { Button, Space, Table, message } from "antd";
import { Box, Pressable } from "native-base";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import CustomDeleteModal from "../common/custom_delete_modal";
import { useTranslation } from "react-i18next";

const SectorsList = (props) => {
  const { set_add_sector_modal, set_header_type, set_form_values, get_one } =
    props;
  const dispatch = useDispatch();
  const [delete_modal_open, set_delete_modal_open] = useState(false);
  const { t } = useTranslation();
  const { items: sector_list, loading: sector_loading } =
    useDynamicSelector("get_all_sector");
  const {
    status: delete_status,
    loading: delete_loading,
    error: delete_error,
  } = useDynamicSelector("delete_sector");
  const handle_row_click = () => {
    set_add_sector_modal(true);
    set_header_type("Edit");
  };

  const get_all_sector_list = () => {
    let keys = [{ key: "get_all_sector", loading: true }];
    dispatch(dynamicRequest(keys, get_all_sector, {}, MethodType.POST));
  };
  useEffect(() => {
    get_all_sector_list();
  }, []);

  const delete_one_sector = () => {
    let keys = [{ key: "delete_sector", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_sector + get_one?.id, {}, MethodType.DELETE)
    );
  };
  useEffect(() => {
    if (delete_status === "success") {
      message.success(t("sector_deleted"));
      dispatch(dynamicClear("delete_sector"));
      set_delete_modal_open(false);
      get_all_sector_list();
    } else if (delete_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("delete_sector"));
    }
  }, [delete_status, delete_error]);
  const handle_close = () => {
    set_delete_modal_open(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      width: "250px",
    },
    // {
    //   title: "Weight",
    //   dataIndex: "weight",
    //   align: "left",
    //   width: "250px",
    //   onCell: (record) => ({
    //     onClick: () => {
    //       handle_row_click(record);
    //     },
    //   }),
    // },
    // {
    //   title: "Indicator",
    //   dataIndex: "indicator",
    //   align: "left",
    //   width: "250px",
    //   onCell: (record) => ({
    //     onClick: () => {
    //       handle_row_click(record);
    //     },
    //   }),
    // },
    {
      title: "Action",
      dataIndex: "",
      align: "left",
      width: "50px",
      render: (record) => {
        return (
            <Pressable
              danger
              onPress={() => {
                set_form_values(record);
                set_delete_modal_open(true);
              }}
            >
              <AiFillDelete />
            </Pressable>
        );
      },
    },
  ];

  return (
    <Box style={{ padding: "40px", marginBottom: "40px" }}>
      <Table
        style={{ width: "100%", height: "100%", marginTop: "50px" }}
        columns={columns}
        dataSource={sector_list}
        size="large"
        loading={sector_loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handle_row_click({
                ...record, logo: record.logo ? [
                  {
                    name: "view",
                    uid: 1,
                    url: record?.logo,
                    status: "done",
                  },
                ] : []
              }), set_form_values({
                ...record, logo: record.logo ? [
                  {
                    name: "view",
                    uid: 1,
                    url: record?.logo,
                    status: "done",
                  },
                ] : []
              })
            }// click row
          };
        }}
      />
      <CustomDeleteModal
        handle_close={handle_close}
        delete_modal_open={delete_modal_open}
        form_values={get_one}
        delete_function={delete_one_sector}
        delete_loading={delete_loading}
        content={"sector"}
      />
    </Box>
  );
};

export default SectorsList;
