import { sector_image } from "@helpers/image_constants";
import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_all_sector } from "@services/redux/slices/dynamic_entity/rest/rest_sector";
import {
  Button,
  Card,
  Empty,
  Form,
  Input,
  Select,
  Skeleton,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import {
  Box,
  Center,
  HStack,
  Image,
  Pressable,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomPageTitle from "../common/custom_page_title";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import {
  get_my_sectors,
  survey_sector_result,
  survey_sector_search,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { useLocation } from "react-router-dom";
import { navigateWithProps, retrieveItem } from "@helpers/functions";
import { AiFillExclamationCircle } from "react-icons/ai";
import { FaMedal } from "react-icons/fa";
import SectorPublish from "./sector_publish";
import { startCase } from "lodash";
import SkeletonLoading from "../ui/skeleton_loading/skeleton_loading";

const SectorList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const { Title } = Typography;
  const [search_string, set_search_string] = useState("");
  const [publish_modal, setPublishModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [search_string_value, set_search_string_value] = useState([]);
  const role = retrieveItem("user");

  // const search_string = Form.useWatch("search_string", form)
  const [index, set_index] = useState();

  const { items: sector_list, loading: sector_list_loading } =
    useDynamicSelector("get_my_sectors");
  const {
    info,
    loading: survey_sector_loading,
    is_allowed_to_publish,
  } = useDynamicSelector("survey_sector_result");
  console.log("data", info, is_allowed_to_publish);

  const {
    items: survey_sector_search_list,
    loading: survey_sector_search_loading,
  } = useDynamicSelector("survey_sector_search");

  const get_all_sector_list = () => {
    let keys = [{ key: "get_my_sectors", loading: true }];
    dispatch(
      dynamicRequest(keys, get_my_sectors, { survey_id: id }, MethodType.POST)
    );
  };

  const get_sector_search = () => {
    let keys = [{ key: "survey_sector_search", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        survey_sector_search,
        { survey_id: id, search_string: search_string },
        MethodType.POST
      )
    );
  };

  const get_survey_sector_result = (record) => {
    let keys = [{ key: "survey_sector_result", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        survey_sector_result + record?.id,
        {},
        MethodType.GET
      )
    );
  };

  const handleListClick = (value) => {
    set_index(value);
  };
  const handleBack = () => {
    history.push(ROUTES.USER_SURVEY);
  };

  useEffect(() => {
    get_all_sector_list();
  }, []);

  useEffect(() => {
    if (survey_sector_search_list) {
      set_search_string_value(survey_sector_search_list);
    }
  }, [survey_sector_search_list]);

  useEffect(() => {
    if (search_string?.length > 3) {
      get_sector_search();
    }
  }, [search_string]);

  const SectorCardLoading = () => {
    let loading_list = [{ id: "1" }, { id: "1" }, { id: "1" }, { id: "1" }];
    loading_list.map((list, index) => {
      return <Skeleton.Button key={index} className="sector-card" active />;
    });
  };

  const handleRedirectIndicator = (item) => {
    // history.push(`${ROUTES.USER_INDICATOR}/${item?.id}/${id}`);
    navigateWithProps(`${ROUTES.USER_INDICATOR}/${item?.id}/${id}`, {
      survey: item,
    });
  };

  const handle_publish_modal = (record) => {
    get_survey_sector_result(record);
    setActionItem(record);
    setPublishModal(true);
  };

  const handleRedirectMap = (record) => {
        navigateWithProps(
      `${ROUTES.SECTORAL_RANKING_SECTOR_MAP}/${record?.survey_id}/${record?.sector_id}`,
      {
        item: record,
        sector: record?.sector,
        sector_id: record?.sector_id,
        survey_id: record?.survey_id,
        id: record?.id,
        is_sector_map: true,
      }
    );
  };

  const sector_card = (item, index1) => {
    return (
      <Pressable
        onPress={() => {
          handleRedirectIndicator(item);
        }}
        style={{ zIndex: -10 }}
      >
        <Card
          key={index1}
          className="sector-card"
          style={{ backgroundColor: index1 === index ? "#D7EDF7" : "" }}
          onMouseEnter={() => handleListClick(index1)}
          onMouseLeave={() => handleListClick(null)}
        >
          {role.name === "State DES User" && (
            <HStack
              position={"absolute"}
              justifyContent={"flex-end"}
              width={"90%"}
              zIndex={1000000}
              space={"10px"}
            >
              {item?.survey_sector_status?.[0]?.status === "Completed" ? (
                <Text fontSize={"14px"} color={"green.600"} bold>
                  Published
                </Text>
              ) : (
                <Pressable
                  style={{ zIndex: 1000000 }}
                  onPress={() => {
                    handle_publish_modal(item);
                  }}
                >
                  <AiFillExclamationCircle size={"20px"} />
                </Pressable>
              )}

              <Pressable
                onPress={() => {
                  handleRedirectMap(item);
                }}
              >
                <FaMedal size={18} />
              </Pressable>
            </HStack>
          )}
          <HStack alignItems={"center"} space={4}>
            <Box
              // width={{
              //   base: '10px',
              //   sm: "10px",
              //   xs: "10px",
              //   md: "40px",
              //   lg: "40px",
              //   xl: "80px",
              //   "2xl": "80px",
              //   "3xl": "80px",
              // }}
              w={"80px"}
              borderRadius={"50%"}
              background={"gray.300"}
              padding="20px"
              //   height={"100px"}
            >
              <img alt="sector_img" src={item?.sector?.logo} />
            </Box>
            <Text
              // fontSize={{
              //   base: '10px',
              //   sm: "10px",
              //   xs: "10px",
              //   md: "10px",
              //   lg: "10px",
              //   xl: "14px",
              //   "2xl": "18px",
              //   "3xl": "18px",
              // }}
              fontSize={"18px"}
              bold
              color={index1 === index ? "#1c326e" : "black"}
            >
              {item?.sector?.name}
            </Text>
          </HStack>
          {/* <HStack justifyContent={"flex-end"} mt={"10px"}>
                        <Text fontSize={"12px"} color={"red.400"} bold>
                            {"In Progress"}
                        </Text>
                    </HStack> */}
        </Card>
      </Pressable>
    );
  };
  const handle_back = (route, values) => {
    navigateWithProps(route, values);
  };
  const SearchFelids = ({ list }) => {
    return (
      <Tooltip title={startCase(list.type)} placement="bottomLeft">
        {list.name}
      </Tooltip>
    );
  };
  const handle_search_sector = (value, values) => {
    let filter_search_value = survey_sector_search_list?.find(
      (list) => list?.id === value
    );

    if (filter_search_value?.type === "indicator") {
      navigateWithProps(
        `${ROUTES.INDICATOR_QUESTION_VIEW}/${filter_search_value?.id}/${filter_search_value?.json_data?.indicator?.id}/${filter_search_value?.json_data?.survey?.id}`,
        {
          item: filter_search_value,
          sector: filter_search_value?.json_data?.sector,
          sector_id: filter_search_value?.json_data?.survey?.survey_sector_id,
          survey_id: filter_search_value?.json_data?.survey?.id,
        }
      );
    } else {
      navigateWithProps(
        `${ROUTES.USER_INDICATOR}/${filter_search_value?.id}/${filter_search_value?.json_data?.survey?.id}`,
        {
          survey: filter_search_value?.json_data?.survey,
        }
      );
    }
  };
  const handle_search_string = (val, values) => {
    set_search_string(val);
  };

  const handle_close = () => {
    setActionItem({});
    setPublishModal(false);
  };

  return (
    <>
      <div style={{ padding: "40px" }}>
        {sector_list_loading ? (
          <Center>
            <SkeletonLoading size="default" />
          </Center>
        ) : (
          <>
            {/* <CustomPageTitle
                            title={` -  `}
                        /> */}
            <HStack space={"4px"}>
              <Pressable onPress={() => handle_back(`${ROUTES.USER_SURVEY}`)}>
                <Title className="title-style">Survey</Title>
              </Pressable>
              {sector_list?.[0]?.survey?.name ? (
                <Title className="title-style-none">
                  {`/ ${sector_list?.[0]?.survey?.name}`}
                  {`( ${sector_list?.[0]?.survey?.year} )`}
                </Title>
              ) : (
                ""
              )}
              <Title className="title-style-none">
                {/* {` / ${survey_sector?.[0]?.sector?.name}`} */}
              </Title>
            </HStack>
            <HStack justifyContent={"flex-end"} mb={"30px"}>
              {role.name !== "District Department User" && (
                <Box width={"300px"} textAlign={"end"}>
                  {/* <Text bold fontSize={"md"}>
                  Search
                </Text> */}

                  <Select
                    allowClear
                    showSearch
                    loading={survey_sector_search_loading}
                    onChange={handle_search_sector}
                    onSearch={handle_search_string}
                    filterOption={(input, option) =>
                      option.props.children.props.list.name
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 ||
                      option.props.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Search here"
                  >
                    {search_string.length > 3 &&
                      search_string_value?.map((list, index) => (
                        <Select.Option key={list?.name} value={list?.id}>
                          <SearchFelids list={list} />
                        </Select.Option>
                      ))}
                  </Select>
                </Box>
              )}
            </HStack>
            {sector_list?.length ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  placeItems: "center",
                }}
              >
                <SimpleGrid
                  columns={{
                    base: 2,
                    sm: 1,
                    xs: 1,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    "2xl": 4,
                    "3xl": 4,
                  }}
                  spacingX={{ base: 3, xl: 10 }}
                  spacingY={{ base: 3, xl: 10 }}
                  justifyContent={"center"}
                  alignItems={"start"}
                >
                  {sector_list?.map((sector, index) => {
                    return sector_card(sector, index);
                  })}
                </SimpleGrid>
              </div>
            ) : (
              <Empty />
            )}

            <HStack justifyContent={"flex-end"} space="7" mt="20">
              <Button
                onClick={() => {
                  handleBack();
                }}
                danger
              >
                Back
              </Button>
            </HStack>
          </>
        )}
      </div>
      <SectorPublish
        survey_sector_loading={survey_sector_loading}
        info={info}
        is_allowed_to_publish={is_allowed_to_publish}
        get_all_sector_list={get_all_sector_list}
        publish_modal={publish_modal}
        actionItem={actionItem}
        handle_close={handle_close}
      />
    </>
  );
};

export default SectorList;
