import { retrieveItem } from "@helpers/functions";
import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { survey_data_report } from "@services/redux/slices/dynamic_entity/rest/process";
import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { useDownloadExcel } from "react-export-table-to-excel";
const SurveyDataReport = ({ district }) => {
  const dispatch = useDispatch();
  const user = retrieveItem("data");
  const [excelData, setExcelData] = useState([]);
  const { items: data, loading: survey_data_report_loading } =
    useDynamicSelector("survey_data_report");
  useEffect(() => {
    if (data?.length) {
      let newData = JSON.parse(JSON.stringify(data)); // Deep copy of data array
      newData.forEach((x) => {
        if (x.answers?.length) {
          x.answers.forEach((y) => {
            let numerator_source = y.numerator_question?.answer?.map(
              (z) => z.department_office.name
            );
            let denominator_source = y.denominator_question?.answer?.map(
              (z) => z.department_office.name
            );
            let mergedArray = numerator_source.concat(denominator_source);
            let uniqueArray = [...new Set(mergedArray)];
            y.source = uniqueArray;
          });
        }
      });
      setExcelData(newData);
    }
  }, [data]);
  console.log("excelData", excelData);
  useEffect(() => {
    get_all_process_list();
  }, []);
  const get_all_process_list = () => {
    let keys = [{ key: "survey_data_report", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        survey_data_report,
        {
          district_id: district?.id,
        },
        MethodType.POST
      )
    );
  };
  const handleDownloadReport = (type, fn, dl, excelData) => {
    var elt = document.getElementById("survey-data-report");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "survey data" });

    // Get the first sheet in the workbook
    var ws = wb.Sheets[wb.SheetNames[0]];

    // Loop through each cell in the sheet and apply text-align style
    for (var cell in ws) {
      if (ws.hasOwnProperty(cell)) {
        // Check if it's a cell with content (not a header, etc.)
        if (cell[0] === "!" || cell[0] === "_") continue;

        // Apply text-align style to each cell
        ws[cell].s = {
          alignment: { horizontal: "center" /* or "left" or "right" */ },
        };

        // Convert x?.unique_id to string if it exists in the sheet
        excelData.forEach((report) => {
          report.answers.forEach((x) => {
            if (ws[cell].v === x.unique_id) {
              ws[cell].t = "s"; // Indicate that the cell contains a string
              ws[cell].v = "'" + x.unique_id.toFixed(2).toString(); // Convert to string and prepend with apostrophe
            }
          });
        });
      }
    }

    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, fn || `Report.` + (type || "xlsx"));
  };

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
    excelData: excelData,
    handleDownload: handleDownloadReport,
  });
  const calculateIndicator = (x, y) => {
    let indicator;
    const numerator = x.numerator_question?.answer?.find(
      (an) => an.department_office.name === y
    );
    const denominator = x.denominator_question?.answer?.find(
      (an) => an.department_office.name === y
    );
    const second_numerator = x.second_numerator_question?.answer?.find(
      (an) => an.department_office.name === y
    );
    const multiplier = x.survey_indicators?.[0]?.multiplier;
    if (numerator?.value && denominator?.value) {
      if (x.second_numerator_question?.text) {
        indicator =
          ((numerator.value - second_numerator.value) / denominator.value) *
          multiplier;
      } else {
        indicator = (numerator.value / denominator.value) * multiplier;
      }
    } else {
      indicator = numerator?.value;
    }
    console.log(
      "indicator",
      numerator?.value,
      second_numerator?.value,
      denominator?.value,
      multiplier,
      indicator
    );
    if (indicator > x.restriction_value) {
      indicator = x.restriction_value;
    }
    if (x.is_direct_value) {
      indicator = numerator?.value;
    }
    return indicator.toFixed(3);
  };

  const getNumerator = (x, y) => {
    const numerator = x.numerator_question?.answer?.find(
      (an) => an.department_office.name === y
    );

    const second_numerator = x.second_numerator_question?.answer?.find(
      (an) => an.department_office.name === y
    );
    let value;
    if (x.second_numerator_question?.text) {
      value = numerator.value - second_numerator.value;
    } else value = numerator.value || 0;
    return value.toFixed(3);
  };
  const getDenominator = (x, y) => {
    const denominator = x.denominator_question?.answer?.find(
      (an) => an.department_office.name === y
    );
    return denominator?.value.toFixed(3) || "";
  };

  return (
    <div>
      <Button
        loading={survey_data_report_loading}
        onClick={() => {
          onDownload(excelData);
        }}
      >
        Survey Data Report
      </Button>

      <div ref={tableRef} style={{ display: "none" }}>
        <table
          id="survey-data-report"
          bordered
          className="table-style"
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "100%",
            overflow: "auto",
          }}
        >
          <thead className="table-style">
            <tr className="table-style">
              <th className="table-style">Sl.no</th>
              <th className="table-style">Indicator.no</th>
              <th className="table-style">DGGI Sectors / Indicators</th>
              <th className="table-style">Numerator</th>
              <th className="table-style">Denominator</th>
              <th className="table-style" colSpan={2}>
                Source
              </th>
              <th className="table-style">Multiplier</th>
              <th className="table-style">Data Submitted By</th>
              <th className="table-style" colspan="3">
                {district?.name
                  ? district?.name
                  : user?.officer_details?.name?.split("-")[1]}
              </th>
            </tr>
            <tr className="table-style">
              <th className="table-style"></th>
              <th className="table-style"></th>
              <th className="table-style"></th>
              <th className="table-style"></th>
              <th className="table-style"></th>
              <th className="table-style">Numerator Source</th>
              <th className="table-style">Denominator Source</th>
              <th className="table-style"></th>

              <th className="table-style"></th>
              <th className="table-style">Numerator</th>
              <th className="table-style">Denominator</th>

              <th className="table-style">Indicator Value</th>
            </tr>
          </thead>
          <tbody className="table-style">
            {excelData?.map((report, index) => {
              return (
                <>
                  <tr className="table-style">
                    <td className="table-style" style={{ fontWeight: "bold" }}>
                      {index + 1}
                    </td>
                    {/* <td className="table-style" colSpan={"3"}></td> */}
                    <td
                      className="table-style"
                      colSpan="11"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {report?.name}
                    </td>
                    {/* <td className="table-style" colSpan={"7"}></td> */}
                  </tr>
                  {report?.answers?.map((x, i) => {
                    return (
                      <>
                        <tr className="table-style">
                          <td
                            className="table-style"
                            rowSpan={x.source?.length + 1}
                          >
                            {i + 1}
                          </td>
                          <td
                            className="table-style"
                            rowSpan={x.source?.length + 1}
                            style={{ textAlign: "right" }}
                          >
                            {x?.unique_id}
                          </td>
                          <td
                            className="table-style"
                            rowSpan={x.source?.length + 1}
                          >
                            {x.name}
                          </td>
                          <td
                            className="table-style"
                            rowSpan={x.source?.length + 1}
                          >
                            {x.numerator_question?.text}
                            {x.second_numerator_question?.text
                              ? " - " + x.second_numerator_question?.text
                              : ""}
                          </td>
                          <td
                            className="table-style"
                            rowSpan={x.source?.length + 1}
                          >
                            {x.denominator_question?.text}
                          </td>
                          <td
                            className="table-style"
                            rowSpan={x.source?.length + 1}
                          >
                            {x.numerator_question?.source_departments
                              ?.map((x, i) => x.name)
                              .join(", ")}
                          </td>
                          <td
                            className="table-style"
                            rowSpan={x.source?.length + 1}
                          >
                            {x.denominator_question?.source_departments
                              ? x.denominator_question?.source_departments
                                  ?.map((x) => x.name)
                                  .join(", ")
                              : "-"}
                          </td>
                          <td
                            className="table-style"
                            rowSpan={x.source?.length + 1}
                          >
                            {x.survey_indicators?.[0]?.multiplier == 0 ||
                            x.survey_indicators?.[0]?.multiplier == 1
                              ? "-"
                              : x.survey_indicators?.[0]?.multiplier}
                          </td>
                          {!x?.source.length && (
                            <>
                              <td className="table-style"></td>
                              <td className="table-style"></td>
                              <td className="table-style"></td>
                              <td className="table-style"></td>
                            </>
                          )}
                        </tr>
                        {x?.source
                          ?.filter?.((a) => !a?.includes("DES"))
                          .map((y) => (
                            <tr className="table-style">
                              <td className="table-style">{y}</td>
                              <td className="table-style">
                                {x.second_numerator_question?.text
                                  ? (
                                      x.numerator_question?.answer?.find(
                                        (an) => an.department_office.name === y
                                      )?.value -
                                      x.second_numerator_question?.answer?.find(
                                        (an) => an.department_office.name === y
                                      )?.value
                                    ).toFixed(3)
                                  : x.numerator_question?.answer?.find(
                                      (an) => an.department_office.name === y
                                    )?.value !== undefined
                                  ? x.numerator_question.answer.find(
                                      (an) => an.department_office.name === y
                                    ).value
                                  : ""}
                              </td>
                              <td className="table-style">
                                {x.denominator_question?.answer?.find(
                                  (an) => an.department_office.name === y
                                )?.value !== undefined
                                  ? x.denominator_question?.answer?.find(
                                      (an) => an.department_office.name === y
                                    )?.value
                                  : ""}
                              </td>
                              <td className="table-style">
                                {x.numerator_question?.answer?.find(
                                  (an) => an.department_office.name === y
                                )?.value &&
                                x.denominator_question?.answer?.find(
                                  (an) => an.department_office.name === y
                                )?.value
                                  ?  (
                                      ((x.second_numerator_question?.text
                                        ? x.numerator_question?.answer?.find(
                                            (an) =>
                                              an.department_office.name === y
                                          )?.value -
                                          x.second_numerator_question?.answer?.find(
                                            (an) =>
                                              an.department_office.name === y
                                          )?.value
                                        : x.numerator_question?.answer?.find(
                                            (an) =>
                                              an.department_office.name === y
                                          )?.value) /
                                        x.denominator_question?.answer?.find(
                                          (an) =>
                                            an.department_office.name === y
                                        )?.value) *
                                      x.survey_indicators?.[0]?.multiplier
                                    ).toFixed(3) > x.restriction_value &&
                                    x.restriction_value 
                                  : x.is_direct_value === true &&
                                    x.numerator_question?.answer?.find(
                                      (an) => an.department_office.name === y
                                    )?.value}
                              </td>
                            </tr>
                          ))}
                        {x?.source
                          ?.filter?.((a) => a?.includes("DES"))
                          .map((y) => (
                            <tr className="table-style">
                              <td className="table-style">{y}</td>
                              <td className="table-style">
                                {getNumerator(x, y)}
                              </td>
                              <td className="table-style">
                                {getDenominator(x, y)}
                              </td>
                              <td className="table-style">
                                {calculateIndicator(x, y)}
                              </td>
                            </tr>
                          ))}
                      </>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SurveyDataReport;
