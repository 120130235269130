import { MethodType } from '@helpers/service_call';
import { dynamicRequest, get_all_department, useDynamicSelector } from '@services/redux';
import { get_all_area_district } from '@services/redux/slices/dynamic_entity/rest/rest_area';
import { Form, Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';

const FilterForm = ({form}) => {
    const dispatch = useDispatch()
    const { items } = useDynamicSelector("get_all_department");
    const { items: district_list } = useDynamicSelector("get_all_area_district");

    const get_all_department_values = (data) => {
        let keys = [{ key: "get_all_department", loading: true }];
        dispatch(dynamicRequest(keys, get_all_department, {}, MethodType.POST));
    };
    const get_all_area_function = () => {
        let keys = [{ key: "get_all_area_district", loading: true }];
        dispatch(dynamicRequest(keys, get_all_area_district, {}, MethodType.POST));
    };
    useEffect(() => {
        get_all_department_values()
        get_all_area_function()
    }, [])
    return (
        <div>
            <Form layout="vertical" style={{ display: "flex", flexDirection: "row", gap: "20px" }} form={form}>
                <Form.Item name={"department_id"} label="Department">
                    <Select allowClear showSearch filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } style={{ width: "200px" }}>
                        {items?.map((option) => (
                            <Select.Option key={option?.name} value={option?.id}>
                                {option?.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={"area_id"} label="District">
                    <Select allowClear showSearch filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } style={{ width: "200px" }}>
                        {district_list?.map((option) => (
                            <Select.Option key={option?.data?.name} value={option?.data?.id}>
                                {option?.data?.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </div>
    )
}

export default FilterForm
