import { Text } from "native-base";
import React from "react";

const SaveSubmitConfirmationModal = () => {
  return (
    <div>
      <Text>{"Are you sure you want to submit this Data?"}</Text>
    </div>
  );
};

export default SaveSubmitConfirmationModal;
