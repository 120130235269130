import { Button, Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";

const CustomDeleteModal = (props) => {
  const { delete_modal_open, set_delete_modal_open, delete_record, form_values, handle_close, delete_function, delete_loading, content } = props;

  const set_delete_record = () => {
    set_delete_modal_open(false);
  };

  return (
    <Modal
      title="Confirmation"
      open={delete_modal_open}
      onCancel={handle_close}
      footer={[
        <Button
          key="cancel"
          onClick={handle_close}
          danger
        >
          Cancel
        </Button>,
        <Button
          onClick={delete_function}
          type="primary"
          loading={delete_loading}
        >
          Ok
        </Button>,
      ]}
    >
      Do you want to delete <b>{form_values?.name}</b> {content}?
    </Modal>
  );
};

export default CustomDeleteModal;
