import { callService } from "@helpers/config";
import { MethodType } from "@helpers/service_call";
import {
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import CustomButton from "@views/components/common/custom_button";
import CustomDeleteModal from "@views/components/common/custom_delete_modal";
import CustomModal from "@views/components/common/custom_modal";
import CustomPageTitle from "@views/components/common/custom_page_title";
import DepartmentList from "@views/components/departments/department_list";
import DepartmentOfficersList from "@views/components/departments_officers/department_officers_list";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DepartmentOfficerForm from "@views/components/departments_officers/department_officers_form/department_officer_form";
import { useTranslation } from "react-i18next";
import { get_all_department_office } from "@services/redux/slices/dynamic_entity/rest/rest_department_office";
import FilterForm from "@views/components/departments_officers/filter_form";

const DepartmentOfficers = () => {
  const [add_modal, set_add_modal_open] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const { Search } = Input;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [header_type, set_header_type] = useState("");
  const department_list = useDynamicSelector("get_all_department");
  const create_response = useDynamicSelector("department_office_create");
  const update_response = useDynamicSelector("department_office_update");
  const [form] = Form.useForm();
  const department_id = Form.useWatch("department_id", form);
  const area_id = Form.useWatch("area_id", form);
  const delete_record_modal = (record) => {
    // set_delete_modal_open(true);
    // set_delete_record(record);
  };

  const add_department = () => {
    setInitialValues(null);
    set_header_type("Add");

    set_add_modal_open(true);
  };
  const get_all_department_officer = (data) => {
    let keys = [{ key: "get_all_department_office", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_department_office,
        { search_string: data, department_id: department_id, area_id: area_id },
        MethodType.POST
      )
    );
  };
  const onSearch = (value) => {
    let search_value = value?.target?.value || value;
    if (search_value?.length > 2 || search_value?.length === 0)
      get_all_department_officer(search_value);
  };
  useEffect(() => {
    get_all_department_officer();
  }, [department_id, area_id]);
  return (
    <Row
      style={{
        width: "100%",
        overflowY: "auto",
        maxHeight: "100vh",
      }}
    >
      <Col span={24}>
        <CustomPageTitle title="Departments office" />
        <HStack
          style={{
            width: "100%",
            justifyContent: "space-between",
            zIndex: 100000,
            marginTop: "40px",
            marginBottom: "-90px",
            padding: "30px",
            alignItems: "center",
          }}
        >
          <FilterForm form={form} />
          <HStack>
            <Search
              enterButton={true}
              onSearch={onSearch}
              allowClear={true}
              onChange={onSearch}
              placeholder="Search"
              style={{ width: "250px" }}
            />
            <CustomButton
              button_name="Add Department office"
              onclick_button={add_department}
              header_type={header_type}
            />
          </HStack>
        </HStack>

        <DepartmentOfficersList
          delete_record_modal={delete_record_modal}
          set_add_modal_open={set_add_modal_open}
          set_header_type={set_header_type}
          header_type={header_type}
          setInitialValues={setInitialValues}
        />
        {/* <CustomModal
          header_type={header_type}
          add_modal={add_modal}
          set_add_modal_open={set_add_modal_open}
          initialValues={initialValues}
        /> */}
        <Modal
          title={`${header_type} Department office`}
          open={add_modal}
          destroyOnClose={true}
          onCancel={() => set_add_modal_open(false)}
          width={600}
          footer={[
            <Button
              htmlType="submit"
              form="department_form"
              type="primary"
              loading={create_response?.loading || update_response?.loading}
            >
              {header_type?.includes("Edit") ? t("update") : t("create")}
            </Button>,
            <Button
              key="cancel"
              onClick={() => set_add_modal_open(false)}
              danger
            >
              {t("close")}
            </Button>,
          ]}
        >
          <DepartmentOfficerForm initialValues={initialValues} />
        </Modal>
        {/* <CustomDeleteModal
          delete_modal_open={delete_modal_open}
          // set_delete_modal_open={set_delete_modal_open}
          delete_record={delete_record}
          initialValues={initialValues}
        /> */}
      </Col>
    </Row>
  );
};

export default DepartmentOfficers;
