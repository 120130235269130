import { Form, Input, message } from "antd";
import React from "react";

const SectorName = () => {
  return (
    <Form.Item
      name="name"
      label=" Name"
      rules={[
        {
          required: true,
          message: "Please Enter the Name",
        },
      ]}
    >
      <Input style={{ fontSize: "16px" }} />
    </Form.Item>
  );
};

export default SectorName;
