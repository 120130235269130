import CustomPageTitle from "@views/components/common/custom_page_title";
import AddIndicatorButtons from "@views/components/indicators/add_indicator_buttons";
import AddIndicatorForm from "@views/components/indicators/indicator_form/add_indicator_form";
import IndicatorList from "@views/components/indicators/indicator_list";
import { Card, Row } from "antd";
import { HStack } from "native-base";
import React from "react";

const Indicators = () => {
  return (
    <>
      <CustomPageTitle title="Indicators" />
        {/* <Card
          style={{
            backgroundColor: "#ccccc4",
            borderRadius: "30px",
            height: "550px",
            width: "100%",
          }}
        > */}
          <IndicatorList/>
        {/* </Card> */}

      {/* <AddIndicatorButtons /> */}
    </>
  );
};

export default Indicators;
