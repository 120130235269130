import { Space, Typography } from "antd";
import React from "react";

const { Title } = Typography;
const CustomPageTitle = ({ title }) => {
  return (
    <Space
      style={{
        width: "100%",
        maxHeight: "100vh",
      }}
    >
      <Title
        style={{
          fontSize: "23px",
          fontWeight: "600",
          margin: "10px",
          color: "#042446",
          marginTop: "20px",
          // textShadow: "0.5px 1px 0.5px gray"
        }}
      >
        {title}
      </Title>
    </Space>
  );
};

export default CustomPageTitle;
