import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, HStack, Text, VStack } from "native-base";
import Tree from "../tree_view/tree_view";
// import ActionButton from "../ui/dialog/action_button";
// import AntdModal from "../ui/antd_modal/antd_modal";
// import CategoryDetails from "./category_details";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { useDispatch } from "react-redux";
import { showNotification } from "@helpers/notify";
import { Button, Form, Modal, message } from "antd";
import { retrieveItem } from "@helpers/storage";
import CustomPageTitle from "../common/custom_page_title";
import LevelDetails from "./level_details";
import LevelToMapDepartment from "./level_to_map_department";
import {
  delete_level,
  get_all_nested_level,
} from "@services/redux/slices/dynamic_entity/rest/rest_levels";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";

function LevelList() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const history = useHistory();

  //   const {
  //     status: createStatus,
  //     error: createError,
  //     loading: create_loading,
  //   } = useDynamicSelector("create_category");
  //   const {
  //     status: updateStatus,
  //     error: updateError,
  //     loading: update_loading,
  //   } = useDynamicSelector("update_category");

  const { items: level_list, loading: loading } = useDynamicSelector(
    "get_all_nested_level"
  );
  const {
    status: delete_status,
    error: delete_error,
    status_code,
  } = useDynamicSelector("delete_level");
  //   const {
  //     loading: deleteLoading,
  //     status: deleteStatus,
  //     error: deleteError,
  //   } = useDynamicSelector("delete_category");
  let categories = [
    {
      id: "ffa87b31-c26d-4718-b177-2b6f98441877",
      name: "District",
      sub_categories: [
        {
          id: "e603a639-5834-4f54-bb1a-3082f1f22da4",
          name: "Corporation",
          sub_categories: [],
        },
        {
          id: "49ffb3fc-bfea-4251-8be8-f8f1826e9c18",
          name: "Town Panchayat",
          sub_categories: [],
        },
        {
          id: "60a55e9c-478a-4224-bce9-647f0a85b329",
          name: "Union",
          sub_categories: [
            {
              id: "49ffb3fc-bfea-4251-8be8-f8f1826e9c18",
              name: "Village Panchayat",
              sub_categories: [],
            },
          ],
        },
        {
          id: "d19ed68d-c6ec-479c-9f31-40767d2a09e0",
          name: "Municipality",
          sub_categories: [],
        },
        {
          id: "b3d104db-82d2-44a9-b79d-8418c96bf420",
          name: "GCC",
          sub_categories: [
            {
              id: "b3d104db-82d2-44a9-b79d-8418c96bf420",
              name: "Region",
              sub_categories: [
                {
                  id: "b3d104db-82d2-44a9-b79d-8418c96bf420",
                  name: "Zone",
                  sub_categories: [
                    {
                      id: "b3d104db-82d2-44a9-b79d-8418c96bf420",
                      name: "Ward",
                      sub_categories: [
                        {
                          id: "b3d104db-82d2-44a9-b79d-8418c96bf420",
                          name: "Street",
                          sub_categories: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: "bbb8bba6-27a4-4103-9ea9-7cabda2b9c96",
          name: "Township",
          sub_categories: [],
        },
        {
          id: "bbb8bba6-27a4-4103-9ea9-7cabda2b9c96",
          name: "Port Trust",
          sub_categories: [],
        },
        {
          id: "bbb8bba6-27a4-4103-9ea9-7cabda2b9c96",
          name: "Highways",
          sub_categories: [],
        },
      ],
    },
  ];
  const user = retrieveItem("role");
  const [action_type, setActionType] = useState(null);
  const [action_item, setActionItem] = useState({});
  const [form_values, set_form_values] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modal_open, setModalOpen] = useState(false);
  const [level_to_department_map, set_level_to_department_map] =
    useState(false);

  const handleAdd = (item) => {
    let values = { ...item, parent_name: item?.name };
    delete values.name;
    set_form_values(values);
    setActionItem({ parent_category_id: item?.parent_category_id });
    setActionType("add");
    setModalOpen(true);
    form.resetFields();
  };

  const handleEdit = (item) => {
    set_form_values(item);
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_level", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_level + record?.id, {}, MethodType.DELETE)
    );
  };

  const handleCategoriesOrderUpdate = (values) => {
    // let keys = [{ key: "updateHomeScreenCategory", loading: true }];
    // dispatch(
    //   dynamicRequest(keys, update_home_category_mutation, {
    //     id: record?.id,
    //   })
    // );
  };

  const handleGetProducts = (data) => {
    set_form_values(data);
    set_level_to_department_map(true);
    // history.push({
    //     pathname: `${ROUTES.PRODUCT}/${data.parent_category_id}`,
    //     state: { data },
    // });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setActionType("");
    setActionItem({});
    form.resetFields();
    set_form_values({});
    set_level_to_department_map(false);
  };

  const getCategoriesList = () => {
    // let keys = [{ key: "vendor_categories", loading: true }];
    // dispatch(
    //   dynamicRequest(keys, get_vendor_categories, {
    //     //page_limit: 1,
    //   })
    // );
  };
  const get_level_list = () => {
    let keys = [{ key: "get_all_nested_level", loading: true }];
    dispatch(dynamicRequest(keys, get_all_nested_level, {}, MethodType.POST));
  };

  useEffect(() => {
    get_level_list();
  }, []);
  useEffect(() => {
    if (delete_status === "success") {
      message.success(t("level_deleted"));
      dispatch(dynamicClear("delete_level"));
      get_level_list();
    } else if (delete_error) {
      message.error(t("something_went_wrong"));
    }
  }, [delete_status, delete_error]);

  let header_actions = [
    {
      height: "50px",
      label: t("add_category"),
      colorScheme: "success",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  const handle_close = () => {
    setModalOpen(false);
  };
  return (
    <Box>
      <VStack space={5} p={10}>
        <Box top="0px" ml={2} mb={-2}>
          <Text fontSize="lg" fontWeight="bold">
            <CustomPageTitle title={t("level")} />
          </Text>
        </Box>

        <HStack space={3} justifyContent="flex-end" m={3}>
          <Button
            onClick={handleAdd}
            type="primary"
            style={{ width: "150px", height: "40px", borderRadius: "10px" }}
          >
            {t("add_level")}
          </Button>
        </HStack>

        <Box width="100%">
          <Tree
            loading={false}
            data={level_list}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleGetProducts={handleGetProducts}
            handleAdd={handleAdd}
            handleCategoriesOrderUpdate={handleCategoriesOrderUpdate}
            user={user}
          />
        </Box>
      </VStack>

      {/* <AntdModal
        width={"350px"}
        header={
          action_type == "edit" ? t("update_category") : t("add_category")
        }
        isOpen={modal_open}
        onClose={handleModalClose}
        onPress={handleAdd}
        component={
          <CategoryDetails
            label={action_type === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={action_item}
            form={form}
          />
        }
      /> */}
      <Modal
        title={
          action_type?.includes("add") && form_values?.parent_name
            ? `Add level under ${
                form_values?.parent_name || form_values.parent_name
              }`
            : !form_values?.parent_name
            ? "Create level"
            : "Edit Level"
        }
        open={modal_open}
        onCancel={handleModalClose}
        footer={null}
      >
        <LevelDetails
          form_values={form_values}
          onCancel={handleModalClose}
          action_type={action_type}
          get_level_list={get_level_list}
        />
      </Modal>
      <Modal
        open={level_to_department_map}
        onCancel={handleModalClose}
        title={`Map Departments to ${
          form_values?.name || form_values.parent_name
        }`}
        footer={null}
      >
        <LevelToMapDepartment
          form_values={form_values}
          onCancel={handleModalClose}
          get_level_list={get_level_list}
        />
      </Modal>
    </Box>
  );
}

export default LevelList;
