import { Button, Empty, Form, Input, InputNumber, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import CustomPageTitle from "../common/custom_page_title";
import { Box, Center, HStack, Text, VStack } from "native-base";
import DraggableTable from "../system/draggable_table/draggable_table";
import SectorForm from "../system/draggable_table/sector_form";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import {
  create_survey,
  update_survey_sector,
  update_survey,
  get_survey_sector,
  update_survey_sector_serial_number,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { navigate } from "@helpers/functions";
import { useLocation } from "react-router-dom";

const SurveyDetails = () => {
  const { action_type, id } = useParams();
  const [sector_modal, set_sector_modal] = useState(false);
  const [survey_values, set_survey_values] = useState([]);
  const [indicator_values, set_indicator_values] = useState({});
  const [updated_value, set_updated_values] = useState([]);

  const { state } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("create_survey");
  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("update_survey");
  const {
    loading: sector_map_loading,
    status: sector_map_status,
    error: sector_map_error,
  } = useDynamicSelector("update_survey_sector");
  const { items: getall_survey_sector, loading: get_survey_sector_loading } =
    useDynamicSelector("get_survey_sector");
  const { error: create_survey_error, status: create_survey_status } =
    useDynamicSelector("create_survey_indicator");
  const { error: update_survey_error, status: update_survey_status } =
    useDynamicSelector("update_survey_indicator");
  const { status: delete_survey_status, error: delete_survey_error } =
    useDynamicSelector("delete_survey_indicator");

  const {
    loading: update_serial_number_loading,
    error: update_serial_number_error,
    status: update_serial_number_status,
  } = useDynamicSelector("update_survey_sector_serial_number");
  const handle_close = () => {
    set_sector_modal(false);
  };
  const handle_add_sector = () => {
    set_sector_modal(true);
  };
  const handle_back = () => {
    history.push(ROUTES.SURVEY);
  };
  const create_one_survey = (data) => {
    let keys = [{ key: "create_survey", loading: true }];
    dispatch(dynamicRequest(keys, create_survey, { ...data }, MethodType.PUT));
  };
  const update_one_survey = (id, data) => {
    let keys = [{ key: "update_survey", loading: true }];
    dispatch(
      dynamicRequest(keys, update_survey + id, { ...data }, MethodType.PATCH)
    );
  };
  const get_one_survey_sector = (sector_values) => {
    let keys = [{ key: "update_survey_sector", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        update_survey_sector,
        {
          survey_id: state?.id,
          ...sector_values,
          serial_number: getall_survey_sector?.length + 1,
        },
        MethodType.PUT
      )
    );
  };

  const handle_submit = (value) => {
    if (state?.id) {
      update_one_survey(state?.id, value);
    } else {
      create_one_survey(value);
    }
  };
  useEffect(() => {
    if (create_status === "success") {
      message.success(t("survey_created"));
      dispatch(dynamicClear("create_survey"));
      handle_back();
    } else if (create_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("create_survey"));
    } else if (update_status === "success") {
      message.success(t("survey_updated"));
      dispatch(dynamicClear("update_survey"));
    } else if (update_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("update_survey"));
    } else if (sector_map_status === "success") {
      message.success(t("sector_map_update"));
      handle_close();
      get_all_survey_sector(state?.id);
      dispatch(dynamicClear("update_survey_sector"));
    } else if (sector_map_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("update_survey_sector"));
    }
  }, [
    create_status,
    create_error,
    update_error,
    update_status,
    sector_map_status,
    sector_map_error,
  ]);

  useEffect(() => {
    if (update_serial_number_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("update_survey_sector_serial_number"));
    } else if (update_serial_number_status === "success") {
      message.success(t("sector_serial_number_updated_successfully"));
      get_all_survey_sector(state?.id);

      dispatch(dynamicClear("update_survey_sector_serial_number"));
    }
  }, [update_serial_number_error, update_serial_number_status]);

  const get_all_survey_sector = (id) => {
    let keys = [{ key: "get_survey_sector", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_survey_sector,
        { survey_id: id },
        MethodType.POST
      )
    );
  };

  const handle_update_order = () => {
    let keys = [{ key: "update_survey_sector_serial_number", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        update_survey_sector_serial_number,
        updated_value,
        MethodType.POST
      )
    );
  };
  useEffect(() => {
    if (state?.id && action_type?.includes("edit")) {
      form.setFieldsValue(state);
      get_all_survey_sector(state?.id);
    } else {
      form.resetFields();
    }
  }, [state, action_type]);
  useEffect(() => {
    if (getall_survey_sector) {
      set_survey_values(getall_survey_sector);
    }
  }, [getall_survey_sector]);

  useEffect(() => {
    if (
      create_survey_status === "success" ||
      delete_survey_status == "success" ||
      update_survey_status === "success"
    )
      get_all_survey_sector(state?.id);
  }, [create_survey_status, delete_survey_status, update_survey_status]);

  return (
    <div>
      <CustomPageTitle
        title={action_type?.includes("edit") ? "Update Survey" : "Add Survey"}
      />
      <VStack padding={"30px"} width={"100%"} space={"20px"}>
        <Form style={{ width: "100%" }} onFinish={handle_submit} form={form}>
          <VStack width={"40%"}>
            <Form.Item label="Name" name={"name"} rules={[{ required: true, message: "Name is monitory" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Number" name={"number"} rules={[{ required: true, message: "Number is monitory" }]}>
              <InputNumber style={{ textAlign: "start !important" }} />
            </Form.Item>
            <Form.Item label="Year" name={"year"} rules={[{ required: true, message: "Year is monitory" }]}>
              <Input />
            </Form.Item>
          </VStack>
          <HStack
            justifyContent={"center"}
            alignItems={"center"}
            mt={action_type?.includes("edit") ? "0px" : "50px"}
          >
            {action_type?.includes("edit") ? (
              <CustomPageTitle title={"Sector"} />
            ) : (
              <Box width={"90%"}></Box>
            )}
            <HStack justifyContent={"flex-end"} space={"20px"}>
              {action_type?.includes("edit") && (
                <Button type="primary" onClick={handle_add_sector}>
                  Add Sector
                </Button>
              )}
              <Button
                type="primary"
                htmlType="submit"
                loading={create_loading || update_loading}
              >
                {action_type?.includes("edit") ? "Update" : "Submit"}
              </Button>
              {action_type?.includes("edit") && (
                <Button
                  type="primary"
                  loading={update_serial_number_loading}
                  onClick={() => {
                    handle_update_order();
                  }}
                >
                  {"Update Order"}
                </Button>
              )}
              <Button danger onClick={handle_back}>
                Back
              </Button>
            </HStack>
          </HStack>
        </Form>

        {action_type?.includes("edit") && state?.id && get_survey_sector_loading ? (
          <Center>
            <Spin size="default" />
          </Center>
        ) : getall_survey_sector?.length !== 0 && getall_survey_sector && action_type?.includes("edit") ? (
          <DraggableTable
            set_sector_modal={set_sector_modal}
            getall_survey_sector={survey_values}
            sector_map_loading={sector_map_loading}
            get_one_survey_sector_function={get_one_survey_sector}
            set_updated_values={set_updated_values}
            get_all_survey_sector={get_all_survey_sector}
          />
        ) : (
          <> {id && <Empty description={"No Sectors"} />}</>
        )}
        <SectorForm
          open={sector_modal}
          onCancel={handle_close}
          title={"Add Sector"}
          map_to_sector={get_one_survey_sector}
          sector_map_loading={sector_map_loading}
          form_values={{}}
        />
      </VStack>
    </div>
  );
};

export default SurveyDetails;
