import CustomPageTitle from "@views/components/common/custom_page_title";
import DepartmentPendency from "@views/components/user/reports/department_pendency";
import DistrictPendency from "@views/components/user/reports/district_pendency";
import SectorIndicatorPendency from "@views/components/user/reports/sector_indicator_pendency";
import React from "react";

const SectorIndicatorPendencyReport = () => {
  const container_style = {
    // height: "100vh",
    overflowY: "auto",
    width: "100%",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  };

  return (
    <div style={container_style}>
      <CustomPageTitle title="Sector / Indicator  Pendency Report" />

      <SectorIndicatorPendency />
    </div>
  );
};

export default SectorIndicatorPendencyReport;
