import { gql } from "@apollo/client";
import { useEffect, useRef, useState } from "react";

export const languages = ["en"];
export const ProjectName = "District Good Governance Index";
export const defaultPageLimit = 999999999999;
export const defaultPageNumber = 1;
export const page_count = 12;
export const operator_matches_page_count = 1;
export const DEFAULT_COUNTRY_CODE = "IN";
export const DEFAULT_STATE_CODE = "TN";

export const astrology_charts = [
  { path: "D1", value: "Brith Chart" },
  { path: "D9", value: "Navamansha Chart" },
];

export const GOOGLE_API_KEY = "AIzaSyCVY8hRJ-X4VVYVuDECrRQynxwvuk6Kgow";
export const LOCATION_PROVIDER = "google";

export const ASTROLOGY_USER_ID = "620110";
export const ASTROLOGY_API_KEY = "cb343e1cba3ecc769520b97ea12cfdc9";
export const ASTROLOGY_URL =
  "https://json.astrologyapi.com/v1/horo_chart_image/";

export const ASTROLOGY_BASE_URL =
  "https://json.astrologyapi.com/v1/horo_chart_image/";

export const IMAGE_URL = "https://cdn.standardmatrimony.com/";

export const PROMO_URL = "https://stdmry.page.link/";

export const HOROSCOPE_URL = "https://horoscope.standardmatrimony.com/";

export const Data = [
  {
    name: "mano",
    gender: "m",
    dob: "1995-10-02T00:00:00.000Z",
    country_id: "IN",
    state_id: "TN",
    city_id: "chennai",
  },
];

export const numberFormat = (value) => {
  if (typeof value === "number") return `${value?.toLocaleString("en-IN")}`;
  else return "0";
};

// export const fileQuery = gql`
//   mutation {
//     createHospitalPatientVisitDocumentUploadUrl {
//       status
//       url
//       error {
//         status_code
//         message
//       }
//     }
//   }
// `;
export const process_options=[
  {
    label:"District",
    value:"district"
  },
  {
    label:"Department",
    value:"department"
  },
]
export const fileQuery = gql`
  query create_file_upload_url($custom: create_file_upload_url_input) {
    create_file_upload_url(custom: $custom) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;

export const color_gradients = {
  menu_gradients: "#0a2773",
  header: "#5868cc",
  banner_gradient:
    "linear-gradient(99.18deg, rgb(31 0 95 / 65 %) 13.03 %, rgb(175 19 179 / 52 %) 92.33 %)",
  // banner_: "linear-gradient(45deg, rgb(31 0 95), rgb(167 18 173 / 58%))",
  sports_event_gradient: "linear-gradient(99.9deg, #381d6d, #aa2dae)",
  menubar_gradient:
    "linear-gradient(99.18deg, rgb(50, 13, 107) 13.03%, rgb(176 61 185) 92.33%)",
  transparent_color: "rgb(31 0 95 / 0%)",
  text_gradient:
    "linear-gradient(99.18deg, rgb(31 0 95 / 65%) 13.03%, rgb(175 19 179 / 28%) 92.33%)",
  monogram_card_gradient: "#3349B8",
};

export const paginationOptions = {
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "50", "100"], // Customize the page size options
  defaultPageSize: 10, // Set the default page size
  showTotal: (total, range) => `Total ${total} items`,
};

export function useComponentSize(ref) {
  const initialState = { width: 0, height: 0 };
  const [dimensions, setdDimensions] = useState(initialState);
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        setdDimensions({ width, height });
      });
    });
    if (ref.current) resizeObserverRef.current.observe(ref.current);
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [ref]);
  return dimensions;
}

export const queryStringSet = (key, value) => {
  const queryParams = new URLSearchParams();
  queryParams.set(key, value);
  return queryParams.toString();
};

export const queryStringRetrieve = (key, value) => {
  const queryParams = new URLSearchParams(value);
  return queryParams.get(key);
};

export const status_types = {
  "State DES User": [
    { id: "approved", name: "Approved" },
    { id: "submitted_to_me", name: "Submitted" },
    { id: "reverted_by_me", name: "Reverted" },
    // { id: "pending_with_des", name: "Pending with des" },
    { id: "published", name: "Published" },
  ],
  "Super Admin": [
    { id: "submitted_by_me", name: "Submitted" },
    { id: "reverted_by_me", name: "Reverted" },
  ],
  "District Department User": [
    { id: "submitted_by_me", name: "Submitted" },
    { id: "reverted_to_me", name: "Reverted" },
  ],
  "District Collector": [
    { id: "submitted_by_me", name: "Submitted" },
    { id: "reverted_by_me", name: "Reverted" },
  ],
  "District DES User": [
    { id: "submitted_to_me", name: "Submitted to me" },
    { id: "submitted_by_me", name: "Submitted by me" },
    { id: "reverted_to_me", name: "Reverted to me" },
    { id: "reverted_by_me", name: "Reverted by me" },
  ],
};
export const StatusCodes = {
  // Error: "500",
  // InvalidData: "422",
  // InvalidMediaType: "415",
  // Forbidden: "403",
  // NotFound: "404",
  // Duplicate: "409",
  ["500"]: "Something went wrong",
  ["422"]: "Invalid Data",
  ["404"]: "Not Found",
  ["409"]: "Duplicate",
  ["412"]: "Not Found",
};
