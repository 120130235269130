import { Form, Select } from "antd";
import React from "react";

const DepartmentName = () => {
  const options = [
    { value: "option 1", label: "Department 1" },
    { value: "option2", label: "Department 2" },
    { value: "option3", label: "Department 3" },
  ];
  return (
    <Form.Item name="department_name" label="Department Name">
      <Select
        style={{ width: "250px", height: "30px", fontSize: "16px" }}
        allowClear
      >
        {options?.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DepartmentName;
