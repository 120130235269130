import { Input, Table, Typography } from "antd";
import React, { useEffect } from "react";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import {
  get_all_survey,
  get_survey_indicators,
  get_survey_sector,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { MethodType } from "@helpers/service_call";
import { HStack, Pressable, VStack } from "native-base";
import { IoMdEye } from "react-icons/io";
import { navigateWithProps } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const RankingSurveySectorIndicator = () => {
  const dispatch = useDispatch();
  const { items, loading } = useDynamicSelector("get_survey_indicators");
  const { survey_id, sector_id } = useParams();
  const location = useLocation();
  const { Search } = Input;

  const handle_redirect_sector = (values) => {
    navigateWithProps(
      `${ROUTES.SECTORAL_RANKING_INDICATOR_MAP}/${survey_id}/${sector_id}/${values?.id}`,
      {
        survey: location?.state?.survey,
        sector: location?.state?.sector,
        indicator: values,
      }
    );
  };
  const { Title } = Typography;

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "",
      render: (record) => record?.indicator?.name,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (record) => (
        <Pressable onPress={() => handle_redirect_sector(record)}>
          <IoMdEye size={20} />
        </Pressable>
      ),
    },
  ];

  const get_all_survey_sector_indicator = (data) => {
    let keys = [{ key: "get_survey_indicators", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_survey_indicators,
        { survey_id: survey_id, sector_id: sector_id, search_string: data },
        MethodType.POST
      )
    );
  };

  useEffect(() => {
    get_all_survey_sector_indicator();
  }, []);

  const handle_back = (route, values) => {
    navigateWithProps(route, values);
  };

  const onSearch = (value) => {
    let search_value = value?.target?.value || value;
    if (search_value?.length > 2 || search_value?.length === 0)
      get_all_survey_sector_indicator(search_value);
  };

  return (
    <div style={{ padding: "10px" }}>
      <HStack space={"3px"}>
        <Pressable
          onPress={() =>
            handle_back(
              `${ROUTES.SECTORAL_RANKING}/${location?.state?.survey?.id}`,
              {}
            )
          }
        >
          <Title className="title-style">Sectoral ranking</Title>
        </Pressable>
        <Pressable
          onPress={() =>
            handle_back(
              `${ROUTES.SECTORAL_RANKING_SECTOR}/${location?.state?.survey?.id}`,
              { survey: location?.state?.survey }
            )
          }
        >
          <Title className="title-style-none">
            {` / ${location?.state?.survey?.name}`}
          </Title>
        </Pressable>
        <Title className="title-style-none">
          {` / ${location?.state?.sector?.sector?.name}`}
        </Title>
      </HStack>
      <VStack padding={"20px"}>
        <HStack justifyContent={"flex-end"} mb={"20px"} space={"20px"}>
          <Search
            enterButton={true}
            onSearch={onSearch}
            allowClear={true}
            onChange={onSearch}
            placeholder="Search"
            style={{ width: "250px" }}
          />
        </HStack>
        <Table columns={columns} dataSource={items} loading={loading} />
      </VStack>
    </div>
  );
};

export default RankingSurveySectorIndicator;
