import { MethodType } from '@helpers/service_call';
import { dynamicClear, dynamicRequest, useDynamicSelector } from '@services/redux';
import { update_survey } from '@services/redux/slices/dynamic_entity/rest/rest_survey';
import { Modal, message } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const SurveyDelete = ({ sector_form, type, open, onCancel, title, form_item, get_all_survey_values }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        loading: delete_loading,
        status: delete_status,
        error: delete_error,
    } = useDynamicSelector("update_survey");
    const delete_survey = () => {
        let keys = [{ key: "update_survey", loading: true }];
        dispatch(
            dynamicRequest(
                keys,
                update_survey + form_item?.id,
                {},
                MethodType.DELETE
            )
        );
    };
    useEffect(() => {
        if (delete_status === "success") {
            message.success(t("survey_deleted"));
            dispatch(dynamicClear("update_survey"));
            onCancel();
            get_all_survey_values()
        } else if (delete_error) {
            message.error(t("something_went_wrong"));
            dispatch(dynamicClear("update_survey"));
        }
    }, [delete_status, delete_error]);
    return (
        <div>
            <Modal open={open} onCancel={onCancel} title={title} onOk={delete_survey} okButtonProps={{ loading: delete_loading }} cancelButtonProps={{ danger: true }}>
                Do you want to delete <b>{form_item?.name}</b> Survey?
            </Modal>
        </div>
    )
}

export default SurveyDelete
