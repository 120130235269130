import { Form, Radio, Select } from "antd";
import { Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const Numerator = ({ form, question_list }) => {
  const { t } = useTranslation();
  const numerator = Form.useWatch("numerator", form);
  const question_one = Form.useWatch("numerator_question_id", form);
  const question_two = Form.useWatch("second_numerator_question_id", form);
  const denominator_question_id = Form.useWatch(
    "denominator_question_id",
    form
  );
    
  const options = [
    { value: "simple", label: "Simple" },
    { value: "complex", label: "Complex" },
  ];

  const question_options = [
    {
      label: "Question1",
      value: "q1",
    },
    {
      label: "Question2",
      value: "q2",
    },
    {
      label: "Question3",
      value: "q3",
    },
  ];
  return (
    <>
      <Form.Item name="numerator" label="Numerator" labelCol={{ span: 24 }} rules={[{ required: true, message: "Numerator is monitory" }]}>
        {/* <Select
        style={{ width: "350px", height: "30px", fontSize: "16px" }}
        allowClear
      >
        {options?.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select> */}
        <Radio.Group
          defaultValue={"simple"}
          buttonStyle="outline"
          options={options}
        />
      </Form.Item>
      <Form.Item
        name="numerator_question_id"
        label={numerator === "complex" ? "Question one" : "Question"}
        rules={[{ required: true, message: `${numerator === "complex" ? "Question one" : "Question"} is monitory"` }]}
      >
        <Select
          style={{ fontSize: "16px" }}
          allowClear
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {question_two 
            ? question_list
              ?.filter(
                (list) => list?.id !== question_two || denominator_question_id
              )
              ?.map((option, index) => (
                <Select.Option key={option?.id} value={option?.id}>
                  {option?.text}
                </Select.Option>
              ))
            : question_list?.map((option, index) => (
              <Select.Option key={option?.id} value={option?.id}>
                {option?.text}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      {numerator?.includes("complex") && (
        <>
          <Form.Item
            name={"second_numerator_question_id"}
            label={"Question two"}
            rules={[{ required: true, message: "Question two is monitory" }]}
          >
            <Select style={{ fontSize: "16px" }} allowClear showSearch>
              {question_one || denominator_question_id
                ? question_list
                  ?.filter(
                    (list) =>
                      list?.id !== question_one || denominator_question_id
                  )
                  ?.map((option, index) => (
                    <Select.Option key={option?.id} value={option?.id}>
                      {option?.text}
                    </Select.Option>
                  ))
                : question_list?.map((option, index) => (
                  <Select.Option key={option?.id} value={option?.id}>
                    {option?.text}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Text bold color={"gray.400"}>
            {t("numerator_equation")}
          </Text>
        </>
      )}
    </>
  );
};

export default Numerator;
