import { MethodType } from "@helpers/service_call";
import {
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import {
  create_area,
  update_area,
} from "@services/redux/slices/dynamic_entity/rest/rest_area";
import { get_all_level } from "@services/redux/slices/dynamic_entity/rest/rest_levels";
import { Button, Form, Input, Select } from "antd";
import { HStack } from "native-base";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const AreaChildFormForm = ({
  areas_form_values,
  onCancel,
  parent_area_id,
  level_value,
  action_type,
  level_id,
}) => {
  const { items: levels_list } = useDynamicSelector("get_all_level");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    if (areas_form_values?.id) {
      form.setFieldsValue(areas_form_values);
    } else {
      form.resetFields();
    }
  }, [areas_form_values]);

  const handle_close = () => {
    onCancel();
    form.resetFields();
  };

  const get_all_department_values = (data) => {
    let keys = [{ key: "get_all_level", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_level,
        { parent_level_id: level_id },
        MethodType.POST
      )
    );
  };
  const create_one_area = (data) => {
    let keys = [{ key: "get_all_department", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_department, { ...data }, MethodType.POST)
    );
  };
  const update_one_area = (data) => {
    let keys = [{ key: "get_all_department", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_department, { ...data }, MethodType.POST)
    );
  };

  useEffect(() => {
    get_all_department_values();
  }, []);
  const handle_submit = (values) => {
    values.parent_area_id = parent_area_id;
    values.level_id = values.level_id ? values.level_id : level_value?.level_id;
    if (areas_form_values?.id) {
      let keys = [{ key: "update_area", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          `${update_area}${areas_form_values?.id}`,
          values,
          MethodType.PATCH
        )
      );
    } else {
      let keys = [{ key: "create_area", loading: true }];
      dispatch(dynamicRequest(keys, `${create_area}`, values, MethodType.PUT));
    }
  };
  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handle_submit}>
        <Form.Item label="Name" name={"name"}>
          <Input />
        </Form.Item>
        {action_type === "add" ? (
          <></>
        ) : (
          <Form.Item label="Level" name={"level_id"}>
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear
              showSearch
            >
              {levels_list?.map(
                (option) => (
                  (
                    <Select.Option key={option?.name} value={option?.id}>
                      {option?.name}
                    </Select.Option>
                  )
                )
              )}
            </Select>
          </Form.Item>
        )}
        <HStack justifyContent={"flex-end"} space={"20px"}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          <Button danger onClick={handle_close}>
            Close
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default AreaChildFormForm;
