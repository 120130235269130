import { paginationOptions, queryStringRetrieve, status_types } from '@helpers/constants'
import { MethodType } from '@helpers/service_call'
import { dynamicRequest, useDynamicSelector } from '@services/redux'
import { dashboard_indicator } from '@services/redux/slices/dynamic_entity/rest/rest_dashboard'
import { Form, Input, Select, Table, Typography } from 'antd'
import { HStack, Pressable, VStack } from 'native-base'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TabBar } from 'react-native-tab-view'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import CustomPageTitle from '../common/custom_page_title'
import { get_all_survey } from '@services/redux/slices/dynamic_entity/rest/rest_survey'
import { navigateWithProps, retrieveItem } from '@helpers/functions'
import { camelCase, upperFirst } from 'lodash'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTES } from '@views/routes/my_routes'
import { AiFillExclamationCircle, AiOutlineEye } from 'react-icons/ai'

const DashboardIndicatorList = () => {
    const { status, survey_id } = useParams()
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const { items: dashboard_indicator_list, loading: dashboard_indicator_loading } = useDynamicSelector("dashboard_indicator")
    const { items: survey_list } = useDynamicSelector("get_all_survey")
    const [form] = Form.useForm()

    const status_type = Form.useWatch("type", form)

    const { Title } = Typography;
    const { Search } = Input;
    const role = retrieveItem("user")
    const location = useLocation()

    let type = queryStringRetrieve("type", status)

    const get_dashboard = (search_string) => {
        let key = [{ key: "dashboard_indicator", loading: true }];
        dispatch(dynamicRequest(key, dashboard_indicator, { type: status_type, search_string, survey_id: survey_id }, MethodType.POST));
    };

    const get_survey_list = () => {
        let key = [{ key: "get_all_survey", loading: true }];
        dispatch(dynamicRequest(key, get_all_survey, {}, MethodType.POST));
    };

    useEffect(() => {
        if (status_type) {
            get_dashboard();
        }
    }, [status_type]);

    useEffect(() => {
        form.setFieldsValue({ type: type })
        get_survey_list()
    }, [])

    const indicator_actions = (record) => {
        if (record.status !== "active") {
            return (
                <HStack space={"20px"}>
                    {role.name === "State DES User" && (
                        <Pressable
                            onPress={() => {
                                handle_publish_modal(record);
                            }}
                        >
                            <AiFillExclamationCircle size={18} />
                        </Pressable>
                    )}
                    <Pressable
                        onPress={() => {
                            handleRedirectMap(record);
                        }}
                    >
                        <FaMedal size={18} />
                    </Pressable>
                </HStack>
            );
        } else {
            return (
                <HStack space={"20px"}>
                    <Pressable
                        onPress={() => {
                            handleView(record);
                        }}
                    >
                        <AiOutlineEye size={18} />
                    </Pressable>
                    {role.name === "State DES User" && (
                        <Pressable
                            onPress={() => {
                                handle_publish_modal(record);
                            }}
                        >
                            <AiFillExclamationCircle size={18} />
                        </Pressable>
                    )}
                </HStack>
            );
        }
    };


    const columns = [
        {
            title: t("table:indicator_number"),
            dataIndex: "",
            key: "unique_id",
            width: '150px',
            render: (record) => record?.indicator?.unique_id || "-"

        },
        {
            title: t("table:indicator_description"),
            key: "name",
            dataIndex: "",
            render: (record) => record?.indicator?.name
        },
        // {
        //     title: t("table:action"),
        //     key: "name",
        //     dataIndex: "",
        //     width: "100px",
        //     // render: (record) => indicator_actions(record)
        // }
    ]
    const handle_back = (route, values) => {
        navigateWithProps(route, values)
    }

    const onSearch = (value) => {
        let search_value = value?.target?.value || value
        if (search_value?.length > 2 || search_value?.length === 0)
            get_dashboard(search_value)
    }
    let status_type_name = status_types?.[role?.name].find((list) => list?.id === status_type)?.name

    const handle_edit = (record, rowIndex) => {
        // history.push(
        //   `${ROUTES.INDICATOR_QUESTION_VIEW}/${record?.indicator_id}/${record?.id}/${survey_id}`
        // );
        navigateWithProps(
            `${ROUTES.INDICATOR_QUESTION_VIEW}/${record?.id}/${record?.indicator_id}/${record?.survey?.id}`,
            {
                is_dashboard_view: true,
                item: { ...record, status_name: status_type_name, status: status, survey_name: location?.state?.data?.survey_name, survey_year: location?.state?.data?.survey_year },
                // index: record?.index,
                // sector: survey_sector?.[0]?.sector,
                // sector_id: sector_id,
                // survey_id: survey_id,
            }
        );
    };

    return (
        <VStack padding={"30px"}>
            <HStack space={"3px"}>
                <Pressable onPress={() => handle_back(ROUTES.DASHBOARD)}>
                    <Title className='title-style'>
                        Dashboard
                    </Title>
                </Pressable>
                <Title className='title-style-none'>
                    {`/ ${location?.state?.name}`}
                </Title>
                <Title className='title-style-none'>
                    {`/ ${location?.state?.data?.survey_name} ( ${location?.state?.data?.survey_year} )`}
                </Title>
            </HStack>

            <HStack justifyContent={"space-between"} mb={"5px"} space={"20px"} alignItems={"center"} mt={"20px"}>
                <Form layout='vertical' form={form} >
                    {/* <Form.Item name={"survey_id"} label={"Survey"} style={{ width: "200px" }}>
                        <Select options={survey_list} fieldNames={{ label: "name", value: "id" }} allowClear />
                    </Form.Item> */}
                    <Form.Item name={"type"} label={"Status"} style={{ width: "200px" }}>
                        <Select options={status_types?.[role?.name]} fieldNames={{ label: "name", value: "id" }} />
                    </Form.Item>
                </Form>
                <Search
                    enterButton={true}
                    onSearch={onSearch}
                    allowClear={true}
                    onChange={onSearch}
                    placeholder="Search"
                    style={{ width: '250px' }}
                />

            </HStack>
            <Table columns={columns} loading={dashboard_indicator_loading} dataSource={dashboard_indicator_list} onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => handle_edit(record, rowIndex), // click row
                };
            }} pagination={paginationOptions} />
        </VStack>
    )
}

export default DashboardIndicatorList
