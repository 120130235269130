import { numberFormat } from "@helpers/constants";
import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_previous_year_indicator_answer } from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import CustomPageTitle from "@views/components/common/custom_page_title";
import { Card, Skeleton, Spin } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const previous_numerator_question = (props) => {
  const { item, indicator } = props;

  return (
    <Box width={"200px"}>
      <>
        {item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.numerator_question?.id
        )?.value ? (
          <Text bold fontSize={"md"} textAlign={"right"}>
            {
              item?.survey_indicators?.[0]?.answers.find(
                (x) => x.question_id === indicator?.numerator_question?.id
              )?.value
            }
          </Text>
        ) : (
          <Text bold fontSize={"md"}>
            {"No Data Available"}
          </Text>
        )}
      </>
    </Box>
  );
};

const previous_second_numerator_question = ({ item, indicator }) => {
  return (
    <Box width={"200px"}>
      <>
        {item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.second_numerator_question?.id
        )?.value ? (
          <Text bold fontWeight={"md"} textAlign={"right"}>
            {
              item?.survey_indicators?.[0]?.answers.find(
                (x) =>
                  x.question_id === indicator?.second_numerator_question?.id
              )?.value
            }
          </Text>
        ) : (
          <Text bold fontSize={"md"}>
            {"No Data Available"}
          </Text>
        )}
      </>
    </Box>
  );
};

const previous_denominator_question = ({ item, indicator }) => {
  <Box width={"200px"}>
    <>
      {item?.survey_indicators?.[0]?.answers.find(
        (x) => x.question_id === indicator?.denominator_question?.id
      )?.value ? (
        <Text bold fontWeight={"md"} textAlign={"right"}>
          {
            item?.survey_indicators?.[0]?.answers.find(
              (x) => x.question_id === indicator?.denominator_question?.id
            )?.value
          }
        </Text>
      ) : (
        <Text bold fontSize={"md"} textAlign={"right"}>
          {"No Data Available"}
        </Text>
      )}
    </>
  </Box>;
};
const PreviousYearIndicatorSecondFormat = (props) => {
  const { survey_indicator_id, indicator, children } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [score, setScore] = useState();

  const item = useDynamicSelector("get_previous_year_indicator_answer");
  const get_previous_year_indicator_with_answer = () => {
    let keys = [{ key: "get_previous_year_indicator_answer", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_previous_year_indicator_answer,
        { survey_indicator_id: survey_indicator_id },
        MethodType.POST
      )
    );
  };

  useEffect(() => {
    get_previous_year_indicator_with_answer();
  }, []);
  useEffect(() => {
    if (indicator?.is_direct_value) {
      setScore(item?.survey_indicators?.[0]?.answers?.[0]?.value);
    } else {
      if (indicator.second_numerator_question?.text) {
        let numerator_1 = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.numerator_question?.id
        )?.value;

        let numerator_2 = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.second_numerator_question?.id
        )?.value;
        let denominator = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.denominator_question?.id
        )?.value;
        let score =
          ((numerator_1 - numerator_2) / denominator) *
          indicator.survey_indicators?.[0]?.multiplier;
        setScore(score);
      } else {
        let numerator_1 = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.numerator_question?.id
        )?.value;

        let denominator = item?.survey_indicators?.[0]?.answers.find(
          (x) => x.question_id === indicator?.denominator_question?.id
        )?.value;
        let score =
          (numerator_1 / denominator) *
          indicator.survey_indicators?.[0]?.multiplier;
        setScore(score);
      }
    }
  }, [indicator, item]);

  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { item, indicator, score });
    }
    return child;
  });
};
PreviousYearIndicatorSecondFormat.numerator_question =
  previous_numerator_question;
PreviousYearIndicatorSecondFormat.second_numerator_question =
  previous_second_numerator_question;
PreviousYearIndicatorSecondFormat.denominator_question =
  previous_denominator_question;

export default PreviousYearIndicatorSecondFormat;
