import { StatusCodes } from "@helpers/constants";
import { MethodType, status_codes } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { sector_publish } from "@services/redux/slices/dynamic_entity/rest/rest_publish";
import { Button, Modal, Spin, message } from "antd";
import { Center, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SkeletonLoading from "../ui/skeleton_loading/skeleton_loading";

const SectorPublish = ({
  handle_close,
  publish_modal,
  actionItem,
  info,
  survey_sector_loading,
  is_allowed_to_publish,
  get_all_sector_list
}) => {
  const dispatch = useDispatch();
  console.log("actionItem", actionItem);
  const {
    status: publish_status,
    error: publish_error,
    loading: publish_loading,
    status_code: status_code,
  } = useDynamicSelector("sector_publish");
  const fjn = useDynamicSelector("sector_publish");
  useEffect(() => {
    if (publish_status == "success") {
      message.success("Result published successfully");
      handle_close();
      get_all_sector_list();
      dispatch(dynamicClear("sector_publish"));
    } else if (publish_status === "failure") {
      // message.error(StatusCodes?.[status_code]);
      message.error("something went wrong");
      dispatch(dynamicClear("sector_publish"));
    } else if (publish_error) {
      // message.error(StatusCodes?.[status_code]);
      message.error("something went wrong");
      dispatch(dynamicClear("sector_publish"));
    }
  }, [publish_status, publish_error]);

  const handle_publish = () => {
    let keys = [{ key: "sector_publish", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        sector_publish,
        { survey_sector_id: actionItem?.id },
        MethodType.POST
      )
    );
  };

  return (
    <div>
      <Modal
        title="Confirmation"
        open={publish_modal}
        onCancel={handle_close}
        footer={
          actionItem?.survey_sector_status?.[0]?.status === "Completed"
            ? [
                <Button key="cancel" onClick={handle_close} danger>
                  Close
                </Button>,
              ]
            : [
                <Button key="cancel" onClick={handle_close} danger>
                  Cancel
                </Button>,
                is_allowed_to_publish === true ? (
                  <>
                    <Button
                      onClick={handle_publish}
                      type="primary"
                      loading={publish_loading}
                    >
                      Publish
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={handle_publish}
                      type="primary"
                      loading={publish_loading}
                      disabled
                    >
                      Publish
                    </Button>
                  </>
                ),
              ]
        }
      >
        {actionItem?.survey_sector_status?.[0]?.status === "Completed" ? (
          "Sector already published"
        ) : (
          <>
            {survey_sector_loading ? (
              <Center>
                <Spin size="large" />
              </Center>
            ) : (
              <VStack space="20px">
                <Text fontSize={"14px"}>
                  Are you sure you want to publish this{" "}
                  {<b>{actionItem?.sector?.name}</b>} sector?
                </Text>
                <Text fontSize={"14px"}>{info}</Text>
                {is_allowed_to_publish === false ? (
                  <Text fontSize={"14px"} color="red.400">
                    Please publish all available indicators in this sector to
                    publish this sector
                  </Text>
                ) : (
                  " "
                )}
              </VStack>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default SectorPublish;
