import { Modal } from "antd";
import { Text } from "native-base";
import React from "react";

const AreaDelete = ({ sector_form, type, areas_form_values, onCancel }) => {
  return (
    <div>
      <Text>
        Do you want to delete <b>{areas_form_values?.data?.name}</b> Area?
      </Text>
    </div>
  );
};

export default AreaDelete;
