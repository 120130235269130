import { Button, Form, Modal } from "antd";
import React from "react";
import AddDepartmentForm from "./source_department_form/add_department_form";

const AddModal = (props) => {
  const [form] = Form.useForm();
  const { header_type, set_add_source_department, add_department } = props;
  const handle_submit = () => {
    set_add_source_department(false);
  };
  return (
    <Modal
      title={`${header_type} Department`}
      open={add_department}
      onCancel={() => set_add_source_department(false)}
      footer={[
        <Button
          key="cancel"
          onClick={() => set_add_source_department(false)}
          style={{ backgroundColor: "lightblue", border: "transparent" }}
        >
          Back
        </Button>,
        <Button
          form="department form"
          onClick={handle_submit}
          htmlType="submit"
          style={{
            backgroundColor: "green",
            color: "white",
            border: "transparent",
          }}
        >
          Save
        </Button>,
      ]}
    >
      <AddDepartmentForm />
    </Modal>
  );
};

export default AddModal;
