import { navigate } from '@helpers/functions'
import { MethodType } from '@helpers/service_call'
import { dynamicClear, dynamicRequest, useDynamicSelector } from '@services/redux'
import { submit_forget_password } from '@services/redux/slices/dynamic_entity/rest/rest_forget_password'
import { ROUTES } from '@views/routes/my_routes'
import { Modal, message } from 'antd'
import { Text } from 'native-base'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

const ForgetConformationModal = ({ handle_close, open, confirmation_value }) => {
    const { t } = useTranslation();
    const { status, error, loading } = useDynamicSelector("submit_forget_password")
    const dispatch = useDispatch()

    const handle_forget_password = () => {
        let keys = [{ key: "submit_forget_password", loading: true }];
        dispatch(
            dynamicRequest(
                keys,
                submit_forget_password,
                { ...confirmation_value },
                MethodType.POST
            )
        );
    }

    useEffect(() => {
        if (status === "success") {
            message.success(t("mail_sent_successfully"))
            dispatch(dynamicClear("submit_forget_password"))
            handle_close()
            navigate(ROUTES.LOGIN)
        } else if (error) {
            message.error(t("something_went_wrong"))
            dispatch(dynamicClear("submit_forget_password"))
        }
    }, [status, error])

    return (
        <div>
            <Modal onCancel={handle_close} open={open} title={"Confirmation"} onOk={handle_forget_password} okButtonProps={{ loading: loading }} cancelButtonProps={{ danger: true }}>
                <Text>
                    If your username is registered in our system, a password reset email will be sent to the email address associated with your account. Please check your email for further instructions
                </Text>
            </Modal>
        </div>
    )
}

export default ForgetConformationModal
