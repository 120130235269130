import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_indicator } from "@services/redux/slices/dynamic_entity/rest/rest_indicators";
import { Button, Card, Skeleton, Spin, Typography } from "antd";
import { Center, HStack, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomPageTitle from "../common/custom_page_title";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { navigateWithProps, retrieveItem } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";
import { RiArrowUpDoubleLine } from "react-icons/ri";
import { RiArrowDownDoubleLine } from "react-icons/ri";
const SurveyQuestionDetails = (props) => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  // const [isOpen, setIsOpen] = useState(false);
  const { indicator_id, survey_id, indicator } = props;
  const { Title } = Typography;
  const department = retrieveItem("department");

  const numerationDepartment =
    indicator?.numerator_question?.source_departments.map((x) => x.name);

  const numeration_current_Department =
    indicator?.numerator_question?.source_departments
      ?.map((x) => x.name)
      ?.find((name) => name == department?.name);

  const numeratorSource = numerationDepartment?.join(", ");
  // const numeratorSource = numerationDepartment?.join(", ")

  const numerationDepartment2 =
    indicator?.second_numerator_question?.source_departments.map((x) => x.name);
  const numeratorSource2 = numerationDepartment2?.join(", ");

  const denominatorDepartment =
    indicator?.denominator_question?.source_departments?.map((x) => x.name);
  const denominatorSource = denominatorDepartment?.join(", ");
  const QuestionLabelValue = ({ label, value, color }) => {
    return label && value ? (
      <HStack backgroundColor={color ? color : "white"} padding={"10px"}>
        <Text w={"200px"} fontSize={"16px"}>
          {label}
        </Text>
        <Text fontSize={"16px"}>{`:  ${value ? value : ""}`}</Text>
      </HStack>
    ) : (
      <></>
    );
  };
  const handle_back = (route, values) => {
    navigateWithProps(route, values);
  };

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      {indicator?.loading ? (
        <Center>
          <Spin />
        </Center>
      ) : (
        <>
          {/* <CustomPageTitle title={`Survey > ${"2022-2023"} > Public Health`} /> */}
          {location.state.is_dashboard_view ? (
            <HStack space={"4px"}>
              <Pressable onPress={() => handle_back(`${ROUTES.DASHBOARD}`)}>
                <Title className="title-style">Dashboard</Title>
              </Pressable>
              <Pressable
                onPress={() =>
                  handle_back(
                    `${ROUTES.DASHBOARD}/${location?.state?.item?.status}`,
                    {
                      name: location?.state?.item?.status_name,
                      data: {
                        survey_name: location?.state?.item?.survey_name,
                        survey_year: location?.state?.item?.survey_year,
                      },
                    }
                  )
                }
              >
                <Title className="title-style">
                  {` / ${location?.state?.item?.status_name} `}
                </Title>
              </Pressable>
              <Title className="title-style-none">
                {` / ${location?.state?.item?.indicator?.name}`}
              </Title>
            </HStack>
          ) : (
            <HStack space={"4px"}>
              <Pressable onPress={() => handle_back(`${ROUTES.USER_SURVEY}`)}>
                <Title className="title-style">Survey</Title>
              </Pressable>
              <Pressable
                onPress={() =>
                  handle_back(
                    `${ROUTES.SECTOR_LIST}/${location?.state?.survey_id}`,
                    { survey: location?.state?.survey }
                  )
                }
              >
                <Title className="title-style">
                  {` / ${
                    location?.state?.item?.survey_indicators?.[0]?.survey
                      ?.name || location?.state?.item?.json_data?.survey?.name
                  } ( ${
                    location?.state?.item?.survey_indicators?.[0]?.survey
                      ?.year || location?.state?.item?.json_data?.survey?.year
                  } )`}
                </Title>
              </Pressable>
              <Pressable
                onPress={() =>
                  handle_back(
                    `${ROUTES.USER_INDICATOR}/${location?.state?.sector_id}/${location?.state?.survey_id}`
                  )
                }
              >
                <Title className="title-style">
                  {` / ${location?.state?.sector?.name}`}
                </Title>
              </Pressable>
              <Title className="title-style-none">
                {` / ${location?.state?.item?.name}`}
              </Title>
            </HStack>
          )}
          <HStack justifyContent={"center"} alignItems={"center"}>
            <Button
              icon={
                isOpen ? <RiArrowUpDoubleLine /> : <RiArrowDownDoubleLine />
              }
              type="primary"
              onClick={toggleContent}
            >
              {`${!isOpen ? "Show" : "Hide"} Meta-data`}
            </Button>
          </HStack>
          <div
            className={isOpen ? "content" : "non-content"}
            style={{ mb: isOpen ? "0px" : "-60px" }}
          >
            <QuestionLabelValue
              label={t("indicator_description")}
              value={indicator.name}
              color={"#b0b0b04a"}
            />
            <QuestionLabelValue
              label={indicator?.is_direct_value ? t("data") : t("numerator")}
              value={indicator.numerator_question?.text}
            />
            <QuestionLabelValue
              label={
                indicator?.is_direct_value
                  ? t("data_source")
                  : t("numerator_source")
              }
              value={numeratorSource}
              color={"#b0b0b04a"}
            />
            {indicator.second_numerator_question?.text && (
              <QuestionLabelValue
                label={t("numerator2")}
                value={indicator.second_numerator_question?.text}
              />
            )}
            {indicator.second_numerator_question?.text && (
              <QuestionLabelValue
                label={t("numerator_source2")}
                value={numeratorSource2}
                color={"#b0b0b04a"}
              />
            )}
            {indicator?.denominator_question?.text && (
              <QuestionLabelValue
                label={t("denominator")}
                value={indicator?.denominator_question?.text}
              />
            )}
            {indicator?.denominator_question?.text && (
              <QuestionLabelValue
                label={t("denominator_source")}
                value={denominatorSource}
                color={"#b0b0b04a"}
              />
            )}
            <QuestionLabelValue
              label={t("multiplier")}
              value={
                indicator.survey_indicators?.[0]?.multiplier !== 1
                  ? indicator.survey_indicators?.[0]?.multiplier
                  : "Nil"
              }
            />
            <QuestionLabelValue
              label={t("periodicity")}
              value={indicator.periodicity}
              color={"#b0b0b04a"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SurveyQuestionDetails;
