import CustomPageTitle from "@views/components/common/custom_page_title";
import UserSectorList from "@views/components/user_sector/user_sector";
import { Card } from "antd";
import { HStack } from "native-base";
import React from "react";

const UserSector = () => {
  const container_style = {
    // height: "100vh",
    overflowY: "auto",
    width: "100%",
    padding: "30px",
  };
  return (
    <div style={container_style}>
      {/* <CustomPageTitle title="2021" /> */}
      <HStack></HStack>
      <Card>
        <UserSectorList />
      </Card>
    </div>
  );
};

export default UserSector;
