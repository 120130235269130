import { departments } from "@helpers/config";
import { CallService, MethodType } from "@helpers/service_call";
import { showToast } from "@helpers/toast";
import { useDynamicSelector } from "@services/redux";
import {
  department_delete,
  dynamicClear,
  dynamicRequest,
} from "@services/redux/slices/dynamic_entity";
import { Button, Modal, Space, Table, Typography, message } from "antd";
import { Box, HStack, Pressable } from "native-base";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import CustomDeleteModal from "../common/custom_delete_modal";
import { FaUserFriends } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { navigateWithProps } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";
import {
  department_officer_delete,
  get_all_department_officer,
} from "@services/redux/slices/dynamic_entity/rest/rest_department_officers";
import DepartmentOfficerResetForm from "./department_officers_reset_form";
import { IoReloadOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const DepartmentOfficerUsersList = (props) => {
  const {
    delete_record_modal,
    set_add_modal_open,
    set_header_type,
    setInitialValues,
    handle_edit_department_user,
  } = props;
  const dispatch = useDispatch();
  const department_list = useDynamicSelector("get_all_department_office");
  const create_response = useDynamicSelector("department");
  const update_response = useDynamicSelector("department_officer_update");
  const create_dept_officer = useDynamicSelector("department_officer_create");
  const update_dept_officer = useDynamicSelector("department_officer_update");
  const reset_password_department_officers = useDynamicSelector(
    "reset_password_department_officers"
  );
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const delete_department_user = useDynamicSelector(
    "department_officer_delete"
  );
  const [page, setPage] = React.useState(1);
  const [values, setValues] = useState({});
  const [delete_record, set_delete_record] = useState({});
  const [delete_modal_open, set_delete_modal_open] = useState(false);
  const [reset_password_modal, set_reset_password_modal] = useState(false);
  const handle_row_click = (record) => {
    set_add_modal_open(true);
    set_header_type("Edit");
    setInitialValues({
      ...record,
      email: record?.system_user?.email,
      mobile: record?.system_user?.mobile,
      username: record?.system_user?.username,
    });
  };

  const handleResetPasswordModal = (record) => {
    setValues(record);
    set_reset_password_modal(true);
  };
  const get_all_department_values = (data) => {
    let keys = [{ key: "get_all_department_office", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_department_officer,
        { department_office_id: id },
        MethodType.POST
      )
    );
  };

  useEffect(() => {
    if (id) {
      get_all_department_values();
    }
  }, [id]);

  useEffect(() => {
    if (create_dept_officer?.status === "success") {
      message.success("Department Officer added successfully");
      set_add_modal_open(false);
      get_all_department_values();
      dispatch(dynamicClear("department_officer_create"));
    } else if (create_dept_officer?.status === "error") {
      message.error("Something went wrong");
      dispatch(dynamicClear("department_officer_create"));
    }
    if (update_dept_officer?.status === "success") {
      message.success("Department Officer updated successfully");
      set_add_modal_open(false);
      get_all_department_values();
      dispatch(dynamicClear("department_officer_update"));
    } else if (update_dept_officer?.status === "error") {
      message.error("Something went wrong");
      dispatch(dynamicClear("department_officer_update"));
    }
    if (delete_department_user?.status === "success") {
      message.success("Department Officer deleted successfully");
      set_delete_modal_open(false);
      get_all_department_values();
      dispatch(dynamicClear("department_officer_delete"));
    } else if (delete_department_user?.status === "error") {
      message.error("Something went wrong");
      dispatch(dynamicClear("department_officer_delete"));
    }
  }, [create_dept_officer, update_dept_officer, delete_department_user]);

  useEffect(() => {
    if (reset_password_department_officers?.status_code === "200") {
      message.success("The password has been reset successfully");
      set_reset_password_modal(false);
      dispatch(dynamicClear("reset_password_department_officers"));
    } else if (reset_password_department_officers?.status_code === "500") {
      message.error("Something went wrong");
      set_reset_password_modal(false);
      dispatch(dynamicClear("reset_password_department_officers"));
    }
  }, [reset_password_department_officers]);
  const handle_user = (value) => {
    navigateWithProps(`${ROUTES.DEPARTMENTS_OFFICER_USERS}/${value?.id}`, {
      department_officer: value,
    });
  };

  const columns = [
    // {
    //   title: "S.No",
    //   dataIndex: "",
    //   align: "left",
    //   width: "300px",
    //   render: (value, item, index) => (page - 1) * 10 + index + 1,
    // },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      width: "300px",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      align: "left",
      width: "300px",
    },
    {
      title: "Action",
      dataIndex: "",
      align: "left",
      width: "50px",
      render: (_, record) => (
        <HStack space={"10px"}>
          <Pressable onPress={() => handle_row_click(record)}>
            <FiEdit color={"black"} size={18} />
          </Pressable>
          <Pressable onPress={() => handleResetPasswordModal(record)}>
            <IoReloadOutline color={"black"} size={18} />
          </Pressable>
          <Pressable
            onPress={() => {
              delete_record_modal(record);
              set_delete_record(record);
              set_delete_modal_open(true);
              set_add_modal_open(false);
            }}
          >
            <AiFillDelete size={18} />
          </Pressable>
        </HStack>
      ),
    },
  ];
  const delete_function = () => {
    let keys = [{ key: "department_officer_delete", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        department_officer_delete + delete_record?.id,
        {},
        MethodType.DELETE
      )
    );
  };
  const handle_close = () => {
    set_delete_modal_open(false);
  };
  return (
    <Box style={{ padding: "40px", marginBottom: "40px" }}>
      <Table
        loading={department_list?.loading}
        style={{ width: "100%", height: "100%", marginTop: "50px" }}
        columns={columns}
        dataSource={department_list?.items}
        size="large"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_row_click(record), // click row
          };
        }}
      />
      {/* <CustomDeleteModal
        delete_modal_open={delete_modal_open}
        set_delete_modal_open={set_delete_modal_open}
        form_values={delete_record}
        initialValues={initialValues}
      /> */}
      <Modal
        title="Confirmation"
        open={delete_modal_open}
        onCancel={handle_close}
        footer={[
          <Button key="cancel" onClick={handle_close} danger>
            Cancel
          </Button>,
          <Button
            onClick={delete_function}
            type="primary"
            loading={delete_department_user?.loading}
          >
            Ok
          </Button>,
        ]}
      >
        Do you want to delete <b>{delete_record?.name}</b> department officer?
      </Modal>
      <Modal
        title={`Reset Password`}
        open={reset_password_modal}
        destroyOnClose={true}
        onCancel={() => set_reset_password_modal(false)}
        width={600}
        footer={[
          <Button
            htmlType="submit"
            form="reset_form"
            type="primary"
            loading={reset_password_department_officers?.loading}
          >
            {t("submit")}
          </Button>,
          <Button key="cancel" onClick={() => set_add_modal_open(false)} danger>
            {t("close")}
          </Button>,
        ]}
      >
        <DepartmentOfficerResetForm initialValues={values} />
      </Modal>
    </Box>
  );
};

export default DepartmentOfficerUsersList;
