import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";

const Denominator = (props) => {
  const { question_list, form } = props
  const [denominator_questions, set_denominator_questions] = useState([])
  const options = [
    { value: "option1", label: "Denominator 1" },
    { value: "option2", label: "Denominator 2" },
    { value: "option3", label: "Denominator 3" },
  ];

  const question_one = Form.useWatch("numerator_question_id", form)
  const question_two = Form.useWatch("second_numerator_question_id", form)

  useEffect(() => {
  
    if (question_one && question_two) {
      set_denominator_questions(question_list?.filter((list) => list?.id !== question_one && list?.id !== question_two));
    } else if (question_two) {
      set_denominator_questions(question_list?.filter((list) => list?.id !== question_two));
    } else if (question_one) {
      set_denominator_questions(question_list?.filter((list) => list?.id !== question_one));
    } else {
      set_denominator_questions(question_list);
    }
  }, [question_one, question_two, question_list]);

  return (
    <Form.Item name="denominator_question_id" label="Denominator" labelCol={{ span: 24 }} rules={[{ required: true, message: "Denominator is monitory" }]}>
      <Select
        style={{ fontSize: "16px" }}
        allowClear
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {question_one || question_two ? denominator_questions?.map((option, index) => (
          <Select.Option key={option?.id} value={option?.id}>
            {option?.text}
          </Select.Option>
        )) : denominator_questions?.map((option, index) => (
          <Select.Option key={option?.id} value={option?.id}>
            {option?.text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Denominator;
