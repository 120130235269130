import { navigate } from "@helpers/functions";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import { get_all_level } from "@services/redux/slices/dynamic_entity/rest/rest_levels";
import {
  create_question,
  update_question,
} from "@services/redux/slices/dynamic_entity/rest/rest_question";
import CustomButton from "@views/components/common/custom_button";
import CustomPageTitle from "@views/components/common/custom_page_title";
import AddModal from "@views/components/questions/add_modal";
import SelectQuestion from "@views/components/questions/select_question";
import SourceDepartmentTable from "@views/components/questions/source_department_table";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Card, Col, Form, Input, Row, Select, message } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Questions = () => {
  const [add_department, set_add_source_department] = useState("");
  const [header_type, set_header_type] = useState("");
  const [todoList, setTodoList] = useState([
    {
      department_id: "",
      level: "",
    },
  ]);
  const { action_type } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const department_list = Form.useWatch("department", form);
  const dispatch = useDispatch();
  const { items: departments, loading: department_list_loading } =
    useDynamicSelector("get_all_department");
  const { items: levels } = useDynamicSelector("get_all_level");
  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("create_question");
  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("update_question");

  const add_source_department = () => {
    set_header_type("Add");
    set_add_source_department(true);
  };

  const handle_submit = (values) => {
    // let source_department = values?.department?.map((list) => {
    //   return {
    //     id: list?.department_id
    //   }
    // })
    // const source_departments = Object.values(values?.department)?.map((item) => { return { id: item?.department_id } });
    // const source_departments= Object.values(values?.department)?.map((item) => { return { id: item?.department_id } });
    let departments = values?.source_departments?.map((list) => {
      return { id: list };
    });
    let levels = values?.source_levels?.map((list) => {
      return { id: list };
    });
    let form_values = {
      ...values,
      source_departments: departments,
      source_levels: levels,
    };
    if (state?.id) {
      update_one_question(form_values);
    } else {
      create_one_question(form_values);
    }
  };

  const handle_back = () => {
    history.push(ROUTES.QUESTIONS);
  };
  const get_all_department_values = (data) => {
    let keys = [{ key: "get_all_department", loading: true }];
    dispatch(dynamicRequest(keys, get_all_department, {}, MethodType.POST));
  };
  const get_all_level_values = (data) => {
    let keys = [{ key: "get_all_level", loading: true }];
    dispatch(dynamicRequest(keys, get_all_level, {}, MethodType.POST));
  };
  const create_one_question = (data) => {
    let keys = [{ key: "create_question", loading: true }];
    dispatch(
      dynamicRequest(keys, create_question, { ...data }, MethodType.PUT)
    );
  };
  const update_one_question = (data) => {
    let keys = [{ key: "update_question", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        update_question + state?.id,
        { ...data },
        MethodType.PATCH
      )
    );
  };
  useEffect(() => {
    get_all_department_values();
    get_all_level_values();
  }, []);

  useEffect(() => {
    if (state?.id && action_type.includes("edit")) {
      let departments = state?.source_departments?.map((list) => list?.id);
      let levels = state?.source_levels?.map((list) => list?.id);
      let form_values = {
        ...state,
        source_departments: departments,
        source_levels: levels,
        is_previous_question: state?.same_as_previous_year_question_id
          ? true
          : false,
        fixed_answer: state?.fixed_answer,
      };
      form.setFieldsValue(form_values);
    }
  }, [state, action_type]);

  useEffect(() => {
    if (create_status === "success") {
      message.success(t("question_created"));
      dispatch(dynamicClear("create_question"));
      navigate(ROUTES.QUESTIONS);
    } else if (create_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("create_question"));
    } else if (update_status === "success") {
      message.success(t("question_updated"));
      navigate(ROUTES.QUESTIONS);
      dispatch(dynamicClear("update_question"));
    } else if (update_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("update_question"));
    }
  }, [create_status, create_error, update_error, update_status]);
  return (
    <>
      <CustomPageTitle
        title={
          action_type?.includes("edit") ? "Update Question" : "Add Question"
        }
      />
      <VStack padding={"50px"}>
        <Form
          id="question_form"
          form={form}
          onFinish={handle_submit}
          layout="vertical"
        >
          <Card
            style={{
              borderRadius: "15px",
              width: "100%",
            }}
          >
            <SelectQuestion form={form} />
            <Box marginLeft={"-12px"}>
              <Form.Item
                name={"source_departments"}
                label={
                  <span className="label-font-size">Source departments</span>
                }
                style={{ marginLeft: "20px", fontSize: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Source departments is monitory",
                  },
                ]}
              >
                <Select
                  style={{ width: "350px", fontSize: "16px" }}
                  allowClear
                  mode="multiple"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {departments
                    ?.filter(
                      (list, index) =>
                        list?.id !== department_list?.[index]?.department_id
                    )
                    ?.map((option) => (
                      <Select.Option key={option?.name} value={option?.id}>
                        {option?.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Box>
            <Box marginLeft={"-12px"}>
              <Form.Item
                name={"source_levels"}
                label={
                  <span className="label-font-size">Applicable levels</span>
                }
                style={{ marginLeft: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Applicable levels is monitory",
                  },
                ]}
              >
                <Select
                  style={{ width: "350px", fontSize: "16px" }}
                  allowClear
                  mode="multiple"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {levels?.map((option) => (
                    <Select.Option key={option?.name} value={option?.id}>
                      {option?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Box>
          </Card>
          <HStack justifyContent={"flex-end"} space={"10px"} mt={"20px"}>
            <Button
              type="primary"
              htmlType="submit"
              loading={create_loading || update_loading}
            >
              {action_type?.includes("edit") ? "Update" : "Submit"}
            </Button>
            <Button danger onClick={handle_back}>
              Back
            </Button>
          </HStack>
        </Form>
      </VStack>
      <AddModal
        header_type={header_type}
        set_header_type={set_header_type}
        add_department={add_department}
        set_add_source_department={set_add_source_department}
      />
    </>
  );
};

export default Questions;
