import {
  Badge,
  Button,
  Input,
  Modal,
  Spin,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AiFillDelete,
  AiFillExclamationCircle,
  AiOutlineEye,
} from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import {
  dynamicClear,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import { get_all_indicator } from "@services/redux/slices/dynamic_entity/rest/rest_indicators";
import { useDispatch } from "react-redux";
import { MethodType } from "@helpers/service_call";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import {
  get_my_survey_indicators,
  survey_indicator_progress,
  survey_indicator_result,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { useLocation, useParams } from "react-router-dom";
import CustomPageTitle from "@views/components/common/custom_page_title";
import { FaMedal } from "react-icons/fa";
import { navigateWithProps, retrieveItem } from "@helpers/functions";
import { indicator_publish } from "@services/redux/slices/dynamic_entity/rest/rest_publish";
import { paginationOptions } from "@helpers/constants";

const UserIndicatorList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { sector_id, survey_id } = useParams();
  const { Title } = Typography;
  const location = useLocation();
  const role = retrieveItem("user");

  const [indicator_list, setIndicatorList] = useState([]);
  console.log("indicator_list", indicator_list);
  const {
    status: publish_status,
    error: publish_error,
    loading: publish_loading,
  } = useDynamicSelector("indicator_publish");

  const [publish_modal, setPublishModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const { items: survey_sector, loading: indicator_loading } =
    useDynamicSelector("get_my_survey_indicators");
  console.log("survey_sector", survey_sector);
  const {
    items: survey_sector_progress_list,
    loading: survey_sector_progress_loading,
  } = useDynamicSelector("survey_indicator_progress");

  const {
    info,
    is_allowed_to_publish,
    loading: survey_indicator_loading,
  } = useDynamicSelector("survey_indicator_result");
    const handle_edit = (record, rowIndex) => {
    // history.push(
    //   `${ROUTES.INDICATOR_QUESTION_VIEW}/${record?.survey_indicators?.[0]?.id}/${record?.id}/${survey_id}`
    // );
    navigateWithProps(
      `${ROUTES.INDICATOR_QUESTION_VIEW}/${record?.survey_indicators?.[0]?.id}/${record?.id}/${survey_id}`,
      {
        index: record?.index,
        item: record,
        sector: survey_sector?.[0]?.sector,
        sector_id: sector_id,
        survey_id: survey_id,
      }
    );
  };
  const handleView = (record) => {
    history.push(
      `${ROUTES.INDICATOR_QUESTION_VIEW}/${record?.survey_indicators?.[0]?.id}/${record?.id}/${survey_id}`
    );
  };
  const handleBack = () => {
    history.push(`${ROUTES.SECTOR_LIST}/${survey_id}`);
  };

  const handle_publish_modal = (record) => {
    get_survey_indicator_result(record);
    setActionItem(record);
    setPublishModal(true);
  };

  const handle_close = () => {
    setActionItem({});
    setPublishModal(false);
  };

  useEffect(() => {
    let temp = survey_sector?.[0]?.sector?.indicators?.map((x, i) => {
      return {
        index: i,
        ...x,
      };
    });
    setIndicatorList(temp);
  }, [survey_sector]);

  useEffect(() => {
    if (publish_status == "success") {
      message.success("Result published successfully");
      handle_close();
      get_all_indicator_list();
      dispatch(dynamicClear("indicator_publish"));
    } else if (publish_status === "failure") {
      message.error("something went wrong ");
      dispatch(dynamicClear("indicator_publish"));
    } else if (publish_error) {
      message.error("something went wrong ");
      dispatch(dynamicClear("indicator_publish"));
    }
  }, [publish_status, publish_error]);

  const handle_publish = () => {
    let keys = [{ key: "indicator_publish", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        indicator_publish,
        { survey_indicator_id: actionItem?.survey_indicators?.[0]?.id },
        MethodType.POST
      )
    );
  };
  const get_all_indicator_list = () => {
    let keys = [{ key: "get_my_survey_indicators", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_my_survey_indicators,
        { survey_sector_id: sector_id, survey_id: survey_id },
        MethodType.POST
      )
    );
  };

  const get_survey_indicator_result = (record) => {
    let keys = [{ key: "survey_indicator_result", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        survey_indicator_result + record?.survey_indicators?.[0]?.id,
        {},
        MethodType.GET
      )
    );
  };

  const get_indicator_progress_list = () => {
    let keys = [{ key: "survey_indicator_progress", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        survey_indicator_progress,
        { survey_sector_id: sector_id },
        MethodType.POST
      )
    );
  };

  const handleRedirectMap = (record) => {
    navigateWithProps(
      `${ROUTES.SECTORAL_RANKING_INDICATOR_MAP}/${survey_id}/${sector_id}/${record?.survey_indicators?.[0]?.id}`,
      {
        item: record,
        sector: survey_sector?.[0]?.sector,
        sector_id: sector_id,
        survey_id: survey_id,
      }
    );
  };

  useEffect(() => {
    get_all_indicator_list();
    get_indicator_progress_list();
  }, []);

  const indicator_actions = (record) => {
    if (record.status !== "active") {
      return (
        <HStack
          space={"20px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {record?.survey_indicators?.[0]?.survey_indicator_status?.[0]
            ?.status === "Completed" ? (
            <Text fontSize={"12px"} color={"green.600"} bold>
              Published
            </Text>
          ) : (
            <>
              {role.name === "State DES User" && (
                <Pressable
                  onPress={() => {
                    handle_publish_modal(record);
                  }}
                >
                  <AiFillExclamationCircle size={18} />
                </Pressable>
              )}
            </>
          )}
          <Pressable
            onPress={() => {
              handleRedirectMap(record);
            }}
          >
            <FaMedal size={18} />
          </Pressable>
        </HStack>
      );
    } else {
      return (
        <HStack space={"20px"}>
          <Pressable
            onPress={() => {
              handleView(record);
            }}
          >
            <AiOutlineEye size={18} />
          </Pressable>
          {role.name === "State DES User" && (
            <Pressable
              onPress={() => {
                handle_publish_modal(record);
              }}
            >
              <AiFillExclamationCircle size={18} />
            </Pressable>
          )}
        </HStack>
      );
    }
  };
  const handle_status = (record) => {
    let status_list = record?.[0]?.survey_indicator_status?.find(
      (list) => list?.status
    )?.status;
    if (status_list === "RevertedToMe") {
      return (
        <Tag color="#ff410c" className="status-label">
          Reverted
        </Tag>
      );
    } else if (status_list === "SubmittedByMe") {
      return (
        <Tag color="#00c513" className="status-label">
          Submitted
        </Tag>
      );
    } else if (status_list === "InProgress") {
      return (
        <Tag color="#99BC85" className="status-label">
          Saved
        </Tag>
      );
    } else {
      return (
        <Tag color="#ffa500ad" className="status-label">
          Yet to start
        </Tag>
      );
    }
  };
  const handle_data_points = (record) => {
    let point = survey_sector_progress_list?.find(
      (value) => value?.id === record?.[0]?.id
    );
    return (point?.current_point !== 0 && point?.current_point !== undefined) ||
      (point?.total_point !== 0 && point?.current_point !== undefined) ? (
      <HStack>
        <Text>{`${point?.current_point}/${point?.total_point}`}</Text>
      </HStack>
    ) : (
      "-"
    );
  };

  const column = [
    {
      title: t("table:indicator_number"),
      dataIndex: "unique_id",
      key: "unique_id",
      render: (record) => record || "-",
    },
    {
      title: t("table:indicator_description"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("table:data_points"),
      dataIndex: "",
      key: "data_points",
      render: (record) => handle_data_points(record?.survey_indicators),
    },
    {
      title: t("table:status"),
      dataIndex: "",
      key: "status",
      render: (record) => handle_status(record?.survey_indicators),
    },
    {
      title: t("table:estimation"),
      dataIndex: "",
      key: "action",
      width: "150px",
      render: (record) => indicator_actions(record),
    },
  ];
  let filter_columns = [];
  if (role?.name === "State DES User") {
    filter_columns = column?.filter((list) => list.key !== "status");
  } else if (role?.name === "District DES User") {
    filter_columns = column?.filter((list) => list.key !== "action");
  } else if (role?.name === "Super Admin") {
    filter_columns = column;
  } else if (role?.name === "District Department User") {
    filter_columns = column?.filter(
      (list) => list?.key !== "data_points" && list.key !== "action"
    );
  } else {
    filter_columns = column?.filter(
      (list) => list?.key !== "data_points" && list.key !== "action"
    );
  }

  const handle_back = (route, values) => {
    navigateWithProps(route, values);
  };

  return (
    <VStack padding={"0px"}>
      {indicator_loading ? (
        <></>
      ) : survey_sector?.length ? (
        <HStack space={"4px"}>
          <Pressable onPress={() => handle_back(`${ROUTES.USER_SURVEY}`)}>
            <Title className="title-style">Survey</Title>
          </Pressable>
          <Pressable
            onPress={() =>
              handle_back(`${ROUTES.SECTOR_LIST}/${survey_id}`, {})
            }
          >
            <Title className="title-style">
              {`/ ${
                survey_sector?.[0]?.sector?.indicators?.[0]
                  ?.survey_indicators?.[0]?.survey?.name ||
                location?.state?.survey?.survey?.name
              }`}
              {`( ${
                survey_sector?.[0]?.sector?.indicators?.[0]
                  ?.survey_indicators?.[0]?.survey?.year ||
                location?.state?.survey?.survey?.year
              } )`}
            </Title>
          </Pressable>
          <Title className="title-style-none">
            {` / ${survey_sector?.[0]?.sector?.name}`}
          </Title>
        </HStack>
      ) : null}
      <Box marginTop={"10px"}>
        <Table
          columns={filter_columns}
          dataSource={indicator_list}
          loading={indicator_loading || survey_sector_progress_loading}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => handle_edit(record, rowIndex), // click row
            };
          }}
          pagination={paginationOptions}
        />
      </Box>

      <HStack justifyContent={"flex-end"} space="7" mt="5" zIndex={-10}>
        <Button
          onClick={() => {
            handleBack();
          }}
          danger
        >
          Back
        </Button>
      </HStack>

      <Modal
        title="Confirmation"
        open={publish_modal}
        onCancel={handle_close}
        footer={
          actionItem?.survey_indicators?.[0]?.survey_indicator_status?.[0]
            ?.status === "Completed"
            ? [
                <Button key="cancel" onClick={handle_close} danger>
                  Close
                </Button>,
              ]
            : [
                <Button key="cancel" onClick={handle_close} danger>
                  Cancel
                </Button>,

                is_allowed_to_publish === true ? (
                  <Button
                    onClick={handle_publish}
                    type="primary"
                    loading={publish_loading}
                  >
                    Publish
                  </Button>
                ) : (
                  <Button
                    onClick={handle_publish}
                    type="primary"
                    loading={publish_loading}
                    disabled
                  >
                    Publish
                  </Button>
                ),
              ]
        }
      >
        <>
          {actionItem?.survey_indicators?.[0]?.survey_indicator_status?.[0]
            ?.status === "Completed" ? (
            <>Indicator already published.</>
          ) : (
            <>
              {survey_indicator_loading ? (
                <Center>
                  <Spin size="large" />
                </Center>
              ) : (
                <VStack space={"20px"}>
                  <Text fontSize={"14px"}>
                    Are you sure you want to publish this{" "}
                    {<b>{actionItem?.name}</b>} indicator?
                  </Text>
                  <Text fontSize={"14px"}>{info}</Text>
                  {is_allowed_to_publish === false ? (
                    <Text fontSize={"14px"} color="red.400">
                      Please approve all submitted data under this indicator to
                      publish this indicator
                    </Text>
                  ) : (
                    ""
                  )}
                </VStack>
              )}
            </>
          )}
        </>
      </Modal>
    </VStack>
  );
};

export default UserIndicatorList;
