import { MethodType } from '@helpers/service_call';
import { dynamicClear, dynamicRequest, useDynamicSelector } from '@services/redux';
import { delete_question } from '@services/redux/slices/dynamic_entity/rest/rest_question';
import { Modal, message } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const DeleteQuestionModal = ({ sector_form, type, open, onCancel, title, form_item, get_all_question_list }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { status: delete_status, error: delete_error, loading: delete_loading } = useDynamicSelector("delete_question")
  const handle_delete_question = () => {
    let keys = [{ key: "delete_question", loading: true }];
    dispatch(dynamicRequest(keys, delete_question + form_item?.id, {}, MethodType.DELETE));
  }
  useEffect(() => {
    if (delete_status === "success") {
      message.success(t("delete_question"))
      dispatch(dynamicClear("delete_question"))
      onCancel()
      get_all_question_list()
    } else if (delete_error) {
      message.error(t("something_went_wrong"))
      dispatch(dynamicClear("delete_question"))
    }
  }, [delete_error, delete_status])
  return (
    <div>
      <Modal open={open} onCancel={onCancel} title={title} confirmLoading={delete_loading} onOk={handle_delete_question} cancelButtonProps={{ danger: true }}>
        Do you want to delete <b>{form_item?.text}</b> questions?
      </Modal>
    </div>
  )
}

export default DeleteQuestionModal
