import { Button, Col, Form, Input, InputNumber, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import IndicatorName from "../indicator_form_inputs/indicator_name";
import IndicatorType from "../indicator_form_inputs/indicator_type";
import Periodicity from "../indicator_form_inputs/periodicity";
import Weight from "../indicator_form_inputs/weight";
import Numerator from "../indicator_form_inputs/numerator";
import IndicatorNumber from "../indicator_form_inputs/indicator_number";
import Direction from "../indicator_form_inputs/direction";
import Multiplier from "../indicator_form_inputs/multiplier";
import Sector from "../indicator_form_inputs/sector";
import Denominator from "../indicator_form_inputs/denominator";
import { HStack } from "native-base";
import {
  create_question,
  get_all_question,
  update_question,
} from "@services/redux/slices/dynamic_entity/rest/rest_question";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import {
  create_indicator,
  update_indicator,
} from "@services/redux/slices/dynamic_entity/rest/rest_indicators";
import { useTranslation } from "react-i18next";

const AddIndicatorForm = (props) => {
  const { form_values, onCancel, get_all_indicator_list } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { items: question_list, loading: question_loading } =
    useDynamicSelector("get_all_question");
  const {
    loading: create_indicator_loading,
    status: create_indicator_status,
    error: create_indicator_error,
  } = useDynamicSelector("create_indicator");
  const {
    loading: update_indicator_loading,
    status: update_indicator_status,
    error: update_indicator_error,
  } = useDynamicSelector("update_indicator");

  const dispatch = useDispatch();
  const [indicator_form_values, set_indicator_form_values] = useState({});

  // message.success("Indicator added successfully");
  const handle_submit = (values) => {
    delete values.numerator;
    set_indicator_form_values(values);
    if (form_values?.id) update_one_question(values);
    else create_one_question(values);
  };
  const handle_reset = () => {
    onCancel();
    form.resetFields();
  };
  const get_all_question_list = (data) => {
    let keys = [{ key: "get_all_question", loading: true }];
    dispatch(dynamicRequest(keys, get_all_question, {}, MethodType.POST));
  };
  const create_one_question = (data) => {
    let keys = [{ key: "create_indicator", loading: true }];
    dispatch(
      dynamicRequest(keys, create_indicator, { ...data }, MethodType.PUT)
    );
  };

  const update_one_question = (data) => {
    let keys = [{ key: "update_indicator", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        update_indicator + form_values?.id,
        { ...data, sector_id: form_values?.sector_id },
        MethodType.PATCH
      )
    );
  };
  useEffect(() => {
    get_all_question_list();
  }, []);
  useEffect(() => {
    if (form_values?.id) {
      let filtered_form_values = {
        ...form_values,
        numerator: form_values?.second_numerator_question?.id
          ? "complex"
          : "simple",
      };
      form.setFieldsValue(filtered_form_values);
    } else {
      form.resetFields();
    }
  }, [form_values]);

  useEffect(() => {
    if (create_indicator_status === "success") {
      message.success(t("indicator_created"));
      dispatch(dynamicClear("create_indicator"));
      get_all_indicator_list();
      handle_reset();
    } else if (create_indicator_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("create_indicator"));
    } else if (update_indicator_status === "success") {
      message.success(t("indicator_updated"));
      dispatch(dynamicClear("update_indicator"));
      get_all_indicator_list();
      handle_reset();
    } else if (update_indicator_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("update_indicator"));
    }
  }, [
    create_indicator_status,
    create_indicator_error,
    update_indicator_status,
    update_indicator_error,
  ]);

  return (
    <>
      <Form
        id="indicator_form"
        form={form}
        style={{ marginTop: "2%" }}
        onFinish={handle_submit}
        layout="vertical"
      >
        <Row gutter={80} style={{ display: "flex", flexWrap: "wrap" }}>
          <Col span={12}>
            <IndicatorName />
            <Direction />
            {/* <Weight /> */}
            <Numerator form={form} question_list={question_list} />
            <Form.Item
              label="Indicator number"
              name={"unique_id"}
              style={{ marginTop: "2px" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Restriction Value"
              name={"restriction_value"}
              style={{ marginTop: "2px" }}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <IndicatorType />
            <Periodicity />
            <Multiplier />
            <Denominator question_list={question_list} form={form} />
            <Form.Item
              label="Order number"
              name={"order_number"}
              style={{ marginTop: "2px" }}
            >
              <InputNumber />
            </Form.Item>

            {/* <Multiplier /> */}
            {/* <Sector /> */}
          </Col>
        </Row>
        <HStack mt={"30px"} justifyContent={"flex-end"} space={"10px"}>
          <Button htmlType="button" danger onClick={handle_reset}>
            Close
          </Button>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              htmlType="submit"
              type="primary"
              loading={create_indicator_loading || update_indicator_loading}
            >
              Submit
            </Button>
          </Form.Item>
        </HStack>
      </Form>
    </>
  );
};

export default AddIndicatorForm;
