import { MethodType } from "@helpers/service_call";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_all_sector } from "@services/redux/slices/dynamic_entity/rest/rest_sector";
import { get_survey_sector } from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import { HStack } from "native-base";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const SectorForm = ({
  form_values,
  type,
  open,
  onCancel,
  title,
  map_to_sector,
  sector_map_loading,
}) => {
  // const handleChange = (val, values) => {
  //   let get_form_values = { ...values, id: "" };
  //   if (type !== "Edit") sector_form(get_form_values);
  //   // sector_form(values);
  // };
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { items: sector_list } = useDynamicSelector("get_all_sector");

  const handle_submit = (values) => {
    map_to_sector(values);
  };
  const get_sectors = () => {
    let keys = [{ key: "get_all_sector", loading: true }];
    dispatch(dynamicRequest(keys, get_all_sector, {}, MethodType.POST));
  };

  useEffect(() => {
    get_sectors();
  }, []);
  useEffect(() => {
    if (form_values?.id) {
      form.setFieldsValue(form_values);
    } else {
      form.resetFields();
    }
  }, [form_values]);

  return (
    <Modal open={open} onCancel={onCancel} title={title} footer={null}>
      <Form onFinish={handle_submit} form={form}>
        <Form.Item label="Sector" name={"sector_id"} rules={[{ required: true, message: "Sector is monitory" }]}>
          {/* <Input /> */}
          
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {sector_list?.map((option) => (
              <Select.Option key={option?.id} value={option?.id}>
                {option?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item label="Sector number" name={"sector_number"}>
          <InputNumber />
        </Form.Item> */}
        {/* <Form.Item label="Weight" name={"weight"}>
          <InputNumber />
        </Form.Item> */}
        <HStack justifyContent={"flex-end"} space={"10px"}>
          <Button danger onClick={onCancel}>
            Close
          </Button>
          <Button htmlType="submit" type="primary" loading={sector_map_loading}>
            Submit
          </Button>
        </HStack>
      </Form>
    </Modal>
  );
};

export default SectorForm;
