import AreaList from '@views/components/areas/area_list'
import CustomPageTitle from '@views/components/common/custom_page_title'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Areas = () => {
    const{t}=useTranslation()
    return (
        <div>
            <CustomPageTitle title={t("areas")} />
            <AreaList />
        </div>
    )
}

export default Areas
