import { callService } from "@helpers/config";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import CustomButton from "@views/components/common/custom_button";
import CustomDeleteModal from "@views/components/common/custom_delete_modal";
import CustomModal from "@views/components/common/custom_modal";
import CustomPageTitle from "@views/components/common/custom_page_title";
import DepartmentList from "@views/components/departments/department_list";
import DepartmentOfficerUsersList from "@views/components/departments_officer_users/department_officer_user_list";
import { Button, Col, Input, Modal, Row, Typography, message } from "antd";
import { Box, HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DepartmentOfficerUserForm from "@views/components/departments_officer_users/department_officer_user_form/department_officer_user_form";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@views/routes/my_routes";
import { useLocation } from "react-router-dom";
import { navigateWithProps } from "@helpers/functions";
import AdminDepartmentOfficersForm from "@views/components/departments_officers/admin_department_officer_form";
import AdminDepartmentOfficersList from "@views/components/departments_officers/admin_department_officer_list";
import { get_all_department_officer } from "@services/redux/slices/dynamic_entity/rest/rest_department_office";

const AdminDepartmentOfficersPage = () => {
  const [add_modal, set_add_modal_open] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const { Search } = Input;
  const { Title } = Typography;
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [header_type, set_header_type] = useState("");
  const update_response = useDynamicSelector("department_officer_update");

  const get_all_department_values = (data) => {
    let keys = [{ key: "get_all_department_officer", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_department_officer,
        { search_string: data },
        MethodType.POST
      )
    );
  };
  const onSearch = (value) => {
    let search_value = value?.target?.value || value;
    if (search_value?.length > 2 || search_value?.length === 0)
      get_all_department_values(search_value);
  };

  return (
    <VStack padding={"10px"}>
      {/* <CustomPageTitle title="Departments officers" /> */}
      <HStack space={"4px"}>
        <Title className="title-style">Departments officer</Title>
      </HStack>
      <AdminDepartmentOfficersList
        set_add_modal_open={set_add_modal_open}
        set_header_type={set_header_type}
        header_type={header_type}
        setInitialValues={setInitialValues}
      />

      <Modal
        title={`${header_type} Department officer`}
        open={add_modal}
        destroyOnClose={true}
        onCancel={() => set_add_modal_open(false)}
        width={600}
        footer={[
          <Button
            htmlType="submit"
            form="department_form"
            type="primary"
            loading={update_response?.loading}
          >
            {header_type?.includes("Edit") ? t("update") : t("create")}
          </Button>,
          <Button key="cancel" onClick={() => set_add_modal_open(false)} danger>
            {t("close")}
          </Button>,
        ]}
      >
        <AdminDepartmentOfficersForm initialValues={initialValues} />
      </Modal>
    </VStack>
  );
};

export default AdminDepartmentOfficersPage;
