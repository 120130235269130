import React, { useEffect, useRef, useState } from "react";
import { SVGMap, S } from "react-svg-map";
import "react-svg-map/lib/index.css";
import { api_response, locations } from "./map_co-ordinates";
import { useDispatch } from "react-redux";
import { dynamicSet } from "@services/redux";
import { startCase } from "lodash";
import { Text, VStack } from "native-base";
import ColorIndicator from "./color_indicator";

const SectorRankingMap = ({ items, location }) => {
  console.log("items",items);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [isActive, setIsActive] = useState(false);
  // Example usage
  const [tooltipText, setTooltipText] = useState({});
  // const hsvToHsl = (h, s, v) => {
  //   const l = (2 - s) * v / 2;
  //   const hslS = l && l < 1
  //     ? Math.max(0, Math.min(1, s * v / (l < 0.5 ? l * 2 : 2 - l * 2)))
  //     : 0;
  //   return `hsl(${h}, ${hslS}, ${l})`;
  // };
  const hsvToHsl = (h, s, v) => {
    const l = (s * v) / 100;
    const ss = (2 * s) / 100;
    return `hsl(${h},${s},${v})`;
  };

  const HSLToRGB = (h, s, l) => {
    s /= 100;
    l /= 100;
    const k = (n) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n) =>
      l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return `hsl(${(255 * f(4)).toFixed(2)},${(255 * f(0)).toFixed(2)}%,${(
      255 * f(8)
    ).toFixed(2)}%)`;
  };

  const HSLtoRGB = (h, s, l) => {
    // Convert HSL to RGB
    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = l - c / 2;
    let r, g, b;

    if (h >= 0 && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h >= 60 && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h >= 120 && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h >= 180 && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h >= 240 && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }

    // Convert RGB to HEX
    r = Math.round((r + m) * 255)
      .toString(16)
      .padStart(2, "0");
    g = Math.round((g + m) * 255)
      .toString(16)
      .padStart(2, "0");
    b = Math.round((b + m) * 255)
      .toString(16)
      .padStart(2, "0");

    return `#${r}${g}${b}`;
  };

  function hsvToRgb(h, s, v) {
    h = ((h % 360) + 360) % 360;
    s = Math.max(0, Math.min(1, s));
    v = Math.max(0, Math.min(1, v));

    let c = v * s;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = v - c;

    let r, g, b;
    if (h >= 0 && h < 60) {
      [r, g, b] = [c, x, 0];
    } else if (h >= 60 && h < 120) {
      [r, g, b] = [x, c, 0];
    } else if (h >= 120 && h < 180) {
      [r, g, b] = [0, c, x];
    } else if (h >= 180 && h < 240) {
      [r, g, b] = [0, x, c];
    } else if (h >= 240 && h < 300) {
      [r, g, b] = [x, 0, c];
    } else {
      [r, g, b] = [c, 0, x];
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return `rgb(${r}, ${g}, ${b})`;
  }

  function valueToColor(value) {
    if (!value && value !== 0) {
      return '#ffff'; // white
    } else if (value >= 0 && value < 25) {
      return '#FF0000'; // Red
    } else if (value >= 25 && value < 50) {
      return '#e3a12c'; // Orange
    } else if (value >= 50 && value < 75) {
      return '#FFFF00'; // Yellow
    } else if (value >= 75 && value <= 100) {
      return '#01830a'; // Green
    } else {
      return 'Invalid value';
    }
  }



  function hsvToRgb(h, s, v) {
    h = ((h % 360) + 360) % 360;
    s = Math.max(0, Math.min(1, s));
    v = Math.max(0, Math.min(1, v));

    let c = v * s;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = v - c;

    let r, g, b;
    if (h >= 0 && h < 60) {
      [r, g, b] = [c, x, 0];
    } else if (h >= 60 && h < 120) {
      [r, g, b] = [x, c, 0];
    } else if (h >= 120 && h < 180) {
      [r, g, b] = [0, c, x];
    } else if (h >= 180 && h < 240) {
      [r, g, b] = [0, x, c];
    } else if (h >= 240 && h < 300) {
      [r, g, b] = [x, 0, c];
    } else {
      [r, g, b] = [c, 0, x];
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return `rgb(${r}, ${g}, ${b})`;
  }


  useEffect(() => {
    if (items) {
      let api_response_filter = locations.map((list) => {
        let color = items?.find(
          (lista) =>
            startCase(list?.name) === startCase(lista?.data?.area?.name)
        )?.data.color_percentage

        return {
          ...list,
          fill_color: color,
        };
      });


      api_response_filter.forEach((list) => {
        let element = document.getElementById(list.id);
        if (element) {
          // const h = 220; // Blue hue
          // const s = list?.fill_color / 100; // Saturation
          // const l = 1; // Lightness
          let color = valueToColor(list?.fill_color)

          return (element.style.fill = color), (element.style.stroke = "black");
        }
      });
    }
  }, [items]);

  const handleMouseMove = (e) => {
    const regionName = e?.target?.getAttribute("name");
    let district_object = items?.find(
      (lista) => startCase(regionName) === startCase(lista?.data?.area?.name)
    );
    setTooltipText(district_object?.data);

    setIsActive(true);
  };

  const handleMouseLeave = () => {
    setTooltipText("");
    setIsActive(false);
  };

  const handle_mouse_move = (event) => {
    const element = document.getElementById("elementToMove");
    if (element) {
      const rect = element.getBoundingClientRect();
      const offsetX = event.clientX - rect.width / 4;
      const offsetY = event.clientY - rect.height / 4;
      setPosition({ top: offsetY - 200, left: offsetX - 1800 / 2 - 50 });
    }
  };
  return (
    <div
      style={{
        transform: "scale(0.9)",
        width: "75vh",
        marginTop: "-28px",
      }}
      // onMouseMove={handle_mouse_move}
      onMouseMove={handle_mouse_move}
    >
      <SVGMap
        className="svgMap"
        // onLocationBlur={(e) => handle_region_select(e)}
        locationRef={(location) => getLocationRef(location)}
        onLocationMouseOver={(e) => handleMouseMove(e)}
        onLocationMouseOut={(e) => handleMouseLeave()}
        key={"id"}
        map={{
          label: "Map of Tamil Nadu",
          viewBox: "0 0 1591.1 1975.3",
          locations: locations,
        }}
      />
      {isActive && (
        <div
          id="elementToMove"
          className="tooltip"
          style={{
            position: "absolute",
            top: `${position.top}px`,
            left: `${position.left}px`,
            marginLeft: "30px",

            borderRadius: "15px",
            backgroundColor: location?.state?.item?.direction?.includes(
              "Negative"
            )
              ? "rgb(255 138 138)"
              : "#0a2773",
            padding: "10px",
            marginTop: "12vh",
          }}
        >
          <VStack justifyContent={"center"} alignItems={"center"}>
            <Text bold fontSize={"20px"} color={"white"}>
              {tooltipText?.area?.name}
            </Text>
            <Text bold color={"white"} fontSize={"15px"}>
              {tooltipText?.indicator_score}
            </Text>
          </VStack>
        </div>
      )}
      <div style={{ marginTop: "-100px" }}>
        <ColorIndicator items={items} />
      </div>
    </div>
  );
};

export default SectorRankingMap;
