import { Form, Input } from "antd";
import React from "react";

const FormDesignation = () => {
    return (
        <Form.Item
            name="designation"
            label="Designation"
            rules={[
                {
                    required: true,
                    message: "Designation is required",
                },
            ]}
        >
            <Input style={{ fontSize: "16px" }} />
        </Form.Item>
    );
};

export default FormDesignation;
