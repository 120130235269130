import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { CallService, MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import {
  department_create,
  department_update,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import { useParams } from "react-router-dom";
import { HStack, Text } from "native-base";
import { reset_password_department_officers } from "@services/redux/slices/dynamic_entity/rest/rest_department_officers";

const DepartmentOfficerResetForm = ({ initialValues }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [action_item, setActionItem] = useState();
  function generateRandomString(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  var randomString = generateRandomString(6); // Generates a random string of length 10
  console.log("randomString", randomString);

  useEffect(() => {
    setActionItem({ password: randomString });
    form.setFieldsValue({ password: randomString });
  }, []);

  const handleResetPasswordSubmit = (values) => {
    values.department_officer_id = initialValues?.id;
    let key = [{ key: "reset_password_department_officers", loading: true }];
    let query = reset_password_department_officers;
    let variables = { ...values };
    console.log("query", query);
    dispatch(dynamicRequest(key, query, variables, MethodType.POST));
  };
  return (
    <Form
      id="reset_form"
      onFinish={handleResetPasswordSubmit}
      form={form}
      initialValues={action_item}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 20,
      }}
      style={{ width: "100%" }}
    >
      <Form.Item
        name="password"
        label="Reset Password"
        rules={[
          {
            required: false,
            message: "Please Enter the Password",
          },
        ]}
      >
        <Input style={{ fontSize: "16px" }} disabled />
      </Form.Item>
      <Text color="#d91414" fontSize={"16px"} ml="3">
        Please note this password. This will not be shown again.
      </Text>
    </Form>
  );
};

export default DepartmentOfficerResetForm;
