import React, { useEffect, useState } from "react";
import AddSectorForm from "./sector_form/add_sector_form";
import { Button, Form, Modal, message } from "antd";
import { dynamicClear, dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import {
  create_sector,
  get_all_sector,
  update_sector,
} from "@services/redux/slices/dynamic_entity/rest/rest_sector";
import { MethodType } from "@helpers/service_call";
import { useTranslation } from "react-i18next";
import SectorName from "./sector_form/sector_name";
import FileUpload from "../file_upload/file_upload";

const AddSectorModal = (props) => {
  const { add_sector_modal, header_type, set_add_sector_modal, get_one } = props;
  const dispatch = useDispatch();
  const [form_values, set_form_values] = useState({});
  const [form] = Form.useForm();
  const { t } = useTranslation()

  const { status: create_status, loading: create_loading, error: create_error } =
    useDynamicSelector("create_sector");
  const { status: update_status, loading: update_loading, error: update_error } =
    useDynamicSelector("update_sector");


  const create_one_sector = (data) => {
    let keys = [{ key: "create_sector", loading: true }];
    dispatch(dynamicRequest(keys, create_sector, { ...data }, MethodType.PUT));
  };

  const update_one_sector = (values) => {
    let keys = [{ key: "update_sector", loading: true }];
    dispatch(
      dynamicRequest(keys, update_sector + get_one?.id, values, MethodType.PATCH)
    );
  };

  const handle_submit = () => {
    if (header_type !== "Edit") {
      create_one_sector(form_values);
    } else {
      update_one_sector(form_values);
    }
  };

  const handle_close = () => {
    set_add_sector_modal(false);
    form.resetFields();
  };

  useEffect(() => {
    if (get_one?.id) {
      form.setFieldsValue({ ...get_one, url: get_one?.url || [] })
    } else {
      form.setFieldsValue({ url: [] })
    }
  }, [get_one])
  const get_all_sector_list = () => {
    let keys = [{ key: "get_all_sector", loading: true }];
    dispatch(dynamicRequest(keys, get_all_sector, {}, MethodType.POST));
  };
  useEffect(() => {
    if (create_status === "success") {
      message.success(t("sector_created"))
      dispatch(dynamicClear("create_sector"))
      get_all_sector_list()
      handle_close()
    } else if (create_error) {
      message.error(t("something_went_wrong"))
      dispatch(dynamicClear("create_sector"))
    } else if (update_status === "success") {
      message.success(t("sector_updated"))
      dispatch(dynamicClear("update_sector"))
      handle_close()
      get_all_sector_list()
    } else if (update_error) {
      message.error(t("something_went_wrong"))
      dispatch(dynamicClear("update_sector"))
    }
  }, [create_status, create_error, update_status, update_error])
  const on_values_change = (val, values) => {
    values = { ...values, logo: values?.logo?.[0]?.url || [] };
    set_form_values(values);
  };
  return (
    <Modal
      title={`${header_type} Sector`}
      open={add_sector_modal}
      onCancel={handle_close}
      footer={[
        <Button
          onClick={handle_close}
          danger
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          htmlType="submit"
          form="sector_form"
          onClick={handle_submit}
          loading={create_loading || update_loading}
        >
          {header_type?.includes("Add") ? "Submit" : "Update"}
        </Button>,
      ]}
    >
      {/* <AddSectorForm
        set_form_values={set_form_values}
        form={form}
        type={header_type}
      /> */}
      <Form
        id="sector_form"
        // onFinish={handle_submit}
        onValuesChange={on_values_change}
        form={form}
        layout="horizontal"

        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
      >
        <SectorName />
        <FileUpload.File label="Logo" field="logo" allowFileFormats={['png', "jpeg", "jpg"]} />
        {/* <SectorIcon /> */}
        {/* <SectorWeight /> */}
        {/* <SectorIndicator /> */}
      </Form>
    </Modal>
  );
};

export default AddSectorModal;
