import DistrictList from "@views/components/process/district_list";
import DistrictWiseReport from "@views/components/process/district_wise_report";
import { Tabs } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const UserProcessDistrictList = () => {
  const { t } = useTranslation();
  const container_style = {
    // height: "100vh",
    overflowY: "auto",
    width: "100%",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  };

  const card_style = {
    flex: 1,
  };

  const tab_items = [
    {
      id: 1,
      label: t(`Districtwise Sector`),
      key: 1,
      children: <DistrictList />,
    },
    {
      id: 2,
      label: t(`Districtwise Indicator`),
      key: 2,
      children: <DistrictWiseReport />,
    },
  ];
  return (
    <div style={container_style}>
      <Tabs items={tab_items} />
      {/* <CustomPageTitle title="Districts" />
      <DistrictList /> */}
    </div>
  );
};

export default UserProcessDistrictList;
