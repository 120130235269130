import React from "react";
import { Text } from "native-base";

const ColorIndicator = ({ items }) => {   
  const lastElement = items?.slice(-1);

  // if (items?.[3]?.white) {
  //   return items?.[3]?.white;
  // } else if (items?.[3]?.red) {
  //   return items?.[3]?.red;
  // } else if (items?.[3]?.orange) {
  //   items?.[3]?.orange;
  // } else if (items?.[3]?.yellow) {
  //   items?.[3]?.yellow;
  // } else if (items?.[3]?.green) {
  //   items?.[3]?.green;
  // }
  // console.log("color", color);
  const map_colors = [
    {
      color: "rgb(255, 255, 255)",
      value: "No data",
    },
    { color: "#FF0000", value: "0% - 25%" },
    { color: "#e3a12c", value: "25% - 50%" },
    { color: "#FFFF00", value: "50% - 75%" },
    { color: "#01830a", value: "75% - 100%" },
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          marginLeft: "60%",
          flexDirection: "column",
        }}
      >
        {lastElement?.[0]?.white && ( 
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "35px",
                backgroundColor: "rgb(255, 255, 255)",
                border: "1px solid black",
              }}
            />
            <div
              style={{
                width: "200px",
              }}
            >
              <Text bold fontSize={"15px"}>
                {lastElement?.[0]?.white}
              </Text>
            </div>
          </div>
        )}
        {lastElement?.[0]?.red && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "35px",
                backgroundColor: "#FF0000",
                border: "1px solid black",
              }}
            />
            <div
              style={{
                width: "200px",
              }}
            >
              <Text bold fontSize={"15px"}>
                {lastElement?.[0]?.red}
              </Text>
            </div>
          </div>
        )}

        {lastElement?.[0]?.orange && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "35px",
                backgroundColor: "#e3a12c",
                border: "1px solid black",
              }}
            />
            <div
              style={{
                width: "200px",
              }}
            >
              <Text bold fontSize={"15px"}>
                {lastElement?.[0]?.orange}
              </Text>
            </div>
          </div>
        )}
        {lastElement?.[0]?.yellow && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "35px",
                backgroundColor: "#FFFF00",
                border: "1px solid black",
              }}
            />
            <div
              style={{
                width: "200px",
              }}
            >
              <Text bold fontSize={"15px"}>
                {lastElement?.[0]?.yellow}
              </Text>
            </div>
          </div>
        )}
        {lastElement?.[0]?.green && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "35px",
                backgroundColor: "#01830a",
                border: "1px solid black",
              }}
            />
            <div
              style={{
                width: "200px",
              }}
            >
              <Text bold fontSize={"15px"}>
                {lastElement?.[0]?.green}
              </Text>
            </div>
          </div>
        )}
      </div>
      {/* <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "end",
          gap: "45px"
        }}
      >
        <div style={{ fontSize: "20px", fontWeight: "bold" }}>0 </div>
        <div style={{ fontSize: "20px", fontWeight: "bold" }}>25</div>
        <div style={{ fontSize: "20px", fontWeight: "bold" }}>50</div>
        <div style={{ fontSize: "20px", fontWeight: "bold" }}>75</div>
        <div style={{ fontSize: "20px", fontWeight: "bold" }}>100</div>
      </div> */}
    </div>
  );
};

export default ColorIndicator;
