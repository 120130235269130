export const APP_ICON = require("../assets/icons/vertace.png");
export const VERTACE_ICON = require("../assets/icons/vertace.png");
export const GOOGLE_ICON = require("../assets/icons/google.png");
export const FACEBOOK_ICON = require("../assets/icons/facebook.png");
export const APPLE_ICON = require("../assets/icons/apple.png");
export const PROFILE_ICON = require("../assets/icons/profile.png");
export const FILTER_ICON = require("../assets/icons/filter.png");
export const CLOSE_ICON = require("../assets/icons/close-circle.png");
export const HEART_ICON = require("../assets/icons/heart.png");
export const BOOKMARK_ICON = require("../assets/icons/bookmark.png");
export const ACTION_REQUESTED = require("../assets/icons/documents-action.png");
export const APPROVED = require("../assets/icons/approved.png");
export const REJECTED = require("../assets/icons/rejected.png");
export const ABOUT = require("../assets/icons/about.png");
export const EXPIRED = require("../assets/icons/expired.png");
export const SEEKING_CLARIFICATION = require("../assets/icons/Seeking_clarifications.png");
export const DRAFT = require("../assets/icons/draft.png");
export const RENEWAL = require("../assets/icons/renew.png");
export const OFFICER_LOGO = require("../assets/icons/officer.png.png");
export const AGENCY_LOGO = require("../assets/icons/agency.png");
export const GOLD_MEDAL = require("../assets/icons/gold_medal.png.png");
export const SILVER_MEDAL = require("../assets/icons/silver_medal.png.png");
export const BRONZE_MEDAL = require("../assets/icons/bronze_medal.png.png");
export const AGRICULTURE = require("../assets/icons/Agriculture.png");
export const HUMAN_RESOURCE = require("../assets/icons/Human resources.png");
export const INDUSTRY = require("../assets/icons/industry&commerce.png");
export const PUBLIC_HEALTH = require("../assets/icons/public health.png");
export const PUBLIC_INFRASTRUCTURE = require("../assets/icons/public infrastructure.png");
export const SOCIAL_WELFARE = require("../assets/icons/social welfare.png");
export const FINANCIAL_INCLUSION = require("../assets/icons/financial.png");
export const JUDICIARY = require("../assets/icons/judiciary.png");
export const ENVIRONMENT = require("../assets/icons/Environment.png");
export const CITIZEN_CENTRIC = require("../assets/icons/citizen centric.png");
export const TOTAL_DEPARTMENTS = require("../assets/icons/corporation.png");
export const SUBMITTED = require("../assets/icons/submit.png");
export const PENDING = require("../assets/icons/clock.png");
export const NOT_SUBMITTED = require("../assets/icons/pending-tasks.png");
export const PENDING_WITH_STATE = require("../assets/icons/exam.png");
export const sector_image = require("../assets/icons/market.png");
export const REVERTED = require("../assets/icons/revert.png");
