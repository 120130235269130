import { Button, Space } from "antd";
import React from "react";

const CustomButton = ({ button_name, onclick_button, button_icon }) => {
  return (
    <div className="custom-button">
      <Button
        type="primary"
        onClick={() => onclick_button()}
        style={{
          justifyContent: "space-between",
          border: "transparent",
          width: "auto",
        }}
      >
        {button_icon}
        {button_name}
      </Button>
    </div>
  );
};

export default CustomButton;
