import { Modal } from 'antd'
import { HStack } from 'native-base'
import React from 'react'

const DeleteIndicatorModal = ({ open, onCancel, title, form_item, delete_loading, delete_function }) => {
  return (
    <div>
      <Modal open={open} onCancel={onCancel} title={title} onOk={delete_function} confirmLoading={delete_loading} cancelButtonProps={{ danger: true }}>
        Do you want to delete <b>{form_item?.name}</b> indicator?
      </Modal>
    </div>
  )
}

export default DeleteIndicatorModal
