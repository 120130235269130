import {
  AGRICULTURE,
  CITIZEN_CENTRIC,
  ENVIRONMENT,
  FINANCIAL_INCLUSION,
  HUMAN_RESOURCE,
  INDUSTRY,
  JUDICIARY,
  PUBLIC_HEALTH,
  PUBLIC_INFRASTRUCTURE,
  SOCIAL_WELFARE,
} from "@helpers/image_constants";
import CustomButton from "@views/components/common/custom_button";
import CustomPageTitle from "@views/components/common/custom_page_title";
import DraggableTable from "@views/components/system/draggable_table/draggable_table";
import SectorForm from "@views/components/system/draggable_table/sector_form";
import DepartmentCard from "@views/components/user/survey/department_card";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Card, Modal, Select, Table } from "antd";
import { HStack, Pressable } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import SurveyDelete from "@views/components/survey/survey_delete";
import { get_all_survey } from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { navigateWithProps } from "@helpers/functions";

const Survey = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [survey_values, set_survey_values] = useState({});
  const [indicator_modal, set_survey_delete_modal] = useState(false);
  const { items: survey_list, loading: survey_list_loading } =
    useDynamicSelector("get_all_survey");
  const dispatch = useDispatch();
  const department_card_style = {
    display: "flex",
    alignItems: "center",
    margin: "48px",
    flexWrap: "wrap",
  };
  const container_style = {
    // height: "100vh",
    overflowY: "auto",
    width: "100%",
    padding: "30px",
  };
  const handle_add_sector = () => {
    history.push(ROUTES.SURVEY_DETAILS);
  };
  const handle_edit = (record) => {
    navigateWithProps(`${ROUTES.SURVEY_DETAILS}/edit`, record);
  };
  const handle_delete = (survey) => {
    set_survey_values(survey);
    set_survey_delete_modal(true);
  };
  const handle_close_delete_modal = () => {
    set_survey_delete_modal(false);
    set_survey_values({});
  };

  const get_all_survey_values = (data) => {
    let keys = [{ key: "get_all_survey", loading: true }];
    dispatch(dynamicRequest(keys, get_all_survey, {}, MethodType.POST));
  };
  useEffect(() => {
    get_all_survey_values();
  }, []);
  let column = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      title: t("table:number"),
      dataIndex: "number",
      key: "number",
      width: "150px",
    },
    {
      title: t("table:year"),
      dataIndex: "year",
      key: "year",
      width: "150px",
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "",
      width: "150px",
      render: (record) => (
        <HStack space={"20px"}>
          {/* <Pressable
            onPress={() => {
              handle_edit(record);
            }}
          >
            <FiEdit size={18} />
          </Pressable> */}
          <Pressable
            onPress={() => {
              handle_delete(record);
            }}
          >
            <AiFillDelete size={18} />
          </Pressable>
        </HStack>
      ),
    },
  ];

  let data = [
    {
      name: "Survey1",
      number: 111,
      year: 2001,
    },
  ];
  return (
    <div style={container_style}>
      <CustomPageTitle title="Survey" />
      <Card style={{ height: "100%" }}>
        <HStack justifyContent={"flex-end"} mb={"20px"}>
          <Button
            onClick={handle_add_sector}
            style={{ backgroundColor: "#0a2773", color: "white" }}
          >
            Add Survey
          </Button>
        </HStack>
        <Table
          columns={column}
          dataSource={survey_list}
          loading={survey_list_loading}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => handle_edit(record), // click row
            };
          }}
        />
        <SurveyDelete
          open={indicator_modal}
          onCancel={handle_close_delete_modal}
          form_item={survey_values}
          title={"Delete Survey"}
          get_all_survey_values={get_all_survey_values}
        />
      </Card>
    </div>
  );
};

export default Survey;
