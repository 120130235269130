import { Button, Form, Modal } from "antd";
import React from "react";
import DepartmentForm from "../departments/department_form/department_form";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "@services/redux";

const CustomModal = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { header_type, set_add_modal_open, add_modal, initialValues } = props;
  const create_response = useDynamicSelector("department_create");
  const update_response = useDynamicSelector("department_update");
  return (
    <Modal
      title={`${header_type} Department`}
      open={add_modal}
      destroyOnClose={true}
      onCancel={() => set_add_modal_open(false)}
      footer={[
        <Button key="cancel" onClick={() => set_add_modal_open(false)} danger>
          {t("close")}
        </Button>,
        <Button
          htmlType="submit"
          form="department_form"
          type="primary"
          loading={create_response?.loading || update_response?.loading}
        >
          {header_type?.includes("Edit") ? t("update") : t("create")}
        </Button>
      ]}
    >
      <DepartmentForm initialValues={initialValues} />
    </Modal>
  );
};

export default CustomModal;
