import { ROUTES } from "@views/routes/my_routes";
import { Button, Empty, Modal, Spin, message } from "antd";
import {
  Box,
  Card,
  Center,
  HStack,
  Pressable,
  SimpleGrid,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import { AiFillExclamationCircle, AiOutlineEdit } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { RiCoinsFill, RiSurveyLine } from "react-icons/ri";
import { navigate, navigateWithProps, retrieveItem } from "@helpers/functions";
import {
  get_all_survey,
  get_survey_questions,
  survey_result,
} from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { MethodType } from "@helpers/service_call";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { FcSurvey } from "react-icons/fc";
import { GiProgression } from "react-icons/gi";
import SkeletonLoading from "../ui/skeleton_loading/skeleton_loading";
import { FaMedal } from "react-icons/fa";
import { survey_publish } from "@services/redux/slices/dynamic_entity/rest/rest_publish";
const UserSurveyList = () => {
  const history = useHistory();
  const role = retrieveItem("user");
  const [publish_modal, setPublishModal] = useState(false);
  const [actionItem, setActionItem] = useState({});

  const [index, set_index] = useState();
  const dispatch = useDispatch();

  const { items: survey_list, loading: survey_list_loading } =
    useDynamicSelector("get_all_survey");

  const {
    info,
    is_allowed_to_publish,
    loading: survey_loading,
  } = useDynamicSelector("survey_result");

  const {
    status: publish_status,
    error: publish_error,
    loading: publish_loading,
  } = useDynamicSelector("survey_publish");

  const handle_question_view = (value) => {
    // history.push({
    //   pathname: `${ROUTES.SECTOR_LIST}/${value.id}`,
    // });
    navigateWithProps(`${ROUTES.SECTOR_LIST}/${value.id}`, { survey: value });
  };
  const handleListClick = (value) => {
    set_index(value);
  };

  const handel_sector_view = (value) => {
    navigateWithProps(`${ROUTES.USER_SECTOR_AND_ANSWER_VIEW}/${value.id}`, {
      survey: value,
    });
  };

  const handle_district_view = (value) => {
    navigate(`${ROUTES.SECTOR_DISTRICT_VIEW}/${value?.id}`);
  };
  const user = retrieveItem("user");

  const handle_publish_modal = (x) => {
    console.log("record", x);
    get_survey_result(x);
    setActionItem(x);
    setPublishModal(true);
  };

  const handle_close = () => {
    setActionItem({});
    setPublishModal(false);
  };

  const get_survey_result = (x) => {
    let keys = [{ key: "survey_result", loading: true }];
    dispatch(dynamicRequest(keys, survey_result + x.id, {}, MethodType.GET));
  };

  const handle_publish = () => {
    let keys = [{ key: "survey_publish", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        survey_publish,
        { survey_id: actionItem?.id },
        MethodType.POST
      )
    );
  };

  const handleRedirectMap = (x) => {
    console.log("handleRedirectMap", x);
    navigateWithProps(`${ROUTES.SECTORAL_RANKING_SURVEY_MAP}/${x.id}}`, {
      item: x,
      is_survey_view: true,
      survey_id: x.id,
    });
  };

  const get_surveys = (data) => {
    let keys = [{ key: "get_all_survey", loading: true }];
    dispatch(dynamicRequest(keys, get_all_survey, {}, MethodType.POST));
  };

  useEffect(() => {
    if (publish_status == "success") {
      message.success("Result published successfully");
      handle_close();
      dispatch(dynamicClear("survey_publish"));
    } else if (publish_status === "failure") {
      message.error("something went wrong ");
      dispatch(dynamicClear("survey_publish"));
    } else if (publish_error) {
      message.error("something went wrong ");
      dispatch(dynamicClear("survey_publish"));
    }
  }, [publish_status, publish_error]);

  useEffect(() => {
    // get_survey_questions_values()
    get_surveys();
  }, []);

  return (
    <div style={{ paddingLeft: "10px", marginTop: "10px" }}>
      {survey_list_loading ? (
        <Center>
          <SkeletonLoading size="default" />
        </Center>
      ) : (
        <>
          {survey_list?.length !== 0 ? (
            <SimpleGrid
              columns={{
                base: 2,
                sm: 1,
                xs: 1,
                md: 2,
                lg: 2,
                xl: 3,
                "2xl": 3,
                "3xl": 3,
              }}
              spacingX={10}
              spacingY={10}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {survey_list?.map((x, index1) => (
                <Pressable
                  onPress={() => {
                    handle_question_view(x);
                  }}
                >
                  <Card
                    key={x.id}
                    style={{
                      width: "350px",
                      minHeight: "165px",
                      position: "relative",
                      borderRadius: "20px",
                      backgroundColor: index1 === index ? "#D7EDF7" : "",
                      zIndex: 10,
                    }}
                    onMouseEnter={() => handleListClick(index1)}
                    onMouseLeave={() => handleListClick(null)}
                  >
                    <HStack justifyContent="center" alignItems="center" h="20%">
                      <Box flex={1} textAlign="center">
                        {/* <Text bold fontSize={"lg"}>
                  {x.year}
                </Text> */}
                      </Box>
                      <Box position="absolute" top="0" right="0">
                        {/* <Pressable onPress={() => handle_question_view(x)}>
                          <RiSurveyLine size={"25"} color={"#1c326e"} />
                        </Pressable> */}
                        <HStack
                          space={"20px"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          {x.status === "Completed" ? (
                            role.name === "State DES User" && (
                              <Text fontSize={"12px"} color={"green.600"} bold>
                                Published
                              </Text>
                            )
                          ) : (
                            <>
                              {role.name === "State DES User" && (
                                <Pressable
                                  onPress={() => {
                                    handle_publish_modal(x);
                                  }}
                                >
                                  <AiFillExclamationCircle size={18} />
                                </Pressable>
                              )}
                            </>
                          )}
                          {role.name === "State DES User" && (
                            <Pressable
                              onPress={() => {
                                handleRedirectMap(x);
                              }}
                            >
                              <FaMedal size={18} />
                            </Pressable>
                          )}
                        </HStack>
                      </Box>

                      {role?.name === "District DES User" && (
                        <Box position="absolute" top="0" right="10">
                          <Pressable
                            onPress={() => {
                              handel_sector_view(x);
                            }}
                          >
                            <GiProgression
                              size={"25"}
                              color={index1 === index ? "#1c326e" : ""}
                            />
                          </Pressable>
                        </Box>
                      )}
                    </HStack>
                    <Box
                      flex={1}
                      textAlign="center"
                      justifyContent={"center"}
                      alignItems={"center"}
                      paddingTop={"-10%"}
                    >
                      <Text
                        bold
                        fontSize={"23px"}
                        color={index1 === index ? "#1c326e" : "#1c326e"}
                      >
                        {x?.name}
                      </Text>
                      <Text
                        bold
                        fontSize={"18px"}
                        color={index1 === index ? "#1c326e" : "#1c326e"}
                      >
                        {x?.year}
                      </Text>
                    </Box>
                    <HStack
                      width={"100%"}
                      space={"10px"}
                      justifyContent={"center"}
                      mt={"20px"}
                      zIndex={100000}
                    >
                      {/* {index1 === index && (
                        <>
                          <Button
                            onClick={() => {
                              handle_district_view(x);
                            }}
                            type="text"
                            style={{ backgroundColor: "white" }}
                          >
                            District view
                          </Button>
                          <Button
                            onClick={() => {
                              handle_question_view(x);
                            }}
                            type="text"
                            style={{ backgroundColor: "white" }}
                          >
                            Question view
                          </Button>
                        </>
                      )} */}
                    </HStack>
                  </Card>
                </Pressable>
              ))}
            </SimpleGrid>
          ) : (
            <Empty description="Empty Survey" />
          )}

          <Modal
            title="Confirmation"
            open={publish_modal}
            onCancel={handle_close}
            footer={
              <>
                <Button key="cancel" onClick={handle_close} danger>
                  Cancel
                </Button>
                {is_allowed_to_publish === true ? (
                  <Button
                    onClick={handle_publish}
                    type="primary"
                    loading={publish_loading}
                  >
                    Publish
                  </Button>
                ) : (
                  <Button
                    onClick={handle_publish}
                    type="primary"
                    loading={publish_loading}
                    disabled
                  >
                    Publish
                  </Button>
                )}
              </>
            }
          >
            <>
              {survey_loading ? (
                <Center>
                  <Spin size="large" />
                </Center>
              ) : (
                <VStack space={"20px"}>
                  <Text fontSize={"14px"}>
                    Are you sure you want to publish this{" "}
                    {<b>{actionItem?.name}</b>} indicator?
                  </Text>
                  <Text fontSize={"14px"}>{info}</Text>
                  {is_allowed_to_publish === false ? (
                    <Text fontSize={"14px"} color="red.400">
                      Please publish all available sectors in this survey to
                      publish this survey
                    </Text>
                  ) : (
                    ""
                  )}
                </VStack>
              )}
            </>
          </Modal>
        </>
      )}
    </div>
  );
};

export default UserSurveyList;
