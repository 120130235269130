import { Button, Card, Modal, Select, message } from "antd";
import { Box, HStack, Pressable, SimpleGrid, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../common/custom_page_title";
import { useParams } from "react-router-dom";
import { FiEdit, FiPlus } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import {
  create_area,
  get_all_area,
} from "@services/redux/slices/dynamic_entity/rest/rest_area";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { MethodType } from "@helpers/service_call";
import AreaChildFormForm from "./area_child_level_form";
import { get_all_level } from "@services/redux/slices/dynamic_entity/rest/rest_levels";
import lodash from "lodash";
import { navigateWithProps } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ChildAreaList = (props) => {
  const { id, level_id, action_type } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCardHovered, setCardHovered] = useState(false);
  const [actiontype, set_action_type] = useState("");
  const [selectedList, setSelectedList] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [add_one_list_modal, set_add_one_list_modal] = useState(false);
  const [form_values, set_form_values] = useState({});
  const [level_value, set_level_value] = useState({});
  const [area_level_list, set_area_level_list] = useState([]);
  const { state } = useLocation();
  const { items, loading } = useDynamicSelector("get_all_area");
  const { status: create_area_status, error: create_area_error } =
    useDynamicSelector("create_area");

  const { status: update_area_status, error: update_area_error } =
    useDynamicSelector("update_area");
  const { items: levels } = useDynamicSelector("get_all_level");
  const handle_edit = (record) => {
    set_action_type("edit");
    set_level_value({});
    set_form_values(record);
    set_add_one_list_modal(true);
  };

  const handle_delete = (record) => {};

  const handleListClick = (list) => {
    setSelectedList(list === selectedList ? null : list);
  };

  const handleRowClick = (index) => {
    setActiveRow(activeRow === index ? null : index);
  };
  const handle_level_add = (record) => {
    set_action_type("add");
    set_level_value(record);
    set_add_one_list_modal(true);
  };
  const handle_close = () => {
    set_add_one_list_modal(false);
    set_form_values({});
  };
  const get_all_area_function = () => {
    let keys = [{ key: "get_all_area", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_area + id,
        { parent_area_id: id },
        MethodType.GET
      )
    );
  };
  const get_all_level_list = (data) => {
    let keys = [{ key: "get_all_level", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_level,
        { parent_level_id: level_id },
        MethodType.POST
      )
    );
  };
  useEffect(() => {
    get_all_area_function();
    get_all_level_list();
  }, [level_id]);

  useEffect(() => {
    set_area_level_list(items);
  }, [items]);
  useEffect(() => {
    if (create_area_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("create_area"));
    } else if (create_area_status === "success") {
      message.success(t("Area created successfully"));
      get_all_area_function();
      handle_close();
      dispatch(dynamicClear("create_area"));
    }
  }, [create_area_status, create_area_error]);

  useEffect(() => {
    if (update_area_error) {
      message.error(t("something_went_wrong"));
      dispatch(dynamicClear("update_area"));
    } else if (update_area_status === "success") {
      message.success(t("Area updated successfully"));
      get_all_area_function();
      handle_close();
      dispatch(dynamicClear("update_area"));
    }
  }, [update_area_status, update_area_error]);

  const handleAddLevel = (level_id) => {
    if (level_id) {
      let temp = {
        name: levels?.find((x) => x.id === level_id)?.name,

        level_id: level_id,

        areas: [],
      };
      set_area_level_list([...area_level_list, temp]);
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <CustomPageTitle
        title={action_type?.includes("edit") ? "Update area" : ""}
      />

      <Box width={"200px"} ml="5">
        <Text>Levels</Text>
        <Select
          allowClear
          onChange={(e) => {
            handleAddLevel(e);
          }}
        >
          {levels?.map((item, index) => {
            let value = lodash.get(item, "id", null);
            let label = lodash.get(item, "name", null);
            return (
              <Option value={value} key={index}>
                {label}
              </Option>
            );
          })}
        </Select>
      </Box>
      <HStack justifyContent={"flex-end"}>
        <Box mr={"4"}>
          <Button
            onClick={() => {
              history.goBack();
            }}
            danger
          >
            Back
          </Button>
        </Box>
      </HStack>
      <SimpleGrid
        columns={{
          base: 2,
          sm: 1,
          xs: 1,
          md: 2,
          lg: 2,
          xl: 2,
          "2xl": 2,
          "3xl": 2,
        }}
        space={"50px"}
        // justifyContent={"space-between"}
        marginLeft={"60px"}
        // marginRight={"60px"}
        // width={"100%"}
        mt={"20px"}
        height={"100vh"}
      >
        {area_level_list?.map((list, index) => {
          return (
            <Card
              style={{ width: "35vw" }}
              bordered
              onMouseEnter={() => handleListClick(index)}
              onMouseLeave={() => handleListClick(null)}
            >
              <HStack justifyContent={"space-between"} mb={"20px"}>
                <Box w={"80px"}>
                  <Text bold fontSize={"20px"}>
                    {"S.No"}
                  </Text>
                </Box>
                <Box width={"350px"}>
                  <Text bold fontSize={"20px"}>
                    {list?.name}
                  </Text>
                </Box>
                <Box width={"50px"}>
                  {selectedList === index ? (
                    <HStack space={"20px"}>
                      <Pressable
                        onPress={() => {
                          handle_level_add(list);
                        }}
                      >
                        <FiPlus size={18} />
                      </Pressable>
                    </HStack>
                  ) : (
                    <HStack width={"95px"}></HStack>
                  )}
                </Box>
              </HStack>

              {list?.areas?.map((area, index) => {
                return (
                  <HStack
                    justifyContent={"space-between"}
                    padding={"10px"}
                    borderBottomWidth={index + 1 === list?.areas.length ? 0 : 1}
                    borderColor={"gray.200"}
                    onMouseEnter={() => handleRowClick(area?.id)}
                    onMouseLeave={() => handleRowClick(null)}
                    key={index}
                  >
                    <Box w={"80px"}>
                      <Text>{index + 1}.</Text>
                    </Box>
                    <Box flex={1}>
                      <Text textAlign={"start"}>{area?.name}</Text>
                    </Box>
                    <Box width={"100px"}>
                      {activeRow === area?.id ? (
                        <HStack space={"20px"}>
                          <Pressable
                            onPress={() => {
                              handle_edit(area);
                            }}
                          >
                            <FiEdit size={18} />
                          </Pressable>
                          <Pressable
                            onPress={() => {
                              handle_delete(list);
                            }}
                          >
                            <AiFillDelete size={18} />
                          </Pressable>
                        </HStack>
                      ) : (
                        <HStack width={"50px"}> </HStack>
                      )}
                    </Box>
                  </HStack>
                );
              })}
            </Card>
          );
        })}
      </SimpleGrid>

      <Modal
        open={add_one_list_modal}
        onCancel={handle_close}
        footer={null}
        title={form_values?.id ? "Update Area" : `Add ${level_value?.name} `}
      >
        <AreaChildFormForm
          areas_form_values={form_values}
          level_value={level_value}
          onCancel={handle_close}
          sub_areas={form_values?.name}
          parent_area_id={id}
          action_type={actiontype}
        />
      </Modal>
    </div>
  );
};

export default ChildAreaList;
