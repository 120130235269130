import React from "react";
import FormSno from "./form_sno";
import FormName from "./form_name";
import FormDescription from "./form_description";
import { Form } from "antd";
import FormLogo from "./form_logo";
import { departments } from "@helpers/config";
import { CallService, MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import {
  department_create,
  department_update,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";

const DepartmentForm = ({ initialValues }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const on_finish = (values) => {
    values.logo = values?.logo?.[0].url;
    if (initialValues?.id) {
      let key = [{ key: "department_update", loading: true }];
      values.id = initialValues.id;
      delete values.id;
      let query = `${department_update}${initialValues.id}`;
      let variables = values;
      dispatch(dynamicRequest(key, query, variables, MethodType.PATCH));
    } else {
      let key = [{ key: "department", loading: true }];
      let query = department_create;
      let variables = values;
      dispatch(dynamicRequest(key, query, variables, MethodType.PUT));
    }
  };
  const handle_change_values = (val, values) => {};
  return (
    <Form
      id="department_form"
      onFinish={on_finish}
      form={form}
      initialValues={initialValues}
      onValuesChange={handle_change_values}
    >
      {/* <FormSno /> */}
      <FormName />
      <FormDescription />
      <FormLogo />
    </Form>
  );
};

export default DepartmentForm;
