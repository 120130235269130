import { Button, Input, Table } from "antd";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_all_question } from "@services/redux/slices/dynamic_entity/rest/rest_question";
import { MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import { navigateWithProps } from "@helpers/functions";
import { process_report_based_on_district } from "@services/redux/slices/dynamic_entity/rest/process";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { paginationOptions } from "@helpers/constants";
import CustomButton from "../common/custom_button";

const DistrictWiseReport = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { items: process_list, loading: process_loading } = useDynamicSelector(
    "process_report_based_on_district"
  );
  const XLSX = require("xlsx");

  const { Search } = Input;

  const column = [
    {
      title: t("table:sector"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("table:no_of_Indicator"),
      children: [
        {
          title: t("table:allocated"),
          dataIndex: "allocated_count",
          key: "allocated_count",
          align: "center",
        },
        {
          title: t("table:pending"),
          dataIndex: "pending_count",
          key: "pending_count",
          align: "center",
        },
        {
          title: t("table:completed"),
          dataIndex: "completed_count",
          key: "completed_count",
          align: "center",
        },
        {
          title: t("table:reverted"),
          dataIndex: "reverted_count",
          key: "reverted_count",
          align: "center",
        },
      ],
    },
  ];

  const handle_close = () => {
    set_delete_modal(false);
    set_form_values({});
  };

  const get_all_process_list = (data) => {
    let keys = [{ key: "process_report_based_on_district", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        process_report_based_on_district,
        { search_string: data },
        MethodType.POST
      )
    );
  };
  useEffect(() => {
    get_all_process_list();
  }, []);

  const onSearch = (value) => {
    let search_value = value?.target?.value || value;
    if (search_value?.length > 2) get_all_process_list(search_value);
    else if (!search_value?.length) get_all_process_list(null);
  };
  const handle_download_excel_report = () => {
    let inputFormat = process_list?.map((x, index) => {
      return [
        index + 1,
        x.name,
        x.allocated_count,
        x.pending_count,
        x.completed_count,
        x.reverted_count,
      ];
    });

    const worksheet = XLSX.utils.aoa_to_sheet([
      ["S.No", "Name", "Number of Indicators"],
      [
        "",
        "",
        "Allocated count",
        "Pending count",
        "Completed count",
        "Reverted count",
      ],
      ...inputFormat,
    ]);

    const workbook = XLSX.utils.book_new();
    const columnWidths = [
      { wch: 5 }, // Width for S.No column
      { wch: 40 }, // Width for Name column
      { wch: 15 }, // Width for Allocated count column
      { wch: 15 }, // Width for Submitted count column
      { wch: 15 }, // Width for Submitted count column
      { wch: 15 }, // Width for Submitted count column
    ];
    worksheet["!cols"] = columnWidths;
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },

      { s: { r: 0, c: 2 }, e: { r: 0, c: 5 } }, //
    ];

    worksheet["!merges"] = merges;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "Pendency Report.xlsx");
  };

  return (
    <div>
      <VStack padding={"20px"}>
        <HStack justifyContent={"flex-end"} mb={"20px"} space={"20px"}>
          {/* <Search
            enterButton={true}
            onSearch={onSearch}
            allowClear={true}
            onChange={onSearch}
            placeholder="Search"
            style={{ width: "250px" }}
          /> */}
          <CustomButton
            button_name="Download"
            onclick_button={handle_download_excel_report}
          />
        </HStack>
        <Table
          columns={column}
          dataSource={process_list}
          loading={process_loading}
          pagination={paginationOptions}
        />
      </VStack>
    </div>
  );
};

export default DistrictWiseReport;
