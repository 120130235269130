import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import {
  dynamicClear,
  dynamicRequest,
  get_all_department,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { MethodType } from "@helpers/service_call";
import { get_all_area } from "@services/redux/slices/dynamic_entity/rest/rest_area";

const FormArea = ({ form }) => {
  const dispatch = useDispatch();
  let level_id = Form.useWatch("level_id", form);
  const { items: area_list, loading: question_loading } =
    useDynamicSelector("get_all_area");
  const get_all_area_list = (data) => {
    let keys = [{ key: "get_all_area", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_area,
        { level_id: level_id },
        MethodType.POST
      )
    );
  };

  useEffect(() => {
    get_all_area_list();
  }, []);
  return (
    <Form.Item
      name="area_id"
      label="Area"
      rules={[
        {
          required: true,
          message: "Area is monitory",
        },
      ]}
    >
      <Select
        style={{ fontSize: "16px" }}
        allowClear
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {area_list?.map((option, index) => (
          <Select.Option key={option?.data?.id} value={option?.data?.id}>
            {option?.data?.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FormArea;
