import { Input, Table, Typography } from 'antd'
import React, { useEffect } from 'react'
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from 'react-redux';
import { get_all_survey } from "@services/redux/slices/dynamic_entity/rest/rest_survey";
import { MethodType } from "@helpers/service_call";
import { HStack, Pressable, VStack } from "native-base"
import { IoMdEye } from "react-icons/io";
import { navigateWithProps } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";


const RankingSector = () => {
    const dispatch = useDispatch()
    const { Title } = Typography;
    const { items, loading } = useDynamicSelector("get_all_survey")
    const { Search } = Input;

    const handle_redirect_sector = (values) => {
        navigateWithProps(`${ROUTES.SECTORAL_RANKING_SECTOR}/${values?.id}`, { survey: values })
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Year",
            dataIndex: "year",
            key: "year"
        },
        {
            title: "Action",
            dataIndex: "",
            key: "",
            render: (record) => (
                <Pressable onPress={() => handle_redirect_sector(record)}>
                    <IoMdEye size={20} />
                </Pressable>
            )
        },
    ]
    const get_all_survey_values = (data) => {
        let keys = [{ key: "get_all_survey", loading: true }];
        dispatch(dynamicRequest(keys, get_all_survey, { search_string: data }, MethodType.POST));
    };
    useEffect(() => {
        get_all_survey_values()
    }, [])

    const onSearch = (value) => {
        let search_value = value?.target?.value || value
        if (search_value?.length > 2 || search_value?.length === 0)
            get_all_survey_values(search_value)
    }

    return (
        <div style={{ padding: "10px" }}>
            <HStack>

                <Title
                    style={{
                        fontSize: "23px",
                        fontWeight: "600",
                        margin: "10px",
                        color: "#042446",
                        marginTop: "20px",
                        textShadow: "0.5px 1px 0.5px gray"
                    }}
                >
                    Sectoral ranking
                </Title>
            </HStack>
            <VStack padding={"20px"}>
                <HStack justifyContent={"flex-end"} mb={"20px"} space={"20px"}>
                    <Search
                        enterButton={true}
                        onSearch={onSearch}
                        allowClear={true}
                        onChange={onSearch}
                        placeholder="Search"
                        style={{ width: '250px' }}
                    />

                </HStack>
                <Table columns={columns} dataSource={items} loading={loading} />
            </VStack>
        </div>
    )
}

export default RankingSector
