import { MethodType } from '@helpers/service_call';
import { dynamicClear, dynamicRequest, get_all_department, useDynamicSelector } from '@services/redux';
import { update_level } from '@services/redux/slices/dynamic_entity/rest/rest_levels';
import { Button, Form, Input, Select, message } from 'antd'
import { HStack, Pressable, Text } from 'native-base';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { CiCircleMinus } from "react-icons/ci";
import { useDispatch } from 'react-redux';

const LevelToMapDepartment = ({ form_values, onCancel, get_level_list }) => {
    const [todoList, setTodoList] = useState([
        {
            department_id: "",
        },
    ]);
    const [show_department, set_show_department] = useState(false)
    const { t } = useTranslation();
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const department_list = Form.useWatch("department_id", form)
    const { items: departments, loading: department_list_loading } = useDynamicSelector("get_all_department");
    const { status: update_status, loading: update_loading, error: update_error } = useDynamicSelector("update_level");


    const handleAddTodo = () => {
        setTodoList((prevTodoList) => [
            ...prevTodoList,
            {
                department_id: "",
            },
        ]);
    };

    const handleRemoveTodo = (index) => {
        setTodoList((prevTodoList) => {
            const updatedTodoList = [...prevTodoList];
            updatedTodoList.splice(index, 1);
            return updatedTodoList;
        });
    };
    const handle_show_department = () => {
        set_show_department(!show_department)
    }
    const get_all_department_values = (data) => {
        let keys = [{ key: "get_all_department", loading: true }];
        dispatch(dynamicRequest(keys, get_all_department, {}, MethodType.POST));
    };

    useEffect(() => {
        get_all_department_values();
    }, []);

    const handle_update = (value) => {
        // let department_list = value.department_id.map((list => ({ id: list })))
        let keys = [{ key: "update_level", loading: true }];
        dispatch(dynamicRequest(keys, update_level + form_values?.id, { department_id: value?.department_id || null }, MethodType.PATCH));
    }

    useEffect(() => {
        if (form_values?.department_id)
            form.setFieldsValue(form_values)
        else
            form.resetFields()
    }, [form_values])

    useEffect(() => {
        if (update_status === "success") {
            message.success(form_values?.name + "  " + t("level_updated"))
            dispatch(dynamicClear("update_level"))
            handle_close()
            get_level_list()
        } else if (update_error) {
            message.error(t("something_went_wrong"))
        }
    }, [update_status, update_error])
    
    const handle_close = () => {
        onCancel()
        form.resetFields()
    }

    return (
        <div>
            <Form form={form} onFinish={handle_update}>
                <Form.Item name={"department_id"} label={"Department"} >
                    <Select mode='single' showArrow showSearch loading={department_list_loading} allowClear>
                        {departments?.map((option) => (
                            <Select.Option key={option?.id} value={option?.id}>
                                {option?.name}
                            </Select.Option>
                        ))}
                    </Select>

                </Form.Item>
                {/* {department_list?.map((list) => {
                    return (
                        <>
                            <Pressable onPress={handle_show_department}>
                                <Text color={"#0a2773"} textDecorationLine={"underLine"}>
                                    {t("approving_authorities")}
                                </Text>
                            </Pressable>
                            {show_department &&
                                <>
                                    <HStack alignItems={"center"} justifyContent={"space-between"} mt={"10px"} mb={"30px"} width={"50%"}>
                                        <Text>
                                            {t("postings")}
                                        </Text>
                                        <Button
                                            onClick={handleAddTodo}
                                            type='primary'
                                        >
                                            {t("add")}
                                        </Button>
                                    </HStack>

                                    {todoList?.map((item, index) => (
                                        <div key={index}>

                                            <HStack
                                                space={"30px"}
                                                key={index}

                                            >
                                                <Text mt={"8px"} bold>
                                                    {`${index + 1}. `}
                                                </Text>
                                                <Form.Item name={[
                                                    "department",
                                                    `${index}`,
                                                    `position`,
                                                ]}>
                                         
                                                    <Input />
                                                </Form.Item>

                                                {todoList.length - 1 === index && (
                                                    <Button
                                                        onClick={() => handleRemoveTodo(index)}
                                                        danger
                                                        type='text'
                                                    >
                                                        <CiCircleMinus size={20} />
                                                    </Button>
                                                )}
                                            </HStack>
                                        </div>
                                    ))}
                                </>}
                        </>
                    )
                })} */}
                <HStack space={"20px"} justifyContent={"flex-end"} >
                    <Button type='primary' htmlType='submit' loading={update_loading}>
                        Submit
                    </Button>
                    <Button danger onClick={handle_close}>
                        Close
                    </Button>
                </HStack>
            </Form>
        </div>
    )
}

export default LevelToMapDepartment
