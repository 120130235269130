import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Modal } from "antd";

const DocumentViewer = (props) => {
  const { title, isOpen, onClose, fileUrl } = props;
  const docs = [
    //  { uri: require("./file.pdf") },
    // {
    //   uri: "https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK",
    // },
    {
      uri: fileUrl,
    },
  ];
  const MyHeader = (state, previousDocument, nextDocument) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null;
    }

    return <></>;
  };

  return (
    <Modal title={title} open={isOpen} onCancel={onClose} footer={null}>
      <DocViewer
        prefetchMethod="GET"
        documents={docs}
        initialActiveDocument={docs[1]}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            overrideComponent: MyHeader,
          },
        }}
      />
    </Modal>
  );
};
export default DocumentViewer;
